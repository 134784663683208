import { statsActions, statsTypes } from '../ducks/stats';

const statsData = ({ dispatch, getState }) => next => (action) => {
  next(action);
  if (action.type !== statsTypes.FETCH_STATS) {
    return;
  }
  const {
    auth: {
      user: { user_id, b2b: { type_user, roles } }
    },
  } = getState() || {};

  const {
    name = '',
    document_number = '',
    email = '',
    min_amount = '',
    max_amount = '',
    callback = () => {
    },
  } = action.payload;

  let userId = 0;
  let band = false;
  if (roles.length > 1) {
    if (roles[0].owner_id != null) {
      userId = roles.map((rol, index) => ({
        ...rol,
        index
      }),)
        .filter(documentTypeFil => documentTypeFil.name === type_user)[0].index;
    } else {
      band = true;
    }
  }

  let url = '';
  switch (type_user) {
    case 'issuing_bank':
      url = `b2b/referred-buyers?bank=${band ? user_id : roles[userId].owner_id}&name=${name}&document_number=${document_number}&email=${email}&min_amount=${min_amount}&max_amount=${max_amount}&unique_role=${band}`;
      break;
    case 'issuing_query':
      url = `b2b/referred-buyers?bank=${band ? user_id : roles[userId].owner_id}&name=${name}&document_number=${document_number}&email=${email}&min_amount=${min_amount}&max_amount=${max_amount}&unique_role=${band}`;
      break;
    case 'acquiring_bank':
      url = `b2b/referred-sellers?bank=${band ? user_id : roles[userId].owner_id}&name=${name}&document_number=${document_number}&email=${email}&min_amount=${min_amount}&max_amount=${max_amount}&unique_role=${band}`;
      break;
    case 'acquiring_query':
      url = `b2b/referred-sellers?bank=${band ? user_id : roles[userId].owner_id}&name=${name}&document_number=${document_number}&email=${email}&min_amount=${min_amount}&max_amount=${max_amount}&unique_role=${band}`;
      break;
    case 'buyer':
      url = `b2b/buyer-stats?buyer_id=${user_id}&days=60`;
      break;
    case 'seller':
      url = `b2b/seller-stats?seller_id=${user_id}&days=60`;
      break;
    default: {
      url = '';
    }
  }
  if (url !== '') {
    dispatch({
      type: statsTypes.API_CALL,
      payload: {
        config: {
          method: 'GET',
          url,
        },
        authorization: true,
        onStart: statsActions.startFetch,
        onComplete: ({ data: responseData }) => {
          const { success, data } = responseData;
          if (success) {
            callback(data);
            dispatch(statsActions.updateStats({
              ...data,
            }));
          }
        },
        onEnd: statsActions.endFetch,
      },
    });
  }
};

const getRelatedUsers = ({ dispatch, getState }) => next => (action) => {
  next(action);

  if (action.type !== statsTypes.FETCH_RELATED_USERS) {
    return;
  }

  const {
    role = '',
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: statsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/related-users?role=${role}`,
      },
      authorization: true,
      onStart: statsActions.startFetch,
      onComplete: ({ data }) => {
        callback(data.data);
        dispatch(statsActions.updateRelatedUsers(data.data));
      },
      onEnd: statsActions.endFetch,
    },
  });
};

export default [statsData, getRelatedUsers];
