import React from 'react';
import PropTypes from 'prop-types';

const Form = ({
  className,
  onSubmit,
  children,
  submitText,
  buttonClasses,
  disabled,
}) => (
  <div className={ `Form ${className}` }>
    <form
      action=""
      onSubmit={ onSubmit }
    >
      { children }
      <div
        className="field is-grouped is-grouped-right"
      >
        <button
          type="submit"
          className={ `button is-block is-primary ${buttonClasses}` }
          disabled={ disabled }
        >
          { submitText }
        </button>
      </div>
    </form>
  </div>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  buttonClasses: '',
  disabled: false,
};

export default Form;
