import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatCurrency, formatDate } from '../../util';
import './styles.scss';
import DateInput from '../../components/DateInput';
import { TOAST_CONFIG } from '../../config/constants';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    downloadCsv: [],
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
  });

  componentDidMount() {
    this.rangeDate();
    /*const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { b2b: { id, type_user } } },
    } = this.props;
    if (type_user === 'issuing_bank') {
      listTransactionsReceiver({});
    }
    if (type_user === 'buyer') {
      listTransactionsSender({ params: { user_id: id } });
    }
    if (type_user === 'acquiring_bank') {
      listTransactionsSender({});
    }
    if (type_user === 'seller') {
      listTransactionsReceiver({ params: { user_id: id } });
    }*/
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
      transactions: { sender, receiver },
    } = this.props;
    let columns = null;
    let data = null;
    let title = null;
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'providerRUC', // Required because our accessor is not a string
            Header: 'RUC '+t('provider'),
            accessor: d => `${d.receiver.idPerson.document_number}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'sendInvoice', // Required because our accessor is not a string
            Header: t('sendInvoice'),
            accessor: d => formatDate(d.date),
          },
          {
            id: 'chargeDate', // Required because our accessor is not a string
            Header: t('chargeDate'),
            accessor: d => d.receiver_status_id === 'PAP' ? '' : formatDate(d.due_date),
          },
          {
            id: 'approvalDate', // Required because our accessor is not a string
            Header: t('approvalDate'),
            accessor: d => d.approved_date === null ? '' : formatDate(d.approved_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => `${formatCurrency(d.adjusted_amount)}`,
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'clientRUC', // Required because our accessor is not a string
            Header: 'RUC '+t('client'),
            accessor: d => `${d.sender.idPerson.document_number}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.receiverStatus.description}`,
          },
        ];
        data = sender;
        title = t('admin');
        break;
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'document_number', // Required because our accessor is not a string
            Header: t('providerRUC'),
            accessor: d => `${d.receiver.idPerson.document_number}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('invoiceSend'),
            headerClassName: 'wordwrap',
            //accessor: d => formatDate(d.date),
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            accessor: 'approved_date',
            Header: t('approvalDate'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.sender_status_id !== 'REC' && cellInfo.original.approved_date !== null ?
                  formatDate(cellInfo.original.approved_date) :
                  ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (row._original.approved_date !== null) {
                if (!filter.value.match(/[\d/]+$/gm)) {
                  return true;
                }
                if (formatDate(row._original.approved_date)
                  .includes(filter.value)) {
                  return formatDate(row._original.approved_date);
                }
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'rec_date', // Required because our accessor is not a string
            accessor: 'rec_date',
            Header: t('rejectionDate'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.sender_status_id === 'REC' && cellInfo.original.rec_date !== null ?
                  formatDate(cellInfo.original.rec_date) :
                  ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (row._original.rec_date !== null) {
                if (!filter.value.match(/[\d/]+$/gm)) {
                  return true;
                }
                if (formatDate(row._original.rec_date)
                  .includes(filter.value)) {
                  return formatDate(row._original.rec_date);
                }
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('ccChargeDate'),
            headerClassName: 'wordwrap',
            accessor: d => d.senderStatus.id !== 'REC' ? formatDate(d.due_date) : '',
          },
          {
            id: 'card_collection_date', // Required because our accessor is not a string
            Header: t('maximumPaymentDate'),
            headerClassName: 'wordwrap',
            //accessor: d =>  d.senderStatus.id !== 'REC' && d.card_collection_date !== null ? formatDate(d.card_collection_date) : '',
            accessor: d => (d.senderStatus.id !== 'REC' && d.senderStatus.id !== 'PAP' && d.card_collection_date !== null) ? formatDate(d.card_collection_date) : '',
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => formatCurrency(d.adjusted_amount),
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            style: { 'whiteSpace': 'normal' },
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = sender;
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'clientRUC', // Required because our accessor is not a string
            Header: 'RUC ' + t('client'),
            accessor: d => `${d.sender.idPerson.document_number}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('shippingDate'),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.receiver_status_id === 'PAG' && cellInfo.original.paid_date !== null)
                  ? formatDate(cellInfo.original.paid_date) : formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
            /*
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (row[filter.id].includes(filter.value)) {
                return row[filter.id];
              }
            },
            Filter: ({filter, onChange}) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ""}
              />*/
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => `${formatCurrency(d.adjusted_amount)}`,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'providerRUC', // Required because our accessor is not a string
            Header: 'RUC '+t('provider'),
            accessor: d => `${d.receiver.idPerson.document_number}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            style: { 'whiteSpace': 'normal' },
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = receiver;
        title = t('customers');
        break;
      case 'seller':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'clientRUC', // Required because our accessor is not a string
            Header: 'RUC ' + t('client'),
            accessor: d => `${d.sender.idPerson.document_number}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('shippingDate'),
            headerClassName: 'wordwrap',
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'rec_date', // Required because our accessor is not a string
            Header: t('rejectionDate'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.receiver_status_id === 'REC' && cellInfo.original.rec_date !== null ?
                  formatDate(cellInfo.original.rec_date) :
                  ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (row._original.rec_date !== null) {
                if (!filter.value.match(/[\d/]+$/gm)) {
                  return true;
                }
                if (formatDate(row._original.rec_date)
                  .includes(filter.value)) {
                  return formatDate(row._original.rec_date);
                }
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            headerClassName: 'wordwrap',
            //accessor: 'pay_provider_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.receiver_status_id === 'PAG' && cellInfo.original.paid_date !== null)
                  ? formatDate(cellInfo.original.paid_date) : formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => `${formatCurrency(d.adjusted_amount)}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            style: { 'whiteSpace': 'normal' },
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = receiver;
        title = t('customers');
        break;
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'providerRUC', // Required because our accessor is not a string
            Header: 'RUC ' + t('provider'),
            accessor: d => `${d.receiver.idPerson.document_number}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('shippingDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.receiver_status_id === 'PAG' && cellInfo.original.paid_date !== null)
                  ? formatDate(cellInfo.original.paid_date) : formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => formatCurrency(d.adjusted_amount),
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'clientRUC', // Required because our accessor is not a string
            Header: 'RUC '+t('client'),
            accessor: d => `${d.sender.idPerson.document_number}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = sender;
        title = t('providers');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  download = () => {
    const {
      auth: { user: { b2b: { type_user } } },
      t
    } = this.props;
    const { sortedData, columns } = this.selectTable.current.getResolvedState();
    const { invoiceDate, invoiceDateUntil } = this.state;

    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].description = sortedData[i]._original.description;
      sortedData[i].totalPay = sortedData[i]._original.adjusted_amount;

      if (type_user === 'acquiring_query' || type_user === 'acquiring_bank' || type_user === 'issuing_query' || type_user === 'issuing_bank') {
        sortedData[i].date = formatDate(sortedData[i]._original.date);
        sortedData[i].clientRUC = sortedData[i].clientRUC + '\t';
        sortedData[i].providerRUC = sortedData[i].providerRUC + '\t';
      }

      if (type_user === 'seller' || type_user === 'acquiring_query' || type_user === 'acquiring_bank' || type_user === 'issuing_query' || type_user === 'issuing_bank') {
        sortedData[i].due_date = (sortedData[i]._original.receiver_status_id === 'PAG' && sortedData[i]._original.paid_date !== null)
          ? formatDate(sortedData[i]._original.paid_date) : formatDate(sortedData[i]._original.due_date);
      }

      if (sortedData[i]._original.receiver.seller === '1' || sortedData[i]._original.sender.buyer === '1') {
        type_user !== 'seller' ?
          sortedData[i].approved_date =
            (sortedData[i]._original.sender_status_id !== 'REC' && sortedData[i]._original.approved_date !== null) ?
              formatDate(sortedData[i]._original.approved_date) : ''
          :
          sortedData[i].date = formatDate(sortedData[i]._original.date);

        if (type_user === 'buyer') {
          sortedData[i].date = formatDate(sortedData[i]._original.date);
          sortedData[i].document_number = sortedData[i].document_number + '\t';
        }
        if (type_user === 'seller') {
          sortedData[i].clientRUC = sortedData[i].clientRUC + '\t';
        }

        sortedData[i].rec_date =
          (sortedData[i]._original.sender_status_id === 'REC' && sortedData[i]._original.rec_date !== null) ?
            formatDate(sortedData[i]._original.rec_date) : '';
      }
    }

    let columns1 = [];
    columns.forEach(function (column) {
      if (column.expander !== true) {
        columns1.push(column);
      }
    });

    const BOM = "\uFEFF";
    const downloadCsv = [[BOM]];
    downloadCsv.push([`${t('reportDate')}: ${formatDate(invoiceDate/1000)} - ${formatDate(invoiceDateUntil/1000)}`]);
    downloadCsv.push(columns1.map(column => column.Header));
    downloadCsv.push(...sortedData.map((sorted) => {
      const downloadCsvObject = {};
      columns1.forEach((column) => {
        downloadCsvObject[column.id] = sorted[column.id];
      });
      return Object.values(downloadCsvObject);
    }));
    this.setState({ downloadCsv }, () => {
      this.csvRef.current.link.click();
    });
  };

  handleDateChange = (dateName, newDate) => {
    if (dateName === 'invoiceDate' && newDate !== '') {
      newDate.setHours(0, 0, 0, 0);
    }
    if (dateName === 'invoiceDateUntil' && newDate !== '') {
      newDate.setHours(23, 59, 59, 999);
    }

    this.setState({
      [dateName]: newDate,
    });
  };

  rangeDate = () => {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      listTransactionAdmin,
      auth: { user: { b2b: { id, type_user } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      listTransactionAdmin({
        params: {
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
          is_admin: true,
        }
      });
    }
    if (type_user === 'issuing_bank' || type_user === 'issuing_query') {
      listTransactionsReceiver({
        params: {
          bank_id: true,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        },
      });
    }
    if (type_user === 'buyer') {
      listTransactionsSender({
        params: {
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        }
      });
    }
    if (type_user === 'acquiring_bank' || type_user === 'acquiring_query') {
      listTransactionsSender({
        params: {
          bank_id: true,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        },
      });
    }
    if (type_user === 'seller') {
      listTransactionsReceiver({
        params: {
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        }
      });
    }
  };

  getTitle = (typeUser) => {
    const {
      t
    } = this.props;

    switch (typeUser) {
      case 'buyer':
        return t('paymentDateRangeClient');
      case 'seller':
        return t('paymentDateRange');
      case 'admin_amex':
      case 'admin':
        return t('sendInvoice');
    }
  };

  render() {
    const {
      transactions: { loading },
      t,
      showMessage,
      auth: { user: { b2b: { type_user } } }
    } = this.props;
    const { downloadCsv, invoiceDate, invoiceDateUntil } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle} />
                    )
                  }
                  {data
                    && (
                    <div className="column is-full">
                      <Panel headingText={t('transactionReport')}>
                        <div className="field" style={{
                          margin: '0 2%',
                        }}>
                          <div className="field-body columns">
                            <div className="columns" style={{ width: '100%', alignItems: 'center' }}>
                              {
                                ['buyer', 'seller', 'admin', 'admin_amex',].includes(type_user) &&
                                  <p className="m-8px" style={{ fontSize: '1.25rem' }}>
                                    {this.getTitle(type_user)}
                                  </p>
                              }
                              <div className="column  is-one-quarter" style={{ zIndex: 99999 }}>
                                <DateInput
                                  name="invoice_date"
                                  label={t('since')}
                                  value={invoiceDate}
                                  onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="FaRegCalendarAlt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div className="column is-one-quarter" style={{ zIndex: 99999 }}>
                                <DateInput
                                  name="invoice_date"
                                  label={t('until')}
                                  value={invoiceDateUntil}
                                  onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="FaRegCalendarAlt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div style={{ marginTop: '20px' }}>
                                <button
                                  type="button"
                                  className="button is-primary"
                                  onClick={() => {
                                    invoiceDate === '' && invoiceDateUntil === '' ?
                                      showMessage({
                                        message: t('emptyFields'),
                                        config: TOAST_CONFIG.WARNING,
                                      })
                                      : invoiceDateUntil < invoiceDate ?
                                      showMessage({
                                        message: t('errorDate'),
                                        config: TOAST_CONFIG.WARNING,
                                      })
                                      :
                                      this.rangeDate();
                                  }}
                                >
                                  {t('consult')}
                                </button>
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        <div>
                          <button
                            className="button is-block is-primary"
                            onClick={this.download}
                          >
                            {`${t('download')} CSV`}
                          </button>
                          <CSVLink
                            data={downloadCsv}
                            filename="summary_b2b.csv"
                            className="hidden"
                            ref={this.csvRef}
                          />
                        </div>
                        {type_user === 'seller' &&
                          <div style={{margin: '1rem'}}>
                            <p>{t('nextDayConditions')}</p>
                          </div>
                        }
                        <TableSimple
                          data={data}
                          columns={columns}
                          reff={this.selectTable}
                          separator=","
                        />
                      </Panel>
                    </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Reports.propTypes = {
  t: PropTypes.func.isRequired,
  listTransactionAdmin: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions }) => (
  {
    auth,
    transactions,
  }
);
export default compose(
  connect(mapStateToProps, { ...transactionsActions }),
  withNamespaces(),
)(Reports);
