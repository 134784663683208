import React        from 'react';
import PropTypes    from 'prop-types';
import NumberFormat from 'react-number-format';
import TextInput    from '../TextInput';


const AmountFormat = ({
  value, currency, displayType, name, label, onChange, onBlur, controlClasses, inputClasses, fieldClasses, allowNegative, disabled
}) => {
  const numberStr = value ? value.toString() : '';
  const check1 = numberStr.indexOf(',');
  const check2 = numberStr.indexOf('.');
  let number = value;
  if (check1 > check2) {
    number = numberStr.replace(/[,a-zA-Z \$]/gi, '').replace(',', '.');
  }
  return (
    <NumberFormat
      thousandSeparator=","
      decimalSeparator="."
      customInput={TextInput}
      displayType={displayType}
      prefix={`${currency} `}
      value={number}
      decimalScale={2}
      allowNegative={allowNegative}
      fixedDecimalScale
      name={name}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      controlClasses="is-expanded has-icons-left"
      inputClasses="has-text-right"
      fieldClasses={fieldClasses}
      disabled={disabled}
      placeholder="00.00"
    />
  )
};

AmountFormat.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  currency: PropTypes.string,
  displayType: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  controlClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  fieldClasses: PropTypes.string,
  allowNegative: PropTypes.bool,
  disabled: PropTypes.bool,
};
AmountFormat.defaultProps = {
  currency: '',
  label: '',
  fieldClasses: '',
  onChange: () => {
  },
  onBlur: () => {
  },
  inputClasses: '',
  controlClasses: '',
  displayType: 'input',
  value: '0,00',
  allowNegative: false,
  disabled: false,
};

export default AmountFormat;