import { paymentConditionsActions, paymentConditionsTypes } from '../ducks/paymentConditions';
import { TOAST_CONFIG } from '../../config/constants';

const url = '/b2b-payment-conditions';
const savePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.CREATE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const editPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.EDIT_PAYMENT_CONDITION) {
    return;
  }

  const {
    id,
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `${url}/${id}`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const deletePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.DELETE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data: { id },
    callback = () => {
    },
    t = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-payment-conditions/delete-condition?id=${id}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: () => {
        callback();
        dispatch(paymentConditionsActions.showMessage({
          message: t('deletePaymentCondition'),
          config: TOAST_CONFIG.SUCCESS,
        }));
      },
      onError: () => {
        dispatch(paymentConditionsActions.showMessage({
          message: t('cantDelete'),
          config: TOAST_CONFIG.INFO,
        }));
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION) {
    return;
  }

  const {
    params: {
      idCondition = '',
      person = '',
      isClient = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/conditions-providers?person=${person}&isClient=${isClient}&idCondition=${idCondition}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.savePaymentCondition(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getDiscountRate = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_DISCOUNT_RATE) {
    return;
  }

  const {
    params: {
      client = '',
      provider = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-payment-conditions/get-discount-rate?client=${client}&provider=${provider}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.savePaymentCondition(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

export default [savePaymentCondition, editPaymentCondition, deletePaymentCondition, getPaymentCondition, getDiscountRate];
