/* eslint-disable no-undef */
import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import logger from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import * as reducers from './ducks';
import {
  api,
  auth,
  bank,
  card,
  clients,
  documentType,
  financingProducts,
  flowApproval,
  item,
  lead,
  messages,
  paymentConditions,
  role,
  route,
  stats,
  transactions,
  userProfile,
} from './middlewares';
import { STATE_VERSION } from '../config/constants';
// eslint-disable-next-line import/no-cycle
import configurei18n from '../i18n';
// eslint-disable-next-line import/no-cycle
import providers from './middlewares/providers';
import log from './middlewares/log';

const configureStore = (initialState = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const rootPersistConfig = {
    key: 'root',
    storage,
    version: STATE_VERSION,
    migrate: (state, version) => {
      const newState = (!state || state._persist.version !== version)
        ? initialState
        : state;
      return Promise.resolve(newState);
    },
  };

  const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

  const history = createBrowserHistory();

  const persistedReducer = persistReducer(
    rootPersistConfig,
    rootReducer(history),
  );

  const routerHistory = routerMiddleware(history);
  const middlewares = [];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  middlewares.push(
    ...[routerHistory, ...auth, ...transactions, ...clients, ...providers,
      ...card, ...paymentConditions, ...documentType, ...item, ...stats, ...api, ...messages,
      ...role, ...bank, ...route, ...financingProducts, ...lead, ...userProfile, ...flowApproval,
      ...log,
    ],
  );

  const store = createStore(connectRouter(history)(persistedReducer),
    initialState, composeEnhancers(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  configurei18n(store);

  return {
    store,
    persistor,
    history,
  };
};

export default configureStore;
