import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchLog = createAction(types.FETCH_LOG);
const getLog = createAction(types.GET_LOG);
const eventLog = createAction(types.EVENT_LOG);
const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchLog,
  getLog,
  eventLog,
};