import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextInput } from '../../../../components';

const FormRecover = ({
  t,
  onSubmit,
  handleInputChange,
  data,
}) => {
  const { password, confirmPassword } = data;
  return (
    <Panel headingText={t('changePassword')}>
      <div className="FormLogin">
        <div className="column is-full" style={{ color: 'black', fontFamily: 'Roboto, Arial, sans-serif' }}>
          <span>{t('enterYourNewPassword')}</span>
        </div>
        <Form
          className="column is-full"
          submitText={t('send')}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <TextInput
            name="password"
            placeholder={t('password')}
            type="password"
            onChange={handleInputChange}
            value={password}
          />
          <TextInput
            name="confirmPassword"
            placeholder={t('confirmPassword')}
            type="password"
            onChange={handleInputChange}
            value={confirmPassword}
          />
          <ul style={{ marginBottom: '3px' }}>
            <li>
              <span className="texto-file">* Longitud 8 a 12 caracteres que contengan al menos tres de las siguientes características: letras mayúsculas (A-Z), letras minúsculas (a-z), números (0-9) o caracteres especiales.</span>
            </li>
            <li>
              <span className="texto-file">* No incluir como parte de la contraseña el nombre del usuario.</span>
            </li>
            <li>
              <span className="texto-file">* No deberán tener caracteres idénticos consecutivos, ni totalmente numéricos, ni totalmente alfabéticos.</span>
            </li>
          </ul>
        </Form>
      </div>
    </Panel>
  );
};

FormRecover.propTypes = {
  t: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormRecover);
