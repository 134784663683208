const START_FETCH = 'role/START_FETCH';
const END_FETCH = 'role/END_FETCH';
const API_CALL = 'role/API_CALL';
const MESSAGE = 'role/MESSAGE';
const FETCH_ROLE = 'role/FETCH_ROLE';
const FETCH_TYPES_ROLE = 'role/FETCH_TYPES_ROLE';
const UPDATE_ROLE = 'role/UPDATE_ROLE';
const UPDATE_TYPES_ROLE = 'role/UPDATE_TYPES_ROLE';
const ADD_ROLE = 'role/ADD_ROL';
const SAVE_ROLE = 'role/SAVE_ROLE';
const DELETE_ROLE = 'role/DELETE_ROLE';
const REMOVE_ROLE = 'role/REMOVE_ROLE';
const EDIT_ROLE = 'role/EDIT_ROLE';
const OWNER_ID = 'role/OWNER_ID';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_ROLE,
  FETCH_TYPES_ROLE,
  UPDATE_ROLE,
  UPDATE_TYPES_ROLE,
  ADD_ROLE,
  SAVE_ROLE,
  DELETE_ROLE,
  REMOVE_ROLE,
  EDIT_ROLE,
  OWNER_ID,
};
