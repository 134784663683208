import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Card,
    Form,
    Loading,
    Modal,
    Panel,
    SelectSingle,
    TextInput,
    Toggle
} from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';


class SearchUser extends Component {
    constructor(props) {
        super(props);
        const {
            location: { state },
        } = props;
        this.state = { ...this.getInitialState(), ...state };
    }

    getInitialState = () => ({
        documentNumber: '',
        userInformation: [],
        commerceCodes: [],
        commerceCode: '',
        descriptionCommerceCode: '',
        documentTypeIndex: null,
    });

    componentDidMount() {
    }

    resetState = () => {
        this.setState(this.getInitialState());
    };

    handleDocTypeChange = ({ value }) => this.setState({ documentTypeIndex: value });

    handleInputChange = (event) => {
        const {
        target: {
            checked, value: targetValue, type, name,
        },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;
        this.setState({
            [name]: value,
        });
    };

    formatNumber = (n) => {
        if (n.length < 14) {
        return n.replace(/\D/g, '')
            .replace(/([0-9]{14})$/);
        } else {
        return;
        }
    };

    validateEmptyFields = () => {
        const { documentNumber, documentTypeIndex } = this.state;

        return documentNumber !== '' && documentTypeIndex !== null;
    };

    searchUser = (onToggle) => {
        const { 
            t,
            showMessage,
            searchUser,
            getProviderCommerceCode,
            consultCommerceCode,
            history,
            documentType: { list: documentTypes = [] },
        } = this.props;

        const { documentNumber, documentTypeIndex } = this.state;
        const { document_type = '' } = documentTypes[documentTypeIndex] || {};
        if (this.validateEmptyFields()) {
            searchUser({
                params: {
                    document: documentNumber,
                    document_type,
                },
                callback: (response) => {
                    if (response.length === 0) {
                        onToggle({
                            newUser: true,
                        });
                    } else {
                        if (response.roles.length > 1) {
                            showMessage({
                                message: t('userHasBoth'),
                                config: TOAST_CONFIG.WARNING,
                            });
                        } else {
                            this.setState({
                                userInformation: response,
                            });
                            consultCommerceCode({
                                params: {
                                    ruc: response.document,
                                },
                                callback: (response) => {
                                    if (response.hasOwnProperty('error')) {
                                        showMessage({
                                            message: response.error,
                                            config: TOAST_CONFIG.WARNING,
                                        });
                                        history.push('/dashboard');
                                    }else{
                                        this.setState({
                                            commerceCodes: response,
                                        });
                                        onToggle({
                                            userFound: true,
                                        });
                                    }
                                }
                            });
                        }
                    }
                }
            });
        }else{
            showMessage({
                message: t('emptyAll'),
                config: TOAST_CONFIG.WARNING,
            });
        }
    }

    addUserRole = () => {
        const { t, history, addRoleUser, showMessage } = this.props;
        const { userInformation, commerceCode, commerceCodes, } = this.state;

        let ccode = commerceCode !== '' ? commerceCodes[commerceCode].code : '';

        const data = {
            role: userInformation.roles[0].id,
            user: userInformation.id,
            document_type: 'RUC',
            document: userInformation.document,
            code: ccode,
        }

        addRoleUser({
            data,
            callback: (response) => {
                showMessage({
                    message: t('successAddRole'),
                    config: TOAST_CONFIG.SUCCESS,
                });
                history.push('/dashboard');
            }
        });
    }

    handleCommerceChange = (newVal, label) => {
        const {
            commerceCodes,
        } = this.state;
    
        this.setState({
            [label.name]: newVal.value,
            descriptionCommerceCode: commerceCodes[newVal.value].code,
        });
    };

    createNewUser = () => {
        const {
            auth: { user: { b2b: { type_user } } },
            history,
        } = this.props;
        const { documentNumber, documentTypeIndex: documentType } = this.state;

        history.push({
            pathname: type_user === 'acquiring_bank' ? '/create-provider' : '/create-customer',
            state: { documentNumber, documentType },
        });
    }

    render() {
        const {
            t,
            auth: { user: { b2b: { type_user } } },
            providers: { loading: providerLoading },
            clients: { loading: clientLoading },
            documentType: { list: documentTypes = [] } = {},
        } = this.props;
        const {
            documentNumber,
            userInformation,
            commerceCodes,
            commerceCode,
            documentTypeIndex,
            descriptionCommerceCode,
        } = this.state;
        const { document_type: documentType = '' } = documentTypes[documentTypeIndex] || {};

        return (
            <Toggle>
                {({ toggled, onToggle, toggleProps }) => {
                    return (
                        <div className="dashboard">
                            {(providerLoading || clientLoading) && <Loading />}
                            <Fragment>
                            {
                                <Panel headingText={type_user == 'acquiring_bank' ? t('createProvider') : t('createCustomer')}>
                                    <Form
                                        submitText={t('search')}
                                        onSubmit={(event) => {
                                        event.preventDefault();
                                        this.searchUser(onToggle);
                                        }}
                                        buttonClasses="mr-8px"
                                    >
                                        <div className="field is-horizontal">
                                            <div className="field-body columns">
                                                <div className="column is-two-fifths">
                                                    <SelectSingle
                                                        name="documentType"
                                                        label={t('documentType')}
                                                        selectClasses="is-fullwidth"
                                                        placeholder={t('Select')}
                                                        onChange={this.handleDocTypeChange}
                                                        options={
                                                            documentTypes.map(( { document_type: documentType }, index ) => ({
                                                            label: documentType, value: index,
                                                            }))
                                                        }
                                                        value={documentTypeIndex}
                                                    />
                                                </div>
                                                <div className="column is-two-fifths">
                                                    <TextInput
                                                        name="documentNumber"
                                                        label={t('documentNumber')}
                                                        value={documentNumber}
                                                        onChange={this.handleInputChange}
                                                        placeholder={t('documentNumber')}
                                                        type="text"
                                                        controlClasses="is-expanded"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Form>
                                </Panel>
                            }
                            {toggled && toggleProps.userFound && (
                                <Modal onToggle={onToggle} className="condition-modal">
                                    <Panel>
                                        <Card
                                            title={userInformation.name}
                                            items={[
                                                {
                                                    name: 'documentNumber',
                                                    value: 'RUC '+userInformation.document,
                                                },
                                                {
                                                    name: 'address',
                                                    value: userInformation.address,
                                                },
                                                {
                                                    name: 'authUser',
                                                    value: userInformation.admin_name,
                                                },
                                                {
                                                    name: 'email',
                                                    value: userInformation.email,
                                                },
                                            ]}
                                        />
                                        { userInformation.roles[0].name == 'buyer' &&
                                        <div>
                                            <div className="user-found">
                                                <p>{t('clientRucExist')}</p>
                                                <p>{t('wantRegisterProvider')}</p>
                                            </div>
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <SelectSingle
                                                            name="commerceCode"
                                                            label={t('descriptionOfTheMerchantAffiliateCode')}
                                                            selectClasses="is-fullwidth"
                                                            placeholder={t('Select')}
                                                            onChange={this.handleCommerceChange}
                                                            options={
                                                            [
                                                                ...commerceCodes.map((item, index) => ({
                                                                label: item.description,
                                                                value: index,
                                                                })),
                                                            ]}
                                                            value={commerceCode}
                                                        />
                                                    </div>
                                                    <div className="column">
                                                        <TextInput
                                                            name="descriptionCommerceCode"
                                                            label={t('code')}
                                                            value={descriptionCommerceCode}
                                                            placeholder={t('description')}
                                                            type="text"
                                                            controlClasses="is-expanded"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        { userInformation.roles[0].name == 'seller' && 
                                        <div>
                                            <div className="user-found">
                                                <p>{t('providerRucExist')}</p>
                                                <p>{t('wantRegisterClient')}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="field is-horizontal" style={{marginTop: '1.5em'}}>
                                            <div className="field-body columns">
                                                <div className="column" style={{textAlign: 'center'}}>
                                                    <button
                                                        className="button is-primary"
                                                        onClick={this.addUserRole}
                                                        //disabled={commerceCode === ''}
                                                    >
                                                        { userInformation.roles[0].name == 'seller' ? t('sameMailClient') : t('sameMailProvider')}
                                                    </button>
                                                </div>
                                                <div className="column" style={{textAlign: 'center'}}>
                                                    <button
                                                        className="button is-primary"
                                                        onClick={this.createNewUser}
                                                    >
                                                        { userInformation.roles[0].name == 'seller' ? t('newMailClient') : t('newMailProvider')}
                                                    </button>
                                                </div>
                                                <div className="column" style={{textAlign: 'center'}}>
                                                <button
                                                        className="button is-danger"
                                                        onClick={onToggle}
                                                    >
                                                        {t('cancel')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>                                      
                                    </Panel>
                                </Modal>
                            )}
                            {toggled && toggleProps.newUser && (
                                <Modal onToggle={onToggle}>
                                    <Panel>
                                        <div className="user-found" style={{marginTop: '2em'}}>
                                            <p>{documentType} {t('unregistered')}</p>
                                        </div>
                                        <div style={{textAlign: 'center', marginBottom: '2em'}}>
                                            <button
                                                className="button is-primary"
                                                onClick={this.createNewUser}
                                            >
                                                {type_user == 'acquiring_bank' ?  t('createProvider') : t('createCustomer')}
                                            </button>
                                        </div>
                                    </Panel>
                                </Modal>
                            )}
                            </Fragment>
                        </div>
                    )
                }}
            </Toggle>
        );
    }
}

SearchUser.propTypes = {
    providers: PropTypes.shape(Object).isRequired,
    auth: PropTypes.shape(Object).isRequired,
    documentType: PropTypes.shape(Object).isRequired,
    location: PropTypes.shape(Object).isRequired,
    fetchDocumentTypes: PropTypes.func.isRequired,
    createProvider: PropTypes.func.isRequired,
    getProviders: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    router: PropTypes.shape(Object).isRequired,
    clients: PropTypes.shape(Object).isRequired,
    history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                            providers,
                            documentType,
                            auth,
                            role,
                            router,
                            clients,
                        }) => ({
    providers,
    documentType,
    auth,
    role,
    router,
    clients,
});
export default compose(
    connect(mapStateToProps, {
        ...documentTypeActions,
        ...providersActions,
        ...roleActions,
        ...clientsActions,
    }),
    withNamespaces(),
)(SearchUser);
