import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { SubMenu } from '../index';
import { customIcon } from '../../../../../../util';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
  }

  showDropdownMenu() {
    const { setSelected, url, selected } = this.props;
    setSelected(url === selected ? '' : url);
  }

  render() {
    const {
      t,
      pathname,
      url,
      icon,
      name,
      submenu,
      isHidden,
      selected,
    } = this.props;
    let activeSubmenu = false;
    let iconMenu = '';
    if (submenu !== null) {
      iconMenu = <span className="icon" style={{ marginRight: '5px', marginTop: '.2rem' }}>{customIcon('FaAngleDown')}</span>;
      {/*
        iconMenu = <i className="icon fas fa-angle-down" aria-hidden="true"/>;
      */}
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i += 1) {
        if (pathname === submenu.items[i].url) {
          activeSubmenu = true;
        }
      }
    }
    if (isHidden) {
      return null;
    }
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
      <li
        className={
          `${pathname === url || activeSubmenu
            ? 'active'
            : ''}`
        }
        onClick={this.showDropdownMenu}
      >
        <Link
          to={url}
          onClick={() => {
            if (pathname === url) {
              // eslint-disable-next-line no-undef
              window.location.reload();
            }
          }}
        >
          <div className="menuContent">
            <span className="icon" style={{ marginRight: '5px' }}>
              {customIcon(icon)}
            </span>
            <span style={{ whiteSpace: 'pre-line', lineHeight: '20px', textAlign: 'start' }}>{t(name)}</span>
            {iconMenu}
          </div>
        </Link>
        {submenu && (
          <SubMenu
            items={submenu.items}
            open={selected === url}
            pathname={pathname}
          />
        )}
      </li>
    );
  }
}

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  submenu: PropTypes.shape(Object),
};

Menu.defaultProps = {
  submenu: null,
  pathname: '',
  url: '',
};

export default withNamespaces()(Menu);
