import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { cardActions } from '../../state/ducks/card';
import {
  Loading,
  Panel,
  TableList,
  TransactionRow,
} from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { customIcon } from '../../util';

class PrepaidCard extends Component {
  componentDidMount() {
    const { fetchCardData, checkBalance } = this.props;
    fetchCardData();
    checkBalance();
  }

  render() {
    const {
      t, card: cardData,
    } = this.props;
    const {
      card, transactions: transactionsData, loading,
    } = cardData;

    const { transactions, card_number, balance } = transactionsData;

    if (!card) {
      return (
        <Panel>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  {t('noCard')}
                </td>
              </tr>
            </tbody>
          </table>
        </Panel>
      );
    }

    return (
      <div>
        {loading && <Loading />}
        <Panel>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  {t('prepaidCard')}
                  <br />
                  <span className="text-verde">{card_number}</span>
                </td>
                <td>{balance}</td>
                <td className="has-text-right">
                  <Link
                    className="button is-link"
                    to="/prepaid-card/recharge"
                  >
                    <span className="icon is-small">
                      {customIcon('FaLevelDownAlt')}
                    </span>
                    &nbsp;
                    {t('recharge')}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </Panel>
        {
          transactions.length > 0
            ? (
              <TableList
                onChangePage={this.onChangePage}
                title={t('')}
                head={(
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('concept')}</th>
                    <th>{t('import')}</th>
                  </tr>
                )}
              >
                {
                  transactions.map(transaction => (
                    <TransactionRow
                      key={transaction.id}
                      {...transaction}
                    >
                      <td>{transaction.date}</td>
                      <td>{transaction.description}</td>
                      <td>{transaction.amount}</td>
                    </TransactionRow>
                  ))
                }
              </TableList>
            )
            : (
              <Panel>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        {t('noTransactions')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
            )
        }
      </div>
    );
  }
}

PrepaidCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
  fetchCardData: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card }) => ({
  card,
});

export default compose(
  connect(mapStateToProps, { ...cardActions, ...transactionsActions }),
  withNamespaces(),
)(PrepaidCard);
