import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { customIcon } from '../../util';

const TransactionRow = ({ children, openDetail }) => (
  <tr>
    {
      openDetail
      && (
        <td>
          <button
            type="button"
            className="button is-info is-small is-outlined is-rounded"
            onClick={openDetail}
          >
            <span className="icon is-small">
              {customIcon('FaInfo')}
            </span>
          </button>
        </td>
      )
    }
    {children}
  </tr>
);

TransactionRow.propTypes = {
  children: PropTypes.element.isRequired,
  openDetail: PropTypes.bool.isRequired,
};

export default TransactionRow;
