import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchCardData = createAction(types.FETCH_CARD_DATA);
const updateCardData = createAction(types.UPDATE_CARD_DATA);
const rechargeCard = createAction(types.RECHARGE_CARD);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchCardData,
  updateCardData,
  rechargeCard,
};
