import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './styles.scss';

/**
 * Created by Feedback on 25/10/18.
 * @param name
 * @param placeholder
 * @param onChange
 * @param options array of options for the select
 * @param disabled boolean to disable input
 * @param selectClasses array of classes for the select
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @returns {*}
 * @constructor
 */

const SelectSingle = ({
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  isRtl,
  name,
  label,
  placeholder,
  onChange,
  onInputChange,
  options,
  styles,
  helpText,
  value,
  inputValue,
}) => (
  <div>
    <div htmlFor={name} className="label">{label}</div>
    <Select
      className={`basic-single ${styles}`}
      styles={{
        menuPortal: provided => ({
          ...provided,
          zIndex: 1000,
        }),
      }}
      inputId={name}
      placeholder={placeholder}
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      name={name}
      // eslint-disable-next-line no-undef
      menuPortalTarget={document.querySelector('body')}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      defaultValue={value >= 0 ? options[value] : null}
      defaultInputValue="sell"
      value={value >= 0 ? options[value] : null}
    />
    <p className="help is-danger">{helpText}</p>
  </div>
);

SelectSingle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.shape(Object),
  helpText: PropTypes.string,
  inputValue: PropTypes.string,
};

SelectSingle.defaultProps = {
  label: null,
  placeholder: null,
  isClearable: false,
  isSearchable: false,
  isDisabled: false,
  isLoading: false,
  isRtl: false,
  styles: {},
  onInputChange: () => {},
  helpText: '',
  value: '',
  inputValue: '',
};
export default SelectSingle;
