const getMenuOptions = (hasAccessTo, userHasRoles, type_user) => ([
  {
    url: '/dashboard',
    icon: 'FaChartLine',
    name: 'dashboard',
    isHidden: !hasAccessTo('/dashboard'),
  },
  {
    url: '/search-user',
    icon: 'FaUserPlus',
    name: type_user === 'issuing_bank' ? 'createCustomer' : 'createProvider',
    isHidden: !hasAccessTo('/search-user'),
  },
  /*{
    url: '/create-provider',
    icon: 'fas fa-user-plus',
    name: 'createProvider',
    isHidden: !hasAccessTo('/create-provider'),
  },*/
  {
    url: '#bills',
    icon: 'FaRegListAlt',
    name: type_user === ('seller') ? 'sentInvoices' : 'receivedInvoices',
    isHidden: !hasAccessTo(['/review-invoices', '/approved-invoices', '/paid-invoices', '/reject-invoices']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/review-invoices',
            name: 'underReview',
            isHidden: !hasAccessTo(['/review-invoices']),
          },
          {
            id: 'sm4-2',
            url: '/approved-invoices',
            name: 'approved',
            isHidden: !hasAccessTo(['/approved-invoices']),
          },
          {
            id: 'sm4-3',
            url: '/paid-invoices',
            name: 'paid',
            isHidden: !hasAccessTo(['/paid-invoices']),
          },
          {
            id: 'sm4-4',
            url: '/reject-invoices',
            name: 'rejectInvoice',
            isHidden: !hasAccessTo(['/reject-invoices']),
          },
        ],
    },
  },
  {
    url: '#invoices',
    icon: 'FaFileInvoiceDollar',
    name: 'loadInvoice',
    isHidden: !hasAccessTo(['/load-invoice', '/load-invoice-group']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/load-invoice',
            name: 'loadInvoice',
            isHidden: !hasAccessTo('/load-invoice'),
          },
          {
            id: 'sm4-2',
            url: '/load-invoice-group',
            name: 'invoiceGroup',
            isHidden: !hasAccessTo(['/load-invoice-group']),
          },
        ]
    }
  },
  {
    url: '/related-users',
    icon: 'FaUsers',
    name: 'customers',
    isHidden: !hasAccessTo('/related-users'),
  },
  {
    url: '/related-users-client',
    icon: 'FaUsers',
    name: 'providers',
    isHidden: !hasAccessTo('/related-users-client'),
  },
  {
    url: '#banks',
    icon: 'FaUniversity',
    name: 'banks',
    isHidden: !hasAccessTo(['/register-bank', '/bank-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-bank',
            name: 'registerBank',
            isHidden: !hasAccessTo(['/register-bank']),
          },
          {
            id: 'sm4-2',
            url: '/bank-list',
            name: 'bankList',
            isHidden: !hasAccessTo(['/bank-list']),
          },
        ],
    },
  },
  {
    url: '#roles',
    icon: 'FaUserTag',
    name: 'admin',
    isHidden: !hasAccessTo(['/register-role', '/role-list', '/admin-routes', '/create-profile', '/profile-list', '/create-bank-user', '/bank-user-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-role',
            name: 'registerRole',
            isHidden: !hasAccessTo(['/register-role']),
          },
          {
            id: 'sm4-2',
            url: '/role-list',
            name: 'roleList',
            isHidden: !hasAccessTo(['/role-list']),
          },
          {
            id: 'sm4-3',
            url: '/admin-routes',
            name: 'adminRoutes',
            isHidden: !hasAccessTo(['/admin-routes']),
          },
          {
            id: 'sm4-4',
            url: '/create-profile',
            name: 'createProfile',
            isHidden: !hasAccessTo(['/create-profile']),
          },
          {
            id: 'sm4-5',
            url: '/profile-list',
            name: 'profileList',
            isHidden: !hasAccessTo(['/profile-list']),
          },
          {
            id: 'sm4-6',
            url: '/approval-flow-list',
            name: 'flowsApproval',
            isHidden: !hasAccessTo(['/approval-flow-list']),
          },
          {
            id: 'sm4-7',
            url: '/create-bank-user',
            name: 'createUser',
            isHidden: !hasAccessTo(['/create-bank-user']),
          },
          {
            id: 'sm4-5',
            url: '/bank-user-list',
            name: 'profileList',
            isHidden: !hasAccessTo(['/bank-user-list']),
          },
        ],
    },
  },
  {
    url: '/log-view',
    icon: 'FaFileAlt',
    name: 'logView',
    isHidden: !hasAccessTo('/log-view'),
  },
  {
    url: '/operation-log',
    icon: 'FaFileAlt',
    name: 'operationLog',
    isHidden: !hasAccessTo('/operation-log'),
  },
  /*  {
      url: '#routes',
      icon: 'fas fa-shield-alt',
      name: 'routes',
      isHidden: !hasAccessTo(['/register-route', '/route-list']),
      submenu: {
        items:
          [
            {
              id: 'sm4-1',
              url: '/register-route',
              name: 'registerRoute',
              isHidden: !hasAccessTo(['/register-route']),
            },
            {
              id: 'sm4-2',
              url: '/route-list',
              name: 'routeList',
              isHidden: !hasAccessTo(['/route-list']),
            },
          ],
      },
    },
    {
    url: '#financingProducts',
    icon: 'fas fa-money-check-alt',
    name: 'financingProducts',
    isHidden: !hasAccessTo(['/register-financing-products', '/financing-products']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-financing-products',
            name: 'addFinancingProducts',
            isHidden: !hasAccessTo(['/register-financing-products']),
          },
          {
            id: 'sm4-2',
            url: '/financing-products',
            name: 'financingProducts',
            isHidden: !hasAccessTo(['/financing-products']),
          },
        ],
    },
  }, */
  /* {
    url: '#alta',
    icon: 'fas fa-user-clock',
    name: 'lead',
    isHidden: !hasAccessTo(['/create-lead', '/lead-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-lead',
            name: 'createLead',
            isHidden: !hasAccessTo(['/create-lead']),
          },
          {
            id: 'sm4-2',
            url: '/lead-list',
            name: 'leadList',
            isHidden: !hasAccessTo(['/lead-list']),
          },
        ],
    },
  }, */
  {
    url: '/clients',
    icon: 'FaRegIdBadge',
    name: 'clients',
    isHidden: !hasAccessTo('/clients'),
  },
  {
    url: '/providers',
    icon: 'FaRegIdBadge',
    name: 'providers',
    isHidden: !hasAccessTo('/providers'),
  },
  {
    url: '#reports',
    icon: 'FaThList',
    name: 'reports',
    isHidden: !hasAccessTo(['/reports', '/conciliation']),
    submenu: {
      items:
          [
            {
              id: 'sm4-1',
              url: '/reports',
              name: 'reports',
              isHidden: !hasAccessTo(['/reports']),
            },
            {
              id: 'sm4-2',
              url: '/conciliation',
              name: 'reconciliationReport',
              isHidden: !hasAccessTo(['/conciliation']),
            },
          ],
    },
  },
  {
    url: '/transactions',
    icon: 'FaThList',
    name: 'transactions',
    isHidden: !hasAccessTo('/transactions'),
  },
]);

export default getMenuOptions;
