import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { authActions } from '../../../state/ducks/auth';
import { TOAST_CONFIG } from '../../../config/constants';
import { Loading } from '../../../components';
import FormRecover from './components/FormRecover';
import Logo from '../../../resources/images/assets/logo_with_amex.png';
import LogoNew from '../../../resources/images/assets/abl2.jpg';
import './styles.scss';
import { customIcon } from '../../../util';

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: '',
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount = () => {
    const { auth: { language } } = this.props;
    this.setState({ language });
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      t,
      passwordResetToken,
      showMessage,
      history,
    } = this.props;
    const {
      password,
      confirmPassword
    } = this.state;
    const url = window.location.href;

    // eslint-disable-next-line prefer-const
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(url)) {
      params[match[1]] = match[2];
    }

    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        params.password = password;
        passwordResetToken({
          params,
          callback: () => {
            history.push('/dashboard');
            showMessage({
              message: t('changePasswordSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          }
        });
      } else {
        showMessage({
          message: t('nonMatchingPasswords'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    } else if (password !== '' || confirmPassword !== '') {
      showMessage({
        message: t('emptyFields'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  render() {
    const {
      auth: { logged, loading },
      t,
    } = this.props;
    const { language, password, confirmPassword } = this.state;
    if (logged === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}
        <section className="hero is-info is-fullheight">
          <div className="hero-body">
            <div style={{ alignSelf: 'flex-end', marginBottom: '1rem' }}>
              <div className="field">
                <label htmlFor="language" className="label has-text-white">
                  {t('language')}
                  <div className="control has-icons-left">
                    <div className="select is-small">
                      <select name="language" value={language} onChange={this.handleSelectChange}>
                        <option value="es">{t('es')}</option>
                        <option value="pt">{t('pt')}</option>
                        <option value="en">{t('en')}</option>
                      </select>
                    </div>
                    <div className="icon is-small is-left">
                      {customIcon('FaGlobe')}
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="container">
              <div className="column panel fadeInUp animated" style={{marginTop: '14vh'}}>
                {/*<figure className="image is-centered mt-32px mb-16px">*/}
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '2.5em', marginBottom: '2.5em'}}>
                    <img src={LogoNew} alt="logo"/>
                  </div>
                {/*</figure>*/}
                <FormRecover
                  handleSubmit={this.handleSubmit}
                  handleInputChange={this.handleInputChange}
                  data={{ password, confirmPassword }}
                />
                <div style={{textAlign: 'center', color: '#646464', fontSize: ".7em", paddingTop: '2em'}}>
                        {t('disclaimer')}
                      </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(RecoverPassword);
