import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextArea } from '../../../../components';

const FormReject = (props) => {
  const {
    t,
    onSubmit,
    handleInputChange,
    data,
    isEdit,
  } = props;
  const { rejectReason } = data;
  return (
    <Panel headingText={t('rejectReason')}>
      <div className="FormLogin">
        <Form
          className="column is-full"
          submitText={t('send')}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
          buttonClasses={`mr-8px ${isEdit ? '' : ' is-hidden'}`}
        >
          <TextArea
            name="rejectReason"
            value={rejectReason}
            onChange={handleInputChange}
            placeholder=""
            controlClasses="is-expanded"
            maxLength="120"
            disabled={!isEdit}
          />
          <br/>
        </Form>
      </div>
    </Panel>
  );
};

FormReject.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    rejectReason: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormReject);
