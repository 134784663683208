import LogoNew from '../../../resources/images/assets/abl2.jpg';
import './styles.scss';

const TermsComponent = () => {
    return (
        <>
            <div className='terms-header'>
                <label className='header-title'>Términos y condiciones de la plataforma</label >
                <img src={LogoNew} alt="logo" style={{ width: '20%' }} />
            </div>
            <div className='terms-body'>
                <ol id="l1">
                    <li data-list-text={1}>
                        <h1
                            style={{
                                paddingTop: "8pt",
                                paddingLeft: "41pt",
                                textIndent: "-32pt",
                                textAlign: "left"
                            }}
                        >
                            Definiciones<span className="s2">.</span>
                        </h1>
                        <p
                            className="s3"
                            style={{
                                paddingTop: "12pt",
                                paddingLeft: "41pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            Parágrafo primero:{" "}
                            <span className="p">
                                Para los efectos de este documento, los términos señalados a
                                continuación tendrán el significado que se les asigna a cada uno de
                                ellos.
                            </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l2">
                            <li data-list-text="1.1.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>AMERICAN EXPRESS</u>
                                    <u>®</u>
                                    <u>:</u>{" "}
                                    <span className="p">
                                        hace referencia a la compañía propietaria de la franquicia
                                        internacional de tarjetas de crédito del mismo nombre, que ha
                                        suscrito el acuerdo que constituye al BANCO como licenciatario
                                        exclusivo de la marca de tarjetas AMERICAN EXPRESS® en la
                                        República del Ecuador.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.2.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>BANCO:</u>{" "}
                                    <span className="p">
                                        es Banco Guayaquil S.A., que es una entidad financiera privada,
                                        sujeta al control de la Superintendencia de Bancos, constituida de
                                        conformidad con las leyes del Ecuador y está regida por las normas
                                        del Código Orgánico Monetario y Financiero. El BANCO se encuentra
                                        legalmente autorizado a realizar las operaciones previstas en el
                                        Art. 194 de la norma antes referida, identificada con el registro
                                        único de contribuyentes número 0990049459001 y con domicilio en
                                        las calles Pichincha No. 107 y Francisco de Paula Ycaza,
                                        Guayaquil, Guayas – Ecuador.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.3.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>CONTRATOS:</u>{" "}
                                    <span className="p">
                                        Se refiere, indistintamente, a los contratos que han suscrito, por
                                        separado, los ESTABLECIMIENTOS y los TARJETAHABIENTES con el BANCO
                                        para el acceso y uso de la PLATAFORMA AMEX BUSINESS LINK.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.4.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>DISPOSITIVOS&nbsp; DE&nbsp; PAGO:</u>{" "}
                                    <span className="p">
                                        son los mecanismos, herramientas o artículos habilitados o que
                                        habilite el BANCO en el futuro, para que los TARJETAHABIENTES
                                        gestionen en la PLATAFORMA AMEX BUSINESS LINK sus
                                    </span>
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    pagos al ESTABLECIMIENTO y que pueden consistir en tarjetas de
                                    crédito (físicas o virtuales), créditos, transferencias u otro medio
                                    de pago al que el BANCO le haya conferido dicha calidad. Debido a
                                    que la PLATAFORMA AMEX BUSINESS LINK funciona sobre el riel
                                    transaccional para tarjetas de crédito, y para fines de gestión de
                                    los pagos por parte de los TARJETAHABIENTES al ESTABLECIMIENTO, el
                                    BANCO ha previsto la emisión de tarjetas de crédito virtuales, que
                                    tendrán asignadas una determinada línea de crédito, y que a su vez
                                    funcionarán con un código BIN principal.
                                </p>
                            </li>
                            <li data-list-text="1.5.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>ESTABLECIMIENTO:</u>{" "}
                                    <span className="p">
                                        es la persona natural o jurídica que se encuentra afiliada a la
                                        red de pagos de la PLATAFORMA AMEX BUSINESS LINK y que provee
                                        bienes o servicios al TARJETAHABIENTE.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.6.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>FACTURAS:</u>{" "}
                                    <span className="p">
                                        son los documentos tributarios donde constan los consumos que los
                                        ESTABLECIMIENTOS facturan por la venta de sus productos o
                                        servicios, a nombre del TARJETAHABIENTE que los adquiera o reciba.
                                        Los datos de las FACTURAS deben ser ingresados a la PLATAFORMA
                                        AMEX BUSINESS LINK, para que se pueda procesar su pago, de
                                        conformidad con las condiciones comerciales acordadas entre el
                                        TARJETAHABIENTE y los respectivos ESTABLECIMIENTOS.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.7.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>
                                        <i>FUENTES DE INFORMACIÓN:</i>
                                    </u>
                                    <i> </i>se entenderá que este término incluye, de forma expresa:{" "}
                                    <b>(i) </b>a los burós de información crediticia facultados para
                                    operar en el país;
                                </p>
                                <p
                                    className="s1"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    (ii){" "}
                                    <span className="p">
                                        a la Dirección Nacional de Registros Públicos (incluyendo a las
                                        entidades que reemplacen o cumplieren similares funciones a estas
                                        últimas); y,{" "}
                                    </span>
                                    (iii){" "}
                                    <span className="p">
                                        en general, a toda entidad, pública, privada y/o de economía
                                        mixta, autorizada por la ley, por el ESTABLECIMIENTO, el
                                        TARJETAHABIENTE y/o por los USUARIOS, según
                                    </span>
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    corresponda, para recabar y manejar los datos personales de los
                                    USUARIOS o aquellos que entregue el ESTABLECIMIENTO o
                                    TARJETAHABIENTE.
                                </p>
                            </li>
                            <li data-list-text="1.8.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>IDENTIFICACIONES&nbsp; ELECTRÓNICAS:</u>{" "}
                                    <span className="p">
                                        es cualquier método de identificación, autenticación y/o mecanismo
                                        de seguridad electrónica previsto por el BANCO u operadores de su
                                        sistema informático, para el acceso y operatividad de la
                                        PLATAFORMA AMEX BUSINESS LINK. Podrán consistir en códigos,
                                        claves, pines, contraseñas, contraseñas de un solo uso (conocidas
                                        como{" "}
                                    </span>
                                    one-time passwords{" "}
                                    <span className="p">
                                        u OTP), coordenadas, token, entre otros mecanismos o
                                        procedimientos que se haya establecido o establezca en el futuro
                                        para la realización de transacciones y en
                                    </span>
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    general operaciones, ya sea que se encuentre asociado o no a otros
                                    elementos para su utilización, como, por ejemplo: tarjetas
                                    magnéticas, usuarios, dispositivos de seguridad u otros.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }} />
                            </li>
                            <li data-list-text="1.9.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>
                                        <i>PLATAFORMA </i>
                                    </u>
                                    <i>AMEX BUSINESS LINK: </i>es la plataforma o terminal electrónica
                                    denominada Amex Business Link<span className="s6">®</span>
                                    <a
                                        href="http://www.bancoguayaquil.com/"
                                        style={{
                                            color: "black",
                                            fontFamily: "Arial, sans-serif",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            textDecoration: "none",
                                            fontSize: "10.5pt"
                                        }}
                                        target="_blank"
                                    >
                                        , para productos de AMERICAN EXPRESS®, que ha sido habilitada por
                                        el BANCO a través de su sitio web institucional (
                                    </a>
                                    <span
                                        style={{
                                            color: "#0462C1",
                                            fontFamily: "Arial, sans-serif",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            textDecoration: "underline",
                                            fontSize: "10.5pt"
                                        }}
                                    >
                                        www.bancoguayaquil.com
                                    </span>
                                    ), para que los TARJETAHABIENTES puedan gestionar sus pagos a los
                                    ESTABLECIMIENTOS según las FACTURAS que hayan ingresado. El acceso y
                                    uso de la PLATAFORMA AMEX BUSINESS LINK estará sujeto a los términos
                                    y condiciones previstos en este instrumento y a los indicados en la
                                    misma plataforma
                                </p>
                            </li>
                            <li data-list-text="1.10.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>TARJETAHABIENTES:</u>{" "}
                                    <span className="p">
                                        es la persona jurídica que es cliente del BANCO y contrata con
                                        este último el acceso y uso de la PLATAFORMA AMEX BUSINESS LINK,
                                        para gestionar sus pagos a favor de los ESTABLECIMIENTOS, por
                                        prestación de servicios o venta de bienes.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.11.">
                                <p
                                    className="s5"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>TÉRMINOS Y CONDICIONES:</u>{" "}
                                    <span className="p">
                                        es este documento que contiene los Términos y Condiciones de uso
                                        de la PLATAFORMA AMEX BUSINESS LINK.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="1.12.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>
                                        <i>USUARIOS:</i>
                                    </u>
                                    <i> </i>este término se usa para referirse a las personas que
                                    mantienen una relación laboral o contractual con el ESTABLECIMIENTO
                                    y/o el TARJETAHABIENTE, según aplique, y quienes son autorizados por
                                    estos últimos ante el BANCO para operar la PLATAFORMA AMEX BUSINESS
                                    LINK, en su nombre y representación, a través de las
                                    IDENTIFICACIONES ELECTRÓNICAS. Tanto los USUARIOS designados por el
                                    ESTABLECIMIENTO, como los designados por el TARJETAHABIENTE, pueden
                                    ser de dos tipos: <b>1.11.1. </b>USUARIOS ADMINISTRADORES y/o{" "}
                                    <b>1.11.2. </b>USUARIOS ADICIONALES. Cuando
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    en estos TÉRMINOS Y CONDICIONES se utilice el término USUARIO se
                                    estará haciendo alusión a ambos tipos de USUARIOS, en forma conjunta
                                    y sin distinción alguna.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo segundo:{" "}
                                    <span className="p">
                                        Salvo que del contexto de estos TÉRMINOS Y CONDICIONES se pueda
                                        entender algo distinto, se los interpretará de acuerdo con las
                                        siguientes reglas: (i) las palabras en singular incluirán al
                                        plural y viceversa; (ii) las palabras que incluyen un género se
                                        entenderán referirse a cualquiera de ellos; y, (iii) los términos
                                        definidos en esta cláusula aplicarán, íntegramente, para éstos
                                        TÉRMINOS Y CONDICIONES, así como también para las modificaciones
                                        y/o actualizaciones que, de tiempo en tiempo, emita el BANCO.
                                    </span>
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={2}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Objeto de estos TÉRMINOS Y CONDICIONES y aceptación del Destinatario.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p
                            className="s3"
                            style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "justify" }}
                        >
                            Parágrafo primero:{" "}
                            <span className="p">
                                Objeto y Destinatario de estos TÉRMINOS Y CONDICIONES.
                            </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l3">
                            <li data-list-text="2.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El servicio que el BANCO brinda a través de la PLATAFORMA AMEX
                                    BUSINESS LINK está dirigido única y exclusivamente para USUARIOS
                                    domiciliados en el Ecuador y que sean legalmente capaces para
                                    contratar y contraer obligaciones. En caso de que el USUARIO:{" "}
                                    <b>(i) </b>no esté domiciliado en el Ecuador; <b>(ii) </b>no tenga
                                    capacidad legal para contratar y contraer obligaciones; o,{" "}
                                    <b>(iii) </b>no tiene certeza de cumplir con estas condiciones, el
                                    USUARIO conoce y acepta que podrá verse parcial o totalmente
                                    impedido de acceder o de utilizar la PLATAFORMA AMEX BUSINESS LINK,
                                    sin culpa o responsabilidad de ningún tipo a cargo del BANCO.
                                </p>
                            </li>
                            <li data-list-text="2.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Estos TÉRMINOS Y CONDICIONES regulan la relación entre los USUARIOS
                                    designados por el ESTABLECIMIENTO o el TARJETAHABIENTE, según
                                    corresponda, y el BANCO, respecto de los servicios brindados a
                                    través de la PLATAFORMA AMEX BUSINESS LINK, con especial atención al
                                    procesamiento y pago de las FACTURAS.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo segundo:{" "}
                                    <span className="p">
                                        Aceptación de estos TÉRMINOS Y CONDICIONES.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="2.3.">
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO, al acceder a la PLATAFORMA AMEX BUSINESS LINK, acepta
                                    que ha leído y comprendido íntegramente estos TÉRMINOS Y
                                    CONDICIONES, por lo que al usar la PLATAFORMA AMEX BUSINESS LINK,
                                    incluyendo todas sus funcionalidades y/o servicios, confirma de
                                    manera libre, específica, completa e informada su conformidad y
                                    aceptación de este documento.
                                </p>
                            </li>
                            <li data-list-text="2.4.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO declara ser legalmente capaz y que, por lo tanto, tiene
                                    la capacidad legal para aceptar y obligarse a cumplir las
                                    condiciones contenidas en estos TÉRMINOS Y CONDICIONES y cualquier
                                    otro documento relacionado, así como que consiente y entrega
                                    voluntariamente al BANCO la información personal requerida para el
                                    uso de la PLATAFORMA AMEX BUSINESS LINK; siendo que el USUARIO
                                    garantiza al BANCO que la referida información es exacta, veraz y
                                    fidedigna, haciéndose personalmente responsable en caso de
                                    inexactitud, falsedad o error.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={3}>
                        <h1
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-32pt",
                                textAlign: "justify"
                            }}
                        >
                            Acerca de la PLATAFORMA AMEX BUSINESS LINK.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p
                            className="s3"
                            style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "justify" }}
                        >
                            Parágrafo primero:{" "}
                            <span className="p">
                                Información relacionada con la titularidad y operación de la
                                PLATAFORMA AMEX BUSINESS LINK.
                            </span>
                        </p>
                        <ol id="l4">
                            <li data-list-text="3.1.">
                                <p
                                    style={{
                                        paddingTop: "12pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO es licenciatario de la marca de tarjetas de crédito
                                    AMERICAN EXPRESS® en la República del Ecuador. En virtud de esta
                                    licencia, el BANCO está autorizado para actuar como emisor,
                                    administrador y adquirente de las tarjetas de crédito AMERICAN
                                    EXPRESS® de Banco Guayaquil; entre ellas, la tarjeta “Business
                                    American Express®” para los clientes corporativos y empresariales
                                    del BANCO.
                                </p>
                            </li>
                            <li data-list-text="3.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    AMERICAN EXPRESS® es el titular de los derechos emanados de la
                                    PLATAFORMA AMEX BUSINESS LINK. Para poner la PLATAFORMA AMEX
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    BUSINESS LINK a disposición del público en el mercado ecuatoriano,
                                    AMERICAN EXPRESS® y el BANCO, en calidad de licenciatario de la
                                    PLATAFORMA AMEX BUSINESS LINK, han implementado los mecanismos
                                    necesarios para permitir el acceso a la PLATAFORMA AMEX BUSINESS
                                    LINK a través de la página web del BANCO.
                                </p>
                            </li>
                            <li data-list-text="3.3.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-35pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    La PLATAFORMA AMEX BUSINESS LINK ofrecerá las siguientes
                                    funcionalidades:
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    (i) los ESTABLECIMIENTOS podrán procesar en línea los cargos que,
                                    por la venta de sus productos o servicios, hayan autorizado los
                                    TARJETAHABIENTES que, a su vez, son sus clientes y (ii) los clientes
                                    del BANCO, que sean TARJETAHABIENTES de las tarjetas AMERICAN
                                    EXPRESS® que determine el BANCO, podrán gestionar sus pagos a los
                                    ESTABLECIMIENTOS, atendiendo las FACTURAS que hayan ingresado a la
                                    PLATAFORMA AMEX BUSINESS LINK y las condiciones comerciales
                                    acordadas entre el ESTABLECIMIENTO y el TARJETAHABIENTE.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo segundo:{" "}
                                    <span className="p">
                                        Condiciones generales de uso de la PLATAFORMA AMEX BUSINESS LINK.
                                    </span>
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text="3.4.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO podrá requerir al USUARIO que utilice diversas formas de
                                    IDENTIFICACIONES ELECTRÓNICAS para el acceso y uso seguro de la
                                    PLATAFORMA AMEX BUSINESS LINK. Estas IDENTIFICACIONES
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    ELECTRÓNICAS son personales, intransferibles y deberán mantenerse en
                                    secreto por el USUARIO, quien será único y exclusivo responsable de
                                    su custodia y uso. El BANCO podrá modificar los mecanismos de
                                    generación de IDENTIFICACIONES ELECTRÓNICAS para operar la
                                    PLATAFORMA AMEX BUSINESS LINK a su solo arbitrio, sin necesidad de
                                    autorización o aceptación previa del USUARIO.
                                </p>
                            </li>
                            <li data-list-text="3.5.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Si el USUARIO sospecha o detecta que se ha vulnerado la PLATAFORMA
                                    AMEX BUSINESS LINK o las IDENTIFICACIONES ELECTRÓNICAS empleadas
                                    para su acceso u operación, deberá notificarlo inmediatamente al
                                    BANCO. Hasta que no se cumpla con dicha notificación, las
                                    operaciones realizadas a través de las IDENTIFICACIONES ELECTRÓNICAS
                                    se presumirán realizadas por el USUARIO.
                                </p>
                            </li>
                            <li data-list-text="3.6.">
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    En caso de daño, sustracción o extravío de las IDENTIFICACIONES
                                    ELECTRÓNICAS, el USUARIO podrá seguir el procedimiento establecido
                                    por el BANCO en la PLATAFORMA AMEX BUSINESS LINK para la emisión de
                                    nuevas, previo el pago de las tarifas o cargos establecidos por el
                                    BANCO de conformidad con la ley y dentro de los parámetros
                                    permitidos por las autoridades competentes.
                                </p>
                            </li>
                            <li data-list-text="3.7.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO podrá anular las IDENTIFICACIONES ELECTRÓNICAS en los
                                    siguientes casos: (i) por mal uso por parte del USUARIO; (ii) por su
                                    vulneración o compromiso; (iii) por incumplimiento de estos TÉRMINOS
                                    Y CONDICIONES; (iv) por terminación de la relación contractual con
                                    del ESTABLECIMIENTO o del TARJETAHABIENTE el BANCO; y, (v) por
                                    muerte del USUARIO.
                                </p>
                            </li>
                            <li data-list-text="3.8.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO podrá requerir al USUARIO que actualice de forma periódica
                                    las IDENTIFICACIONES ELECTRÓNICAS, en cuyo caso el USUARIO deberá
                                    actualizarlas siguiendo los parámetros de seguridad recomendados por
                                    el BANCO.
                                </p>
                            </li>
                            <li data-list-text="3.9.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO no solicitará al USUARIO sus claves, códigos de
                                    autenticación, coordenadas, pines u otros tipos de IDENTIFICACIONES
                                    ELECTRÓNICAS a través de llamadas o mensajes telefónicos o por
                                    correos electrónicos; por lo que, serán responsabilidad del USUARIO
                                    los daños o perjuicios que se ocasionen por su entrega a terceros
                                    por esos u otros medios.
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingTop: "12pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo tercero:{" "}
                                    <span className="p">
                                        De la disponibilidad de la PLATAFORMA AMEX BUSINESS LINK.
                                    </span>
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text="3.10.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO será responsable del mantenimiento, funcionamiento y
                                    disponibilidad de la PLATAFORMA AMEX BUSINESS LINK propiedad de
                                    AMERICAN EXPRESS®.
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    La disponibilidad de la PLATAFORMA AMEX BUSINESS LINK estará sujeta
                                    a las condiciones contenidas en los CONTRATOS y sus anexos o
                                    enmiendas, según corresponda.
                                </p>
                            </li>
                            <li data-list-text="3.11.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Sin perjuicio de lo anterior, el BANCO no será responsable de
                                    interrupciones, intermitencias, o problemas de funcionamiento o
                                    disponibilidad de la PLATAFORMA AMEX BUSINESS LINK.
                                    Consecuentemente, el porcentaje de disponibilidad establecido en los
                                    CONTRATOS podría disminuir en caso de que se produzcan: <b>(i) </b>
                                    situaciones constitutivas de caso fortuito o fuerza mayor conforme a
                                    la definición contenida en el Código Civil; o, <b>(ii) </b>otras
                                    circunstancias que fueren imputables al ESTABLECIMIENTO, el
                                    TARJETAHABIENTE, a los USUARIOS u otros terceros (como sería, por
                                    ejemplo, el uso incorrecto de las IDENTIFICACIONES ELECTRÓNICAS;
                                    ingresar datos erróneos en la PLATAFORMA AMEX BUSINESS LINK;
                                    problemas de conectividad u operación de redes enlazadas a la
                                    PLATAFORMA AMEX BUSINESS LINK que no pertenezcan al BANCO; u otros
                                    de naturaleza o efectos similares).
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo cuarto:{" "}
                                    <span className="p">
                                        Afiliación a la PLATAFORMA AMEX BUSINESS LINK.
                                    </span>
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text="3.12.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    La afiliación de los USUARIOS a la PLATAFORMA AMEX BUSINESS LINK, y
                                    la creación de las IDENTIFICACIONES ELECTRÓNICAS correspondientes,
                                    se ejecutará siguiendo las instrucciones del ESTABLECIMIENTO o el
                                    TARJETAHABIENTE, según aplique. Tanto el ESTABLECIMIENTO como el
                                    TARJETAHABIENTE tendrán la obligación de entregar al BANCO la
                                    documentación que este les solicite con relación a cada USUARIO, así
                                    como también de proporcionar la información que el BANCO considere
                                    necesaria, para el proceso de habilitación de las IDENTIFICACIONES
                                    ELECTRÓNICAS.
                                </p>
                            </li>
                            <li data-list-text="3.13.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Mediante la aceptación de estos TÉRMINOS Y CONDICIONES, los USUARIOS
                                    designados por el ESTABLECIMIENTO y el TARJETAHABIENTE,
                                    respectivamente, aceptan ser afiliados por el BANCO para el uso de
                                    la PLATAFORMA AMEX BUSINESS LINK bajo los parámetros constantes en
                                    estos TÉRMINOS Y CONDICIONES, en los CONTRATOS correspondientes y/o
                                    en los anexos, adendas o cualesquiera otros documentos físicos o
                                    electrónicos que sean relacionados con la operación de la PLATAFORMA
                                    AMEX BUSINESS LINK y que
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    el BANCO notifique al ESTABLECIMIENTO o al TARJETAHABIENTE, según
                                    corresponda, para su conocimiento y aceptación.
                                </p>
                            </li>
                            <li data-list-text="3.14.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    En virtud de lo antes indicado, al aceptar estos TÉRMINOS Y
                                    CONDICIONES los USUARIOS declaran no tener objeción alguna a la
                                    afiliación realizada según las instrucciones del ESTABLECIMIENTO y/o
                                    el TARJETAHABIENTE.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "42pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Parágrafo quinto:{" "}
                                    <span className="p">
                                        Creación de USUARIOS en la PLATAFORMA AMEX BUSINESS LINK y
                                        funciones.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="3.15.">
                                <p
                                    style={{
                                        paddingTop: "12pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>Creación de USUARIOS:</u> La creación de USUARIOS se sujetará al
                                    procedimiento que, para el efecto, se ha establecido en el CONTRATO
                                    respectivo.
                                </p>
                            </li>
                            <li data-list-text="3.16.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    <u>Funciones de los USUARIOS:</u> Las funciones de los USUARIOS
                                    designados variará según el tipo de USUARIO, observando las
                                    siguientes disposiciones:
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <ol id="l5">
                                    <li data-list-text="3.16.1.">
                                        <p
                                            style={{
                                                paddingLeft: "84pt",
                                                textIndent: "-42pt",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Sobre los USUARIOS ADMINISTRADORES:
                                        </p>
                                        <ol id="l6">
                                            <li data-list-text="a)">
                                                <p
                                                    style={{
                                                        paddingLeft: "111pt",
                                                        textIndent: "-26pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Los USUARIOS ADMINISTRADORES del ESTABLECIMIENTO
                                                </p>
                                                <p
                                                    style={{
                                                        paddingLeft: "105pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    tendrán las siguientes funciones: (i) designar a los
                                                    USUARIOS ADICIONALES, asignarles perfiles y de ser el caso,
                                                    deshabilitarlos;
                                                </p>
                                                <p
                                                    style={{
                                                        paddingLeft: "105pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    (ii) cargar los datos de las FACTURAS; (iii) consultar el
                                                    estado de las FACTURAS; (iv) consultar la lista de los
                                                    TARJETAHABIENTES; (v) generar reportes y estadísticas; y
                                                    (vi) otras que estén habilitadas en la PLATAFORMA AMEX
                                                    BUSINESS LINK.
                                                </p>
                                            </li>
                                            <li data-list-text="b)">
                                                <p
                                                    style={{
                                                        paddingLeft: "111pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Los USUARIOS ADMINISTRADORES del TARJETAHABIENTE
                                                </p>
                                                <p
                                                    style={{
                                                        paddingLeft: "105pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    tendrán las siguientes funciones: (i) designar a los
                                                    USUARIOS ADICIONALES, asignarles perfiles y deshabilitarlos;
                                                    (ii) aprobar o rechazar el pago de las FACTURAS; (iii) crear
                                                    flujos de aprobación de las FACTURAS; (iv) consultar el
                                                    estado de las FACTURAS; (v) consultar los ESTABLECIMIENTOS;
                                                    (vi) generar reportes y estadísticas; y (vii) otras que
                                                    estén habilitadas en la PLATAFORMA AMEX BUSINESS LINK.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text="3.16.2.">
                                        <p
                                            style={{
                                                paddingLeft: "84pt",
                                                textIndent: "-42pt",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Sobre los USUARIOS ADICIONALES:
                                        </p>
                                        <ol id="l7">
                                            <li data-list-text="a)">
                                                <p
                                                    className="s1"
                                                    style={{
                                                        paddingLeft: "105pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    {" "}
                                                    <span className="p">
                                                        Los USUARIOS ADICIONALES designados por el USUARIO
                                                        ADMINISTRADOR del ESTABLECIMIENTO podrán realizar las
                                                        siguientes funciones: (i) cargar los datos de las
                                                        FACTURAS; (ii) consultar el estado de las FACTURAS; (iii)
                                                        consultar la lista de los TARJETAHABIENTES; (iv) Generar
                                                        reportes y estadísticas; y (v) Otras que estén habilitadas
                                                        en la PLATAFORMA AMEX BUSINESS LINK.
                                                    </span>
                                                </p>
                                            </li>
                                            <li data-list-text="b)">
                                                <p
                                                    className="s1"
                                                    style={{
                                                        paddingLeft: "105pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    {" "}
                                                    <span className="p">
                                                        Los USUARIOS ADICIONALES designados por el USUARIO
                                                        ADMINISTRADOR del TARJETAHABIENTE podrán realizar las
                                                        siguientes funciones: (i) consultar estado de las
                                                        FACTURAS; (ii) aprobar o rechazar el pago de las FACTURAS;
                                                        (iii) consultar los ESTABLECIMIENTOS; (iv) generar
                                                        reportes y estadísticas; y, (v) otras que estén
                                                        habilitadas en la PLATAFORMA AMEX BUSINESS LINK.
                                                    </span>
                                                </p>
                                                <p
                                                    style={{
                                                        paddingLeft: "84pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Debe considerarse que los USUARIOS ADICIONALES designados
                                                    por el ESTABLECIMIENTO o por el TARJETAHABIENTE, según
                                                    corresponda, no pueden crear otros usuarios, ni asignarles
                                                    perfiles o deshabilitarlos.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingLeft: "41pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Parágrafo sexto:{" "}
                                                    <span className="p">Procesamiento de transacciones.</span>
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.17.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Para el procesamiento de transacciones en la PLATAFORMA AMEX
                                    BUSINESS LINK y el consecuente pago de las FACTURAS, el
                                    ESTABLECIMIENTO deberá cumplir con lo siguiente: (i) Los USUARIOS
                                    podrán acceder a la PLATAFORMA AMEX BUSINESS LINK a través de sus
                                    IDENTIFICACIONES ELECTRÓNICAS,
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    para ingresar la información de las FACTURAS que desea que sean
                                    pagadas por
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    los TARJETAHABIENTES con los DISPOSITIVOS DE PAGO; (ii) una vez
                                    ingresados los datos de las FACTURAS, la PLATAFORMA AMEX BUSINESS
                                    LINK notificará a los TARJETAHABIENTES cada vez que tengan FACTURAS
                                    pendientes de pago, para su aceptación o rechazo; (iii) la
                                    PLATAFORMA AMEX BUSINESS LINK permitirá al ESTABLECIMIENTO
                                    visualizar la lista de las FACTURAS cuyo pago ha sido aceptado y
                                    aquellos que fueron rechazados. El ESTABLECIMIENTO podrá eliminar
                                    las FACTURAS ingresadas mientras los TARJETAHABIENTES no las
                                    hubiesen aceptado o rechazado, todo lo cual podrá ser revisado por
                                    los TARJETAHABIENTES en los reportes generados por la PLATAFORMA
                                    AMEX BUSINESS LINK; (iv) el BANCO procesará los pagos de las
                                    FACTURAS aceptadas con cargo a los DISPOSITIVOS DE PAGO, de acuerdo
                                    con los términos y otras condiciones particulares de financiamiento
                                    que hayan sido acordados entre el ESTABLECIMIENTO y cada uno de los
                                    TARJETAHABIENTES, dentro de los parámetros autorizados por el BANCO
                                    en las Condiciones Comerciales que forman parte integrante de los
                                    CONTRATOS correspondientes. El BANCO liquidará los pagos que tuviere
                                    que hacer al ESTABLECIMIENTO de acuerdo con los términos de las
                                    aceptaciones que hubieren realizado los TARJETAHABIENTES, atendiendo
                                    las Condiciones Comerciales aplicables a la relación entre el
                                    ESTABLECIMIENTO y cada uno de los TARJETAHABIENTES, que fueron
                                    entregadas oportunamente al BANCO para su registro y archivo; (v) el
                                    BANCO pagará al ESTABLECIMIENTO el monto neto de la liquidación de
                                    pagos realizados por los TARJETAHABIENTES, luego de descontar
                                    costos, créditos, inconsistencias, y otros rubros que deban ser
                                    imputados a esta liquidación de conformidad con el CONTRATO y la
                                    ley; (vi) el ESTABLECIMIENTO podrá solicitar al BANCO reportes de
                                    las transacciones procesadas de manera diaria, semanal o mensual; y,
                                    (vii) la PLATAFORMA AMEX BUSINESS LINK también permitirá al
                                    ESTABLECIMIENTO contar con una conciliación automática de sus ventas
                                    y pagos recibidos y los pendientes.
                                </p>
                            </li>
                            <li data-list-text="3.18.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Para el procesamiento de transacciones en la PLATAFORMA AMEX
                                    BUSINESS LINK y el consecuente pago de las FACTURAS, el
                                    TARJETAHABIENTE deberá cumplir con lo siguiente: (i) los USUARIOS
                                    podrán acceder a la PLATAFORMA AMEX BUSINESS LINK a través de las
                                    identificaciones asignadas por el TARJETAHABIENTE o USUARIO
                                    ADMINISTRADOR, según fuere el caso, y sus respectivas contraseñas.
                                    En la PLATAFORMA AMEX BUSINESS LINK, los USUARIOS podrán revisar los
                                    datos de las FACTURAS, cargados por los ESTABLECIMIENTOS tales como:
                                    número, monto a pagar, fecha de cobro, concepto o descripción,
                                    estado (en revisión, aprobadas o pagadas), entre otros; junto con
                                    los datos, se podrá visualizar un ejemplar de las FACTURAS; (ii){" "}
                                    <b>l</b>os USUARIOS cuyos perfiles les permitan aprobar FACTURAS,
                                    podrán aceptar o rechazar su pago entre aquellas que consten
                                    enlistadas bajo el estatus “<i>en revisión</i>”. Las FACTURAS que
                                    fueran aprobadas pasarán a la lista de “<i>aprobadas</i>”; en tanto
                                    que las rechazadas, serán notificadas al ESTABLECIMIENTO a la
                                    dirección de correo electrónico que hubiere reportado ante el BANCO,
                                    junto con las razones para su rechazo. Para la aprobación de las
                                    FACTURAS, el USUARIO ADMINISTRADOR del TARJETAHABIENTE podrá
                                    establecer niveles o flujos de aprobación entre los USUARIOS cuyos
                                    perfiles se encuentren habilitados para ese tipo de acción; (iii) el
                                    BANCO gestionará el pago de las FACTURAS que consten aprobadas, con
                                    cargo a los DISPOSITIVOS DE PAGO en los plazos y demás condiciones
                                    acordadas entre el TARJETAHABIENTE y los ESTABLECIMIENTOS, que hayan
                                    sido notificadas al BANCO en los formularios establecidos por este
                                    último para dicho fin. Las FACTURAS cuyo pago haya sido procesado
                                    por el BANCO, pasarán del estatus “<i>aprobadas</i>” a “
                                    <i>pagadas</i>”; (iv) los USUARIOS podrán realizar consultas y
                                    filtrar información de las FACTURAS y su estado, utilizando los
                                    criterios de búsqueda dispuestos en la PLATAFORMA AMEX BUSINESS
                                    LINK, así como generar reportes y estadísticas sobre los valores
                                    pagados, valores pendientes, próximos pagos, entre otros.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Parágrafo séptimo:{" "}
                                    <span className="p">
                                        Reclamos y reglas en materia de responsabilidad.
                                    </span>
                                </p>
                            </li>
                            <li data-list-text="3.19.">
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Respecto de las reglas aplicables a reclamos y en materia de
                                    responsabilidad, se aplicarán los procedimientos previstos en los
                                    CONTRATOS correspondientes.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={4}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Sobre el tratamiento y protección de datos personales.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l8">
                            <li data-list-text="4.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El responsable del tratamiento de los datos personales es BANCO
                                    GUAYAQUIL S.A., identificado con registro único de contribuyentes
                                    número 0990049459001, con domicilio en las calles Pichincha 107 y P.
                                    Icaza, en la ciudad de Guayaquil, provincia del Guayas, República
                                    del Ecuador.
                                </p>
                            </li>
                            <li data-list-text="4.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Los datos personales proporcionados por su titular o que sean
                                    recopilados, extraídos, generados, difundidos, transmitidos o
                                    gestionados de alguna forma por el BANCO, serán tratados de acuerdo
                                    con las disposiciones contenidas en el CONTRATO que el
                                    ESTABLECIMIENTO o el TARJETAHABIENTE, según
                                </p>
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    corresponda, suscribieron para poder acceder a la PLATAFORMA AMEX
                                    BUSINESS LINK.
                                </p>
                            </li>
                            <li data-list-text="4.3.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    En el mencionado CONTRATO, también constan los términos bajo los
                                    cuales el BANCO tratará los datos personales que recopile o gestione
                                    en el futuro.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={5}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Derechos de propiedad intelectual.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l9">
                            <li data-list-text="5.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO y/o AMERICAN EXPRESS®, según corresponda, se reservan para
                                    sí todos los derechos de propiedad intelectual e industrial sobre la
                                    PLATAFORMA AMEX BUSINESS LINK y sus sistemas, herramientas,
                                    aplicaciones y canales electrónicos que se utilizaren para su acceso
                                    y operación, lo que incluye su interfaz para interactuar con los
                                    usuarios (<i>look &amp; feel</i>), bases de datos o programas de
                                    ordenador subyacentes, códigos fuentes, contenido y elementos tales
                                    como gráficos, imágenes, textos, videos, sonidos, entre otros. De
                                    igual manera, el BANCO y/o AMERICAN EXPRESS®, según corresponda, se
                                    reservan la propiedad y uso de los signos distintivos que
                                    aparecieran en la PLATAFORMA AMEX BUSINESS LINK, de los que fueran
                                    titulares, licenciatarios o beneficiarios bajo cualquier título,
                                    tales como marcas, diseños, logotipos, nombres comerciales, lemas
                                    comerciales, apariencias distintivas y demás elementos sujetos a
                                    protección, que hayan sido registrados o se encuentren en proceso de
                                    registro ante la autoridad competente.
                                </p>
                            </li>
                            <li data-list-text="5.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO autoriza al USUARIO el acceso y uso de la PLATAFORMA AMEX
                                    BUSINESS LINK de forma no exclusiva. Por lo tanto, el USUARIO
                                    reconoce que el uso de la PLATAFORMA AMEX BUSINESS LINK, así como la
                                    contratación o uso de los productos o servicios ofrecidos a través
                                    de esta, no implica bajo circunstancia alguna la cesión o
                                    transferencia de los derechos de propiedad intelectual o industrial
                                    que corresponden al BANCO bajo las calidades antes indicadas, sobre
                                    la PLATAFORMA AMEX BUSINESS LINK, su web ni sobre sus elementos,
                                    incluidos los signos distintivos. En consecuencia, el USUARIO no
                                    podrá, ni total ni parcialmente, reproducir o distribuir su
                                    contenido, transformar, traducir, preparar trabajos derivados,
                                    descompilar, realizar ingeniería inversa, desmontar o tratar de
                                    obtener por cualquier medio los códigos fuentes de la PLATAFORMA
                                    AMEX BUSINESS LINK, o de cualquiera de los servicios o productos
                                    ofrecidos a través de esta, así como de los softwares o
                                    documentación del BANCO. El USUARIO tampoco podrá eliminar, ocultar
                                    ni modificar en cualquier forma los avisos sobre propiedad
                                    intelectual que estén incorporados o contenidos en la PLATAFORMA
                                    AMEX BUSINESS LINK, incluidos los productos o servicios ahí
                                    ofrecidos o en sus enlaces. El USUARIO no podrá realizar actividades
                                    en la PLATAFORMA AMEX BUSINESS LINK que pudieren dañar o comprometer
                                    su normal acceso y operación.
                                </p>
                            </li>
                            <li data-list-text="5.3.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO reconoce que deberá utilizar la PLATAFORMA AMEX BUSINESS
                                    LINK y los productos y servicios que se ofrecen a través de esta, de
                                    acuerdo con estos TÉRMINOS Y CONDICIONES y con base en las
                                    regulaciones que se
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    establezcan en los CONTRATOS y/u otros convenios, políticas y/o
                                    términos y condiciones aplicables a servicios o productos asociados
                                    a la PLATAFORMA AMEX BUSINESS LINK. El USUARIO se compromete también
                                    a acceder y operar la PLATAFORMA AMEX BUSINESS LINK y los productos
                                    y servicios ofrecidos a través de ésta, siguiendo los parámetros y
                                    recomendaciones de seguridad proporcionadas por el BANCO en el
                                    respectivo canal o en cualquier otro documento físico o virtual
                                    dispuesto para ello. Tales medidas de seguridad conllevarán, en
                                    particular, tener actualizados los sistemas de detección de{" "}
                                    <i>software </i>malicioso y los parches de seguridad de los
                                    navegadores, entre otras medidas similares. Por consiguiente, el
                                    USUARIO reconoce que el BANCO no responderá por los daños y
                                    perjuicios que aquél pudiera llegar a sufrir por incumplir dichos
                                    procedimientos, o por hechos de terceros sobre los que el BANCO no
                                    tuviere control o responsabilidad, tales como riesgos inherentes a
                                    los sistemas de navegación, dispositivos o en general medios
                                    empleados para acceder a la PLATAFORMA AMEX BUSINESS LINK y hacer
                                    uso de ella, incluidos los daños y perjuicios ocasionados por
                                    cualquier tipo de software maliciosos, virus o cualquier otro tipo
                                    de elemento dañino.
                                </p>
                            </li>
                            <li data-list-text="5.4.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO podrá incluir en la PLATAFORMA AMEX BUSINESS LINK, si así
                                    fuera del caso, enlaces a otros sitios web propios o de terceros,
                                    sin que ello signifique promoción, recomendación o conformidad con
                                    su contenido. En especial, en el caso de enlaces de terceros, el
                                    BANCO no será responsable de la veracidad, exactitud, vigencia,
                                    suficiencia o integridad de la información contenida en dichos
                                    enlaces, ni de los servicios promocionados o facilitados en ellos o
                                    de sus políticas de uso o medidas de seguridad, todo lo cual deberá
                                    ser verificado por el USUARIO al momento de su ingreso. Cualquier
                                    reclamo del USUARIO sobre su operación o contenido, deberá ser
                                    dirigido de forma directa al proveedor de dichos servicios o al
                                    autor de tales contenidos, pues el USUARIO desde ya reconoce que el
                                    BANCO no mantiene responsabilidad sobre tal particular.
                                </p>
                            </li>
                            <li data-list-text="5.5.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO aclara, y el USUARIO entiende y acepta sin reserva alguna,
                                    que los datos y demás información mostrada o contenida en la
                                    PLATAFORMA AMEX BUSINESS LINK no constituyen una opinión técnica, ni
                                    asesoramiento legal, fiscal, financiero, de inversión, técnico, ni
                                    oferta o garantía, ni debe ser entendida como una recomendación para
                                    realizar alguna operación o para tomar alguna decisión, por lo que
                                    no se hace responsable del uso o destino que se le dé en dicho
                                    sentido. El BANCO tampoco será responsable de que la información
                                    contenida en la PLATAFORMA AMEX BUSINESS LINK responda a las
                                    expectativas del USUARIO.
                                </p>
                            </li>
                            <li data-list-text="5.6.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    En caso de que el USUARIO detecte en la PLATAFORMA AMEX BUSINESS
                                    LINK algún funcionamiento irregular, indicios de vulneración de sus
                                    sistemas de seguridad o algún contenido que pudiere ocasionar daños
                                    o perjuicios al BANCO, al USUARIO o a terceros, o que fuere
                                    contrario al orden público y ordenamiento jurídico vigente, se
                                    compromete a notificar de forma inmediata al BANCO, a través de los
                                    canales de atención al cliente dispuestos por este último. La
                                    recepción de la notificación por parte del BANCO no supone su
                                    aceptación de los hechos denunciados. Sin embargo, el BANCO podrá
                                    suspender la operación de la PLATAFORMA AMEX BUSINESS LINK, o
                                    retirar los contenidos denunciados como ilícitos o lesivos.
                                </p>
                            </li>
                            <li data-list-text="5.7.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Finalmente, el BANCO se reserva el derecho de actualizar y modificar
                                    la PLATAFORMA AMEX BUSINESS LINK, incluidas sus interfaces,
                                    contenidos y productos y servicios que se ofrecen a través de ella,
                                    en cualquier momento y sin necesidad de aviso previo o solicitud del
                                    consentimiento del USUARIO.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={6}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Uso de medios electrónicos.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l10">
                            <li data-list-text="6.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO acepta y declara que todas las instrucciones y órdenes
                                    impartidas a través de las IDENTIFICACIONES ELECTRÓNICAS se
                                    considerarán, sin excepción, como manifestación expresa de su
                                    voluntad. El USUARIO renuncia irrevocablemente, sin reserva alguna a
                                    oponer, bajo cualquier circunstancia, la
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    alegación de que, en las IDENTIFICACIONES ELECTRÓNICAS, no consta su
                                    firma manuscrita o la(s) firma(s) manuscrita(s) de su(s)
                                    representante(s); o, de que éstas no guardan relación con las firmas
                                    autógrafas registradas en los archivos del BANCO. Por lo tanto, las
                                    transacciones y demás operaciones así solicitadas y procesadas se
                                    considerarán válidas, libres y conscientes, y el BANCO podrá
                                    tramitarlas sin necesidad de confirmación o requisito adicional
                                    alguno. Esto sin perjuicio de las verificaciones que el BANCO por su
                                    parte pueda realizar. En dicho sentido, el USUARIO reconoce a los
                                    mecanismos de identificación electrónica previstos por el BANCO, la
                                    misma confiabilidad y efectos jurídicos de las firmas electrónicas,
                                    contemplados en la Ley de Comercio Electrónico, Firmas Electrónicas
                                    y Mensajes de Datos, y demás legislación aplicable. Por lo tanto, en
                                    ninguna circunstancia el USUARIO podrá desconocer los actos
                                    realizados mediante tales IDENTIFICACIONES ELECTRÓNICAS, ni aún a
                                    pretexto de que fueron realizados por una persona no autorizada.
                                </p>
                            </li>
                            <li data-list-text="6.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Los USUARIOS reconocen además que los documentos y, en general, los
                                    registros históricos recopilados en la PLATAFORMA AMEX BUSINESS
                                    LINK, sistemas y/o redes del BANCO, constituirán prueba plena y
                                    evidencia suficiente de la solicitud, instrucciones, transacciones,
                                    operaciones y/o autorizaciones emitidas por el USUARIO, renunciando
                                    éste de manera expresa a su impugnación. El USUARIO autoriza
                                    expresamente al BANCO a grabar, captar, registrar y/o almacenar la
                                    información antes mencionada y a reproducirla, mostrarla y/o
                                    exhibirla en la medida que sea necesario aclarar, explicar,
                                    demostrar, probar, y/o verificar las instrucciones impartidas y
                                    operaciones realizadas, en especial ante cualquier autoridad
                                    administrativa o judicial.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={7}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Limitación de responsabilidad.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l11">
                            <li data-list-text="7.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El BANCO no responderá por los daños o perjuicios, directos,
                                    indirectos o de cualquier naturaleza que pudieran sufrir el USUARIO
                                    o terceros, y que se originaren por cualquiera de las siguientes
                                    circunstancias: <b>(i) </b>si la consulta u operación requerida por
                                    el USUARIO no pudiera procesarse por no encuadrarse en los
                                    parámetros prefijados por el BANCO para la prestación de los
                                    servicios a través de la PLATAFORMA AMEX BUSINESS LINK. En tal
                                    sentido, el USUARIO reconoce que la PLATAFORMA AMEX BUSINESS LINK es
                                    un mecanismo o sistema electrónico que ha sido programado por el
                                    BANCO para que sus servicios operen bajo parámetros predeterminados,
                                    por lo que los requerimientos que no se ajusten a dichos parámetros
                                    no serán procesados; <b>(ii) </b>por incompatibilidad con el
                                    software del equipo o dispositivo desde el cual el USUARIO accede a
                                    la PLATAFORMA AMEX BUSINESS LINK. En ese sentido, el BANCO podrá
                                    recomendar al USUARIO, sin asumir responsabilidad por ello, que se
                                    utilice determinados softwares, sistemas operativos, versiones o
                                    navegadores, para el correcto desempeño de la PLATAFORMA AMEX
                                    BUSINESS LINK; <b>(iii) </b>por virus, ataques cibernéticos,
                                    vulneraciones, filtraciones u otras modalidades de intrusión que
                                    afecten la seguridad de las redes, sistemas y/o dispositivos desde
                                    los cuales el USUARIO opere la PLATAFORMA AMEX BUSINESS LINK.{" "}
                                    <b>(iv) </b>Así también, por problemas o errores operativos o de
                                    conectividad de tales redes o sistemas, o por restricciones
                                    impuestas por autoridades administrativas o judiciales para su
                                    funcionamiento; <b>(v) </b>por errores del USUARIO en el uso de sus
                                    IDENTIFICACIONES ELECTRÓNICAS (ejemplo: error en el ingreso de datos
                                    de identificación o de contraseñas), DISPOSITIVOS DE PAGO, o, en
                                    general, por el manejo incorrecto del equipo o dispositivo a través
                                    del cual el USUARIO accede a la PLATAFORMA AMEX BUSINESS LINK;{" "}
                                    <b>(vi) </b>por la sustracción, pérdida, deterioro o destrucción de
                                    los equipos o dispositivos electrónicos utilizados por el USUARIO
                                    para operar la PLATAFORMA AMEX BUSINESS LINK; <b>(vii) </b>por
                                    negligencia del USUARIO en el acceso y manejo de la PLATAFORMA AMEX
                                    BUSINESS LINK o negligencia en la custodia o uso de las
                                    IDENTIFICACIONES ELECTRÓNICAS, DISPOSITIVOS DE PAGO, claves,
                                    códigos, coordenadas,
                                </p>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    tarjetas u otros elementos de identificación personal requeridos
                                    para su acceso, lo que incluye incumplir con las recomendaciones de
                                    seguridad dadas por el BANCO;
                                </p>
                                <p
                                    className="s1"
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    (viii){" "}
                                    <span className="p">
                                        por la calidad del servicio de internet utilizado por el USUARIO
                                        para la conectividad del equipo o dispositivo con el que accede a
                                        la PLATAFORMA AMEX BUSINESS LINK;{" "}
                                    </span>
                                    (ix){" "}
                                    <span className="p">
                                        por el uso o destino que el USUARIO confiera a la información
                                        recibida u operaciones realizadas a través de la PLATAFORMA AMEX
                                        BUSINESS LINK;{" "}
                                    </span>
                                    (x){" "}
                                    <span className="p">
                                        por los valores cobrados por el operador del servicio internet o
                                        cualquier otro sistema utilizado por el USUARIO para acceder y
                                        operar la PLATAFORMA AMEX BUSINESS LINK;{" "}
                                    </span>
                                    (xi){" "}
                                    <span className="p">
                                        por casos fortuitos o situaciones de fuerza mayor según la
                                        definición contenida en el Código Civil; ni,{" "}
                                    </span>
                                    (xii){" "}
                                    <span className="p">
                                        en general, por cualquier otro hecho u actuación que no pueda ser
                                        directa e inequívocamente imputable al BANCO.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={8}>
                        <h1
                            style={{
                                paddingTop: "12pt",
                                paddingLeft: "41pt",
                                textIndent: "-32pt",
                                textAlign: "left"
                            }}
                        >
                            Licitud de fondos y transacciones.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l12">
                            <li data-list-text="8.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO reconoce y garantiza al BANCO que las operaciones que se
                                    realicen en o a través de la PLATAFORMA AMEX BUSINESS LINK, así como
                                    todos los fondos o recursos que se reciban, transfieran o dispongan
                                    a través de ellos, deberán tener origen y finalidad lícitos.
                                    Consecuentemente, no podrán estar vinculados a actividades ilegales
                                    o ilícitas relacionadas y/o tipificadas como tales en la ley.
                                </p>
                            </li>
                            <li data-list-text="8.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Además, el USUARIO reconoce y acepta que el BANCO se reserva la
                                    facultad de realizar los análisis y verificaciones que considere
                                    pertinentes, de requerir la información y documentación que estime
                                    necesaria, e informar a la autoridad competente en casos de
                                    investigación de los actos sancionados en las leyes, o en el evento
                                    de que se detectaran transacciones o movimientos que admitan ser
                                    calificados o considerados como inusuales o injustificados.
                                </p>
                            </li>
                            <li data-list-text="8.3.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El USUARIO se compromete a respetar en todo momento el orden público
                                    y la legislación vigente, por lo que no podrá emplear los productos
                                    y servicios que se ofrecen a través de la PLATAFORMA AMEX BUSINESS
                                    LINK para fines ilícitos o fraudulentos tipificados como tales en la
                                    legislación ecuatoriana; o, de cualquier otra forma que pudiera
                                    causar daños o perjuicios al BANCO o a terceros, incluido el daño a
                                    la reputación e imagen del BANCO.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={9}>
                        <p
                            className="s1"
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-32pt",
                                textAlign: "justify"
                            }}
                        >
                            <u>
                                Plazo de estos TÉRMINOS Y CONDICIONES y modificaciones o reformas{" "}
                            </u>{" "}
                            <u>posteriores.</u>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l13">
                            <li data-list-text="9.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Estos TÉRMINOS Y CONDICIONES entrarán en vigor en la fecha en que
                                    sean publicados en la PLATAFORMA AMEX BUSINESS LINK y su vigencia
                                    será de carácter indefinido.
                                </p>
                            </li>
                            <li data-list-text="9.2.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    Sin perjuicio de lo anterior, el BANCO se reserva el derecho de
                                    actualizar, modificar o eliminar en cualquier tiempo estos TÉRMINOS
                                    Y CONDICIONES, ya sea en forma total o parcial. Dichas
                                    actualizaciones, modificaciones o eliminaciones surtirán efectos
                                    inmediatos a partir de su publicación en la PLATAFORMA AMEX BUSINESS
                                    LINK o a partir de la fecha de su notificación a los USUARIOS por
                                    cualquier medio que el BANCO considere oportuno, lo que suceda
                                    primero.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text={10}>
                        <h1
                            style={{ paddingLeft: "41pt", textIndent: "-32pt", textAlign: "left" }}
                        >
                            Legislación aplicable y controversias.
                        </h1>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <ol id="l14">
                            <li data-list-text="10.1.">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-36pt",
                                        textAlign: "justify"
                                    }}
                                >
                                    El uso y contenido de la PLATAFORMA AMEX BUSINESS LINK, así como los
                                    servicios y productos que lo integran, están regulados por el
                                    ordenamiento jurídico ecuatoriano. En consecuencia, conflicto
                                    relacionado con cualquiera de los productos o servicios de la
                                    PLATAFORMA AMEX BUSINESS LINK deberá ser resuelto bajo esa
                                    legislación.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </>
    )
}

export default TermsComponent;