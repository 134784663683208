import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import UserForm from '../../components/UserForm';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    lastName: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    position: '',
    adminPosition: '',
    roleType: -1,
    roles: [],
    edit: false,
    hidden: false,
  });

  componentDidMount() {
    const {
      auth: { user: { user_id } },
      fetchRoles,
      fetchDocumentTypes,
      router: { location: { pathname } },
      documentType: { list: documentTypeList },
    } = this.props;

    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        owner_id: user_id,
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleItemChange = (newValue) => {
    this.setState({ roles: newValue });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  createUser = () => {
    const {
      createProvider,
      editProvider,
      editUser,
      t,
      showMessage,
      documentType: { list: documentTypesList },
      role: { list: roleList },
      router: { location: { pathname } },
      history,
    } = this.props;

    const {
      name,
      lastName,
      roleType,
      documentType,
      documentNumber,
      email,
      roles,
      adminPosition,
    } = this.state;

    if (documentType === -1) {
      showMessage({
        message: t('SelectDocumentType'),
        config: TOAST_CONFIG.WARNING,
      });
      return;
    }

    let role_name = [];

    if (roles.length === 1) {
      role_name = roles[0].id;
    } else {
      for (let i = 0; i < roles.length; i++) {
        role_name.push(roles[i].id);
      }
    }

    const data = {
      name,
      lastname: lastName,
      document_type: documentType,
      document_number: documentNumber,
      email,
      role_name,
      user_type: 'flecha',
      agency_user_type: 'business_admin',
      b2b: {
        bank_id: null,
        admin: 0,
        admin_position: adminPosition,
        issuing_bank: 0,
        acquiring_bank: 0,
        seller: 0,
        buyer: 0,
      },
    };

    switch (data.document_type) {
      case 0:
        data.document_type = 'CI';
        break;
      case 1:
        data.document_type = 'Pasaporte';
        break;
      case 2:
        data.document_type = 'Pasaporte';
        break;
    }

    if (this.validateEmptyFields()) {
     // if (this.isEmail(email)) {
        createProvider({
          data,
          is_perfil: true,
          callback: (response) => {
            console.info(response);
            history.push('/dashboard');
            showMessage({
              message: t('createUserSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
          },
        });
      /*} else {
        showMessage({
          message: t('incorrectEmail', { email }),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }*/
    } else {
      showMessage({
        message: t('emptyAll'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      lastName,
      documentNumber,
      documentType,
      email,
      roles,
      adminPosition,
    } = this.state;

    return !(
      name === '' ||
      lastName === '' ||
      documentNumber === '' ||
      documentType === -1 ||
      email === '' ||
      adminPosition === '' ||
      roles.length < 0);
  };

  labelType = (roleList) => {
    const {
      t,
    } = this.props;
    const roles = [];

    for (let i = 0; i < roleList.length; i++) {
      roles.push({
        id: roleList[i].id,
        name: roleList[i].name,
        type: roleList[i].type,
        owner_id: roleList[i].owner_id,
        label_type: t(roleList[i].name)
      });
    }

    return roles;
  };

  render() {
    const {
      t,
      documentType: { list: documentTypesList, loading: documentLoading },
      role: { list: roleList, loading: roleLoading },
    } = this.props;
    const {
      name,
      lastName,
      documentNumber,
      email,
      adminPosition,
      documentType,
      edit,
      hidden,
      roles,
    } = this.state;
    return (
      <div>
        {(documentLoading || roleLoading) && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <UserForm
              t={t}
              title={t('createUser')}
              onSubmit={this.createUser}
              handleInputChange={this.handleInputChange}
              name={name}
              lastName={lastName}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              documentNumber={documentNumber}
              documentType={documentType}
              email={email}
              position={adminPosition}
              roleList={this.labelType(roleList)}
              roles={roles}
              edit={edit}
              hidden={hidden}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateUser.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  roles: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                           providers,
                           documentType,
                           auth,
                           role,
                           router,
                         }) => ({
  providers,
  documentType,
  auth,
  role,
  router,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...roleActions,
  }),
  withNamespaces(),
)(CreateUser);
