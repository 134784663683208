import { clientsActions, clientsTypes } from '../ducks/clients';

const fetchClients = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_CLIENTS) {
    return;
  }

  const {
    params: {
      id = '',
      related = false,
    } = {},
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/clients?id=${id}&related=${related}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(clientsActions.saveClients(data));
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchClient = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_CLIENT) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/client?id=${id}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(clientsActions.setClient(data));
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchClientsConditions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_CLIENTS_CONDITIONS) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/clients-with-conditions?id=${id}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(clientsActions.setClientsConditions(data));
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const sendResponse = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.APPROVE_INVOICE) {
    return;
  }
  const {
    data,
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/approve',
        data,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const defer = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.DEFER_INVOICE) {
    return;
  }

  const {
    data,
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/defer',
        data,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const editUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.EDIT_USER) {
    return;
  }

  const {
    data,
    callback = () => {},
  } = action.payload;

  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/user/update-buyer-seller-information',
        data,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};


const searchUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.SEARCH_USER) {
    return;
  }

  const {
    params: {
      document = '',
      document_type = '',
    } = {},
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/search-user-by-document?document=${document}&document_type=${document_type}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const addRoleUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.ADD_ROLE_USER) {
    return;
  }

  const {
    data,
    callback = () => {},
  } = action.payload;

  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/add-role-user',
        data,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

export default [fetchClients, fetchClientsConditions, sendResponse, defer, fetchClient, editUser, searchUser, addRoleUser];