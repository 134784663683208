const START_FETCH = 'Card/START_FETCH';
const END_FETCH = 'Card/END_FETCH';
const API_CALL = 'Card/API_CALL';
const MESSAGE = 'Card/MESSAGE';
const FETCH_CARD_DATA = 'Card/FETCH_CARD_DATA';
const UPDATE_CARD_DATA = 'Card/UPDATE_CARD_DATA';
const RECHARGE_CARD = 'Card/RECHARGE_CARD';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_CARD_DATA,
  UPDATE_CARD_DATA,
  RECHARGE_CARD,
};
