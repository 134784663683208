const pt = {
  translations: {
    accept: 'Ok',
    acceptTerms: 'Aceite os termos e condições do Acordo',
    account: 'Conta',
    accountBalance: 'Saldo da conta',
    accountPlural: 'Contas',
    accumulatedAmount: 'Saldo acumulado diário',
    acquiring_bank: 'Banco Adquirente',
    action: 'Operação',
    active: 'Ativo',
    activate: 'Ativar',
    addComment: 'Inserir comentário ...',
    addFinanceProductSuccess: 'Produto de financiamento criado com sucesso',
    addFinancingProducts: 'Adicionar produtos de financiamento',
    additionalInformation: 'Informação Adicional',
    address: 'Endereço',
    admin: 'Administrar',
    adminRoutes: 'Administrar endereços',
    adminUser: 'Usuario Administrador',
    authUser: 'Usuário autorizado',
    advance: 'Antecipar',
    advanceCommercialConditions: 'Condições comerciais de anticipo',
    affiliateCode: 'Código de Afiliado',
    agreementText: 'Ao utilizar nossa plataforma você concorda com nossos',
    alias: 'Pseudônimo',
    amount: 'Valor',
    amountTotal: 'Valor total',
    amountInstallments: 'Valor das parcelas',
    amountMoney: 'Valor do dinheiro',
    amountToCollect: 'Extrato diário',
    amountToGet: 'Saldo a receber',
    amountToPay: 'Saldo a pagar',
    anticipatedAmount: 'Valor Antecipado',
    approvalDate: 'Data de aprovação',
    approve: 'Aprovar',
    approver: 'Aprovador',
    approved: 'Aprovadas',
    approvedInvoices: 'F. Aprovadas',
    approvedInvoicesTitle: 'Faturas Aprovadas',
    arrangedDays: 'Días acordados',
    assign: 'Atribuir',
    assignRoute: 'Atribuir rotas',
    assignedRoutes: 'Endereços atribuidos',
    assignRoleRoute: 'A função e o endereço foram configurados com sucesso',
    assignRouteSuccess: 'O endereço foi configurado com sucesso',
    authorization: 'Autorização',
    available: 'Acessível',
    avance: 'Anticipo',
    balance: 'Saldo',
    bankList: 'Lista de bancos',
    bankName: 'Nome do banco',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condições gerais (Pagamento de Faturas)',
    bills: 'Faturas enviadas',
    billPayment: 'Pagamento de faturas',
    businessName: 'Razão social',
    by_amount: 'Por quantidade',
    by_hierarchy: 'Por hierarquia de posições',
    cardPaymentDate: 'Data do pagamento com cartão',
    charge: 'Cargo',
    chargeDate: 'Data de cobrança',
    clientName: 'Nome do cliente',
    clientPaymentCondition: 'Determine as condições de pagamento para este',
    codeArea: 'Prefixo + n˚ telefone',
    commercialConditions: 'Condições Comercias',
    condition: 'Condição',
    concept: 'Conceito',
    confirm: 'Aceitar',
    confirmInvoices: 'Confirme as faturas',
    confirmPassword: 'Confirmar senha',
    confirmTransfer: 'Confirmar sua operação',
    continue: 'Continuar',
    consult: 'Consultar',
    consultTradeAffiliationCode: 'Consulte o código de afiliação comercial',
    createBank: 'Criar novo banco',
    createBankSuccess: 'Banco {{name}} criado com sucesso',
    createClientSuccess: 'Cliente {{name}} criado com sucesso',
    createCustomer: 'Criar \nCliente',
    createInvoiceSuccess: 'A factura N° {{invoice_number}} foi registrada com sucesso',
    createNewItem: 'Criar nova industria "{{inputValue}}"',
    createProvider: 'Criar \nFornecedor',
    createProviderSuccess: 'Fornecedor {{name}} criado com sucesso',
    createProfile: 'Criar Perfil',
    createProfileSuccess: 'Perfil {{name}} criado com sucesso',
    createRol: 'Criar função',
    createRolSuccess: 'Função {{rolName}} criada com sucesso',
    createUser: 'Criar usuário',
    createUserSuccess: 'usuário {{name}} criada com sucesso',
    createFlowApproval: 'Criar fluxo de aprovação',
    createFlowApprovalSuccess: 'Approval flow, criada com sucesso',
    creditPeriod: 'Período de crédito',
    customer: 'Cliente',
    customerInformation: 'Informação do cliente selecionado',
    customerName: 'Nome do cliente',
    customers: 'Clientes',
    code: 'Código',
    cort: 'Corte',
    cortDate: 'Data de corte',
    configuration: 'Configuração',
    changePassword: 'Mudar senha',
    changePasswordSuccess: 'Senha alterada com sucesso',
    insertYourEmailAddress: 'Insira o seu endereço de email',
    enterYourNewPassword: 'Digite sua nova senha',
    successEmail: 'Um email foi enviado para o seu endereço de email.',
    daily: 'Diário',
    dashboard: 'Sumário',
    date: 'Data',
    daysNumber: 'Número de días',
    defer: 'Diferido',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteInvoice: 'A fatura foi excluída',
    deletePaymentCondition: 'A condição de pagamento foi removida',
    demographicInformation: 'Dados de identificação',
    deposit: 'Depositar',
    description: 'Descripción',
    descriptionOfTheMerchantAffiliateCode: 'Descrição do código de afiliado do comerciante',
    destinationAccount: 'Cuenta destino',
    deactivate: 'Desativar',
    detail: 'Detalle',
    differ: 'Adiado',
    discountRate: 'Taxa de desconto',
    discountRateOutOfLimits: 'Taxa de desconto fora dos limites',
    documentNumber: 'Nro. de documento',
    documentType: 'Tipo de documento',
    document: 'Documento',
    dontPose: 'Não tem',
    edit: 'Alterar',
    editPaymentConditionSuccess: 'Condições de pago editada',
    editClientSuccess: 'Cliente {{name}} editada',
    editProviderSuccess: 'Fornecedor {{name}} editada',
    editProfileSuccess: 'Perfil {{name}} editada',
    editUserSuccess: 'Usuário {{name}} editada',
    email: 'Correio Electrônico',
    emptyFields: 'Devem ser completados todos os campos.',
    emptyAll: 'Certifique-se de preencher todas as informações',
    enter: 'Entrar',
    enterCodeSms: 'Verifique e insira o código\n de segurança enviado por SMS',
    errors: 'Erros',
    errorDate: 'A data \'Desde\' que deve ser menor',
    exemptAmount: 'Subtotal sem IVA',
    exemptAmountTable: 'Sub. sem IVA',
    taxAmountTable: 'Sub. com VAT',
    errorLoad: 'Você deve carregar um arquivo de extensão {{type}}',
    expiredInvoices: 'Aprovadas / Pagadas',
    extract: 'Sacar',
    extractAccount: 'Saque na rede B2B',
    extractDetail1: 'Codigo de aprobação gerado com sucesso',
    extractDetail2: 'Saldo a retirar',
    extractDetail3: 'Em',
    extractDetail4: 'Completar o saque em um local da nossa rede',
    extractDetail5: 'Com o seu número de telefone',
    extractDetail6: 'Proporcione o numero de telefone ao operador do local para completar o saque iniciado',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Apresentese con o seguinte codigo no local da nossa rede',
    financingProducts: 'Produtos de \nfinanciamento',
    finish: 'Finalizar',
    fiscaldocument: 'Documento Fiscal',
    flexibleConditions: 'Condições Flexíveis (Financiamento)',
    flowApproval: 'Fluxo de aprovação',
    flowsApproval: 'fluxos de aprovação',
    flowApprovalCheck: 'fluxo de aprovação padrão',
    forgotPassword: '¿Esqueceu a sua senha?',
    fullName: 'Nome completo',
    goBack: 'Voltar',
    greaterThan: 'Major',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menos igual',
    greaterThanOrEqualTo: 'Maior igual',
    fileSize: 'O tamanho máximo permitido é de {{size}}',
    fileSizeExceeded: 'Tamanho do arquivo excedido',
    import: 'Quantidade',
    issuedInvoices: 'Faturas emitidas',
    inCharge: 'Responsável',
    incorrectDate: 'Você não pode inserir uma data antes, {{dateNow}}',
    incorrectEmail: '{{email}} ñao parece um email válido. Verifique se você tem o formato correto',
    incorrectDays: 'Você deve inserir um valor maior ou igual a 0',
    installmentNumber: 'Número de parcelas',
    installments: 'N° de parcelas',
    interestRate: 'Taxa de juros',
    invalidAmount: 'Quantidade inválida',
    invalidRoleOrRoute: 'A função e o endereço não podem estar vazios',
    invoiceCount: 'Número de Faturas',
    invoiceDate: 'Data da fatura',
    invoice: 'Factura',
    invoices: 'Faturas',
    invoicesApprover: 'Aprovador de faturas',
    invoicesApproverCheckbox: 'Permitir a aprovação de faturas de dentro ou fora do fluxo de aprovação.',
    invoicesNumber: 'Número de fatura',
    invoiceTotalAmount: 'Valor em Faturas',
    invoiceSend: 'Data de envio',
    invoiceDetail: 'Detalhe de fatura',
    invoicesError: 'Faturas com erros: {{ cant }} ',
    invoiceGroup: 'Grupo de fatura',
    invoicesToApproved: 'Contas a aprovar',
    invoicePending: 'Emitir',
    invoiceProvidersReceiver: 'Fatura de recebimento',
    invoiceManualResolution: 'Faturas que exigem resolução manual',
    invoiceMarkPaid: 'A fatura nº {{invoiceNumber}} foi marcada como paga',
    invoicesSentSuccess: 'Faturas enviadas com sucesso',
    issuing_bank: 'Banco Emisor',
    issuing_query: 'Emisor inquerir',
    quantity: 'Quantidade',
    acquiring_query: 'Adquirente inquerir',
    items: 'Indústrias',
    lastDays: 'Últimos días',
    lastMovements: 'Últimas operações',
    listInvoices: 'Em revisão',
    listPayments: 'Lista de pagos',
    load: 'Carregar',
    loadCsv: 'Carga CSV',
    loadZip: 'Carregar mídia ZIP',
    loading: 'Carregando...',
    loadingContent: 'Carregando conteúdo ...',
    loadInvoice: 'Carregando \nFatura',
    loanPeriod: 'Período do empréstimo',
    logout: 'Sair',
    loginAsCustomer: 'Faça login como cliente',
    loginAsProvider: 'Faça login como provedor',
    logView: 'Visualização de log',
    operationLog: 'Log de eventos',
    mainUser: 'Usuario Principal',
    markAsPaid: 'Marcar como pago',
    manualResolution: 'Resolução Manual',
    maximumPaymentDate: 'Data máxima de pagamento',
    message: 'Mensagem',
    messageUserProcess: 'No momento, você não possui um tipo de fluxo de aprovação configurado. Entre em contato com seu representante para ativar esta funcionalidade.',
    merchantAffiliateCode: 'Código de afiliado do comerciante',
    monthly: 'Mensal',
    name: 'Nome',
    newBank: 'Novo banco',
    newPassword: 'Nova senha',
    newPaymentCondition: 'Novas Condições de Pago',
    newRecharge: 'Novas Recargas',
    newProfile: 'Novo usuário',
    newRole: 'Nova função',
    newTransfer: 'Nova Transferencia',
    next: 'Continuar',
    nextCollection: 'Próxima Cobranza',
    nextDays: 'Próximos días',
    nextPage: 'Próxima',
    nextPayment: 'Próximo Pagamento',
    noCard: 'Não possui cartão prepagado',
    noData: 'Sem informação',
    noItemsAvailable: 'Não existem industrias disponiveis',
    nonMatchingPasswords: 'As senhas inseridas não correspondem',
    noRowsFound: 'Sem informação',
    noTransactions: 'Este cartão não possue operações',
    numberInstallments: 'Número de parcelas',
    of: 'de',
    operationVolume: 'Quantidade de Operações',
    operator: 'Condição',
    originAccount: 'Conta de origem',
    params: 'Parâmetros',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Valor Pagado',
    paidInvoices: 'Faturas Pagadas',
    paidOut: 'Pago',
    password: 'Senha',
    payedAmounts: 'Total diario faturado',
    billsToPay: 'Total diário a ser cobrado',
    payment: 'Pagamento',
    paymentDateInvoice: 'Data de cobrança:',
    paymentConditionProvider: 'Condições comerciais para fornecedores de',
    paymentConditionClients: 'Condições comerciais para clientes de',
    paymentDate: 'Data de cobranza',
    paymentDateTC: 'Data de cobrança do TC',
    datePayment: 'Data de pagamento',
    paymentDeadline: 'Prazo de pagamento',
    paymentOrders: 'Ordens de Pagamento',
    paymentsTransfers: 'Pagamento e Transferencias',
    pending: 'Pendentes',
    pendingInvoices: 'F. Pendentes',
    period: 'Periodo',
    present: 'Atual',
    prepaidCard: 'Cartões Prepagados',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    productName: 'Nome do Produto',
    profileName: 'Nome do perfil',
    provider: 'Fornecedor',
    profileList: 'Lista de usuários',
    providerName: 'Nome do fornecedor',
    providers: 'Fornecedores',
    providerRUC: 'Fornecedores RUC',
    providerPaymentCondition: 'Determinar as condições de pagamento para este provedor',
    query: 'Inquerir',
    rate: 'Percentual',
    dateRange: 'Período',
    respJson: 'Responder JSON',
    respXml: 'Responder XML',
    response: 'Responder',
    receivedInvoices: 'Faturas \nRecebidas',
    receptionDate: 'Data de recepção',
    recharge: 'Recarregar',
    recipient: 'Destinatário',
    recoverPassword: 'Recuperar senha',
    registerBank: 'Registrar banco',
    registerRole: 'Registrar função',
    registerRoute: 'Registrar endereço',
    reject: 'Rejeitar',
    rejectedInvoices: 'F. Rejeitadas',
    rejectionDate: 'Data de rejeição',
    rejectReason: 'Rejeitar razão',
    relatedUsers: 'Usuarios Relacionados',
    removeRouteSuccess: 'O Endereço foi removido com sucesso',
    reportDate: 'Data do relatório',
    requiredInstallments: 'O numero de parcelas é invalido',
    requiredName: 'O nome é invalido',
    requiredPeriod: 'O periodo é invalido',
    requiredRate: 'A taxa é invalida',
    requiredTypeFinance: 'O tipo de financiamento é invalido',
    roleCantBeEmpty: 'A função não pode estar vazia',
    roleList: 'Lista de funções',
    roles: 'Funções',
    rol: 'Função',
    roleType: 'Tipo da função',
    rolName: 'Nome da função ',
    rolType: 'Tipo da função',
    routeList: 'Lista de endereços',
    routes: 'Endereços',
    rows: 'Filas',
    save: 'Salvar',
    Select: 'Escolha ...',
    selected: 'Selecionar',
    SelectDocumentType: 'Escolha um tipo de documento',
    SelectBank: 'Escolha um banco',
    SelectOption: 'Escolha uma opção',
    selectOrTypeItems: 'Escolha ou escreva uma industria...',
    selectOrTypeRoutes: 'Escolha ou escreva um endereço',
    selectOrTypeRol: 'Escolha ou escreva um função',
    selectRecipient: 'Escolha destinatario',
    send: 'Enviar',
    sendInvoice: 'Data de envio da fatura',
    sentInvoices: 'Faturas \nenviadas',
    sendInvoices: 'Faturas a serem enviadas: {{ cant }}',
    sendSelectedInvoices: 'Enviar faturas selecionadas',
    settings: 'Configurações',
    seeHow: 'Ver como',
    shippingDate: 'Data de envio',
    showQR: 'Mostrar código QR',
    since: 'De',
    taxAmounTable: 'Sub. com IVA',
    startCountingFrom: 'Dias acordados a partir de',
    summary: 'Resumo',
    targetNumber: 'Número de cartão',
    total: 'Total',
    terms: 'termos e condições',
    totalAmount: 'Valor total',
    totalBuyers: 'Total de empresas pagadoras',
    totalBuyersDeferred: 'Empresas com Faturas Diferidas',
    totalBuyersInvoiced: 'Empresas com Faturas Aprovadas',
    totalCharged: 'Total cobrado {{month}}',
    totalDebtAmount: 'Valor total do pagamento',
    totalOwedAmount: 'Valor total do cobro',
    totalPay: 'Total do pagamento',
    totalSellers: 'Fornecedores associados',
    totalSellersCharged: 'Fornecedores com faturamento Aprovado',
    totalSellersInvoiced: 'Proveedores com faturamente Pendente',
    totalToApprove: 'Total a aprovar',
    totalToReceiver: 'Total a receber',
    transactionType: 'Tipo de operação',
    transfer: 'Transferir',
    transferCharge: 'Taxa da operação',
    transferDetail2: 'Voucher',
    transferDetail3: 'Enviado para',
    transferDetail4: 'Valor enviado',
    transferDetail5: 'Valor total descontado',
    transferDetail: 'Transferência efetuada',
    transferState: 'Status',
    transferType: 'Tipo de operação',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamento',
    typeOfApprovalFlow: 'Tipo de fluxo de aprovação',
    typeOfEmitFlow: 'Tipo de fluxo de emissão',
    underReview: 'Em revisão',
    underReviewInvoices: 'Faturas em revisão',
    uniqueCode: 'Código único',
    uploadedFiles: 'Faturas processadas com sucesso',
    username: 'Nome do usuario',
    until: 'Até',
    virtualAccount: 'Conta Virtual',
    yearly: 'Anual',
    resource: 'Recurso',
    view: 'Ver',
    daysAdvance: 'Dias de adiantamento',
    daysDefer: 'Dias de diferimento',
    anualRate: 'Taxa anual',
    percentage: 'Taxa de juros anual',
    interest_rate: 'Juros',
    adjusted_amount: 'Montante',
    cantDeleteRol: 'A função não pode ser eliminada porque tem asignado um ou mais endereços',
    deleteRol: 'A função foi eliminada',
    deleteRoute: 'O Endereço foi eliminado',
    cantDeleteRoute: 'O endereço não pode ser eliminado porque esta asignada a uma ou mais funções',
    createLead: 'Requerer \n alta',
    sellerCreateLead: 'Requerer alta do fornecedor',
    buyerCreateLead: 'Requerer alta do cliente',
    requireSuccess: '{{name}} requerido com sucesso',
    buyerName: 'Nome do cliente',
    sellerName: 'Nome do fornecedor',
    leadList: 'Lista de \nrequisitos',
    createRoleSuccess: 'Função {{rolName}} criada com sucesso',
    updateBank: 'Atualizar banco',
    updateInformation: 'Atualizar informação',
    adminName: 'Nome',
    adminPosition: 'Posição',
    invoicesApproved: 'Faturas Aprovadas',
    approvedMount: 'Valor Aprovado',
    nInvoices: 'Nro. de Faturas',
    advanceMount: 'Valor adiantado',
    deferMount: 'Valor diferido',
    totalCommissionAmount: 'Comição total',
    discountAmount: 'Valor do desconto',
    reports: 'Reportes',
    createPaymentCondition: 'Criar condição \nde pagamento',
    addCondition: ' Adicionar condição',
    receiverDiscountShare: 'Porcentagem de pagamento do fornecedor',
    paymentClient: 'O pagamento do cliente é',
    createPaymentConditionSuccess: 'Condição de pagamento criada com sucesso',
    paymentCondition: 'Condição',
    client: 'Cliente',
    download: 'Descarregar',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    language: 'Idioma',
    lead: 'Alta',
    completeComercialConditionOrEmptyAll: 'Completar os dados das condições comerciais o deixeos vazios si não deseja adicionar condições comerciais com os fornecedores',
    transactions: 'Transações',
    totalReceiver: 'Total a cobrar',
    providersReceiver: 'Fornecedores por cobrar',
    clientsPay: 'Clientes por pagar',
    clients: 'Clientes',
    selectedBank: 'Banco',
    uploadInvoice: 'Carregar fatura',
    loadInvoiceData: 'Carregar dados de fatura',
    naoReter: 'Não reter IR (propaganda, publicidade e comições)',
    disable: 'Desabilitar',
    status: 'Estado',
    clientPay: 'Pagamento do cliente',
    referrer: 'Referido',
    // Despues de enviar a traduccion a portugues
    invoiceApprovedSuccess: 'Fatura aprovada com sucesso',
    invoicesApprovedSuccess: 'Fatura(s) aprovada(s) com sucesso',
    invoiceRejectedSuccess: 'Fatura rejeitada com sucesso',
    invoicesRejectedSuccess: 'Fatura(s) rejeitada(s) com sucesso',
    actionNotAllowed: 'Ação não permitida',
    limit: 'Limite',
    limitAmount: 'Montante limite',
    new: 'Novo',
    paymentMethod: 'Método de pagamento',
    target: 'Cartão',
    line: 'Linha',
    defaultFlow: 'Fluxo padrão',
    condition_amount: 'Quantidade',
    invoiceNumberValidate: 'O formato deve ser xxx-xxx-xxxxxxxxx',
    amountValidate: 'A quantia deve ser positiva',
    cantCreateTransaction: 'O número da fatura já existe',
    penddingInvoices: 'Você tem {{pending_invoices}} faturas pendentes sem aprovar',
    rejectInvoices: 'Você tem {{reject_invoices}} faturas rejeitadas',
    emitInvoices: 'Você tem {{emit_invoices}} faturas sem emitir',
    search: 'Procurar',
    main_bank: 'Usuário principal do Banco',
    user_list: 'Lista de Usuários',
    editFlowApprovalSuccess: 'fluxo de aprovação editado com sucesso',
    emissionFlow: 'Fluxo de Emissão',
    transactionReport: 'Relatório de transação',
    paymentDateRange: 'Período de coleta da fatura',
    paymentDateRangeClient: 'Período de pagamento da fatura',
    identificationNumber: 'Número de identificação',
    time: 'Hora',
    event: 'Evento',
    info: 'Em formação',
    infoInvoiceSuccess: '{{cant}} faturas selecionadas para enviar',
    infoInvoiceError: '{{cant}} faturas com erros',
    invoiceErrorInfo: '(Faturas com erros não serão levadas em consideração para envio)',
    questionInfo: '¿Tem certeza de que deseja enviar faturas de {{cant}}?',
    infoInvoices: 'Informação de fatura',
    infoError: 'Informação de erro',
    infoLog: 'Informações do evento',
    higherHierarchy: 'Hierarquia mais alta',
    highestHierarchyUser: 'Usuário da hierarquia mais alta',
    yes: 'Sim',
    no: 'Não',
    owner_role: "Papel do dono",
    taxAmount: 'Subtotal com IVA',
    ccChargeDate: 'Data de cobrança do CC',
    nextDayConditions: '*Condições aplicáveis: 24 horas após a aprovação do pagamento, exceto finais de semana e feriados, considera o primeiro dia útil.',
    vendorMerchantAffiliateCodes: 'Códigos de afiliados de comerciante do fornecedor: {{provider}}',
    affiliationCode: 'Códigos de afiliados do comerciante',
    buyerCode: 'Código do cliente',
    seller: 'Fornecedor',
    sellerCode: 'Código do Fornecedor',
    welcomeToB2b: 'Bem-vindo a B2B',
    financialCost: 'Custo financeiro',
    percentageChargeForService: 'Taxa percentual de serviço suportada pelo provedor',
    serviceInChargeOfTheClient: 'Taxa percentual por serviço a cargo do cliente',
    supplierPaymentPercentage: '% provedor de pagamento',
    customerPaymentPercentage: '% pagamento do cliente',
    updateFinancialCost: 'Atualizar % do custo financeiro',
    notHaveRelatedClients: 'Eles não têm clientes relacionados',
    negotiatedFinancialCost: 'Custo financeiro negociado',
    reconciliationReport: 'Relatório de reconciliação',
    '/create-provider': 'Criar fornecedores',
    '/create-customer': 'Criar clientes',
    '/dashboard': 'Tela principal',
    '/load-invoice': 'Carregar faturas',
    '/customers': 'Listar, editar ou desativar clientes',
    // '/invoices': 'Listar, editar, aprovar ou rejeitar faturas sem aprovar',
    '/expired-invoices': 'Listar faturas expiradas',
    '/related-users': 'Cliente ou fornecedores necessários',
    '/register-bank': 'Criar bancos',
    '/financing-products': 'Produtos financeiros',
    '/bank-list': 'Listar, editar ou desativar bancos',
    '/register-role': 'Criar funções',
    '/role-list': 'Listar, editar e excluir funções',
    '/register-route': 'Criar rotas',
    '/route-list': 'Listar, editar e excluir rotas',
    '/register-financing-products': 'Criar financiamento do produto',
    '/admin-routes': 'Atribuir ou remover rotas para funções',
    '/review-invoices': 'Listar, editar, aprovar ou rejeitar faturas sem aprovar',
    '/approved-invoices': 'Listar, adiantar ou adiar faturas aprovadas',
    '/paid-invoices': 'Listar faturas pagas',
    '/create-lead': 'Exigir clientes ou fornecedores',
    '/lead-list': 'Listar, aprovar ou rejeitar obrigatório',
    '/reports': 'Exporte faturas para o Excel por cliente ou fornecedor, de acordo com o usuário conectado',
    '/create-payment-condition': 'Criar condição de pagamento',
    '/transactions': 'Faturas geradas pelo banco',
    '/create-profile': 'Criar perfis',
    '/profile-list': 'Listar, editar ou excluir perfis',
    '/create-approval-flow': 'Criar fluxo de aprovação de fatura',
    '/account-profile': 'Conta de perfil',
    '/invoices': 'Faturas',
    '/reject-invoices': 'Faturas rejeitadas',
    '/invoice-detail': 'Detalle do fatura',
    '/approval-flow-list': 'Listar, editar fluxos de aprovação',
    '/edit-approval-flow': 'Editar fluxo de aprovação',
    '/select-role': 'Selecione o tipo de função para entrar no B2B',
    '/related-users-client': 'Usuários Relacionados',
    '/user-commerce-code': 'Códigos de afiliados do comerciante',
    '/list-invoice': 'Lista de faturas',
    '/load-invoice-group': 'Carregar grupo de fatura',
    //'/pending-invoices': '',
  },
};

export default pt;
