import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { BankForm, Loading } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';

class CreateBank extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    registerLoading: false,
    bankType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'flecha',
    agency_user_type: 'business_admin',
    b2b: {
      bank_id: null,
      admin: 0,
      issuing_bank: 0,
      acquiring_bank: 0,
      seller: 0,
      buyer: 0,
    },
  });

  componentDidMount() {
    const { fetchDocumentTypes, fetchRoles } = this.props;
    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        type: 'bank',
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  validateEmptyFields = () => {
    const {
      name,
      bankType,
      documentType,
      documentNumber,
      email,
      password,
      confirmPassword,
    } = this.state;

    if (name === '' || documentType === -1 || documentNumber === '' || bankType === -1
      || email === '' || password === '' || confirmPassword === '') {
      return false;
    }
    return true;
  };

  createProvider = () => {
    const {
      createProvider,
      showMessage,
      t,
      history,
      role: { list: roleList },
      documentType: { list: documentTypesList },
    } = this.props;

    const {
      name,
      bankType,
      documentType,
      documentNumber,
      email,
      password,
      confirmPassword,
    } = this.state;

    if (this.validateEmptyFields()) {
      if (password === confirmPassword) {
        const data = {
          name,
          document_type: documentTypesList[documentType].document_type,
          document_number: documentNumber,
          email,
          password,
          user_type: 'flecha',
          agency_user_type: 'business_admin',
          items: [],
          role_name: roleList[bankType].id,
          b2b: {
            bank_id: null,
            admin: 0,
            issuing_bank: 0,
            acquiring_bank: 0,
            [roleList[bankType].name]: 1,
            seller: 0,
            buyer: 0,
          },
        };
        this.setState({ registerLoading: true });
        createProvider({
          data,
          is_perfil: true,
          callback: () => {
            history.push('/bank-list');
            showMessage({
              message: t('createBankSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
          },
        });
      } else {
        showMessage({
          message: t('nonMatchingPasswords'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    } else {
      showMessage({
        message: t('emptyFields'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  render() {
    const {
      t,
      clients: { loading: clientLoading },
      documentType: { list: documentTypesList, loading: documentTypesLoading },
      role: { list: roleList, loading: roleLoading },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      password,
      confirmPassword,
      registerLoading,
      bankType,
      documentType,
    } = this.state;
    const loading = clientLoading || documentTypesLoading || roleLoading || registerLoading;
    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <BankForm
              documentTypesList={documentTypesList}
              roleList={roleList}
              name={name}
              documentNumber={documentNumber}
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              handleInputChange={this.handleInputChange}
              handleSelectChange={this.handleSelectChange}
              t={t}
              onSubmit={this.createProvider}
              bankType={bankType}
              documentType={documentType}
              title={t('createBank')}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateBank.propTypes = {
  clients: PropTypes.shape(Object).isRequired,
  role: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
                          clients,
                          documentType,
                          auth,
                          role,
                        }) => ({
  clients,
  documentType,
  auth,
  role,
});

export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...roleActions,
    ...clientsActions,
  }),
  withNamespaces(),
)(CreateBank);
