import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import RoleForm from '../../components/RoleForm';
import { authActions } from '../../state/ducks/auth';
import { customIcon } from '../../util';

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    is_approver: '',
    owner_role: '',
  });

  componentDidMount() {
    const {
      fetchRoles,
      fetchTypesRole,
      auth: {
        user: {
          b2b: { id },
        },
      },
    } = this.props;
    fetchTypesRole({});
    fetchRoles({
      params: {
        owner_id: id,
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  updateRol = (onToggle) => {
    const {
      editRole,
      role: { type: { list: typeList } },
      fetchRoles,
      auth: {
        user: { user_id, b2b: { roles, type_user } },
      },
    } = this.props;
    const { id, name, roleType, is_approver } = this.state;
    const data = {
      id,
      name,
      type: typeList[roleType],
      is_approver,
      owner_role_id: roles.map(
        (type, index) => ({
          ...type,
          index
        })
      )
        .filter(typeFil => typeFil.name
          === type_user)[0].id,
    };
    editRole({
      data,
      callback: () => {
        fetchRoles({
          params: {
            owner_id: user_id,
          },
        });
        onToggle();
        this.resetState();
      },
    });
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      role: { list },
    } = this.props;
    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.name} `,
      },
      {
        id: 'is_approver', // Required because our accessor is not a string
        Header: t('invoicesApprover'),
        accessor: d => d.is_approver === 1 ? t('yes') : t('no') ,
      },
      {
        id: 'owner_role', // Required because our accessor is not a string
        Header: t('owner_role'),
        accessor: d => `${t(d.owner_role)} ` ,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
              <tr>
                <td style={{ display: "flex", justifyContent: "center" }} >
                  <button
                    className="button is-primary is-inverted"
                    onClick={() => {
                      this.setState({
                        id: cellInfo.original.id,
                        name: cellInfo.original.name,
                        is_approver: cellInfo.original.is_approver
                      }, () => {
                        onToggle({
                          isEdit: true,
                          role: cellInfo.original,
                        });
                      });
                    }}
                  >
                    <span className="mr-8px">
                      {customIcon('FaRegEdit')}
                    </span>
                    {t('edit')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('roles');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState(
      name === "is_approver"
      ? { [name]: value ? 1 : 0 }
      : { [name]: value }
    );
  };

  render() {
    const {
      t,
      role: { loading, type: { list: typeList } },
    } = this.props;
    const { name, roleType, is_approver } = this.state;

    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <RoleForm
                          t={t}
                          onSubmit={() => this.updateRol(onToggle)}
                          handleInputChange={this.handleInputChange}
                          name={name}
                          typeList={typeList}
                          roleType={roleType}
                          editable
                          checked={is_approver}
                        />
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <div className="panel-top-content">
                            <Link
                              className="button is-primary"
                              to="/register-role"
                              onClick={() => onToggle({
                                // transaction: cellInfo.original,
                                // paymentCondition: advance_condition,
                                // onSubmit: this.advancePayment,
                              })}
                            >
                              <span className="mr-8px">
                                {customIcon('FaPlus')}
                              </span>
                              {t('newRole')}
                            </Link>
                          </div>
                          <TableSimple data={data} columns={columns} />
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Roles.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchTypesRole: PropTypes.func.isRequired,
  editRole: PropTypes.func.isRequired,
  role: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ role, auth }) => (
  {
    role,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions }),
  withNamespaces(),
)(Roles);
