import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, PaymentConditionForm, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { customIcon, formatCurrency, getPath } from '../../util';
import { statsActions } from '../../state/ducks/stats';
import { TOAST_CONFIG } from '../../config/constants';

class PaymentConditions extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    client: null,
    provider: null,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 100,
    clientPayment: 0,
    percentage: 0,
    days: 365,
    editable: true,
    startCountingFrom: 0,
    limitAmount: 0,
    post: 0,
    clientEdit: '',
    codes: [],
    codeDescription: '',
    commerceCode: '',
    idCondition: '',
    percentageChargeForService: -1,
    negotiatedFinancialCost: '',
  });

  componentDidMount() {
    const {
      auth: { user: { b2b: { type_user } } },
      fetchRoles,
      showMessage,
    } = this.props;

    const {
      userInfo, name
    } = this.props.location.state;

    if ( ( userInfo ) ) {
      let array = [];
      if ( userInfo[userInfo.length - 1].error ) {
        showMessage({
          message: (userInfo[userInfo.length - 1].error ),
          config: TOAST_CONFIG.ERROR,
        });
      }
      let condition = type_user === 'issuing_bank' ? (userInfo.length-1) : (userInfo.length-1);
      for(let i = 0; i < condition ; i++){
        array[i] = type_user === 'issuing_bank' ? (userInfo[i]) : (userInfo[i]);
      }
      this.setState({ arrayCondition: array, name: name });
    } else {
      this.setState({ arrayCondition: []});
    }

    fetchRoles({
      params: {
        name: "seller",
      },
    });
  }

  getTitle = () => {
    /*const {
      clients: { sender },
      stats: { sellers, buyers },
      providers: { receiver },
      auth: { user: { b2b: { type_user } } },
    } = this.props;*/
    const {
      name
    } = this.state

    /*const {
      sender_id,
      receiver_id,
    } = this.props.location.state;*/

    //let name = '';
    /*switch (type_user) {
      case 'acquiring_bank':
        if (sender.length > 0 && typeof(sellers) !== 'undefined') {
          const post = sellers.map(
            (listClientMap, index) => ({
              ...listClientMap,
              index,
            }),
          )
            .filter(listClientFil => listClientFil.id
              === receiver_id)[0].index;//sender[0].condition.receiver_id
          name = getPath(['idPerson', 'name'], sellers[post]);
        }
        break;
      case 'issuing_bank':
        if (receiver.length > 0 && typeof(buyers) !== 'undefined') {
          const post = buyers.map(
            (listClientMap, index) => ({
              ...listClientMap,
              index,
            }),
          )
            .filter(listClientFil => listClientFil.id
              === sender_id)[0].index;//receiver[0].condition.sender_id
          name = getPath(['idPerson', 'name'], buyers[post]);
        }
        break;
      default:
        name = '';
    }*/
    return name;
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      clients: { sender },
      providers: { receiver },
      t,
      deletePaymentCondition,
      getClientsConditions,
      getProvidersConditions,
      auth: { user: { profile: { type } } },
      providerCommerceCode,
      role: { list },
      getDiscountRate,
    } = this.props;
    const {
      arrayCondition,
    } = this.state;

    var role_id = '';
    for(var i = 0; i < list.length; i++){
      if(list[i].name === "seller"){
        role_id = list[i].id;
      }
    }

    switch (userType) {
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('customers'),
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            width: 110,
            accessor: d => `${d.condition.days}`,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            accessor: d => ((d.condition.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'commerce_code', // Required because our accessor is not a string
            Header: t('merchantAffiliateCode'),
            headerClassName: 'wordwrap',
            width: 170,
            accessor: d => d.condition.personCode !== null ? `${d.condition.personCode.commerce_code}` : '',
          },
          /*{
            id: 'receiverDiscountShare', // Required because our accessor is not a string
            Header: t('receiverDiscountShare'),
            accessor: d => `${d.conditions.receiver_discount_share * 100} %`,
          },*/
          {
            id: 'limitAmount', // Required because our accessor is not a string
            Header: t('limit'),
            width: 190,
            accessor: d => `${formatCurrency(d.condition.limit_amount)}`,
          },
          {
            id: 'receiverDiscountShare', // Required because our accessor is not a string
            Header: t('supplierPaymentPercentage'),
            headerClassName: 'wordwrap',
            width: 100,
            accessor: d => `${d.condition.receiver_discount_share} %`,//`${d.conditions.receiver_discount_share * 100} %`,
          },
          {
            id: 'discount_rate', // Required because our accessor is not a string
            Header: t('customerPaymentPercentage'),
            headerClassName: 'wordwrap',
            width: 100,
            accessor: d => `${d.condition.discount_rate} %`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            show: type === 'query' ? false : true,
            //accessor: d => `${d.user.id}`,
            Cell: cellInfo => (
              /*accessor: d => `${d.id} `,
              Cell: ({
                original: {
                  id,
                  idPerson: {
                    name
                  },
                  conditions: {
                    id: conditionsId,
                    days,
                    due_from_approval,
                    discount_rate,
                    receiver_discount_share,
                    limit_amount,
                    person_commerce_code,
                  },
                },
                original,
              }) => (
                 */
              <table>
                <tbody>
                  <tr>
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.condition.receiver_id,
                              role: role_id,
                            },
                            callback: (response) => {
                              this.setState({
                                codes: response.commerce_codes,
                                commerceCode: response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.condition.person_commerce_code;
                                }),
                                codeDescription: response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.condition.person_commerce_code;
                                })].commerce_code,
                              });
                            },
                          });
                          this.setState({
                            clientEdit: cellInfo.original.user.idPerson.name, /*sender.map(
                              (listClientMap, index) => ({
                                ...listClientMap,
                                index,
                              }),
                            )
                              .filter(listClientFil => listClientFil.id
                                === id)[0].index,*/
                            idCondition: cellInfo.original.condition.id,
                            baseDays: Number(cellInfo.original.condition.days),
                            startCountingFrom: Number(cellInfo.original.condition.due_from_approval),
                            sender_id: cellInfo.original.condition.sender_id,
                            receiver_id: cellInfo.original.condition.receiver_id,
                            //discountRate: Number(discount_rate),
                            //receiverDiscountShare: (receiver_discount_share * 100).toFixed(2),
                            //clientPayment: 100 - (receiver_discount_share * 100).toFixed(2),
                            limitAmount: cellInfo.original.condition.limit_amount !== null ?
                              this.formatNumber(cellInfo.original.condition.limit_amount) : 0,
                            editable: true,
                            // hidden: true,
                            percentageChargeForService: cellInfo.original.condition.receiver_discount_share === '100.00' ? 0 :
                              cellInfo.original.condition.receiver_discount_share === '50.00' ? 1 : 2,
                            clientPayment: 100 - cellInfo.original.condition.receiver_discount_share,
                            negotiatedFinancialCost: cellInfo.original.condition.negotiated_financial_cost === '0.00' ? '' : cellInfo.original.condition.negotiated_financial_cost,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              role: cellInfo.original,//original,
                            });
                          });
                        }}
                      >
                        <span className="mr-8px" aria-hidden="true">
                          {customIcon('FaRegEdit')}
                        </span>
                        {t('edit')}
                      </button>
                    </td>
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        to="/payment-conditions"
                        onClick={() => {
                          deletePaymentCondition({
                            data: {
                              id: cellInfo.original.condition.id,
                            },
                            t,
                            callback: () => {
                              getClientsConditions({
                                params: {
                                  id: cellInfo.original.condition.receiver_id,
                                }, callback: (data) => {
                                  let array = [];
                                  for(let i = 0; i < (data.length - 1 ) ; i++){
                                    array[i] = (data[i]);
                                  }
                                  this.setState({ arrayCondition: array });
                                }
                              });
                              /*getClientsConditions({
                                params: {
                                  id: cellInfo.original.condition.receiver_id,
                                },
                              });*/
                            },
                          });
                        }}
                      >
                        <span className="mr-8px">
                          {customIcon('FaTimes')}
                        </span>
                        {t('delete')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = arrayCondition;
        title = `${t('commercialConditions')  }: ${  this.getTitle()}`;
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('providers'),
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            width: 120,
            accessor: d => `${d.condition.days}`,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            headerClassName: 'wordwrap',
            accessor: d => ((d.condition.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'commerce_code', // Required because our accessor is not a string
            Header: t('merchantAffiliateCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.condition.personCode !== null ? `${d.condition.personCode.commerce_code}` : '',
          },
          /* {
             id: 'discountRate', // Required because our accessor is not a string
             Header: t('discountRate'),
             accessor: d => `${d.conditions.discount_rate}`,
           },
           {
             id: 'receiverDiscountShare', // Required because our accessor is not a string
             Header: t('receiverDiscountShare'),
             accessor: d => `${(d.conditions.receiver_discount_share * 100).toFixed(2)} %`,
           },*/
          {
            id: 'limitAmount', // Required because our accessor is not a string
            Header: t('limit'),
            accessor: d => `${formatCurrency(d.condition.limit_amount)}`,
          },
          {
            id: 'receiverDiscountShare', // Required because our accessor is not a string
            Header: t('supplierPaymentPercentage'),
            headerClassName: 'wordwrap',
            width: 110,
            accessor: d => `${d.condition.receiver_discount_share} %`,//`${d.conditions.receiver_discount_share * 100} %`,
          },
          {
            id: 'receiver_discount', // Required because our accessor is not a string
            Header: t('customerPaymentPercentage'),
            headerClassName: 'wordwrap',
            width: 110,
            accessor: d => `${d.condition.discount_rate} %`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: ({
              original: {
                user: {
                  id,
                  idPerson: {
                    name
                  },
                },
                condition: {
                  id: conditionsId,
                  days,
                  due_from_approval,
                  discount_rate,
                  receiver_discount_share,
                  limit_amount,
                  person_commerce_code,
                  sender_id, receiver_id,
                },
              },
              original,
            }) => (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          providerCommerceCode({
                            params: {
                                user: id,
                                role: role_id,
                            },
                            callback: (response) => {
                              this.setState({
                                codes: response.commerce_codes.length === 0 ? [] : response.commerce_codes,
                                commerceCode: response.commerce_codes.length === 0 ? [] :
                                  response.commerce_codes.findIndex(function (code) {
                                    return code.id === person_commerce_code;
                                  }),
                                codeDescription: response.commerce_codes.length === 0 ? '' :
                                  response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                    return code.id === person_commerce_code;
                                  })].commerce_code,
                              });
                            },
                          });
                          this.setState({
                            clientEdit: name/*receiver.map(
                              (listClientMap, index) => ({
                                ...listClientMap,
                                index,
                              }),
                            )
                              .filter(listClientFil => listClientFil.id
                                === id)[0].index*/,
                            sender_id,
                            receiver_id,
                            idCondition: original.condition.id,
                            baseDays: Number(days),
                            startCountingFrom: Number(due_from_approval),
                            //discountRate: Number(discount_rate),
                            //receiverDiscountShare: (receiver_discount_share * 100).toFixed(2),
                            //clientPayment: 100 - (receiver_discount_share * 100).toFixed(2),
                            limitAmount: limit_amount !== null ? this.formatNumber(limit_amount) : 0,
                            editable: true,
                            //commerceCode: '3',
                            percentageChargeForService: original.condition.receiver_discount_share === '100.00' ? 0 :
                              original.condition.receiver_discount_share === '50.00' ? 1 : 2,
                            clientPayment: 100 - original.condition.receiver_discount_share,
                            // hidden: true,
                            negotiatedFinancialCost: original.condition.negotiated_financial_cost === '0.00' ? '' : original.condition.negotiated_financial_cost,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              role: original,
                            });
                          });
                        }}
                      >
                        <span className="mr-8px" aria-hidden="true">
                          {customIcon('FaRegEdit')}
                        </span>
                        {t('edit')}
                      </button>
                    </td>
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        to="/payment-conditions"
                        onClick={() => {
                          deletePaymentCondition({
                            data: {
                              id: conditionsId,
                            },
                            t,
                            callback: () => {
                              getProvidersConditions({
                                params: {
                                  id: sender_id,
                                }, callback: (data) => {
                                  let array = [];
                                  for(let i = 0; i < (data.length - 1 ) ; i++){
                                    array[i] = (data[i]);
                                  }
                                  this.setState({ arrayCondition: array });
                                }
                              });
                              /*getProvidersConditions({
                                params: {
                                  id: sender_id,
                                },
                              });*/
                            },
                          });
                        }}
                      >
                        <span className="mr-8px">
                          {customIcon('FaTimes')}
                        </span>
                        {t('delete')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = arrayCondition;// receiver
        title = `${t('paymentConditionProvider')}: ${this.getTitle()}`;
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleSelectChange = (newVal, label) => {
    const { providers, providerCommerceCode, role: { list } } = this.props;
    const { codes, negotiatedFinancialCost } = this.state;

    var role_id = '';
    for(var i = 0; i < list.length; i++){
      if(list[i].name === "seller"){
        role_id = list[i].id;
      }
    }
    if(label.name === 'provider'){
      providerCommerceCode({
        params: {
            user: providers.list[newVal.value].id,
            role: role_id,
        },
        callback: (response) => {
            this.setState({ codes: response.commerce_codes });
        },
      });
    }

    if(label.name === 'commerceCode'){
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].commerce_code,
      });
    }

    if(label.name === 'percentageChargeForService'){
      let porcentaje;
      switch (newVal.value) {
        case 0:
          porcentaje = 100 - 100;
          break;
        case 1:
          porcentaje = 100 - 50;
          break;
        case 2:
          porcentaje = 100 - 0;
          break;
      }
      this.setState({
        clientPayment: porcentaje,
        negotiatedFinancialCost: ( newVal.value === 0 || newVal.value === 1 ) ? '' : negotiatedFinancialCost,
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  updatePaymentCondition = (onToggle) => {
    const {
      auth: { user: { b2b: { type_user } } },
      clients: { sender: clientList },
      providers: { receiver: providerList },
      editPaymentCondition,
      t,
      showMessage,
      getClientsConditions,
      getProvidersConditions,
    } = this.props;
    const {
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      percentage,
      startCountingFrom,
      limitAmount,
      clientEdit,
      commerceCode,
      codes,
      idCondition,
      sender_id,
      receiver_id,
      percentageChargeForService,
      clientPayment,
      arrayCondition,
      negotiatedFinancialCost,
    } = this.state;

    if(codes.length < 1){
      onToggle({});
      showMessage({
        message: 'Usuario no tiene un código de comercio activo',
        config: TOAST_CONFIG.WARNING,
      });
    }else{
      let idList;
      if (type_user === 'acquiring_bank') {
        idList = arrayCondition.map(
          (listClientMap, index) => ({
            ...listClientMap,
            index,
          }),
        )
          .filter(listClientFil => listClientFil.user.idPerson.name
            === clientEdit)[0].index;
      } else {
        idList = arrayCondition.map(
          (listClientMap, index) => ({
            ...listClientMap,
            index,
          }),
        )
          .filter(listClientFil => listClientFil.user.idPerson.name
            === clientEdit)[0].index;
      }
  
      const days = baseDays;
      const persons = type_user === 'acquiring_bank' ? clientList : providerList;
      const ids = type_user === 'acquiring_bank' ? clientList[idList].condition.id : providerList[idList].condition.id;
      const update = type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : providerList[0].condition.sender_id;
      const conditionData = {
        sender_id: sender_id,//type_user === 'acquiring_bank' ? persons[idList].user.id : providerList[0].condition.sender_id,
        receiver_id: receiver_id, //type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : persons[idList].user.id,
        percentage,
        days,
        discount_rate: clientPayment, //porcentaje cliente
        due_from_approval: startCountingFrom,
        receiver_discount_share: 100 - (clientPayment), //porcentaje proveedor
        type: 'base',
        limit_amount: limitAmount.toString()
          .replaceAll(',', ''),
        person_commerce_code: codes.length > 0 ? codes[commerceCode].id : '',
        negotiated_financial_cost: negotiatedFinancialCost,
      };
  
      if (days < 0) {
        showMessage({
          message: t('incorrectDays'),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }
  
      if (conditionData.limit_amount < 0) {
        showMessage({
          message: t('limitAmountValidate'),
          config: TOAST_CONFIG.WARNING,
        });
      } else {
        editPaymentCondition({
          id: idCondition,
          data: conditionData,
          callback: () => {
            if (type_user === 'acquiring_bank') {
              getClientsConditions({
                params: {
                  id: update,
                }, callback: (data) => {
                  let array = [];
                  for(let i = 0; i < (data.length - 1 ) ; i++){
                    array[i] = (data[i]);
                  }
                  this.setState({ arrayCondition: array });
                }
              });
            } else {
              getProvidersConditions({
                params: {
                  id: update,
                }, callback: (data) => {
                  let array = [];
                  for(let i = 0; i < (data.length - 1 ) ; i++){
                    array[i] = (data[i]);
                  }
                  this.setState({ arrayCondition: array });
                }
              });
            }
            onToggle();
            showMessage({
              message: t('editPaymentConditionSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
          },
        });
      }
    }
  };

  updateCodes = () => {
    const {
      auth: { user: { user_id, b2b: { roles, type_user } } },
      fetchRoles,
      providerCommerceCode,
      getDiscountRate,
      clients: { sender },
      providers: { receiver },
      getProvidersConditions,
      getClientsConditions,
      showMessage,
      t,
    } = this.props;
    const {
      sender_id,
      receiver_id,
    } = this.props.location.state;

    if ( type_user === 'issuing_bank' ) {
      getProvidersConditions({
        params: { id: sender_id }, //sender_id receiver[0].condition.sender_id
        callback: (data) => {
          if ( data[data.length - 1].error ) {
            showMessage({
              message: (data[data.length - 1].error ),
              config: TOAST_CONFIG.ERROR,
            });
          }
          let array = [];
          for(let i = 0; i < (data.length - 1 ) ; i++){
            array[i] = (data[i]);
          }
          this.setState({ arrayCondition: array });
        }
      });
    } else {
      getClientsConditions({
        params: { id: receiver_id }, //sender[0].condition.receiver_id
        callback: (data) => {
          if ( data[data.length - 1].error ) {
            showMessage({
              message: (data[data.length - 1].error ),
              config: TOAST_CONFIG.ERROR,
            });
          }
          let array = [];
          for(let i = 0; i < (data.length - 1 ) ; i++){
            array[i] = (data[i]);
          }
          this.setState({ arrayCondition: array });
        }
      });
    }
    
   /* getDiscountRate({
      params: {
        client: '',
        provider: '5cf17a5992079d14647957df',
      },
    });*/
  };

  render() {
    const {
      t, auth: { user: { b2b: { type_user } } },
      clients: { sender: clientList, loading: clientLoading },
      providers: { receiver: providerList, loading: providerLoading },
      paymentConditions: { loading: paymentConditionsLoading },
    } = this.props;
    const persons = type_user === 'acquiring_bank' ? clientList.user : providerList;
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      startCountingFrom,
      limitAmount,
      clientEdit,
      codes,
      commerceCode,
      codeDescription,
      percentageChargeForService,
      negotiatedFinancialCost,
    } = this.state;
    const loading = clientLoading || providerLoading || paymentConditionsLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal className="condition-modal" onToggle={onToggle} band> {loading && <Loading/>}
                        <PaymentConditionForm
                          t={t}
                          onSubmit={() => this.updatePaymentCondition(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handlePercentageChange={this.handlePercentageChange}
                          isClient={type_user !== 'acquiring_bank'}
                          handleSelectChange={this.handleSelectChange}
                          persons={[]}
                          client={client}
                          provider={provider}
                          baseDays={baseDays}
                          discountRate={discountRate}
                          receiverDiscountShare={receiverDiscountShare}
                          clientPayment={clientPayment}
                          startCountingFrom={startCountingFrom}
                          limitAmount={limitAmount}
                          clientEdit={clientEdit}
                          editable={editable}
                          codes={codes}
                          commerceCode={commerceCode}
                          codeDescription={codeDescription}
                          percentageChargeForService={percentageChargeForService}
                          //clientPayment={clientPayment}
                          negotiatedFinancialCost={negotiatedFinancialCost}
                          usedInModals={true}
                          hidden
                        />
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <button
                            className="button is-info"
                            style={{ marginBottom: '1em' }}
                            onClick={() => {
                              this.updateCodes();
                            }}
                          >
                            <span className="icon is-small">
                              {customIcon('FaSync')}
                            </span>
                            <p>{t('updateFinancialCost')}</p>
                          </button>
                          <TableSimple data={data} columns={columns} />
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

PaymentConditions.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  stats: PropTypes.shape(Object).isRequired,
  getClientsConditions: PropTypes.func.isRequired,
  getProvidersConditions: PropTypes.func.isRequired,
  editPaymentCondition: PropTypes.func.isRequired,
  deletePaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           role,
                           auth,
                           clients,
                           providers,
                           stats,
                           paymentConditions,
                         }) => (
  {
    role,
    auth,
    clients,
    providers,
    stats,
    paymentConditions,
  }
  );
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...statsActions,
  }),
  withNamespaces(),
)(PaymentConditions);
