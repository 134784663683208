import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { customIcon, getPath } from '../../util';

class FlowApproval extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
  });

  componentDidMount() {
    const {
      getWorkFlows,
      auth: { user: { b2b: { id } } },
    } = this.props;

    getWorkFlows({
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      flowApproval: { list },
      getWorkFlowId,
      auth: { user: { b2b: { id } } },
      desactivetWorkFlow,
      defaultWorkFlow,
      getWorkFlows,
      history
    } = this.props;
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.name} `,
      },
      {
        id: 'condition', // Required because our accessor is not a string
        Header: t('condition'),
        width: 300,
        accessor: d => d.condition_operator ? `${getPath(['condition_operator'], d)} ${getPath(['condition_value'], d)} ` : '-',
      },
      {
        id: 'activo', // Required because our accessor is not a string
        Header: t('status'),
        width: 80,
        Cell: cellInfo => (
          <span style={{color: cellInfo.original.active === '1' ? 'green' : 'red'}}>
            {customIcon(cellInfo.original.active === '1' ? 'FaRegCheckCircle' : 'FaBan')}
          </span>),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    getWorkFlowId({
                      params: {
                        id: cellInfo.original.id
                      },
                      callback: () => {
                        history.push('/edit-approval-flow');
                      }
                    });
                    {/* selectId({ id:cellInfo.original.id }) */
                    }
                  }}
                >
                  <span className="mr-8px">
                    {customIcon('FaRegEdit')}
                  </span>
                  {t('edit')}
                </button>
              </td>
              <td>
                <button className="button is-primary is-inverted"
                        onClick={() => {
                          desactivetWorkFlow({
                            params: {
                              id: cellInfo.original.id
                            },
                            callback: () => {
                              getWorkFlows({
                              });
                            }
                          });
                        }}
                >
                  {<span className="mr-8px" style={{color: cellInfo.original.active == '1' ? 'red' : 'green'}}>
                    {customIcon(cellInfo.original.active == '1' ? 'FaMinusCircle' : 'FaMinusCircle')}
                  </span>}
                  {(cellInfo.original.active == '1' ? t('deactivate') : t('activate'))}
                </button>
              </td>
              <td>
                <button style={{ display: (cellInfo.original.is_default == '1' ? 'none' : '') }}
                        className="button is-info is-inverted"
                        onClick={() => {
                          defaultWorkFlow({
                            params: {
                              id: cellInfo.original.id
                            },
                            callback: () => {
                              getWorkFlows({
                              });
                            }
                          });
                        }}
                >
                  <span className="mr-8px">
                    {customIcon('FaMinusCircle')}
                  </span>
                  {t('defaultFlow')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('flowsApproval');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      t,
    } = this.props;


    const { title, columns, data } = this.getGridSettings();
    return (
      <div className="dashboard">
        {/* loading && <Loading/> */}
        <div className="columns is-multiline">
          {data
          && (
            <div className="column is-full">
              <Panel headingText={title}>
                <div className="panel-top-content">
                  <Link
                    className="button is-primary"
                    to="/create-approval-flow"
                  >
                    <span className="mr-8px">
                      {customIcon('FaPlus')}
                    </span>
                    {t('createFlowApproval')}
                  </Link>
                </div>
                <TableSimple data={data} columns={columns}/>
              </Panel>
            </div>
          )}
        </div>
      </div>

    );
  }
}

FlowApproval.propTypes = {
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getWorkFlows: PropTypes.func.isRequired,
  getWorkFlowId: PropTypes.func.isRequired,
  selectId: PropTypes.func.isRequired,
  desactivetWorkFlow: PropTypes.func.isRequired,
  defaultWorkFlow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, flowApproval }) => (
  {
    auth,
    flowApproval,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...flowApprovalActions,
  }),
  withNamespaces(),
)(FlowApproval);
