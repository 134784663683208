import React from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import routes, { RouteWithSubRoutes } from './config/routes';
// eslint-disable-next-line import/no-cycle
import configureStore from './state/store';
import './styles/index.scss';
import PropTypes from 'prop-types';

export const initialState = {
  auth: {
    logged: false,
    language: '',
    user: {},
    profile: {},
    contacts: [],
    account: {
      id: 1,
      name: 'Cuenta B2B',
      account_number: 'XXXXXXXXXXX',
      balance: '$ 0,00',
      product: 'AR$',
    },
    b2b: {
      roles: [],
      paths: [],
    },
    loading: false,
    error: null,
  },
  clients: {
    list: [],
    sender: [],
  },
  providers: {
    loading: false,
    list: [],
    receiverCommerce: null,
    receiver: [],
  },
  transactions: {
    balance: 0,
    list: [],
    loading: false,
    error: null,
    receiver: [],
    sender: [],
  },
  documentType: {
    loading: false,
    list: [],
  },
  role: {
    loading: false,
    list: [],
    type: {
      list: [],
    },
  },
  route: {
    loading: false,
    list: [],
    roleRoute: {
      list: [],
    },
  },
  bank: {
    loading: false,
    list: [],
  },
  financingProducts: {
    loading: false,
    list: [],
  },
  item: {
    loading: false,
    list: [],
  },
  stats: {
    loading: false,
    buyer_total: 0,
    active_buyer_total: 0,
    deferred_buyer_total: 0,
    buyers: [],
    seller_total: 0,
    active_seller_total: 0,
    paid_seller_total: 0,
    sellers: [],
    next_payment: null,
    total_debt_amount: 0,
    total_owed_amount: 0,
    graph_data: [],
  },
  paymentConditions: {
    loading: false,
    list: [],
  },
  card: {
    person: null,
    address: null,
    card: null,
    transactions: {
      transactions: [],
    },
    loading: false,
    error: null,
  },
  lead: {
    list: [],
    loading: false,
  },
  userProfile: {
    list: [],
    loading: false,
  },
  flowApproval: {
    loading: false,
    selectId: '',
    list: [],
    activeFlow: [],
  },
  log: {
    loading: false,
    list: [],
  }
};

const App = () => {
  const { store, persistor, history } = configureStore(initialState);
  // This is useful when state gets corrupted and persisted
  // persistor.purge();

  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <ConnectedRouter history={history}>
          <div>
            <Switch>
              {routes.map(
                route => <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
            </Switch>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

App.propTypes = {
  location: PropTypes.shape({}),
};

export default App;
