import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'core-js/features/object';
import 'core-js/features/array';
import 'core-js/features/string';
import 'core-js/features/promise';
import * as Sentry from "@sentry/react";
import App from './App';
import { ENV } from './config/env';

let sentryUrl = '';

switch(ENV){
  case 'qa':
    sentryUrl = "https://6b8ceb8ce1521f34d6d241ea87a459ac@sentry.medullaris.com/46";
  break;
  case 'stage':
    sentryUrl = "https://4b95b443fbb0548942cad66b925b93a7@sentry.medullaris.com/70";
  break;
  case 'production':
    sentryUrl = "https://8d480a3484468dae4bcdf4900ce7c0c2@sentry.medullaris.com/95";
  break;
}
Sentry.init({
    dsn: sentryUrl,
});

// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
