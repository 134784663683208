/*
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - stage
 - qa
 - dev
 - local
 */

export const ENV = 'production';

// Constante para definir la version de la API
export const API_VERSION = 'v3';

// ENDPOINTS de la aplicacion en los diferentes entornos
// Nota: las claves de este objeto deben coincidir con el (o los) valores de la constante ENV
const URL = window.location.href;
export const ENDPOINTS = {
  API: {
//    production: 'https://b2b-api-ec.medullaris.com',
    production: URL.includes('b2b') ? 'https://b2b-api-ec.medullaris.com' : 'https://bancoguayaquil-ec-api.americanexpressbusinesslink.com',	 
    stage: '',
    qa: '',
    dev: 'https://b2benterprise-api-ec-qa.medullaris.com',
    local: 'http://localhost/amex/business_amex/app/api/web',
    prueba: 'https://api-tmp-ec.medullaris.com',
  },
};
