import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, ProfileForm } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';


class CreateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    registerLoading: false,
    roleType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'flecha',
    agency_user_type: 'business_admin',
    b2b: {
      bank_id: null,
      admin: 0,
      issuing_bank: 0,
      acquiring_bank: 0,
      seller: 0,
      buyer: 0,
    },
    is_perfil: false,
    userType: false,
    bank: false,
  });

  componentDidMount() {
    const {
      auth: {
        user: {
          user_id,
          b2b: { type_user },
        },
      },

      fetchDocumentTypes,
      fetchRoles,
      ownerId,
    } = this.props;
    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        owner_id: user_id,
      },
    });
    // fetchItems();
    // getClients({});

    if (type_user === 'issuing_bank' || type_user === 'acquiring_bank') {
      ownerId({ user_id });
      this.setState({ bank: true });
    }

  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  createProvider = () => {
    const {
      createProvider,
      showMessage,
      t,
      history,
      role: { list: roleList },
      documentType: { list: documentTypesList },
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    const {
      name,
      roleType,
      documentType,
      documentNumber,
      email,
      password,
      confirmPassword,
    } = this.state;

    if (this.validateEmptyFields(type_user)) {
      if (documentType === -1) {
        showMessage({
          message: t('SelectDocumentType'),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }
     // if (this.isEmail(email)) {
        const data = {
          name,
          document_type: documentTypesList[documentType].document_type,
          document_number: documentNumber,
          email,
          //password,
          user_type: 'flecha',
          agency_user_type: 'business_admin',
          items: [],
          role_name: type_user === 'issuing_bank' || type_user === 'acquiring_bank' ? roleList[0].id : roleList[roleType].id,
          b2b: {
            bank_id: null,
            admin: 0,
            issuing_bank: 0,
            acquiring_bank: 0,
            seller: 0,
            buyer: 0,
          },
        };
        const is_perfil = true;
        this.setState({ registerLoading: true });
        createProvider({
          data,
          is_perfil,
          callback: (response) => {
            console.info(response);
            history.push('/dashboard');
            showMessage({
              message: t('createProfileSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
          },
        });
      /* } else {
         showMessage({
           message: t('incorrectEmail', { email }),
           config: TOAST_CONFIG.WARNING,
         });
         return;
       }*/
    } else {
      showMessage({
        message: t('emptyAll'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = (type_user) => {
    const {
      name,
      documentNumber,
      documentType,
      roleType,
      email,
    } = this.state;

    if (type_user === 'issuing_bank' || type_user === 'acquiring_bank') {
      return !(email === '' || name === '' || documentNumber === '' || documentType === -1);
    } else {
      return !(email === '' || name === '' || documentNumber === '' || documentType === -1 || roleType === -1);
    }
  };

  deleteDocument = () => {
    const {
      documentType: { list: documentTypesList }
    } = this.props;

    const list = [];

    for (let i = 0; i < documentTypesList.length; i++) {
      if (documentTypesList[i].document_type !== 'RUC') {
        list.push({
          id: documentTypesList[i].id,
          document_type: documentTypesList[i].document_type
        });
      }
    }
    return list;
  };

  render() {
    const {
      t,
      clients: { loading: clientLoading },
      documentType: { list: documentTypesLists, loading: documentTypesLoading },
      role: { list: roleList, loading: roleLoading },
    } = this.props;
    const documentTypesList = this.deleteDocument();
    const {
      name, documentNumber, email, password, confirmPassword, registerLoading, roleType,
      documentType, userType, bank,
    } = this.state;
    const loading = clientLoading || documentTypesLoading || roleLoading || registerLoading;
    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <ProfileForm
              documentTypesList={documentTypesList}
              roleList={roleList}
              name={name}
              documentNumber={documentNumber}
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              handleInputChange={this.handleInputChange}
              handleSelectChange={this.handleSelectChange}
              t={t}
              onSubmit={this.createProvider}
              roleType={roleType}
              documentType={documentType}
              title={t('createProfile')}
              userType={userType}
              bank={bank}
            />
          </div>
        </div>
      </div>

    );
  }
}

CreateProfile.propTypes = {
  clients: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({}).isRequired,
  role: PropTypes.shape({}).isRequired,
  documentType: PropTypes.shape({}).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
                           clients,
                           documentType,
                           auth,
                           role,
                         }) => (
  {
    clients,
    documentType,
    auth,
    role,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...roleActions,
    ...clientsActions,
    ...roleActions,
  }),
  withNamespaces(),
)(CreateProfile);
