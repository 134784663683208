import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Redirect } from 'react-router';
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  InformativeBoxes,
  Loading,
  Panel,
  PaymentConditionForm,
  TableSimple,
  Toggle,
} from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import {
  customIcon,
  formatCurrency,
  formatDate,
  formatFriendlyDate,
  formatFriendlyDateLong,
  getPath,
} from '../../util';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import SearchPersonForm from '../../components/SearchPersonForm';
import DateInput from '../../components/DateInput';
const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    client: null,
    provider: null,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 100,
    clientPayment: 0,
    percentage: 0,
    days: 365,
    editable: false,
    startCountingFrom: 0,
    limitAmount: '',
    inputValue: '',
    alert: false,
    document_number: '',
    name: '',
    email: '',
    min_amount: '',
    max_amount: '',
    search: false,
    stat: '',
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
    codes: [],
    codeDescription: '',
    commerceCode: '',
    percentageChargeForService: -1,
    negotiatedFinancialCost: '',
  });

  componentDidMount() {
    const {
      fetchStats,
      listTransactionsSender,
      listTransactionsReceiver,
      auth: {
        user: {
          user_id,
          b2b: {
            roles, type_user,
          },
        },
      },
      fetchLeads,
      fetchDocumentTypes,
      getClients,
      getProviders,
      statTransaction,
      t,
      fetchRoles,
    } = this.props;
    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        name: "seller",
      },
    });
    getClients({});
    getProviders({});
    fetchStats({});
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        statTransaction({
          params: {
            emmit: true,
            approved: true,
            //min_date: 1589154906,
            //max_date: 1589654906,
          },
          callback: (response) => {
            this.setState({ stat: response });
          }
        });
        break;
      case 'buyer':
        listTransactionsSender({
          t,
          callback: () => this.setState({ alert: 'b' }),
        });
        break;
      case 'seller':
        listTransactionsReceiver({
          t,
          // callback: () => this.setState({ alert: 's' }),
        });
        break;
      case 'issuing_bank':
        fetchLeads({
          params: {
            role_id: 'buyer',
          },
        });
        break;
      case 'acquiring_bank':
        fetchLeads({
          params: {
            role_id: 'seller',
          },
        });
        break;
      default:
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  renderRowSubComponent = ({ row }) => {
    const { _original: data } = row;
    const {
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    return (
      <div className="columns row-sub-component" style={{ padding: '1rem' }}>
        <div className="column">
          <h4>
            {`${t('invoicesApproved')}:`}
          </h4>
          <span>{`${data.approved_invoice_count}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('approvedMount')}:`}
          </h4>
          <span>{`${formatCurrency(data.approved_invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('nInvoices')}:`}
          </h4>
          <span>{`${data.invoice_count}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('totalAmount')}:`}
          </h4>
          <span>{`${formatCurrency(data.invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t(`${type_user === 'issuing_bank' ? 'defer' : 'advance'}Mount`)}:`}
          </h4>
          <span>{`${formatCurrency(data.advance_invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('totalCommissionAmount')}:`}
          </h4>
          <span>{`${formatCurrency(data.commission_advance_invoice_total_amount)}`}</span>
        </div>
      </div>
    );
  };

  getInvoice = (mat, type) => {
    let PAP = 0,
      PEJ = 0;

    for (let i = 0; i < mat.length; i++) {
      if (mat[i].status === 'PAP' && type === 'PAP') {
        PAP = mat[i].total_amount;
        return formatCurrency(PAP);
      }
      if (mat[i].status === 'PEJ' && type === 'PEJ') {
        PEJ = mat[i].total_amount;
        return formatCurrency(PEJ);
      }
    }

    return 0;
  };

  getStatsData = (userType) => {
    let statsData;
    const {
      transactions: { list: graph },
      clients: { list: listClient },
      providers: { list: listProvider },
      stats: {
        seller_total,
        active_seller_total,
        paid_seller_total,
        buyer_total,
        active_buyer_total,
        deferred_buyer_total,
        next_payment,
        total_debt_amount,
        total_owed_amount,
        account_balance,
        paid_total,
        pending_amount,
        invoice_total,
      },
    } = this.props;
    const {
      stat,
    } = this.state;
    const mat = stat.invoices_number;

    switch (userType) {
      case 'admin_amex':
      case 'admin':
        statsData = [
          {
            icon: customIcon('FaRegIdBadge'),
            title: 'clients',
            value: listClient.length,
          }, {
            icon: customIcon('FaRegIdBadge'),
            title: 'providers',
            value: listProvider.length,
          }, {
            icon: customIcon('FaHandHoldingUsd'),
            title: 'totalToReceiver',
            value: mat != undefined ? this.getInvoice(mat, 'PEJ') : 0,
          },
          {
            icon: customIcon('FaHandHoldingUsd'),
            title: 'totalToApprove',
            value: mat != undefined ? this.getInvoice(mat, 'PAP') : 0,
          },
        ];
        break;
      case 'acquiring_bank':
      case 'acquiring_query':
        statsData = [
          {
            icon: customIcon('FaRegIdBadge'),
            title: 'totalSellers',
            value: seller_total,
          }, {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'totalSellersInvoiced',
            value: active_seller_total,
          }, {
            icon: customIcon('FaHandHoldingUsd'),
            title: 'totalSellersCharged',
            value: paid_seller_total,
          },
        ];
        break;
      case 'issuing_bank':
      case 'issuing_query':
        statsData = [
          {
            icon: customIcon('FaRegIdBadge'),
            title: 'totalBuyers',
            value: buyer_total,
          }, {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'totalBuyersInvoiced',
            value: active_buyer_total,
          }, {
            icon: customIcon('FaHandHoldingUsd'),
            title: 'totalBuyersDeferred',
            value: deferred_buyer_total,
          },
        ];
        break;
      case 'buyer':
        statsData = [
          {
            icon: customIcon('FaRegIdBadge'),
            title: 'nextPayment',
            value: next_payment ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {formatFriendlyDateLong(next_payment.due_date_grouped)}
                </div>
                <div className="">{formatCurrency(next_payment.grouped_amount)}</div>
              </div>
            ) : '-',
          }, {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'totalDebtAmount',
            value: total_debt_amount ? formatCurrency(total_debt_amount) : formatCurrency(0),
          }, /*{
            icon: 'fas fa-hand-holding-usd',
            title: 'accountBalance',
            value: account_balance ? formatCurrency(account_balance) : formatCurrency(0),
          },*/
          {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'paidAmount',
            value: paid_total ? formatCurrency(paid_total) : formatCurrency(0),
          },
        ];
        break;
      case 'seller':
        statsData = [
          {
            icon: customIcon('FaRegIdBadge'),
            title: 'nextCollection',
            value: next_payment ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {formatFriendlyDateLong(next_payment.due_date_grouped)}
                </div>
                <div className="">{formatCurrency(next_payment.grouped_amount)}</div>
              </div>
            ) : '-',
          }, {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'totalOwedAmount',
            value: total_owed_amount ? formatCurrency(total_owed_amount) : formatCurrency(0),
          }, /*{
            icon: 'fas fa-hand-holding-usd',
            title: 'accountBalance',
            value: account_balance ? formatCurrency(account_balance) : formatCurrency(0),
          },*/
          {
            icon: customIcon('FaFileInvoiceDollar'),
            title: 'amountToGet',
            value: invoice_total ? invoice_total : 0,
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  getLateralStatsData = (userType) => {
    let statsData;
    const {
      t,
      stats: {
        paid_amount,
        pending_amount,
        financed_amount,
        total_paid,
      },
    } = this.props;
    const date = new Date();
    const months = new Array('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre');
    const month = months[date.getMonth()];
    switch (userType) {
      /* case 'buyer':
          statsData = [
            {
              icon: 'fas fa-file-invoice-dollar',
              title: 'amountToPay',
              value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
            }, {
              icon: 'fas fa-file-invoice-dollar',
              title: 'paidAmount',
              value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
            }, /* {
              icon: 'fas fa-hand-holding-usd',
              title: 'deferredAmount',
              value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
           }, */
      /*  ];
        break;*/
      case 'seller':
        statsData = [
          /*{
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToGet',
            value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
          },  {
            icon: 'fas fa-hand-holding-usd',
            title: 'anticipatedAmount',
            value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
          }, */
          {
            icon: customIcon('FaFileInvoiceDollar'),
            title: t('totalCharged', { month }),
            value: total_paid ? formatCurrency(total_paid) : formatCurrency(0),
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const { search } = this.state;
    const {
      t,
      history,
      stats: { sellers, buyers },
      auth: { user: { b2b: { defer_condition, advance_condition }, profile: { type } } },
      getClients,
      getClient,
      getClientsConditions,
      getProviders,
      getProvidersConditions,
      getProvider,
      getProviderCommerceCode,
      providerCommerceCode,
      role: { list },
    } = this.props;

    var role_id = '';
    for(var i = 0; i < list.length; i++){
      if(list[i].name === "seller"){
        role_id = list[i].id;
      }
    }

    switch (userType) {
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('identificationNumber'),
            accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            accessor: d => `${d.address}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => `${d.username}`,
          },
          {
            id: 'operationVolume', // Required because our accessor is not a string
            Header: t('operationVolume'),
            headerClassName: 'wordwrap',
            accessor: d => formatCurrency(parseFloat(d.invoice_total_amount)),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('commercialConditions'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      style={{ display: (type === 'query' ? 'none' : '') }}
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getClients({
                          params: {
                            //id: cellInfo.original.id,
                          },
                          callback: () => this.setState({
                            id: cellInfo.original.id,
                            baseDays: 0,
                            percentage: 0,
                            discountRate: 0,
                            receiverDiscountShare: 0,
                            // editable: true,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              paymentCondition: cellInfo.original,
                            });
                          }),
                        });
                        providerCommerceCode({
                          params: {
                              user: cellInfo.original.id,
                              role: role_id,
                          },
                          callback: (response) => {
                              this.setState({ codes: response.commerce_codes });
                          },
                        });
                      }}
                    >
                      <span className="mr-8px">
                        {customIcon('FaPlus')}
                      </span>
                      {`${t('new')}`}
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-primary is-inverted ml-8px"
                      onClick={() =>
                        getClientsConditions(
                          { params: { id: cellInfo.original.id },
                            callback: (data) => {
                              history.push({
                                pathname: '/payment-conditions',
                                state: {
                                  userInfo: data,
                                  name: cellInfo.original.idPerson.name,
                                  receiver_id: cellInfo.original.id,
                                },
                              })
                            }
                          })} 
                    >
                      {customIcon('FaRegEye')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      title={t('edit_user')}
                      onClick={() => {
                        getProvider({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push('/edit-provider');
                          }
                        });
                      }}
                    >
                      <span className="mr-8px">
                        {customIcon('FaRegEdit')}
                      </span>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      title={t('merchantAffiliateCode')}
                      onClick={() => {
                        getProviderCommerceCode({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push({
                              pathname: '/commerce-code',
                            });
                          }
                        });
                      }}
                    >
                      {customIcon('FaStore')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        search ? data = sellers : data = [];
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('identificationNumber'),
            accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            accessor: d => `${d.address}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => `${d.username}`,
          },
          {
            id: 'operationVolume', // Required because our accessor is not a string
            Header: t('operationVolume'),
            headerClassName: 'wordwrap',
            accessor: d => formatCurrency(parseFloat(d.invoice_total_amount)),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('commercialConditions'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      style={{ display: (type === 'query' ? 'none' : '') }}
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getProviders({
                          params: {
                            //id: cellInfo.original.id,
                          },
                          callback: () => this.setState({
                            id: cellInfo.original.id,
                            baseDays: 0,
                            percentage: 0,
                            discountRate: 0,
                            receiverDiscountShare: 0,
                            //editable: true,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              paymentCondition: cellInfo.original,
                            });
                          }),
                        });
                      }}
                    >
                      <span className="mr-8px">
                        {customIcon('FaPlus')}
                      </span>
                      {`${t('new')}`}
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-primary is-inverted ml-8px"
                      onClick={() =>
                        getProvidersConditions(
                          { params: { id: cellInfo.original.id },
                            callback: (data) => {
                              history.push({
                                pathname: '/payment-conditions',
                                state: {
                                  userInfo: data,
                                  name: cellInfo.original.idPerson.name,
                                  sender_id: cellInfo.original.id,
                                },
                              })
                            }
                          })}
                    >
                      {customIcon('FaRegEye')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getClient({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push('/edit-customer');
                          }
                        });
                      }}
                    >
                      <span className="mr-8px">
                        {customIcon('FaRegEdit')}
                      </span>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  <td>
                    <button
                        title={t('reconciliationReport')}
                        className="button is-primary is-inverted"
                        onClick={() => history.push({
                          pathname: '/conciliation',
                          state: {
                            userInfo: cellInfo.original
                          },
                        })}
                    >
                      {customIcon('FaThList')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        search ? data = buyers : data = [];
        title = t('customers');
        break;
      case 'buyer':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => formatCurrency(d.adjusted_amount),
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            Cell: cellInfo => (
              <td>
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-primary is-inverted"
                    to="/dashboard"
                    onClick={() => this.approveInvoice(cellInfo.original.id)}
                  >
                    <span className="mr-8px">
                      {customIcon('FaCheck')}
                    </span>
                    {t('accept')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-danger is-inverted"
                    to="/dashboard"
                    onClick={() => this.rejectInvoice(cellInfo.original.id)}
                  >
                    <span className="mr-8px">
                      {customIcon('FaTimes')}
                    </span>
                    {t('reject')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: defer_condition,
                      onSubmit: this.deferPayment,
                    })}
                  >
                    <span className="mr-8px">
                      {customIcon('FaRegClock')}
                    </span>
                    {t('differ')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('payedAmounts');
        break;
      case 'seller':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => `$ ${d.adjusted_amount}`,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.receiverStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            Cell: cellInfo => (
              <td>
                {cellInfo.original.receiver_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: advance_condition,
                      onSubmit: this.advancePayment,
                    })}
                  >
                    <span className="mr-8px">
                      {customIcon('FaRegClock')}
                    </span>
                    {t('advance')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('amountToCollect');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  newPaymentCondition = (onToggle) => {
    const {
      auth: { user: { b2b: { type_user } } },
      clients: { list: clientList },
      providers: { list: providerList },
      createPaymentCondition,
      t,
      showMessage,
    } = this.props;
    const {
      id,
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      percentage,
      startCountingFrom,
      limitAmount,
      commerceCode,
      percentageChargeForService,
      clientPayment,
      negotiatedFinancialCost,
    } = this.state;

    if(commerceCode == ''){
      onToggle({});
      showMessage({
        message: 'Usuario no tiene un código de comercio activo',
        config: TOAST_CONFIG.WARNING,
      });
    }else{
      const days = baseDays;
      const persons = type_user === 'acquiring_bank' ? clientList : providerList;
      const conditionData = {
        sender_id: type_user === 'acquiring_bank' ? persons[client].id : id,
        receiver_id: type_user === 'acquiring_bank' ? id : persons[provider].id,
        percentage,
        days,
        discount_rate: clientPayment, //porcentaje cliente
        due_from_approval: startCountingFrom,
        receiver_discount_share: 100 - (clientPayment), //porcentaje
        type: 'base',
        limit_amount: limitAmount.replaceAll(',', ''),
        person_commerce_code: commerceCode,
        negotiated_financial_cost: negotiatedFinancialCost,
      };

      if (days < 0) {
        showMessage({
          message: t('incorrectDays'),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }

      if (conditionData.limit_amount < 0) {
        showMessage({
          message: t('limitAmountValidate'),
          config: TOAST_CONFIG.WARNING,
        });
      } else {
        createPaymentCondition({
          data: conditionData,
          callback: () => {
            onToggle();
            showMessage({
              message: t('createPaymentConditionSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
          },
        });
      }
    }
  };

  approveInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: true,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({  });
      },
    });
  };

  rejectInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: false,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({  });
      },
    });
  };

  deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    deferInvoice({
      data,
      callback: () => {
        listTransactionsSender({  });
        fetchStats();
        onToggle();
      },
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      listTransactionsReceiver,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({});
        fetchStats();
        onToggle();
      },
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: (value),
      });
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const { clients, providers, providerCommerceCode, role: { list } } = this.props;
    const { editable, codes } = this.state;
    this.setState({
      [label.name]: newVal.value,
    });

    var role_id = '';
    for(var i = 0; i < list.length; i++){
      if(list[i].name === "seller"){
        role_id = list[i].id;
      }
    }

    if(label.name === 'percentageChargeForService'){
      let porcentaje;
      switch (newVal.value) {
        case 0:
          porcentaje = 100 - 100;
          break;
        case 1:
          porcentaje = 100 - 50;
          break;
        case 2:
          porcentaje = 100 - 0;
          break;
      }
      this.setState({
        clientPayment: porcentaje,
      });
    }

    if(label.name === 'provider'){
      providerCommerceCode({
        params: {
            user: providers.list[newVal.value].id,
            role: role_id,
        },
        callback: (response) => {
            this.setState({ codes: response.commerce_codes });
        },
      });
    }

    if(label.name === 'commerceCode'){
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].commerce_code,
      });
    }

    if (editable) {
      const index = newVal.value;
      const conditions = label.name === 'client' ? clients.list[index].conditions : providers.list[index].conditions;

      const percentajeValue = conditions.receiver_discount_share * 100;

      this.setState({
        baseDays: conditions.days,
        percentage: conditions.percentage,
        discountRate: conditions.discount_rate,
        receiverDiscountShare: percentajeValue,
        clientPayment: 100 - percentajeValue,
        limitAmount: conditions.limit_amount,
      });
    }
  };

  searchPerson = () => {
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
    } = this.state;
    const { fetchStats } = this.props;
    this.setState({ search: true });
    fetchStats({
      document_number,
      name,
      email,
      min_amount,
      max_amount,
    });
  };

  getTitle = () => {
    const {
      t,
    } = this.props;
    return {
      title1: t('issuedInvoices'),
      title2: t('invoicesApproved'),
      title3: t('approvedMount'),
      title4: t('invoicesToApproved'),
      title5: t('dateRange'),
    };
  };

  handleDateChange = (dateName, newDate) => {
    if (dateName === 'invoiceDate' && newDate !== '') {
      newDate.setHours(0, 0, 0, 0);
    }
    if (dateName === 'invoiceDateUntil' && newDate !== '') {
      newDate.setHours(23, 59, 59, 999);
    }

    this.setState({
      [dateName]: newDate,
    });
  };

  rangeDate = () => {
    const {
      statTransaction,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      statTransaction({
        params: {
          emmit: true,
          approved: true,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        },
        callback: (response) => {
          this.setState({ stat: response });
        }
      });
    }

  };

  render() {
    const {
      t, auth: { user: { b2b: { type_user } } },
      stats: { graph_data },
      clients: { list: clientList, loading: clientLoading },
      providers: { list: providerList, loading: providerLoading },
      transactions: {
        list: graph,
        pending_invoices,
        reject_invoices,
      },
      showMessage,
      paymentConditions: { loading: paymentLoading },
    } = this.props;
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
    } = this.state;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const { statsData } = this.getStatsData(type_user);
    const { statsData: lateralStatsData } = this.getLateralStatsData(type_user);
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      limitAmount,
      inputValue,
      alert,
      stat,
      invoiceDate,
      invoiceDateUntil,
      codes,
      commerceCode,
      codeDescription,
      percentageChargeForService,
      negotiatedFinancialCost,
    } = this.state;

    const loading = clientLoading || providerLoading || paymentLoading;
    /* if (type_user === 'admin') {
        return <Redirect to="/bank-list"/>;
     } */
    if (type_user === 'main_bank') {
      return <Redirect to="/bank-user-list"/>;
    }
    const { title1, title2, title3, title4, title5 } = this.getTitle();
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              {alert && (
                <Modal onToggle={() => this.setState({ alert: false })}>
                  <div
                    className={`notification ${alert === 's' ? 'is-warning' : 'is-info'} is-light`}>
                    <h1 style={{ fontSize: '18px', textAlign: 'center' }}>
                      <strong>{alert === 's' ? t('rejectInvoices', { reject_invoices }) : t('penddingInvoices', { pending_invoices })}</strong>
                    </h1>
                  </div>
                </Modal>
              )}
              <div className="columns is-multiline">
                <div className="column is-full">
                  {/* <InformativeBoxes items={statsData} size="is-one-four"/> */}
                  <InformativeBoxes 
                    items={
                      (type_user === 'seller' && lateralStatsData) 
                        ? [...statsData, ...lateralStatsData]
                        : statsData
                    } 
                    size="is-one-four"
                  />
                </div>
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal className="condition-modal" onToggle={onToggle} band> {loading && <Loading/>}
                        <PaymentConditionForm
                          t={t}
                          onSubmit={() => this.newPaymentCondition(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handlePercentageChange={this.handlePercentageChange}
                          isClient={type_user !== 'acquiring_bank'}
                          handleSelectChange={this.handleSelectChange}
                          persons={persons}
                          client={client}
                          provider={provider}
                          baseDays={baseDays}
                          discountRate={discountRate}
                          receiverDiscountShare={receiverDiscountShare}
                          clientPayment={clientPayment}
                          editable={editable}
                          limitAmount={limitAmount}
                          hidden
                          handleOnInputChange={this.handleOnInputChange}
                          inputValue={inputValue}
                          codes={codes}
                          commerceCode={commerceCode}
                          codeDescription={codeDescription}
                          percentageChargeForService={percentageChargeForService}
                          negotiatedFinancialCost={negotiatedFinancialCost}
                          usedInModals
                        />
                      </Modal>
                    )
                  }
                  {((type_user !== 'admin' && type_user !== 'admin_amex') && graph_data)
                  && (
                    <div className="column">
                      <Panel headingText={title}>
                        <ResponsiveContainer width="100%" height={500}>
                          <AreaChart
                            data={graph_data}
                            margin={{
                              top: 0,
                              right: 100,
                              bottom: 10,
                              left: 50,
                            }}
                          >
                            <defs>
                              <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                              </linearGradient>
                            </defs>
                            <Area
                              type="monotone"
                              legendType="line"
                              dataKey="y"
                              stroke="#418c5d"
                              fillOpacity={1}
                              fill="url(#greenGradient)"
                            />
                            <XAxis
                              dataKey="label"
                              interval="preserveStartEnd"
                              name={t('date')}
                              tick={{
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                              tickSize={10}
                              minTickGap={50}
                              tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                            />
                            <YAxis
                              interval="preserveStartEnd"
                              name={t('amount')}
                              tick={{
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                              tickFormatter={tick => formatCurrency(tick)}
                            />
                            <CartesianGrid strokeDasharray="5 5"/>
                            <Tooltip
                              formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                              labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                month: 'long',
                                day: '2-digit',
                                weekday: 'long',
                                year: 'numeric',
                              }).format(new Date(label.replace(/-/g, '\/')))}
                            />
                            {graph_data.length > 0 && (
                              <Brush
                                dataKey="label"
                                height={50}
                                stroke="#000000"
                                travellerWidth={10}
                                y={450}
                                startIndex={0}
                                endIndex={60}
                              >
                                <AreaChart>
                                  <Area
                                    type="monotone"
                                    dataKey="y"
                                    fillOpacity={1}
                                    fill="url(#greenGradient)"
                                  />
                                </AreaChart>
                              </Brush>
                            )
                            }
                          </AreaChart>
                        </ResponsiveContainer>
                      </Panel>
                    </div>
                  )}
                  {((type_user === 'admin' || type_user === 'admin_amex') && graph_data)
                  && (
                    <div className="column is-full">
                      <div style={{ zIndex: 99999 }}>
                        {
                          (type_user === 'admin' || type_user === 'admin_amex')
                          && (
                            <div className="block">
                              <div className="column is-full">
                                <div className="columns field-body">
                                  <div className="column">
                                    <p style={{
                                      textAlign: 'center',
                                      fontSize: '1.25rem'
                                    }}>
                                      {t('dateRange')}
                                    </p>
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="FaRegCalendarAlt"
                                      iconPosition="is-left"
                                      position="absolute"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="FaRegCalendarAlt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div style={{ marginTop: '35px' }}>
                                    <button
                                      type="button"
                                      className="button is-primary"
                                      onClick={() => {
                                        invoiceDate === '' && invoiceDateUntil === '' ?
                                          showMessage({
                                            message: t('emptyFields'),
                                            config: TOAST_CONFIG.WARNING,
                                          })
                                          : invoiceDateUntil < invoiceDate ?
                                          showMessage({
                                            message: t('errorDate'),
                                            config: TOAST_CONFIG.WARNING,
                                          })
                                          :
                                          this.rangeDate();
                                      }}
                                    >
                                      {t('send')}
                                    </button>
                                  </div>
                                </div>
                                <div className="columns field-body">
                                  <div className="column"></div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <br/>
                      <div className="columns field-body" style={{ zIndex: 1 }}>
                        <div className="column" style={{ zIndex: 1, }}>
                          <Panel headingText={title1} style={{ zIndex: 1 }}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[0].graph_emmit : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#418c5d"
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5"/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[0].graph_emmit.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title2}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[1].graph_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#418c5d"
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5"/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[1].graph_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                      <div className="columns field-body">
                        <div className="column">
                          <Panel headingText={title3}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[2].graph_amount_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#418c5d"
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('amount')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => formatCurrency(tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5"/>
                                <Tooltip
                                  formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[2].graph_amount_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title4}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[3].graph_pending_approve : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#418c5d"
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5"/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[3].graph_pending_approve.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {(type_user === 'seller' && lateralStatsData) && (
                    <div className="column is-one-third">
                      <InformativeBoxes
                        items={lateralStatsData}
                        size="is-full"
                        className="inverted"
                      />
                    </div>
                  )} */}
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        {
                          (type_user === 'acquiring_bank' || type_user === 'acquiring_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                            />
                          )}
                        {
                          (type_user === 'issuing_bank' || type_user === 'issuing_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                            />
                          )}
                        {(type_user !== 'admin' && type_user !== 'admin_amex') && (
                          <TableSimple
                            data={data}
                            columns={columns}
                            subComponent={this.renderRowSubComponent}
                          />
                        )}
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  stats: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientsConditions: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  getProvidersConditions: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  getProvider: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  statTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           stats,
                           auth,
                           clients,
                           providers,
                           documentType,
                           transactions,
                           role,
                           paymentConditions,
                         }) => ({
  stats,
  auth,
  clients,
  providers,
  documentType,
  transactions,
  role,
  paymentConditions
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...statsActions,
    ...transactionsActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...leadActions,
    ...roleActions,
  }),
  withNamespaces(),
)(Dashboard);
