/**
 * Created by Jonas on 02/09/19.
 */

import React from 'react';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '../index';

const UserForm = ({
                    t,
                    onSubmit,
                    handleInputChange,
                    handleSelectChange,
                    name,
                    lastName,
                    documentTypesList,
                    documentNumber,
                    documentType,
                    email,
                    title,
                    edit,
                    hidden,
                    position,
                    roles,
                    roleType,
                    roleList,
                    handleItemChange,
                  }) => (
  <Panel headingText={title}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="name"
              label={t('name')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="lastName"
              label={t('adminLastname')}
              value={lastName}
              onChange={handleInputChange}
              placeholder={t('adminLastname')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="documentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={
                [{
                  label: 'CI',
                  value: 0,
                }, {
                  label: 'Pasaporte',
                  value: 1,
                },]}
              value={documentType}
              isDisabled={hidden ? hidden : edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={13}
              disabled={edit}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
        <div className="column">
            <TextInput
              name="adminPosition"
              label={t('adminPosition')}
              value={position}
              onChange={handleInputChange}
              placeholder={t('adminPosition')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>
          <div className="column">
            <CreatableMultiSelect
              name="roleType"
              label={t('rol')}
              controlClasses="is-expanded"
              value={roles}
              onChange={handleItemChange}
              placeholder={t('selectOrTypeRol')}
              noOptionsMessage={() => t('noItemsAvailable')}
              //formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
              getOptionLabel={option => option.label_type}
              getOptionValue={option => option.id}
              getNewOptionData={(inputValue, optionLabel) => ({
                id: optionLabel,
                name: optionLabel,
              })
              }
              options={roleList}
            />
          </div>
          <div className="column">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
    </Form>
  </Panel>
);

UserForm.propTypes = {
  /*t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  documentType: PropTypes.number.isRequired,
  roleType: PropTypes.number,
  roleList: PropTypes.arrayOf(PropTypes.object).isRequired,*/
};

UserForm.defaultProps = {
  discountRate: 0,
  client: false,
  adminName: '',
  adminLastname: '',
  adminPosition: '',
};

export default UserForm;
