import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


const SignInFooter = ({ disclaimer }) => {

  return (
    <footer>
      <div className="footer-text">
        <p>
          { disclaimer }
        </p>
      </div>
    </footer>
  );
};

export default SignInFooter;

SignInFooter.propTypes = {
  disclaimer: PropTypes.string.isRequired,
};