/**
 * Created by Jonas on 09/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { getPath } from '../../util';

const PaymentConditionForm = ({
  t,
  onSubmit,
  handleInputChange,
  handleOnInputChange,
  handlePercentageChange,
  handleSelectChange,
  isClient,
  client,
  provider,
  persons,
  baseDays,
  discountRate,
  receiverDiscountShare,
  clientPayment,
  editable,
  startCountingFrom,
  limitAmount,
  hidden,
  band,
  inputValue,
  clientEdit,
  codes,
  commerceCode,
  codeDescription,
  percentageChargeForService,
  serviceInChargeOfTheClient,
  usedInModals,
  negotiatedFinancialCost,
}) => (
  <div className={ usedInModals ? "main-for-modals" : ""} >
    <Panel
      headingText={band ? t('commercialConditions') : editable ? t('edit') : t('commercialConditions')}>
      <Form
        submitText={band ? t('accept') : t('save')}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        buttonClasses={`mr-8px ${editable ? '' : ''}`}
      >
        <div className="field is-horizontal subtitle is-5 text-black" style={{marginTop: 0}}>
          {t('billPayment')}
        </div>
        <div className="field is-horizontal subtitle is-4 text-black">
          {isClient ? t('providerPaymentCondition') : t('clientPaymentCondition')}
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column" style={{ display: (editable ? 'none' : '') }}>
              <SelectSingle
                name={isClient ? 'provider' : 'client'}
                label={t(isClient ? 'provider' : 'customer')}
                placeholder={t('SelectOption')}
                onChange={handleSelectChange}
                options={handleOnInputChange === null || inputValue.length > 1 ? [
                  ...persons.map((person, index) => ({
                    label: `${person.idPerson.name} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], person)} ${person.idPerson.document_number}`,
                    value: index,
                  })),
                ] : [{}]}
                value={ isClient ? provider + 1 : client + 1 }
                selectClasses="is-fullwidth"
                controlClasses="has-icons-left"
                icon="FaRegUser"
                iconPosition="is-left"
                isDisabled={editable}
                isSearchable
                onInputChange={handleOnInputChange}
                inputValue={inputValue}
              />
            </div>
            <div className="column" style={{ display: (editable ? '' : 'none') }}>
              <TextInput
                name="clientEdit"
                label={t(isClient ? 'provider' : 'customer')}
                value={clientEdit}
                onChange={handleInputChange}
                placeholder="client"
                type="text"
                controlClasses="is-expanded"
                //min="0"
                disabled={editable}
              />
            </div>
            <div className="column">
              <TextInput
                name="baseDays"
                label={t('arrangedDays')}
                value={baseDays}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                //min="0"
                step="1"
                disabled={band}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <SelectSingle
                name="startCountingFrom"
                label={t('startCountingFrom')}
                placeholder={t('SelectOption')}
                onChange={handleSelectChange}
                options={
                  [{
                    label: t('receptionDate'),
                    value: 0,
                  }, {
                    label: t('approvalDate'),
                    value: 1,
                  }]}
                selectClasses="is-fullwidth"
                controlClasses="has-icons-left"
                icon="FaRegUser"
                iconPosition="is-left"
                value={startCountingFrom}
                isClearable={false}
                isDisabled={band}
              />
            </div>
            <div className="column">
              <TextInput
                name="limitAmount"
                label={t('limitAmount')}
                value={limitAmount}
                onChange={handleInputChange}
                placeholder="0.00"
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                //min="0"
                //step="0.01"
                //max="999999999999999.99"
                iconPosition="is-right"
                icon="FaMoneyBillWave"
                disabled={band}
              />
            </div>
            <div className="column" style={{ display: (hidden ? 'none' : '') }}>
              <TextInput
                name="discountRate"
                label={t('discountRate')}
                value={discountRate}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                min="0"
                step="0.01"
                max="100"
                iconPosition="is-right"
                icon="FaPercent"
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal" style={{ display: (hidden ? 'none' : '') }}>
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="receiverDiscountShare"
                label={t('receiverDiscountShare')}
                value={receiverDiscountShare}
                onChange={handlePercentageChange}
                placeholder="100"
                type="number"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                min="0"
                step="0.01"
                max="100"
                iconPosition="is-right"
                icon="FaPercent"
              />
            </div>
            <div className="column" style={{ display: (hidden ? 'none' : '') }}>
              <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                {`${t('paymentClient')}:`}
              </div>
              {`${Number(clientPayment)
                .toFixed(2)} %`}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            {/* <div className="column">
              <TextInput
                name="limitAmount"
                label={t('limitAmount')}
                value={limitAmount}
                onChange={handleInputChange}
                placeholder="0.00"
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                //min="0"
                //step="0.01"
                //max="999999999999999.99"
                iconPosition="is-right"
                icon="fas fa-money-bill-wave"
                disabled={band}
              />
            </div>*/}
          </div>
        </div>
        <div className="field is-horizontal" style={{ display: band ? "none" : ""}}>
          <div className="field-body columns">
            <div className="column">
              <SelectSingle
                name={'commerceCode'}
                label={t('descriptionOfTheMerchantAffiliateCode')}
                placeholder={t('SelectOption')}
                onChange={handleSelectChange}
                options={[
                  ...codes.map((code, index) => ({
                    label: code.description,
                    value: index,
                  })),
                ]}
                value={commerceCode}
                selectClasses="is-fullwidth"
                controlClasses="has-icons-left"
                icon="FaRegUser"
                iconPosition="is-left"
                isDisabled={band}
              />
            </div>
            <div className="column">
              <TextInput
                label={t('commerceCode')}
                value={codeDescription}
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                disabled={true}
              />
            </div>
          </div>
        </div>
        {band && 
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
            <TextInput
                label={t('descriptionOfTheMerchantAffiliateCode')}
                value={codeDescription}
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-left"
                disabled={true}
              />
            </div>
            <div className="column">
              <TextInput
                label={t('commerceCode')}
                value={commerceCode}
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                disabled={true}
              />
            </div>
          </div>
        </div>}
        <div className="Panel1">
          <div
            className="columns is-centered"
            style={{ animationDelay: '1s' }}
          >
            <div className="column is-full wow animated fadeInUp">
              <div
                className={'title is-4 panel-header bg-panel text-white'}
                style={{ background: '#016fd0' }}>
                {t('financialCost')}
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <SelectSingle
                      name="percentageChargeForService"
                      label={t('percentageChargeForService')}
                      placeholder={t('SelectOption')}
                      onChange={handleSelectChange}
                      options={
                        [{
                          label: t('100%'),
                          value: 0,
                        }, {
                          label: t('50%'),
                          value: 1,
                        }, {
                          label: t('0%'),
                          value: 2,
                        }]}
                      selectClasses="is-fullwidth"
                      //controlClasses="is-expanded has-icons-right"
                      //inputClasses="has-text-right"
                      ontrolClasses="has-icons-right"
                      icon="FaRegUser"
                      iconPosition="is-right"
                      value={percentageChargeForService}
                      isClearable={false}
                      isDisabled={band}
                    />
                  </div>
                  <div className="column">
                    <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                      {`${t('serviceInChargeOfTheClient')}:`}
                    </div>
                    {`${Number(clientPayment)
                      .toFixed(2)} %`}
                  </div>
                  {/*<div className="column">
                    <TextInput
                      name="serviceInChargeOfTheClient"
                      label={t('serviceInChargeOfTheClient')}
                      value={serviceInChargeOfTheClient}
                      onChange={handleInputChange}
                      placeholder="0"
                      type="number"
                      controlClasses="is-expanded has-icons-right"
                      inputClasses="has-text-right"
                      min="0"
                      step="0.01"
                      max="100"
                      iconPosition="is-right"
                      icon="fas fa-percent"
                    />
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="negotiatedFinancialCost"
                  label={t('negotiatedFinancialCost')}
                  value={negotiatedFinancialCost}
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  onChange={handleInputChange}
                  min="0"
                  step="0.01"
                  disabled={band ? true : !(clientPayment === 100)}
                />
              </div>
              <div className="column">
              </div>
            </div>
          </div>
        </div>
        <br/>
      </Form>
    </Panel>
  </div>
  );

PaymentConditionForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func,
  handlePercentageChange: PropTypes.func,
  handleSelectChange: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  client: PropTypes.number,
  provider: PropTypes.number,
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseDays: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  receiverDiscountShare: PropTypes.number,
  limitAmount: PropTypes.string.isRequired,
  clientPayment: PropTypes.number,
  editable: PropTypes.bool.isRequired,
  band: PropTypes.bool,
  hidden: PropTypes.bool,
  inputValue: PropTypes.string,
  usedInModals: PropTypes.bool,
};

PaymentConditionForm.defaultProps = {
  isClient: false,
  client: null,
  provider: null,
  handleOnInputChange: null,
  inputValue: '',
  usedInModals: false,
};


export default PaymentConditionForm;
