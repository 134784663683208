import { routeActions, routeTypes } from '../ducks/route';
import { TOAST_CONFIG } from '../../config/constants';

const routeData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.FETCH_ROUTE) {
    return;
  }

  const {
    params: {
      id = '', name = '', type = '', owner_id = '', pageSize = 0,
    } = {},
  } = action.payload;
  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-routes?id=${id}&name=${name}&type=${type}&owner_id=${owner_id}&pageSize=${pageSize}`,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(routeActions.updateRoutes({
            list: data,
          }));
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const routeDataByType = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.FETCH_ROUTE_BY_TYPE) {
    return;
  }

  const {
    params: {
      type = '',
    } = {},
  } = action.payload;
  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `api/routes-by-type?&type=${type}`,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(routeActions.updateRoutes({
            list: data,
          }));
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const saveRouteData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.ADD_ROUTE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-routes',
        data,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: rout } = responseData;
        if (success) {
          dispatch(routeActions.saveRoute({
            rout,
          }));
          callback(responseData);
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const fetchRoutesRole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.FETCH_ROUTE_ROLE) {
    return;
  }

  const {
    params: {
      role_id = '', route_id = '', isFull = false, pageSize = 0,
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-role-routes?role_id=${role_id}&route_id=${route_id}&isFull=${isFull}&pageSize=${pageSize}`,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: routesRole } = responseData;
        if (success) {
          dispatch(routeActions.updateRoutesRole({
            list: routesRole,
          }));
          callback(responseData);
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const addRoleRoute = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.CREATE_ROUTE_ROLE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-role-routes',
        data,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success } = responseData;
        if (success) {
          callback(responseData);
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const removeRoleRoute = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.DELETE_ROUTE_ROLE) {
    return;
  }

  const {
    params: {
      role_id = '', route_id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `b2b-role-routes/${role_id},${route_id}`,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: () => {
        callback();
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const removeRoute = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.DELETE_ROUTE) {
    return;
  }

  const {
    data: {
      route_id = '',
    } = {},
    callback = () => {
    },
    t,
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `b2b-routes/${route_id}`,
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: () => {
        callback();
        dispatch(routeActions.removeRoute({ route_id }));
        dispatch(routeActions.showMessage({
          message: t('deleteRoute'),
          config: TOAST_CONFIG.SUCCESS,
        }));
      },
      onError: () => {
        dispatch(routeActions.showMessage({
          message: t('cantDeleteRoute'),
          config: TOAST_CONFIG.INFO,
        }));
      },
      onEnd: routeActions.endFetch,
    },
  });
};

const editRoute = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== routeTypes.EDIT_ROUTE) {
    return;
  }

  const {
    data: {
      id,
      path,
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: routeTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `b2b-routes/${id}`,
        data: {
          path,
        },
      },
      authorization: true,
      onStart: routeActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: routeActions.endFetch,
    },
  });
};

export default [
  routeData,
  routeDataByType,
  saveRouteData,
  fetchRoutesRole,
  addRoleRoute,
  removeRoleRoute,
  removeRoute,
  editRoute,
];
