import React from 'react';
import { withNamespaces } from 'react-i18next';
import ReactTable from 'react-table-legacy';
import PropTypes from 'prop-types';
import './styles.scss';

const TableSimple = ({
                       t,
                       data,
                       columns,
                       subComponent,
                       reff,
                       band,
                     }) => (
  <div className="TableSimple">
    <ReactTable
      data={data}
      columns={columns}
      filterable
      defaultFilterMethod={(filter, row) => String(row[filter.id])
        .toLowerCase()
        .includes(filter.value.toLowerCase())}
      defaultPageSize={band ? 5 : 10}
      className="-striped -highlight"
      previousText={t('previous')}
      nextText={t('next')}
      loadingText={t('loading')}
      noDataText={t('noRowsFound')}
      pageText={t('page')}
      ofText={t('of')}
      rowsText={t('rows')}
      SubComponent={subComponent}
      ref={reff}
    />
  </div>
);

TableSimple.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  columns: PropTypes.arrayOf(Object).isRequired,
  subComponent: PropTypes.func,
  reff: PropTypes.shape(Object),
};

TableSimple.defaultProps = {
  subComponent: () => {
  },
  reff: null,
  band: false,
};

export default withNamespaces()(TableSimple);
