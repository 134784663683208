import React, { Component, Fragment } from "react"
import { compose } from "redux"
import "./styles.scss"
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Panel } from "../../components"
import { authActions } from "../../state/ducks/auth"
import { customIcon } from '../../util';

class SelectRole extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => ({})

  resetState = () => {
    this.setState(this.getInitialState())
  }

  componentDidMount() {}

  updateUser = (typeUser) => {
    const {
      history,
      changeRole,
      auth: {
        user: {
          b2b: { type_user, roles },
          profile: { user_id },
        },
      },
    } = this.props
    if (type_user !== typeUser) {
      const data = {
        user_id,
        new_role:
          roles[
            roles.findIndex(function(code) {
              return code.name === typeUser
            })
          ].id,
      }
      changeRole({
        data,
        callback: () => {
          history.push("/recover-password") //'/dashboard'
        },
      })
    } else {
      history.push("/dashboard")
    }
  }

  render() {
    const { t } = this.props
    const {} = this.state
    return (
      <Fragment>
        <Panel headingText={t("welcomeToB2b")}>
          <div className="SelectRole__main">
            <div className="butt">
              <a
                onClick={this.updateUser.bind(this, "buyer")}
                target="_blank"
                className="buttonB2B"
              >
                <span className="mr-8px">
                  {customIcon('FaUser')}
                </span>
                {t("loginAsCustomer")}
              </a>
            </div>
            <div className="butt">
              <a
                target="_blank"
                className="buttonB2B"
                onClick={this.updateUser.bind(this, "seller")}
              >
                <span className="mr-8px">
                  {customIcon('FaStoreAlt')}
                </span>
                {t("loginAsProvider")}
              </a>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
  }
}

SelectRole.propTypes = {
  t: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})
export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces()
)(SelectRole)
