import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';


class CreateProvider extends Component {
  constructor(props) {
    super(props);
    const { location: { state } } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: -1,
    documentNumber: '',
    address: '',
    email: '',
    password: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    client: '',
    startCountingFrom: 1,
    baseProvider: 0,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminDocumentNumber: '',
    adminDocumentType: -1,
    adminPosition: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    noRetain: false,
    edit: false,
    hidden: false,
    commerceCodes: [],
    commerceCode: -1,
    descriptionCommerceCode: '',
  });

  componentDidMount() {
    const {
      fetchDocumentTypes,
      fetchItems,
      getClients,
      fetchRoles,
      router: { location: { pathname } },
      documentType: { list: documentTypeList },
    } = this.props;

    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchItems();
    getClients({});
    fetchRoles({
      params: {
        name: 'seller',
        type: 'external',
      },
    });

    if (pathname === '/create-provider') {
      this.setState({
        hidden: true,
      });
    }
    if (pathname === '/edit-provider') this.setState(this.arrayProvider);
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-provider':
        return t('createProvider');
      case '/edit-provider':
        return t('edit');
      default:
        return t('');
    }
  };

  arrayProvider = () => {
    const {
      providers: { list: providerList },
      documentType: { list: documentTypeList },
    } = this.props;
    let documentType = -1;

    if (providerList.seller[0].idPerson.idDocumentType !== null) {
      documentType = providerList.seller[0].idPerson.idDocumentType === null ? -1 :
      (documentTypeList.map(
          (documentTypeMap, index) => ({
            ...documentTypeMap,
            index
          })
        ).filter(documentTypeFil => 
          documentTypeFil.document_type === providerList.seller[0].idPerson.idDocumentType.document_type)[0].index);
    }
    const item = [];
    for (let i = 0; i < providerList.items.length; i++) {
      item.push({id: providerList.items[i].id, description: providerList.items[i].description});
    }
    const period = providerList.seller[0].paymentConditionAsBuyer[0] !== null ?
      this.getDays(providerList.seller[0].paymentConditionAsBuyer[0].days) : -1;

    return {
      name: providerList.seller[0].idPerson.name,
      documentType: documentType,
      documentNumber: providerList.seller[0].idPerson.document_number,
      address: providerList.seller[0].address,
      adminName: providerList.seller[0].admin_name,
      adminLastname: providerList.seller[0].admin_lastname,
      adminDocumentNumber: providerList.seller[0].admin_document_number,
      adminDocumentType: providerList.seller[0].admin_document_type === 'CI' ||
      providerList.seller[0].admin_document_type === 'Cédula' ? 0 : 1,
      adminPosition: providerList.seller[0].admin_position,
      email: providerList.seller[0].idPerson.idEmailActive.email,
      percentage: providerList.seller[0].paymentConditionAsBuyer[0] !== null ?
        providerList.seller[0].paymentConditionAsBuyer[0].percentage : 0,
      period,
      descriptionCommerceCode: providerList.seller[0].commerce_code !== null ? providerList.seller[0].description : '',
      commerceCode: providerList.seller[0].cod_provider !== null ? providerList.seller[0].cod_provider : '',
      edit: true,
      items: item,
      hidden: true,
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'documentNumber') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else if (name !== 'documentNumber') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;
    const {
      t,
      showMessage,
    } = this.props;

    const value = type === 'checkbox' ? checked : targetValue;

    if (adminDocumentType === -1) {
      showMessage({
        message: t('SelectDocumentType'),
        config: TOAST_CONFIG.WARNING,
      });
    } else {
      if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
        let num = this.formatNumber(event.target.value);
        this.setState({
          [name]: (num),
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    }
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };


  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  validatePaymentCondition = () => {
    const {
      discountRate, client,
    } = this.state;
    const { t, showMessage } = this.props;
    if (client !== '' && discountRate > 0) {
      if (discountRate > 100) {
        showMessage({
          message: t('discountRateOutOfLimits'),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }
      return true;
    }
    if (client === '' && discountRate === 0) {
      return false;
    }
    showMessage({
      message: t('completeComercialConditionOrEmptyAll'),
      config: TOAST_CONFIG.WARNING,
    });
  };

  createProvider = () => {
    const {
      createProvider,
      auth,
      t,
      showMessage,
      createPaymentCondition,
      documentType: { list: documentTypeList },
      deleteLead,
      clients,
      role: { list: roleList },
      router: { location: { pathname } },
      providers: { list: providerList },
      editUser,
      history,
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      address,
      percentage,
      period,
      baseDays,
      items,
      discountRate,
      lead,
      lead_id,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      client,
      receiverDiscountShare,
      noRetain,
      commerceCode,
    } = this.state;

    if (documentType === -1) {
      showMessage({
        message: t('SelectDocumentType'),
        config: TOAST_CONFIG.WARNING,
      });
      return;
    }

    let validCommerceCode = ''

    if(typeof(providerList[commerceCode]) !== 'undefined'){
      validCommerceCode = providerList[commerceCode].code;
    }

    const data = {
      name,
      document_type: documentTypeList[documentType].document_type,
      document_number: documentNumber,
      email,
      password,
      user_type: 'flecha',
      agency_user_type: 'business_admin',
      role_name: roleList[0].id,
      items,
      commerce_code: validCommerceCode,
      b2b: {
        bank_id: auth.user.b2b.id,
        admin: 0,
        issuing_bank: 0,
        acquiring_bank: 0,
        seller: 1,
        buyer: 0,
        retain: noRetain ? 0 : 1,
        address,
        admin_name: adminName,
        admin_lastname: adminLastname,
        admin_document_number: adminDocumentNumber,
        admin_document_type: adminDocumentType,
        admin_position: adminPosition,
      },
    };

    if(pathname === '/create-provider') {
      
      switch(data.b2b.admin_document_type){
        case 0:
          data.b2b.admin_document_type = 'CI';
        break;
        case 1:
          data.b2b.admin_document_type = "DNI";
        break;
        case 2:
          data.b2b.admin_document_type = "Pasaporte";
        break;
      }

      if (this.validateEmptyFields()) {
      //  if (this.isEmail(email)) {
          createProvider({
            data,
            callback: (response) => {
              const { b2b: { id } } = response;
              /* const conditionData = {
                 sender_id: auth.user.b2b.id,
                 receiver_id: id,
                 percentage,
                 days: this.getDaysInv(period),
                 type: 'advance',
               };*/
              if (lead) {
                deleteLead({
                  params: {
                    id: lead_id,
                  },
                });
              }
              showMessage({
                message: t('createProviderSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
              this.resetState();
              history.push('/dashboard');

              /* createPaymentCondition({
                 data: conditionData,
                 callback: () => {
                   showMessage({
                     message: t('createProviderSuccess', { name }),
                     config: TOAST_CONFIG.SUCCESS,
                   });
                   this.resetState();
                   history.push('/dashboard');
                 },
               });*/
            },
          });
        /* } else {
           showMessage({
             message: t('incorrectEmail', { email }),
             config: TOAST_CONFIG.WARNING,
           });
           return;
         }*/
      } else {
        showMessage({
          message: t('emptyAll'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    }

    if(pathname === '/edit-provider') {
      const data = {
        id_user: providerList.seller[0].id,
        name,
        documentType: documentTypeList[documentType].id,
        documentNumber: documentNumber,
        email,
        items,
        address,
        admin_name: adminName,
        admin_lastname: adminLastname,
        admin_document_number: adminDocumentNumber,
        admin_document_type: adminDocumentType,
        admin_position: adminPosition,
        percentage,
        days: this.getDaysInv(period),
        type: 'B',//Seller
        //admin_document_number: adminDocumentNumber,
        //admin_document_type: adminDocumentType,
      };

      switch(data.admin_document_type){
        case 0:
          data.admin_document_type = 'CI';
        break;
        case 1:
          data.admin_document_type = "DNI";
        break;
        case 2:
          data.admin_document_type = "Pasaporte";
        break;
      }

      if (
        adminName === '' || 
        adminLastname === '' || 
        adminPosition === '' || 
        email === '' || 
        adminDocumentNumber === '' ||
        adminDocumentType === -1) {

        showMessage({
          message: t('emptyAll'),
          config: TOAST_CONFIG.WARNING,
        });
      } else {
       // if (this.isEmail(email)) {
          editUser({
            data,
            callback: () => {
              history.push('/dashboard');
              this.resetState();
              showMessage({
                message: t('editProviderSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
            },
          });
        /*  } else {
            showMessage({
              message: t('incorrectEmail', { email }),
              config: TOAST_CONFIG.WARNING,
            });
            return;
          }*/
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      documentNumber,
      documentType,
      email,
      adminPosition,
      items,
      commerceCode,
    } = this.state;

    return !(
      adminName === '' ||
      adminLastname === '' ||
      adminDocumentType === -1 ||
      adminDocumentNumber === '' ||
      documentNumber === '' ||
      documentType === -1 ||
      email === '' ||
      adminPosition === '' ||
      items.length < 0 || commerceCode === -1);
  };

  consultCode = () => {
    const {
      history,
      showMessage,
      consultCommerceCode,
    } = this.props;
    const {
      documentNumber,
    } = this.state;

    consultCommerceCode({
      params: {
        ruc: documentNumber,
      },
      callback: (response) => {
        if (response.hasOwnProperty('error')) {
          showMessage({
            message: response.error,
            config: TOAST_CONFIG.WARNING,
          });
          history.push('/dashboard');
        } else {
          this.setState({
            commerceCodes: response,
            commerceCode: -1,
            descriptionCommerceCode: '',
          });
        }
      }
    });
  };

  handleCommerceChange = (newVal, label) => {
    const {
      providers: { list },
    } = this.props;

    this.setState({
      [label.name]: newVal.value,
      descriptionCommerceCode: list[newVal.value].code,
    });
  };

  render() {
    const {
      t,
      clients: { loading: clientLoading, list: clientList },
      providers: { loading: providerLoading },
      documentType: { list: documentTypesList },
      item: { list: itemList },
    } = this.props;
    const {
      name,
      documentNumber,
      documentType,
      email,
      password,
      address,
      percentage,
      baseDays,
      items,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      noRetain,
      period,
      edit,
      hidden,
      commerceCodes,
      commerceCode,
      descriptionCommerceCode,
    } = this.state;
    return (
      <div>
        {(clientLoading || providerLoading) && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <PersonForm
              t={t}
              title={this.getTitle()}
              onSubmit={this.createProvider}
              handleInputChange={this.handleInputChange}
              handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
              name={name}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              //={this.state.documentsEcuador}
              documentNumber={documentNumber}
              address={address}
              documentType={documentType}
              items={items}
              itemList={itemList}
              email={email}
              password={password}
              persons={clientList}
              baseDays={baseDays}
              percentage={percentage}
              discountRate={discountRate}
              person="provider"
              adminName={adminName}
              adminLastname={adminLastname}
              adminDocumentNumber={adminDocumentNumber}
              adminDocumentType={adminDocumentType}
              adminPosition={adminPosition}
              handlePercentageChange={this.handlePercentageChange}
              receiverDiscountShare={receiverDiscountShare}
              clientPayment={clientPayment}
              noRetain={noRetain}
              period={period}
              edit={edit}
              hidden={hidden}
              consultCode={this.consultCode}
              commerceCodes={commerceCodes}
              commerceCode={commerceCode}
              descriptionCommerceCode={descriptionCommerceCode}
              handleCommerceChange={this.handleCommerceChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateProvider.propTypes = {
  clients: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                           clients, documentType, auth, item, role, router, providers,
                         }) => ({
  clients,
  documentType,
  auth,
  item,
  role,
  router,
  providers,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...itemActions,
    ...clientsActions,
    ...leadActions,
    ...roleActions,
    ...providersActions,
  }),
  withNamespaces(),
)(CreateProvider);
