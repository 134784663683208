import { transactionsActions, transactionsTypes } from '../ducks/transactions';
import { TOAST_CONFIG } from '../../config/constants';

const listTransactionsAdmin = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.LIST_TRANSACTION_ADMIN) {
    return;
  }
  const {
    params: {
      user_id = '',
      is_admin = false,
      role = '',
      min_date = '',
      max_date = '',
    } = {},
  } = action.payload;

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/transactions?role=${role}&date_min=${min_date}&date_max=${max_date}&is_admin=${is_admin}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.setTransactionsSender(data.data));
      },
      onEnd: transactionsActions.endFetch,
    },
  });

};

const listTransactionsReceiver = ({ dispatch, getState }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.LIST_TRANSACTIONS_RECEIVER) {
    return;
  }

  const {
    auth: {
      user: { b2b: { type_user, roles } }
    },
  } = getState() || {};

  const {
    params: {
      bank_id = '',
      user_id = '',
      min_date = '',
      max_date = '',
      is_admin = false,
    } = {},
    t = null,
    callback = () => {},
  } = action.payload;

  let userId = 0;
  let band = false;
  if (roles.length > 1) {
    if (roles[0].owner_id != null) {
      userId = roles.map((rol, index) => ({
        ...rol,
        index
      }),)
        .filter(documentTypeFil => documentTypeFil.name === type_user)[0].index;
      band = true;
    } else {
      band = false;
    }
  }

  const { router: { location: { pathname } } } = getState() || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/transactions?bank_id=${bank_id}&role=receiver&expired=${pathname === '/expired-invoices'}&pending=${pathname === '/review-invoices'}&approved=${pathname === '/approved-invoices'}&paid=${pathname === '/paid-invoices'}&reject=${pathname === '/reject-invoices'}&date_min=${min_date}&date_max=${max_date}&is_admin=${is_admin}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data: { data: dataRsp, data: { reject_invoices = 0 } } }) => {
        dispatch(transactionsActions.setTransactionsReceiver(dataRsp));
        if (reject_invoices > 0  && t) {
          callback();
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const listTransactionsSender = ({ dispatch, getState }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.LIST_TRANSACTIONS_SENDER) {
      return;
  }
  const {
      params: {
        bank_id = '',
        user_id = '',
        min_date = '',
        max_date = '',
        is_admin = false,
      } = {},
      t = null,
      callback = () => {},
  } = action.payload;
  const {
    auth: {
      user: { b2b: { type_user, roles } }
    },
  } = getState() || {};
  let userId = 0;
  let band = false;
  if (roles.length > 1) {
    if (roles[0].owner_id != null) {
      userId = roles.map((rol, index) => ({
        ...rol,
        index
      }),)
        .filter(documentTypeFil => documentTypeFil.name === type_user)[0].index;
      band = true;
    } else {
      band = false;
    }
  }
  const { router: { location: { pathname } } } = getState() || {};
  dispatch({
      type: transactionsTypes.API_CALL,
      payload: {
          config: {
            method: 'GET',
            url: `/b2b/transactions?bank_id=${bank_id}&role=sender&expired=${pathname === '/expired-invoices'}&pending=${pathname === '/review-invoices'}&approved=${pathname === '/approved-invoices'}&paid=${pathname === '/paid-invoices'}&reject=${pathname === '/reject-invoices'}&date_min=${min_date}&date_max=${max_date}&is_admin=${is_admin}`,
          },
          authorization: true,
          onStart: transactionsActions.startFetch,
          onComplete: ({ data: { data: dataRsp, data: { pending_invoices = 0 } } }) => {
              dispatch(transactionsActions.setTransactionsSender(dataRsp));
              if (pending_invoices > 0 && t) {
                  callback();
              }
          },
          onEnd: transactionsActions.endFetch,
      },
  });
};

const fetchTransactions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTIONS) {
      return;
  }
  const { page = 0 } = action.payload || {};
  dispatch({
      type: transactionsTypes.API_CALL,
      payload: {
          config: {
              method: 'GET',
              url: `/api/user-last-transactions?page=${page}`,
          },
          authorization: true,
          onStart: transactionsActions.startFetch,
          onComplete: ({ data }) => {
              dispatch(transactionsActions.updateTransactions(data.data));
          },
          onEnd: transactionsActions.endFetch,
      },
  });
};

const statTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.STAT_TRANSACTION) {
    return;
  }

  const {
    params: {
      emmit = '',
      approved = '',
      min_date = '',
      max_date = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-transactions/stats?date_min=${min_date}&date_max=${max_date}&days=30&emmit=${emmit}&approved=${approved}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
          dispatch(transactionsActions.saveStatTransaction(
            { list: response.data.data, }
          ));
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const extractQr = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.EXTRACT) {
    return;
  }

  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/user-qr',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
            callback(response.data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const transfer = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.TRANSFER) {
    return;
  }

  const {
    data,
    callback = () => { },
  } = action.payload;

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/user-transaction',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const authorizeTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.AUTHORIZE_TRANSACTION) {
      return;
  }

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/authorize-transaction',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateCharge(data.data));
          dispatch(transactionsActions.updateBalance(data.data));
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const checkBalance = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.CHECK_BALANCE) {
    return;
  }

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/check-balance',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateBalance(data.data));
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const editTrasaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.EDIT_TRANSACTION) {
    return;
  }

  const {
    data: {
      id,
      date,
      invoice_number,
      amount,
      buyer_id,
      seller_id,
      description,
      file,
    },
    data,
    callback = () => { },
  } = action.payload;

  // eslint-disable-next-line no-undef
  const dataForm = new FormData();
  if (file) {
    const dateFormat = new Date(date);
    const sendDate = `${dateFormat.getFullYear()}/${dateFormat.getUTCMonth() + 1}/${dateFormat.getUTCDate()}`;
    dataForm.append('id', id);
    dataForm.append('date', sendDate);
    dataForm.append('invoice_number', invoice_number);
    dataForm.append('amount', amount);
    dataForm.append('buyer_id', buyer_id);
    dataForm.append('seller_id', seller_id);
    dataForm.append('description', description);
    dataForm.append('file', file);
  }

  const dataSend = file ? dataForm : data;

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/edit-transaction',
        data: dataSend,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data: dataResponse }) => {
        if (dataResponse.success) {
            callback();
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const getTransactionList = ({ dispatch, getState }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.GET_TRANSACTION) {
      return;
  }
  const {
    auth: {
      user: { b2b: { type_user, roles } }
    },
  } = getState() || {};

  let userId = 0;
  let band = false;
  if (roles.length > 1) {
    if (roles[0].owner_id != null) {
      userId = roles.map((rol, index) => ({
        ...rol,
        index
      }),)
        .filter(documentTypeFil => documentTypeFil.name === type_user)[0].index;
      band = true;
    } else {
      band = false;
    }
  }
  const {
      callback = () => { },
      params: {
          min_due_date = '',
          max_due_date = '',
          pageSize = 0,
      } = {},
  } = action.payload;
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-transactions?min_due_date=${min_due_date / 1000}&max_due_date=${max_due_date / 1000}&pageSize=${pageSize}&bank_id=${band ? roles[userId].owner_id : ''}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.saveTransaction({ list: data.data }));
          callback();
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const getTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTION) {
    return;
  }

  const {
    callback = () => {
    },
    params: {
      id = '',
      role = '',
    } = {},
  } = action.payload;
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-transactions/transaction?id=${id}&role=${role}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.saveTransaction({ list: data.data }));
          callback();
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const deleteTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.DELETE_TRANSACTION) {
    return;
  }
  const {
    data: { id },
    callback = () => {},
    t = () => {},
  } = action.payload;
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `b2b/delete-transaction?id_transaction=${id}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          callback();
          dispatch(transactionsActions.showMessage({
            message: t('deleteInvoice'),
            config: TOAST_CONFIG.SUCCESS,
          }));
        }
      },
      onError: () => {
        dispatch(transactionsActions.showMessage({
          message: t('cantDelete'),
          config: TOAST_CONFIG.INFO,
        }));
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const approveMultipleInvoices = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.APPROVE_MULTIPLE_INVOICES) {
      return;
  }
  const {
      data,
      callback = () => {},
  } = action.payload;
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/approve-multiple',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
            callback(response.data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const getReconciliationReport = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.GET_RECONCILIATION_REPORT) {
      return;
  }

  const {
    callback = () => {},
    params: {
      code = '',
      date = '',
    } = {},
  } = action.payload;
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/get-reconciliation-report?code=${code}&date=${date}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          callback( data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

export default [
  listTransactionsReceiver,
  listTransactionsSender,
  fetchTransactions,
  extractQr,
  transfer,
  authorizeTransaction,
  checkBalance,
  editTrasaction,
  getTransactionList,
  deleteTransaction,
  getTransaction,
  statTransaction,
  listTransactionsAdmin,
  approveMultipleInvoices,
  getReconciliationReport,
];
