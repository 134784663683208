import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BankForm, Loading, Modal, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { authActions } from '../../state/ducks/auth';
import { bankActions } from '../../state/ducks/bank';
import { providersActions } from '../../state/ducks/providers';
import { roleActions } from '../../state/ducks/role';
import { documentTypeActions } from '../../state/ducks/documentType';
import { TOAST_CONFIG } from '../../config/constants';
import { CSVLink } from 'react-csv';
import { customIcon } from '../../util';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Banks extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    bank_id: '',
    name: '',
    documentType: '',
    documentTypesList: [],
    roleList: [],
    documentNumber: 0,
    bankType: '',
    email: '',
    password: '',
    confirmPassword: '',
    downloadCsv: [],
  });

  componentDidMount() {
    const {
      fetchBanks,
      fetchDocumentTypes,
      fetchRoles,
    } = this.props;
    fetchBanks({});
    fetchDocumentTypes({});
    fetchRoles({
      params: {
        type: 'bank',
      },
    });
  }

  getGridSettings = (onToggle) => {
    const {
      t,
      bank: { list },
      updateStatus,
      fetchBanks,
      documentType: { list: documentTypesList },
      role: { list: roleList },
    } = this.props;
    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => d.idPerson.name,
      },
      {
        id: 'bank_type', // Required because our accessor is not a string
        Header: t('type'),
        accessor: d => t(d.roles[0].name),
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        accessor: d => d.idPerson.idEmailActive.email,
      },
      {
        id: 'document_number', // Required because our accessor is not a string
        Header: t('documentNumber'),
        accessor: d => `${d.idPerson.idDocumentType.document_type} ${d.idPerson.document_number}`,
      },
      {
        id: 'status', // Required because our accessor is not a string
        Header: t('status'),
        width: 100,
        Cell: cellInfo => (
          <span style={{color: cellInfo.original.user_status === '1' ? 'green' : 'red'}}>
            {customIcon(cellInfo.original.user_status === '1' ? 'FaRegCheckCircle' : 'FaBan')}
          </span>),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        Cell: cellInfo => (
          <table>
            <tbody>
              <tr>
                <td>
                  <Link
                    className={`button is-inverted is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                    to="/bank-list"
                    onClick={() => updateStatus({ data: { id_user: cellInfo.original.id, new_status: cellInfo.original.user_status === '1' ? '0' : '1' }, callback: () => fetchBanks({}) })}
                  >
                    {cellInfo.original.user_status === '1'
                      ? t('disable')
                      : t('enable')
                    }
                  </Link>
                  <button
                    className="button is-primary is-inverted"
                    onClick={() => {
                      this.setState({
                        bank_id: cellInfo.original.id,
                        name: cellInfo.original.idPerson.name,
                        documentType: documentTypesList.map(
                          (documentTypeMap, index) => ({ ...documentTypeMap, index }),
                        ).filter(documentTypeFil => documentTypeFil.id === cellInfo.original.idPerson.idDocumentType.id)[0].index,
                        documentNumber: cellInfo.original.idPerson.document_number,
                        bankType: roleList.map(
                          (roleMap, index) => ({ ...roleMap, index }),
                        ).filter(roleFil => roleFil.id == cellInfo.original.roles[0].id)[0].index, 
                        email: cellInfo.original.idPerson.idEmailActive.email,
                        originalEmail: cellInfo.original.idPerson.idEmailActive.email,
                      }, () => {
                        onToggle({
                          isEdit: true,
                          bank: cellInfo.original,
                        });
                      });
                    }}
                  >
                    <span className="mr-8px">
                      {customIcon('FaRegEdit')}
                    </span>
                    {t('edit')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ),
      }];
    const data = list;
    const title = t('bankList');
    return {
      columns,
      data,
      title,
    };
  };

  editProvider = (onToggle) => {
    const {
      t,
      role: { list: roleList },
      documentType: { list: documentTypeList },
      showMessage,
      editProvider,
      fetchBanks,
    } = this.props;
    const {
      bank_id,
      name,
      documentType,
      documentNumber,
      bankType,
      email,
      originalEmail,
      password,
      confirmPassword,
    } = this.state;
    const data = {
      id_user: bank_id,
      country_code: null,
      phone_number: '',
      email,//: email === originalEmail ? '' : email,
      password: '',
      lastname: '',
      name,
      documentType: documentTypeList[documentType].id,
      documentNumber,
      bankType: roleList[bankType].id,
      b2b: {
        issuing_bank: 0,
        acquiring_bank: 0,
        [roleList[bankType].name]: 1,
      },
    };
    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        data.password = password;
      } else {
        showMessage({
          message: t('nonMatchingPasswords'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    } else if (password !== '' || confirmPassword !== '') {
      showMessage({
        message: t('twoValuesWillBe'),
        config: TOAST_CONFIG.WARNING,
      });
    }
    editProvider({
      data,
      callback: () => {
        fetchBanks({});
        this.setState(this.getInitialState());
        onToggle();
      },
    });
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  download = () => {
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    columns.splice(5, 6);

    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].status = sortedData[i]._original.user_status === '1' ? 'Habilitado' : 'Deshabilitado';
    }

    const downloadCsv = [['sep=,']];
    downloadCsv.push(columns.map(column => column.Header));
    downloadCsv.push(...sortedData.map((sorted) => {
      const downloadCsvObject = {};
      columns.forEach((column) => {
        downloadCsvObject[column.id] = sorted[column.id];
      });
      return Object.values(downloadCsvObject);
    }));
    this.setState({ downloadCsv }, () => {
      this.csvRef.current.link.click();
    });
  };

  render() {
    const {
      auth: { loading: authLoading },
      bank: { loading: bankLoading }, t,
      documentType: { list: documentTypesList },
      role: { list: roleList },
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      bankType,
      email,
      password,
      confirmPassword,
      downloadCsv,
    } = this.state;
    const loading = authLoading || bankLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <BankForm
                          documentTypesList={documentTypesList}
                          roleList={roleList}
                          name={name}
                          documentNumber={documentNumber}
                          email={email}
                          password={password}
                          confirmPassword={confirmPassword}
                          handleInputChange={this.handleInputChange}
                          handleSelectChange={this.handleSelectChange}
                          t={t}
                          onSubmit={() => this.editProvider(onToggle)}
                          documentType={documentType}
                          bankType={bankType}
                          title={t('updateBank')}
                        />
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <div className="panel-top-content">
                            <Link
                              className="button is-primary"
                              to="/register-bank"
                              onClick={() => onToggle({
                                // transaction: cellInfo.original,
                                // paymentCondition: advance_condition,
                                // onSubmit: this.advancePayment,
                              })}
                            >
                              <span className="mr-8px" aria-hidden="true">
                                {customIcon('FaPlus')}
                              </span>
                              {t('newBank')}
                            </Link>
                            &nbsp;&nbsp;
                            <button
                              className="button is-primary"
                              onClick={this.download}
                            >
                              <span className="mr-8px" aria-hidden="true">
                                {customIcon('FaFileCsv')}
                              </span>
                              {`${t('download')} CSV`}
                            </button>
                            <CSVLink
                              data={downloadCsv}
                              filename="summary_b2b.csv"
                              className="hidden"
                              ref={this.csvRef}
                            />
                          </div>
                          <TableSimple data={data} columns={columns} reff={this.selectTable}/>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Banks.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  bank: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  role: PropTypes.shape(Object).isRequired,
  // stats: PropTypes.arrayOf(Object).isRequired,
  // transactions: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  editProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  transactions,
  stats,
  auth,
  bank,
  role,
  documentType,
}) => ({
  transactions,
  stats,
  auth,
  bank,
  role,
  documentType,
});
export default compose(
  connect(mapStateToProps, {
    ...statsActions,
    ...transactionsActions,
    ...clientsActions,
    ...providersActions,
    ...bankActions,
    ...authActions,
    ...roleActions,
    ...documentTypeActions,
  }),
  withNamespaces(),
)(Banks);