import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle } from '../../components';
import { userProfileActions } from '../../state/ducks/userProfile';
import { roleActions } from '../../state/ducks/role';
import { documentTypeActions } from '../../state/ducks/documentType';
import { authActions } from '../../state/ducks/auth';
import { customIcon, getPath } from '../../util';
import ProfileForm from '../../components/ProfileForm';
import { TOAST_CONFIG } from '../../config/constants';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    registerLoading: false,
    roleType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    editable: true,
    userType: false,
  });

  componentDidMount() {
    const {
      fetchRoles,
      fetchUserProfile,
      fetchDocumentTypes,
      auth: { user: { b2b: { id } } },
    } = this.props;
    // fetchDocumentTypes({});

    fetchRoles({
      params: {
        owner_id: id,
      },
    });

    fetchUserProfile({

    });

    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  validateEmptyFields = () => {
    const {
      name,
      documentNumber,
    } = this.state;

    if (name === '' || documentNumber === '') {
      return false;
    } else {
      return true;
    }
  };

  updateUserProfile = (onToggle) => {
    const {
      t,
      showMessage,
      editUserProfile,
      role: { list: rolList },
      fetchUserProfile,
      auth: { user: { user_id } },
      documentType: { list: documentTypesLists },
    } = this.props;

    const documentTypesList = this.deleteDocument();
    const {
      id,
      name,
      email,
      documentType,
      documentNumber,
      roleType,
      password,
      confirmPassword,
    } = this.state;

    const data = {
      id,
      name,
      email,
      document_type: documentTypesList[documentType].document_type,
      document_number: documentNumber,
      role_type: rolList[roleType].id,
    };

    if (this.validateEmptyFields()) {
      editUserProfile({
        data,
        callback: (response) => {

          fetchUserProfile({

          });
          onToggle();
          showMessage({
            message: t('editProfileSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    } else {
      showMessage({
        message: t('emptyFields'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      auth: { user: { profile: { type } } },
      userProfile: { list },
      documentType: { list: documentTypesList },
      role: { list: rolList },
      fetchUserProfile,
      auth: { user: { b2b: { id } } },
      updateStatus,
    } = this.props;

    const columns = [
      {
        id: 'fiscalDocument', // Required because our accessor is not a string
        Header: t('documentNumber'),
        accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
      },
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => d.idPerson.name,
      },
      {
        id: 'rol', // Required because our accessor is not a string
        Header: t('rol'),
        accessor: d => type === 'bank' ? t(d.role_name) : `${d.role_name} `,
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        accessor: d => `${d.username}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <Link
                  className={`button is-inverted is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                  to="/profile-list"
                  onClick={() => updateStatus({
                    data: {
                      id_user: cellInfo.original.id,
                      new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                    },
                    callback: () => fetchUserProfile({
                      /*params: {
                        owner_id: id,
                      }*/
                    })
                  })}
                >
                  {cellInfo.original.user_status === '1'
                    ? t('disable')
                    : t('enable')
                  }
                </Link>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    this.setState({
                      id: cellInfo.original.id,
                      documentType: documentTypesList
                        .findIndex(document => document.id
                          === cellInfo.original.idPerson.idDocumentType.id),
                      documentNumber: cellInfo.original.idPerson.document_number,
                      name: cellInfo.original.idPerson.name,
                      email: cellInfo.original.username,
                      roleType: rolList
                        .findIndex(role => role.id === cellInfo.original.role_id),
                      editable: true,
                      userType: type === 'bank' ? true : false,
                    }, () => {
                      onToggle({
                        isEdit: true,
                        role: cellInfo.original,
                      });
                    });
                  }}
                >
                  <span className="mr-8px">
                    {customIcon('FaRegEdit')}
                  </span>
                  {t('edit')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('profileList');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    }, () => (this.state.documentType));
  };

  deleteDocument = () => {
    const {
      documentType: { list: documentTypesList }
    } = this.props;

    const list = [];

    for (let i = 0; i < documentTypesList.length; i++) {
      if (documentTypesList[i].document_type !== 'RUC') {
        list.push({
          id: documentTypesList[i].id,
          document_type: documentTypesList[i].document_type
        });
      }
    }
    return list;
  };

  render() {
    const {
      t,
      userProfile: { loading },
      documentType: { list: documentTypesLists },
      role: { list: rolList },
    } = this.props;
    const documentTypesList = this.deleteDocument();
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      confirmPassword,
      roleType,
      editable,
      userType,
    } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <ProfileForm
                          t={t}
                          documentTypesList={documentTypesList}
                          roleList={rolList}
                          name={name}
                          documentNumber={documentNumber}
                          email={email}
                          password={password}
                          confirmPassword={confirmPassword}
                          documentType={documentType}
                          onSubmit={() => this.updateUserProfile(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handleSelectChange={this.handleSelectChange}
                          roleType={roleType}
                          editable={editable}
                          title={t('edit')}
                          userType={userType}
                          bank={userType}
                        />
                      </Modal>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <div className="panel-top-content">
                          <Link
                            className="button is-primary"
                            to="/create-profile"
                            onClick={() => onToggle({
                              // transaction: cellInfo.original,
                              // paymentCondition: advance_condition,
                              // onSubmit: this.advancePayment,
                            })}
                          >
                            <span className="mr-8px">
                              {customIcon('FaPlus')}
                            </span>
                            {t('newProfile')}
                          </Link>
                        </div>
                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>)}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

UserProfile.propTypes = {
  t: PropTypes.func.isRequired,
  editUserProfile: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}).isRequired,
  documentType: PropTypes.shape({}).isRequired,
  deleteUserProfile: PropTypes.func.isRequired,
  role: PropTypes.shape().isRequired,
  showMessage: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ role, auth, userProfile, documentType }) => (
  {
    role,
    auth,
    userProfile,
    documentType,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...authActions,
    ...userProfileActions,
    ...documentTypeActions,
  }),
  withNamespaces(),
)(UserProfile);
