import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import {Loading, Panel, SelectSingle, TableSimple, Toggle,} from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import './styles.scss';
import DateInput from '../../components/DateInput';
import {statsActions} from "../../state/ducks/stats";

class conciliationReport extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.selectTable = React.createRef();
        this.csvRef = React.createRef();
    }

    getInitialState = () => ({
        downloadCsv: [],
        date: '',
        cycle_date: null,
        clients: [],
        listReport: [],
        isClearable: true,
    });

    componentDidMount() {
        const {
            auth: { user: { b2b: { type_user, cod } } },
            getReconciliationReport,
            fetchStats,
        } = this.props;

        const userInfo = this.props.location.state !== undefined ? this.props.location.state.userInfo : -1;
        if ( type_user === 'issuing_bank' || type_user === 'issuing_query' )  {
            fetchStats({
                callback: (resp) => {
                    this.setState({
                        clients: resp.buyers,
                        client:  this.props.location.state !== undefined ? resp.buyers.findIndex(x => x.id === userInfo.id) : -1,
                    });
                }
            });
        }
        if ( userInfo !== -1 || type_user === 'buyer' ) {
            getReconciliationReport({
                params: {
                    code: type_user === 'buyer' ? cod : userInfo.cod,
                    date: '',
                },
                callback: (resp) => {
                    const array = [];
                    if (!Array.isArray(resp.Registro)) {
                        array.push(resp.Registro);
                    }
                    this.setState({
                        listReport: Array.isArray(resp.Registro) ? resp.Registro : Object.keys(resp.Registro).length > 0 ? array : [],
                    });
                }
            });
        }
        if( type_user === 'issuing_bank' || 
            type_user === 'issuing_query' || 
            type_user === 'main_bank' ) {
            const currentDate = new Date();
            this.handleDateChange(currentDate);
        }
    }

    resetState = () => {
        this.setState(this.getInitialState());
    };

    getGridSettings = () => {
        const {
            t,
            auth: {
                user: {
                    b2b: {
                        type_user,
                    },
                },
            },
        } = this.props;
        const {
            listReport,
        } = this.state;
        let columns;
        let data;
        let title;
        switch (type_user) {
            case 'buyer':
            case 'issuing_query':
            case 'issuing_bank':
            case 'main_bank':
                columns = [
                    {
                        expander: true,
                        show: false,
                    },
                    {
                        id: 'client', // Required because our accessor is not a string
                        Header: t('client'),
                        headerClassName: 'wordwrap',
                        show: type_user !== 'buyer',
                        accessor: d => d.nombreCliente ? d.nombreCliente : '',
                    },
                    {
                        id: 'approvalDate', // Required because our accessor is not a string
                        Header: t('approvalDate'),
                        headerClassName: 'wordwrap',
                        accessor: d => `${d.fchaprobacion}`,
                    },
                    {
                        id: 'maximumPaymentDate', // Required because our accessor is not a string
                        Header: t('maximumPaymentDate'),
                        headerClassName: 'wordwrap',
                        accessor: d => `${d.fchmaxpago}`,
                    },
                    {
                        id: 'invoice', // Required because our accessor is not a string
                        Header: t('invoice'),
                        accessor: d => typeof(d.factura) === "string" ? `${d.factura}` : '',
                    },
                    {
                        id: 'authorization', // Required because our accessor is not a string
                        Header: t('authorization'),
                        accessor: d => `${d.autorizacion}`,
                    },
                    {
                        id: 'providerName', // Required because our accessor is not a string
                        Header: t('providerName'),
                        headerClassName: 'wordwrap',
                        accessor: d => `${d.nombreproveedor}`,
                    },
                    {
                        id: 'amount', // Required because our accessor is not a string
                        Header: t('amount'),
                        accessor: d => `${d.monto}`,
                    },
                    {
                        id: 'financialCost', // Required because our accessor is not a string
                        Header: t('financialCost'),
                        headerClassName: 'wordwrap',
                        accessor: d => `${d.costofinanciero}`,
                    },
                    {
                        id: 'totalPay', // Required because our accessor is not a string
                        Header: t('totalPay'),
                        accessor: d => `${d.totalpagar}`,
                    },
                ];
                data = listReport;
                title = t('admin');
                break;
            default:
                columns = [];
                data = [];
                title = '';
        }
        return {
            columns,
            data,
            title,
        };
    };

    handleSelectChange = (newVal, label) => {
        this.setState({
            [label.name]: newVal.value,
        });
    };

    download = () => {
        const { sortedData, columns } = this.selectTable.current.getResolvedState();

        const BOM = "\uFEFF";
        const downloadCsv = [[BOM]];
        downloadCsv.push(columns.map(column => column.Header));
        downloadCsv.push(...sortedData.map((sorted) => {
            const downloadCsvObject = {};
            columns.forEach((column) => {
                downloadCsvObject[column.id] = sorted[column.id];
            });
            return Object.values(downloadCsvObject);
        }));
        this.setState({ downloadCsv }, () => {
            this.csvRef.current.link.click();
        });
    };

    handleDateChange = (newDate) => {
        let year = newDate !== null ? newDate.getFullYear().toString() : '';

        this.setState({
            cycle_date: newDate === null ? null : newDate,
            date: newDate === null ? '' : (newDate.getMonth() + 1) < 10 ? year.slice(-2) + '0' + (newDate.getMonth() + 1) : year.slice(-2) + (newDate.getMonth() + 1),
        });
    };

    rangeDate = () => {
        const {
            getReconciliationReport,
            auth: { user: { b2b: { type_user, cod } } },
        } = this.props;
        const {
            client,
            clients,
            date,
        } = this.state;
        getReconciliationReport({
            params: {
                code: type_user === 'buyer' ? cod : '', //clients[client].cod,
                date: date,
            },
            callback: (resp) => {
                const array = [];
                if (!Array.isArray(resp.Registro)) {
                    array.push(resp.Registro);
                }
                this.setState({
                    listReport: Array.isArray(resp.Registro) ? resp.Registro : Object.keys(resp.Registro).length > 0 ? array : [],
                });
            }
        });
    };

    getTitle = (typeUser) => {
        const {
            t
        } = this.props;

        switch (typeUser) {
            case 'buyer':
                return t('paymentDateRangeClient');
            case 'seller':
                return t('paymentDateRange');
            case 'admin_amex':
            case 'admin':
                return t('sendInvoice');
        }
    };

    getIsClearable() {
        const {
            auth: { user: { b2b: { type_user } } },
        } = this.props;
        if ( type_user === 'main_bank' ||
            type_user === 'issuing_bank' ||
            type_user === 'issuing_query' ) {
            return false;    
        } else {
            return true;   
        }
    }

    render() {
        const {
            transactions: { loading },
            t,
            auth: { user: { b2b: { type_user } }, language }
        } = this.props;
        const { downloadCsv, cycle_date, clients, client, isClearable, } = this.state;
        const isBank = (type_user === 'issuing_bank' || type_user === 'issuing_query');
        return (
            <Toggle>
                {({ toggled, onToggle }) => {
                    const { columns, data } = this.getGridSettings(onToggle);
                    return (
                        <div className="dashboard">
                            {loading && <Loading />}
                            <div className="columns is-multiline">
                                <Fragment>
                                    {
                                        toggled
                                        && (
                                            <Modal onToggle={onToggle} />
                                        )
                                    }
                                    {data
                                        && (
                                            <div className="column is-full">
                                                <Panel headingText={t('reconciliationReport')}>
                                                    <div className="field" style={{
                                                        margin: '0 2%',
                                                    }}>
                                                        <div className="field-body columns">
                                                            <div className="columns" style={{width: '100%'}}>
                                                                {/*<div className="column">
                                                                    <p style={{fontSize: '1.25rem'}}>
                                                                        {this.getTitle(type_user)}
                                                                    </p>
                                                                </div>*/}
                                                                {/* { isBank && (
                                                                    <div className="column" style={{display: isBank ? '' : 'none',
                                                                            zIndex: 99999}}>
                                                                        <SelectSingle
                                                                            name="client"
                                                                            label={t('client')}
                                                                            selectClasses="is-fullwidth"
                                                                            placeholder={t('Select')}
                                                                            onChange={this.handleSelectChange}
                                                                            options={[
                                                                                ...clients.map((item, index) => ({
                                                                                    label: `${item.idPerson.name} - ${t(item.role_name)} `,
                                                                                    value: index,
                                                                                })),
                                                                            ]}
                                                                            value={client}
                                                                        />
                                                                    </div>
                                                                )} */}
                                                                <div className="column is-one-quarter" style={{zIndex: 99999}}>
                                                                    <DateInput
                                                                        name={'cycle_date'}
                                                                        label={t('maximumPaymentDate')}
                                                                        value={cycle_date}
                                                                        onChange={this.handleDateChange}
                                                                        fieldClasses="is-expanded"
                                                                        controlClasses="has-icons-left"
                                                                        icon="FaRegCalendarAlt"
                                                                        iconPosition="is-left"
                                                                        dateFormat="MM/yyyy"
                                                                        showMonthYearPicker={true}
                                                                        showFullMonthYearPicker={true}
                                                                        locale={language}
                                                                        isClearable={this.getIsClearable()}
                                                                        autoCompleted='off'
                                                                    />
                                                                </div>
                                                                <div style={{marginTop: '35px'}}>
                                                                    <button
                                                                        type="button"
                                                                        className="button is-primary"
                                                                        onClick={() => {
                                                                            this.rangeDate();
                                                                        }}
                                                                    >
                                                                        {t('consult')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="button is-block is-primary"
                                                            onClick={this.download}
                                                        >
                                                            {`${t('download')} CSV`}
                                                        </button>
                                                        <CSVLink
                                                            data={downloadCsv}
                                                            filename="summary_b2b.csv"
                                                            className="hidden"
                                                            ref={this.csvRef}
                                                        />
                                                    </div>
                                                    <TableSimple
                                                        data={data}
                                                        columns={columns}
                                                        reff={this.selectTable}
                                                        separator=","
                                                    />
                                                </Panel>
                                            </div>
                                        )}
                                </Fragment>
                            </div>
                        </div>
                    );
                }}
            </Toggle>
        );
    }
}

conciliationReport.propTypes = {
    t: PropTypes.func.isRequired,
    transactions: PropTypes.shape(Object).isRequired,
    auth: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({ auth, transactions, stats, }) => (
    {
        auth,
        transactions,
        stats,
    }
);
export default compose(
    connect(mapStateToProps, { ...transactionsActions, ...statsActions, }),
    withNamespaces(),
)(conciliationReport);