/**
 * Created by Nelson on 29/10/18.
 */

import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { AmountFormat, Card, Form, Panel, SelectSingle, TextArea, TextInput } from '../index';
import DateInput from '../DateInput';
import { getPath } from '../../util';
import './styles.scss';

const LoadInvoicesForm = (props) => {
  const {
    t,
    disabled,
    formData,
    handleInputChange,
    handleFileChange,
    handleDateChange,
    handleClientChange,
    invoiceAmount,
    invoiceDate,
    description,
    invoiceNumber,
    clients,
    selectedClient,
    onSubmit,
    file,
    fileName,
    detailAmount,
    handleOnInputChange,
    inputValue,
    exemptAmount,
    taxAmount,
    handleExemptChange,
    handleTaxChange,
    descriptionCommerceCode,
    commerceCodes,
    commerceCode,
    handleSelectChange,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('loadInvoice')}>
          <Form
            submitText={t('send')}
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit(formData);
            }}
            buttonClasses="mr-8px"
            disabled={disabled}
          >
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <DateInput
                      name="invoice_date"
                      label={t('invoiceDate')}
                      value={invoiceDate}
                      onChange={handleDateChange}
                      type="text"
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      icon=""
                      iconPosition=""
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_number"
                    type="text"
                    label={t('invoicesNumber')}
                    value={invoiceNumber === null ? '' : invoiceNumber}
                    onChange={handleInputChange}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{9}"
                    title={t('invoiceNumberValidate')}
                    placeholder="000-000-000000000"
                    maxlength="20"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    iconPosition=""
                    icon=""
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-two-third">
                  {/*<TextInput
                    name="exempt_amount"
                    label={t('exemptAmount')}
                    value={ exemptAmount === null ? '' : exemptAmount }
                    onChange={handleExemptChange}
                    placeholder="00.00"
                    title={t('amountValidate')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    // step="0.01"
                    iconPosition="is-left"
                    icon="far fa-usd"
                  />*/}
                  <AmountFormat
                    value={ exemptAmount }
                    name="exempt_amount"
                    label={ t('exemptAmount') }
                    onChange={handleExemptChange}
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    placeholder="00.00"
                  />
                </div>
                <div className="column is-two-third">
                  {/*<TextInput
                    name="tax_amount"
                    label={t('taxAmount')}
                    value={ taxAmount === null ? '' : taxAmount }
                    onChange={handleTaxChange}
                    placeholder="00.00"
                    title={t('amountValidate')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    // step="0.01"
                    iconPosition="is-left"
                    icon="far fa-usd"
                  />*/}
                  <AmountFormat
                    name="tax_amount"
                    label={t('taxAmount')}
                    value={ taxAmount === null ? '' : taxAmount }
                    onChange={(event) => handleTaxChange(event)}
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    placeholder="00.00"
                  />
                </div>
                <div className="column is-two-third">
                  {/*<TextInput
                    name="invoice_amount"
                    label={t('amount')}
                    value={invoiceAmount === null || invoiceAmount === 'NaN' ? '' : invoiceAmount}
                    onChange={handleInputChange}
                    placeholder="00.00"
                    title={t('amountValidate')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    // step="0.01"
                    iconPosition="is-left"
                    icon="far fa-usd"
                    disabled
                  />*/}
                  <AmountFormat
                    value={ invoiceAmount }
                    name="invoice_amount"
                    label={ t('amountTotal') }
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    placeholder="00.00"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="columns is-centered">
              {detailAmount
                && Object.keys(detailAmount).map(key => (
                  <div className="column is-2">
                    <div className="tags has-addons">
                      <span className="tag">{key}</span>
                      <span className="tag is-link">{detailAmount[key] || '-'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="client"
                      label={t('customers')}
                      placeholder={t('SelectOption')}
                      onChange={handleClientChange}
                      options={handleOnInputChange === null || inputValue.length > 1 ? [
                        ...clients.map((client, index) => ({
                          label: `${getPath(['idPerson', 'name'], client)} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], client)} ${getPath(['idPerson', 'document_number'], client)}`,
                          value: index,
                        })),
                      ] : [{}]}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="FaRegUser"
                      iconPosition="is-left"
                      value={ selectedClient + 1 }
                      isSearchable
                      onInputChange={handleOnInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextArea
                    name="invoice_description"
                    label={t('description')}
                    value={description}
                    onChange={handleInputChange}
                    placeholder=""
                    controlClasses="is-expanded"
                    maxLength="120"
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-two-third">
                  <SelectSingle
                    name="commerceCode"
                    label={t('descriptionOfTheMerchantAffiliateCode')}
                    selectClasses="is-fullwidth"
                    placeholder={t('Select')}
                    onChange={handleSelectChange}
                    options={
                      [
                        ...commerceCodes.map((item, index) => ({
                          label: item.description,
                          value: index,
                        })),
                      ]}
                    value={commerceCode}
                  />
                </div>
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <TextInput
                      name="descriptionCommerceCode"
                      label={t('code')}
                      value={descriptionCommerceCode}
                      onChange={handleInputChange}
                      placeholder={t('description')}
                      type="text"
                      controlClasses="is-expanded"
                      title="Three letter country code"
                      disabled={true}
                      isRequired
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-full">
                  <TextInput
                    name="file"
                    label={t('SelectDocumentType')}
                    value={file}
                    fileName={fileName}
                    onChange={handleFileChange}
                    // placeholder="Suba un documento"
                    type="file"
                    // fieldClasses="file has-name"
                    //  labelClasses="file-label"
                    //  controlClasses="is-expanded has-icons-left file-label"
                    //  inputClasses="has-text-right "
                    //  iconPosition="is-left"
                    //  icon="fas fa-upload"
                  />
                  <strong> <span
                    className="texto-file">{t('fileSize', { size: '3MB' })}</span></strong>
                </div>
              </div>
            </div>
            {selectedClient != null
            && (
              <div className="columns">
                <div className="column is-8-tablet is-offset-2-tablet mt-24px">
                  <Card
                    title={t('customerInformation')}
                    subtitle={`${getPath(['idPerson', 'name'], clients[selectedClient])}`}
                    items={[
                      {
                        name: 'documentNumber',
                        value: `${getPath(['idPerson', 'idDocumentType', 'document_type'], clients[selectedClient])} ${getPath(['idPerson', 'document_number'], clients[selectedClient])}`,
                      }, {
                        name: 'address',
                        value: `${getPath(['address'], clients[selectedClient])}`,
                      }, {
                        name: 'email',
                        value: `${getPath(['idPerson', 'idEmailActive', 'email'], clients[selectedClient])}`,
                      },
                      // {
                      //   name: 'paymentDeadline',
                        // eslint-disable-next-line max-len
                        //   value: `${clients[selectedClient].paymentConditionAsBuyer[0].days} días`,
                        // }, {
                        //   name: 'numberInstallments',
                        // eslint-disable-next-line max-len
                        //   value: `${clients[selectedClient].paymentConditionAsBuyer[0].installments}`,
                        // },
                      ]}
                    />
                  </div>
                </div>
              )}
          </Form>
        </Panel>
      </div>
    </div>
  );
};

LoadInvoicesForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleClientChange: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  formData: PropTypes.shape({}).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  handleOnInputChange: PropTypes.func,
  taxAmount: PropTypes.number,
  exemptAmount: PropTypes.number,
};

LoadInvoicesForm.defaultProps = {
  disabled: false,
  clients: [],
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  selectedClient: '',
  file: '',
  fileName: '',
  handleOnInputChange: null,
  inputValue: '',
};


export default withNamespaces()(LoadInvoicesForm);
