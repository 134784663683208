import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';

const InformativeBox = ({
  title, bigValue, icon, size,
}) => (
  <div className={`column ${size} is-flex`}>
    <div className="information-box ">
      <div className="icon"><i>{icon}</i></div>
      <div className="content-title">
        <div className="title">{title}</div>
      </div>
      <div className="big-value">
        <div className="content">
          {bigValue}
        </div>
      </div>
    </div>
  </div>
);

InformativeBox.propTypes = {
  title: PropTypes.string.isRequired,
  bigValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default withNamespaces()(InformativeBox);
