const es = {
  translations: {
    accept: 'Aceptar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    account: 'Cuenta',
    accountBalance: 'Saldo de la cuenta',
    accountPlural: 'Cuentas',
    accumulatedAmount: 'Monto acumulado diario',
    acquiring_bank: 'Banco Adquiriente',
    action: 'Acción',
    active: 'Activo',
    activate: 'Activar',
    addComment: 'Agregar comentario ...',
    addFinanceProductSuccess: 'Producto de financiamiento creado de manera exitosa',
    addFinancingProducts: 'Agregar productos de financiamiento',
    additionalInformation: 'Información Adicional',
    address: 'Dirección',
    admin: 'Administrar',
    adminRoutes: 'Administrar rutas',
    adminUser: 'Usuario Administrador',
    authUser: 'Usuario autorizado',
    advance: 'Anticipar',
    advanceCommercialConditions: 'Condiciones comerciales de anticipo',
    affiliateCode: 'Código de afiliación',
    agreementText: 'Al hacer uso de nuestra plataforma usted está de acuerdo con nuestros',
    alias: 'Alias',
    amount: 'Monto',
    amountTotal: 'Monto total',
    amountInstallments: 'Monto de las cuotas',
    amountMoney: 'Monto de dinero',
    amountToCollect: 'Total diario por cobrar',
    amountToGet: 'Facturas por cobrar',
    amountToPay: 'Monto por pagar',
    anticipatedAmount: 'Monto anticipado',
    approvalDate: 'Fecha de aprobación',
    approve: 'Aprobar',
    approver: 'Aprobador',
    approved: 'Aprobadas',
    approvedInvoices: 'F.Aprobadas',
    approvedInvoicesTitle: 'Facturas aprobadas',
    arrangedDays: 'Días pactados',
    assign: 'Asignar',
    assignRoute: 'Asignar rutas',
    assignedRoutes: 'Rutas asignadas',
    assignRoleRoute: 'El rol y la ruta se asignaron exitosamente',
    assignRouteSuccess: 'La ruta fue asignada exitosamente',
    authorization: 'Autorización',
    available: 'Disponible',
    avance: 'Anticipo',
    balance: 'Saldo',
    bankList: 'Lista de bancos',
    bankName: 'Nombre del banco',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condiciones Base (Pago de Facturas)',
    bills: 'Facturas \nenviadas',
    billPayment: 'Pago de facturas',
    businessName: 'Razón social',
    cardPaymentDate: 'Fecha de pago de tarjeta de crédito',
    by_amount: 'Por monto',
    by_hierarchy: 'Por jerarquía de puestos',
    charge: 'Cargo',
    chargeDate: 'Fecha de cargo',
    clientName: 'Nombre del cliente',
    clientPaymentCondition: 'Determina las condiciones de pago para este cliente',
    codeArea: 'cód. área + n˚ teléfono',
    commercialConditions: 'Condiciones comerciales',
    condition: 'Condición',
    concept: 'Concepto',
    configuration: 'Configuración',
    confirm: 'Confirmar',
    confirmInvoices: 'Confirmar facturas',
    confirmPassword: 'Confirmar contraseña',
    confirmTransfer: 'Confirme su transacción',
    continue: 'Continuar',
    consult: 'Consultar',
    consultTradeAffiliationCode: 'Consultar código de afiliación de comercio',
    cort: 'Corte',
    cortDate: 'Fecha de corte',
    createBank: 'Crear nuevo banco',
    createBankSuccess: 'Banco {{name}} creado de manera exitosa',
    createClientSuccess: 'Cliente {{name}} creado de manera exitosa',
    createCustomer: 'Crear cliente',
    createInvoiceSuccess: 'La factura N° {{invoice_number}} se ha registrado exitosamente',
    createNewItem: 'Crear nueva industria "{{inputValue}}"',
    createProvider: 'Crear proveedor',
    createProviderSuccess: 'Proveedor {{name}} creado de manera exitosa',
    createProfile: 'Crear perfil',
    createProfileSuccess: 'Perfil {{name}} creado de manera exitosa',
    createRol: 'Crear rol',
    createRolSuccess: 'Rol {{rolName}} creado de manera exitosa',
    createUser: 'Crear usuario',
    createUserSuccess: 'Usuario {{name}} creado de manera exitosa',
    createFlowApproval: 'Crear flujo de aprobación de factura',
    createFlowApprovalSuccess: 'Flujo de Aprobación de factura, creado de manera exitosa',
    creditPeriod: 'Período del crédito',
    customer: 'Cliente',
    customerInformation: 'Información del cliente seleccionado',
    customerName: 'Nombre del cliente',
    customers: 'Clientes',
    code: 'Código',
    changePassword: 'Cambiar contraseña',
    changePasswordSuccess: 'Contraseña cambiada de manera exitosa',
    changeProfile: 'Cambiar a perfil {{role}}',
    daily: 'Diario',
    dashboard: 'Resumen',
    date: 'Fecha',
    dateRange: 'Rango de fechas',
    daysNumber: 'Número de días',
    defer: 'Diferido',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteInvoice: 'La factura fue eliminada',
    deletePaymentCondition: 'La condición de pago fue eliminada',
    demographicInformation: 'Datos de identificación',
    deposit: 'Depositar',
    description: 'Descripción',
    descriptionOfTheMerchantAffiliateCode: 'Descripción del código de afiliación de comercio',
    destinationAccount: 'Cuenta destino',
    deactivate: 'Desactivar',
    detail: 'Detalle',
    differ: 'Diferir',
    discountRate: 'Tasa de descuento',
    discountRateOutOfLimits: 'Tasa de descuento fuera de los limites',
    documentNumber: 'Nro. de documento',
    documentType: 'Tipo de documento',
    document: 'Documento',
    dontPose: 'No Posee',
    edit: 'Editar',
    email: 'Correo electrónico',
    incorrectEmail: '{{email}} no parece un correo válido. Revisa que tenga el formato correcto: ejemplo@mail.com',
    emptyFields: 'Debes completar todos los campos.',
    emptyAll: 'Asegúrate de llenar toda la información',
    enter: 'Ingresar',
    enterCodeSms: 'Verifica e ingresa el código\n que te enviamos vía SMS',
    errors: 'Errores',
    errorDate: 'La fecha \'Desde\' tiene que ser menor',
    errorLoad: 'Debe cargar un archivo de extensión {{type}}',
    expiredInvoices: 'Aprobadas / Pagadas',
    exemptAmount: 'Subtotal sin IVA',
    exemptAmountTable: 'Sub. sin IVA',
    extract: 'Extraer',
    extractAccount: 'Extracción en red B2B',
    extractDetail1: 'Codigo de aprobación generado exitosamente',
    extractDetail2: 'Monto a retirar',
    extractDetail3: 'En',
    extractDetail4: 'Completa la extracción en un punto de nuestra Red',
    extractDetail5: 'Con tu número de telefono',
    extractDetail6: 'Proporciona tu numero de telefono al operador del punto para completar la extraccion iniciada',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Preséntate con el siguiente codigo en el punto de la red',
    financingProducts: 'Productos de \nfinanciamiento',
    finish: 'Finalizar',
    fiscaldocument: 'Documento fiscal',
    flexibleConditions: 'Condiciones Flexibles (Financiamiento)',
    flowApproval: 'Flujo de aprobación',
    flowsApproval: 'Flujos de aprobación',
    flowApprovalCheck: 'Flujo de aprobación por defecto',
    forgotPassword: '¿Olvidó su contraseña?',
    fullName: 'Nombre y apellido',
    goBack: 'Regresar',
    greaterThan: 'Mayor',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menor Igual',
    greaterThanOrEqualTo: 'Mayor Igual',
    fileSize: 'El tamaño máximo permitido, es {{size}}',
    fileSizeExceeded: 'Tu archivo supera el peso máximo de 3MB',
    inCharge: 'Responsable',
    import: 'Importe',
    installmentNumber: 'Número de cuotas',
    installments: 'N° de cuotas',
    interestRate: 'Tasa de interés',
    invalidAmount: 'Monto invalido',
    invalidRoleOrRoute: 'El rol y la ruta no pueden estar vacios',
    invoiceCount: 'Número de Facturas',
    invoiceDate: 'Fecha de factura',
    invoiceDetail: 'Detalle de Factura',
    invoicesError: 'Facturas con errores: {{ cant }} ',
    invoiceGroup: 'Grupo de facturas',
    invoiceManualResolution: 'Facturas que requieren resolución manual',
    invoiceMarkPaid: 'Factura N° {{invoiceNumber}} fue marcada como pagada',
    invoicesSentSuccess: 'Facturas enviadas con éxito',
    incorrectDate: 'No puedes ingresar una fecha anterior a, {{dateNow}}',
    incorrectDays: 'Debes ingresar un valor mayor o igual a 0',
    invoice: 'Factura',
    invoices: 'Facturas',
    invoicesApprover: 'Aprobador de facturas',
    invoicesApproverCheckbox: 'Permitir aprobación de facturas dentro y fuera de un flujo de aprobación.',
    invoicesNumber: 'Número de factura',
    invoicePending: 'Por emitir',
    invoiceTotalAmount: 'Monto en Facturas',
    invoiceSend: 'Fecha de envío',
    issuedInvoices: 'Facturas emitidas',
    issuing_bank: 'Banco Emisor',
    items: 'Industrias',
    lastDays: 'Últimos días',
    lastMovements: 'Últimos Movimientos',
    listInvoices: 'En revisión',
    listPayments: 'Listado de pagos',
    load: 'Cargar',
    loadCsv: 'Cargar CSV',
    loadZip: 'Cargar soporte ZIP',
    loading: 'Cargando...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Cargar factura',
    loanPeriod: 'Período del préstamo',
    logout: 'Salir',
    loginAsCustomer: 'Ingresar como cliente',
    loginAsProvider: 'Ingresar como proveedor',
    logView: 'Vista log',
    operationLog: 'Log de Eventos',
    mainUser: 'Usuario Principal',
    markAsPaid: 'Marcar como pagado',
    manualResolution: 'Resolución Manual',
    maximumPaymentDate: 'Fecha máxima de pago',
    message: 'Mensaje',
    messageUserProcess: 'En este momento no posee un tipo de flujo de aprobación configurado. Por favor comuníquese con su representante para activar esta funcionalidad.',
    merchantAffiliateCode: 'Código de afiliación de comercio',
    monthly: 'Mensual',
    name: 'Nombre',
    newBank: 'Nuevo banco',
    newPassword: 'Nueva contraseña',
    newPaymentCondition: 'Nuevas Condiciones de Pago',
    newRecharge: 'Nueva Recarga',
    newRole: 'Nuevo Rol',
    newProfile: 'Nuevo usuario',
    newTransfer: 'Nueva Transferencia',
    next: 'Siguiente',
    nextCollection: 'Próximo cobro',
    nextDays: 'Próximos días',
    nextPage: 'Siguiente',
    nextPayment: 'Próximo pago',
    noCard: 'No posee tarjeta prepagada',
    noData: 'Sin información',
    noItemsAvailable: 'No hay industrias disponibles',
    nonMatchingPasswords: 'Las contraseñas ingresadas no coinciden',
    noRowsFound: 'Sin información',
    noTransactions: 'Esta tarjeta no posee movimientos',
    numberInstallments: 'Número de cuotas',
    of: 'de',
    operationVolume: 'Volumen de operaciones',
    operator: 'Condición',
    originAccount: 'Cuenta origen',
    params: 'Parametros',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Monto pagado',
    paidInvoices: 'Facturas pagadas',
    paidOut: 'Pagado',
    password: 'Contraseña',
    payedAmounts: 'Total diario facturado',
    billsToPay: 'Total diario por facturar',
    payment: 'Pago',
    paymentDate: 'Fecha de cobro',
    paymentDateTC: 'Fecha de cargo TC',
    paymentDateInvoice: 'Fecha de cobro de facturas:',
    datePayment: 'Fecha de pago',
    paymentDeadline: 'Plazo de pago',
    paymentOrders: 'Ordenes de Pago',
    paymentsTransfers: 'Pagos y Transferencias',
    pendingInvoices: 'F.Pendientes',
    pending: 'Pendiente',
    period: 'Periodo',
    prepaidCard: 'Tarjeta Prepagada',
    present: 'Actual',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    productName: 'Nombre del Producto',
    provider: 'Proveedor',
    profileName: 'Nombre del perfil',
    profileList: 'Lista de usuarios',
    providerName: 'Nombre del proveedor',
    providers: 'Proveedores',
    providerRUC: 'RUC proveedor',
    providerPaymentCondition: 'Determina las condiciones de pago para este proveedor',
    query: 'Consulta',
    rate: 'Porcentaje',
    receivedInvoices: 'Facturas \nRecibidas',
    receptionDate: 'Fecha de recepción',
    recharge: 'Recargar',
    recipient: 'Destinatario',
    recoverPassword: 'Recuperar contraseña',
    rejectionDate: 'Fecha de rechazo',
    rejectReason: 'Motivo de Rechazo',
    insertYourEmailAddress: 'Introduce tu dirección de correo electrónico.',
    enterYourNewPassword: 'Introduce tu nueva contraseña',
    registerBank: 'Registrar banco',
    registerRole: 'Registrar rol',
    registerRoute: 'Registrar ruta',
    reject: 'Rechazar',
    rejectInvoice: 'Rechazadas',
    rejectInvoiceAll: 'Facturas rechazadas',
    rejectedInvoices: 'F.Rechazadas',
    relatedUsers: 'Usuarios Relacionados',
    removeRouteSuccess: 'La ruta fue removida exitosamente',
    reportDate: 'Fecha del reporte',
    requiredInstallments: 'El numero de cuotas es invalido',
    requiredName: 'El nombre es invalido',
    requiredPeriod: 'El periodo es invalido',
    requiredRate: 'La tasa es invalida',
    requiredTypeFinance: 'El tipo de financiamiento es invalido',
    respJson: 'Respuesta JSON',
    respXml: 'Respuesta XML',
    response: 'Respuesta',
    roleCantBeEmpty: 'El rol no puede estar vacio',
    roleList: 'Lista de roles',
    roles: 'Roles',
    rol: 'Rol',
    roleType: 'Tipo del rol',
    rolName: 'Nombre del rol ',
    rolType: 'Tipo del rol',
    routeList: 'Lista de rutas',
    routes: 'Rutas',
    rows: 'Filas',
    save: 'Guardar',
    Select: 'Seleccione ...',
    selected: 'Seleccionar',
    SelectDocumentType: 'Elige el tipo de documento para continuar',
    SelectBank: 'Seleccione un banco',
    SelectOption: 'Seleccione una opción',
    selectOrTypeItems: 'Seleccione o escriba las industrias...',
    selectOrTypeRoutes: 'Seleccione o escriba la ruta',
    selectRecipient: 'Seleccionar destinatario',
    selectOrTypeRol: 'Seleccione o escriba los roles',
    send: 'Enviar',
    sendInvoice: 'Fecha de envío de factura',
    sendInvoices: 'Facturas por enviar: {{ cant }}',
    sentInvoices: 'Facturas \nenviadas',
    sendSelectedInvoices: 'Enviar facturas seleccionadas',
    settings: 'Ajustes',
    seeHow: 'Ver como',
    shippingDate: 'Fecha de envío',
    showQR: 'Mostrar código QR',
    since: 'Desde',
    startCountingFrom: 'Días pactados desde',
    summary: 'Resumen',
    successEmail: 'Se ha enviado un correo a tu dirección de correo electrónico.',
    targetNumber: 'Numero de tarjeta',
    taxAmount: 'Subtotal con IVA',
    total: 'Total',
    terms: 'términos y condiciones',
    totalAmount: 'Importe total',
    totalBuyers: 'Total Empresas Pagadoras',
    totalBuyersDeferred: 'Empresas con Facturas Diferidas',
    totalBuyersInvoiced: 'Empresas con Facturas Aprobadas',
    totalCharged: 'Total cobrado {{month}}',
    totalDebtAmount: 'Monto total a pagar',
    totalOwedAmount: 'Monto total a cobrar',
    totalPay: 'Total a pagar',
    totalSellers: 'Proveedores Afiliados',
    totalSellersCharged: 'Proveedores con Facturación Aprobada',
    totalSellersInvoiced: 'Proveedores con Facturación Pendiente',
    totalToApprove: 'Total por aprobar',
    totalToReceiver: 'Total por cobrar',
    transactionType: 'Tipo de transacción',
    transfer: 'Transferir',
    transferCharge: 'Cargo de la operación',
    transferDetail2: 'Comprobante',
    transferDetail3: 'Enviado a',
    transferDetail4: 'Monto enviado',
    transferDetail5: 'Monto total descontado',
    transferDetail: 'Transferencia Realizada',
    transferState: 'Estado',
    transferType: 'Tipo de transacción',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Tipo de flujo de aprobación',
    typeOfEmitFlow: 'Tipo de flujo de emisión',
    underReview: 'En revisión',
    underReviewInvoices: 'Facturas en revisión',
    uploadedFiles: 'Facturas procesadas exitosamente',
    username: 'Nombre de usuario',
    users: 'Usuarios',
    uniqueCode: 'Código único',
    until: 'Hasta',
    virtualAccount: 'Cuenta Virtual',
    yearly: 'Anual',
    resource: 'Recurso',
    view: 'Ver',
    daysAdvance: 'Dias a adelantar',
    daysDefer: 'Dias a diferir',
    anualRate: 'Tasa anual',
    percentage: 'Porcentaje de interes anual',
    interest_rate: 'Monto de interés',
    adjusted_amount: 'Monto ajustado',
    cantDeleteRol: 'El rol no se puede eliminar porque tiene asignado una o mas rutas',
    deleteRol: 'El rol fue elminado',
    deleteRoute: 'La ruta fue eliminada',
    cantDeleteRoute: 'La ruta no se puede eliminar porque esta asignada a uno o mas roles',
    createLead: 'Requerir \n alta',
    sellerCreateLead: 'Requerir alta de proveedor',
    buyerCreateLead: 'Requerir alta de cliente',
    requireSuccess: '{{name}} requerido con exito',
    buyerName: 'Nombre del cliente',
    sellerName: 'Nombre del proveedor',
    leadList: 'Listado de \nrequeridos',
    createRoleSuccess: 'Role {{rolName}} creado exitosamente',
    // Despues de enviar a traduccion a portugues
    updateBank: 'Actualizar banco',
    updateInformation: 'Actualizar información',
    adminName: 'Nombre',
    adminLastname: 'Apellido',
    adminPosition: 'Cargo',
    invoicesApproved: 'Facturas aprobadas',
    invoicesToApproved: 'Facturas por aprobar',
    approvedMount: 'Monto aprobado',
    nInvoices: 'Nro. de Facturas',
    advanceMount: 'Monto adelantado',
    deferMount: 'Monto diferido',
    totalCommissionAmount: 'Comision total',
    discountAmount: 'Monto de descuento',
    reports: 'Reportes',
    createPaymentCondition: 'Crear condición \nde pago',
    addCondition: ' Agregar condición',
    receiverDiscountShare: 'Porcentaje de pago del proveedor',
    paymentClient: 'El pago del cliente es de',
    createPaymentConditionSuccess: 'Condición de pago creada exitosamente',
    paymentCondition: 'Condición',
    paymentConditionProvider: 'Condiciones comerciales para proveedores de',
    paymentConditionClients: 'Condiciones comerciales para clientes de',
    editPaymentConditionSuccess: 'Condición de pago Editada exitosamente.',
    editClientSuccess: 'Cliente {{name}} editado exitosamente',
    editProviderSuccess: 'Proveedor {{name}} editado exitosamente',
    editProfileSuccess: 'Perfil {{name}} editado exitosamente',
    editUserSuccess: 'Usuario {{name}} editado exitosamente',
    client: 'Cliente',
    download: 'Descargar',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    language: 'Lenguaje',
    lead: 'Alta',
    completeComercialConditionOrEmptyAll: 'Complete los datos de las condiciones comerciales o dejelos vacios si no desea agregar condicion comercial con algun proveedor',
    transactions: 'Transacciones',
    totalReceiver: 'Total a cobrar',
    totalInvoices: 'Total facturas',
    quantity: 'Cantidad',
    providersReceiver: 'Factura de proveedores por cobrar',
    clientsPay: 'Clientes por pagar',
    clients: 'Clientes',
    selectedBank: 'Banco',
    uploadInvoice: 'Cargar factura',
    loadInvoiceData: 'Cargar datos de factura',
    naoReter: 'No retener IR (propaganda, publicidad, comisiones y corretaje)',
    disable: 'Deshabilitar',
    status: 'Estado',
    invoiceApprovedSuccess: 'Factura aprobada exitosamente',
    invoicesApprovedSuccess: 'Factura(s) aprobada(s) exitosamente',
    invoiceRejectedSuccess: 'Factura rechazada exitosamente',
    invoicesRejectedSuccess: 'Factura(s) rechazada(s) exitosamente',
    invoiceProvidersReceiver: 'Factura de proveedores por cobrar',
    actionNotAllowed: 'Acción no permitida',
    clientPay: 'Pagos de los clientes',
    referrer: 'Referido',
    buyer: 'Cliente',
    limitAmount: 'Monto límite',
    limitAmountValidate: 'Debes ingresar un valor mayor o igual a 0',
    limit: 'Límite',
    new: 'Nueva',
    paymentMethod: 'Método de pago',
    target: 'Tarjeta',
    line: 'Linea',
    defaultFlow: 'Flujo por defecto',
    condition_amount: 'Monto',
    invoiceNumberValidate: 'El formato válido para el ingreso del número de factura es xxx-xxx-xxxxxxxxx',
    amountValidate: 'El valor debe ser mayor o igual a 0.1',
    cantCreateTransaction: 'Ya existe el número de factura',
    penddingInvoices: 'Tienes {{pending_invoices}} facturas pendientes sin aprobar',
    rejectInvoices: 'Tienes {{reject_invoices}} factura(s) rechazadas',
    emitInvoices: 'Tienes {{emit_invoices}} factura(s) sin emitir',
    search: 'Buscar',
    operationRank: 'Rango de operaciones (desde)',
    maxRank: '(hasta)',
    support: 'Asistencia',
    main_bank: 'Usuario Administrador del Banco',
    user_list: 'Lista de Usuarios',
    edit_user: 'Editar Usuario',
    acquiring_query: 'Banco Adquirente de Consulta',
    issuing_query: 'Banco Emisor de Consulta',
    enable: 'Habilitar',
    transactionReport: 'Reporte de transacciones',
    paymentDateRange: 'Rango de fecha de cobro de facturas',
    paymentDateRangeClient: 'Rango de fecha de pago de facturas',
    identificationNumber: 'No. de Identificación',
    time: 'Hora',
    event: 'Evento',
    info: 'Información',
    infoInvoiceSuccess: '{{cant}} facturas seleccionadas para enviar',
    infoInvoiceError: '{{cant}} facturas con errores',
    invoiceErrorInfo: '(Facturas con errores no serán tomadas en cuenta para el envío)',
    questionInfo: '¿Estás seguro que deseas enviar {{cant}} facturas?',
    infoInvoices: 'Información de facturas',
    infoError: 'Información de errores',
    infoLog: 'Información del evento',
    editFlowApprovalSuccess: 'Flujo de aprobación editado con exito',
    emissionFlow: 'Flujo de emisión',
    higherHierarchy: 'Mayor jerarquía',
    highestHierarchyUser: 'Usuario de mayor jerarquía',
    yes: 'Sí',
    no: 'No',
    owner_role: "Rol de propietario",
    taxAmountTable: 'Sub. con IVA',
    rejectDate: 'Fecha de rechazo',
    ccChargeDate: 'Fecha de cargo TC',
    nextDayConditions: '*Aplica condiciones: 24hrs después de aprobado el pago excepto fin de semana y feriados considerar el primer día hábil.',
    vendorMerchantAffiliateCodes: 'Códigos de afiliación de comercio del proveedor: {{provider}}',
    affiliationCode: 'Códigos de afiliación de comercio',
    commerceCode: 'Código de afiliación',
    updateInfo: 'Actualizar información',
    buyerCode: 'Código del cliente',
    seller: 'Proveedor',
    sellerCode: 'Código del proveedor',
    welcomeToB2b: 'Bienvenido a B2B',
    providerRucExist: 'El RUC consultado está registrado en la plataforma como Proveedor',
    clientRucExist: 'El RUC consultado está registrado en la plataforma como Cliente',
    wantRegisterProvider: '¿Desea dar de alta a este usuario como Proveedor?',
    wantRegisterClient: '¿Desea dar de alta a este usuario como Cliente?',
    unregistered: 'no registrado',
    successAddRole: 'Rol añadido exitosamente',
    userHasBoth: 'El usuario ya posee ambos roles',
    sameMailClient: 'Crear cliente con el mismo correo',
    sameMailProvider: 'Crear proveedor con el mismo correo',
    newMailClient: 'Crear cliente con un nuevo correo',
    newMailProvider: 'Crear proveedor con un nuevo correo',
    cancel: 'Cancelar',
    approveMultipleTransactions: 'Aprobar transacciones seleccionadas',
    rejectMultipleTransactions: 'Rechazar transacciones seleccionadas',
    selectAll: 'Seleccionar todos',
    selectAllFemale: 'Seleccionar todas',
    selectedApprove: 'seleccionadas para aprobar con un monto total de ',
    confirmApprovalNumber: '¿Estás seguro que deseas aprobar la factura No. ',
    confirmApproval: '¿Estás seguro que deseas aprobar?',
    invoiceErrors: 'Varias facturas presentan errores en la aprobación, revise el motivo presionando el siguiente botón en la tabla',
    totalSelected: 'Total aprobarse',
    generalTotal: 'Total general',
    financialCost: 'Costo financiero',
    percentageChargeForService: 'Porcentaje cargo por servicio a cargo del proveedor',
    serviceInChargeOfTheClient: 'Porcentaje cargo por servicio a cargo del cliente',
    supplierPaymentPercentage: '% pago proveedor',
    customerPaymentPercentage: '% pago cliente',
    updateFinancialCost: 'Actualizar % de costo financiero',
    notHaveRelatedClients: 'No tiene usuarios vinculados',
    negotiatedFinancialCost: 'Costo financiero negociado',
    reconciliationReport: 'Reporte de conciliación',
    '/create-provider': 'Crear proveedores',
    '/create-customer': 'Crear clientes',
    '/dashboard': 'Pantalla principal',
    '/load-invoice': 'Cargar facturas',
    '/customers': 'Listar, editar o deshabilitar clientes',
    // '/invoices': 'Listar facturas',
    '/expired-invoices': 'Listar facturas expiradas',
    '/related-users': 'Cliente o proveedores requeridos',
    '/register-bank': 'Crear bancos',
    '/financing-products': 'Financiar productos',
    '/bank-list': 'Listar, editar o deshabilitar bancos',
    '/register-role': 'Crear roles',
    '/role-list': 'Listar, editar y eliminar roles',
    '/register-route': 'Crear rutas',
    '/route-list': 'Listar, editar y eliminar rutas',
    '/register-financing-products': 'Crear financiamiento de productos',
    '/admin-routes': 'Asignar o quitar rutas a roles',
    '/review-invoices': 'Listar, editar, aprobar o rechazar facturas sin aprobar',
    '/approved-invoices': 'Listar, adelantar o diferir facturas aprobadas',
    '/paid-invoices': 'Listar facturas pagadas',
    '/create-lead': 'Requerir clietes o proveedores',
    '/lead-list': 'Listar, aprobar o rechazar requeridos',
    '/reports': 'Exportar a excel facturas por cliente o proveedor segun el usuario loggeado',
    '/create-payment-condition': 'Crear condicion de pago',
    '/transactions': 'Facturas generadas por banco',
    '/create-profile': 'Crear perfiles',
    '/profile-list': 'Listar, editar o eliminar perfiles',
    '/create-approval-flow': 'Crear flujo de aprobacion de factura',
    '/account-profile': 'Cuenta del perfil',
    '/invoices': 'Facturas',
    '/reject-invoices': 'Facturas rechazadas',
    '/invoice-detail': 'Detalle de factura',
    '/approval-flow-list': 'Listar, editar flujos de aprobación',
    '/edit-approval-flow': 'Editar flujo de aprobación',
    '/select-role': 'Seleccionar rol para ingresar a B2B',
    '/related-users-client': 'Usuarios relacionados',
    '/user-commerce-code': 'Códigos de afiliación de comercio',
    '/list-invoice': 'Listar grupo de facturas',
    '/load-invoice-group': 'Cargar grupo de facturas',
    //'/pending-invoices': '',
  },
};

export default es;
