import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes, { object } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Loading,
  LoadInvoicesForm,
  Panel,
  TableList,
  TableSimple,
  TextInput,
  Toggle,
} from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import { customIcon, formatCurrency, formatDate } from '../../util';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import FormReject from './components/FormReject';
import FormApprove from './components/FormApprove';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    return ({
      days: 1,
      interest_rate: 0,
      discount_rate: 0,
      adjusted_amount: 0,
      type: '',
      approveDisabled: false,
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      discount_amount: 0,
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      rejectReason: '',
      isEdit: '',
      selectedInvoices: [],
      selected: {},
      selectAll: 0,
      totalAmountSelected: 0,
    });
  };

  componentDidMount() {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, b2b: { id, type_user } } },
      getClients,
    } = this.props;
    getClients({
      params: {
        id,
        related: true,
      },
    });
    if (type_user === 'buyer') {
      listTransactionsSender({
      });
    }
    if (type_user === 'seller') {
      listTransactionsReceiver({

      });
    }
  }

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/review-invoices':
        return t('underReviewInvoices');
      case '/approved-invoices':
        return t('approvedInvoicesTitle');
      case '/paid-invoices':
        return t('paidInvoices');
      case '/reject-invoices':
        return t('rejectInvoiceAll');
      default:
        return t('listInvoices');
    }
  };

  matchTarget = (targets, request_id) => {
    const { auth: { user: { user_id, profile } } } = this.props;
    let permisions = false;

    if (targets) {

      for (let i = 0; i < targets.roles.length; i++) {
        permisions = permisions || targets.roles[i] === profile.role_id;
      }

      for (let i = 0; i < targets.users.length; i++) {
        permisions = permisions || targets.users[i] === profile.user_id;
      }
      if (request_id !== null) {
        return permisions;
      }
    }

    return permisions !== false ? permisions : user_id === profile.user_id;
  };


  toggleRow = (id, amount) => {
    
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[id] = !this.state.selected[id];
    
    let arrsel = [];
    let invoice = {};

    if(newSelected[id] === true){
      invoice.id = id;
      arrsel.push(invoice);
    }

    if(newSelected[id] === true){
      this.setState({
        totalAmountSelected: this.state.totalAmountSelected + parseFloat(amount),
      });
    }else{
      this.setState({
        totalAmountSelected: this.state.totalAmountSelected - parseFloat(amount),
      });
    }
      
		this.setState({
			selected: newSelected,
      selectedInvoices: arrsel,
			selectAll: 2,
		});
  }

  toggleSelectAll() {

    const { transactions: { sender }, } = this.props;

		let newSelected = {};
    let sum = 0;
    let invoice = {};
    let arrayTransactions = [];

		if (this.state.selectAll === 0) {
			sender.forEach(x => {
				newSelected[x.id] = true;
        invoice.id = x.id;
        arrayTransactions.push(invoice);
        sum += parseFloat(x.adjusted_amount);
			});
		}

		this.setState({
      totalAmountSelected: sum,
			selected: newSelected,
      arrayTransactions: arrayTransactions,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		});
	}

  approveMultiple = (approval, onToggle) => {
    const {
      t,
      transactions: { sender, receiver },
      auth: { user: { user_id, profile } },
      approveMultipleInvoices,
      listTransactionsSender,
      showMessage,
    } = this.props;

    const { selected } = this.state;
    let transactionsId = [];
    let arrayTransactions = [];

    //transactionsId = Object.keys(selected);
    transactionsId = Object.entries(selected).map(item => item[1] ? item[0] : '' ).filter(Boolean);

    arrayTransactions = transactionsId.map(item => ({
      transaction_id: item,
      approved: approval,
      user_id: profile.user_id,
      role_id: profile.role_id
    }));

    approveMultipleInvoices({
      data: arrayTransactions,
      callback: (resp) => {
        const hasRejected = arrayTransactions.some((i) => !i.approved)
        listTransactionsSender({ });
        for(let i = 0; i < resp.length; i++) {
          if(resp[i].process === false) {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
            onToggle();
            onToggle({
              invoiceErrors: true,
            });
            break;
          } else {
            showMessage({
              message: hasRejected ? t('invoicesRejectedSuccess') : t('invoicesApprovedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.setState({
              totalAmountSelected: 0,
              selected: {},
            });
            onToggle();
            break;
          }
        }

        /*if (resp[0].process) {
          showMessage({
            message: t('invoiceApprovedSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }*/
        

      },
    });
  }

  rejectMultiple(onToggle){
    onToggle({
      rejectAll: true,
    })
  }

  rejectSingle(onToggle, transaction){
    onToggle({});
    onToggle({
      isBuyer: true,
      transaction: transaction.id,
      onSubmit: this.rejectInvoice,
    });
  }

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t, transactions: { sender, receiver },
      auth: { user: { user_id, b2b: { defer_condition, advance_condition } } },
      router: { location: { pathname } },
      clients,
      deleteTransaction,
      listTransactionsReceiver,
      fetchTransaction,
      history,
    } = this.props;
    const {
      approveDisabled
    } = this.state;
    switch (userType) {
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
						id: "checkbox",
						accessor: "",
            show: pathname === '/review-invoices' ? true : false,
            width: 50,
            filterable:false,
						Cell: ({ original }) => {
							return (
								<div style={{display: 'flex', justifyContent: 'center'}}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={this.state.selected[original.id] === true}
                    onChange={() => this.toggleRow(original.id, original.adjusted_amount)}
								  />
                </div>
							);
						},
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDateTC'),
            accessor: 'due_date',
            //accessor: d => formatDate(d.due_date),
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => formatCurrency(d.adjusted_amount),
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
            /*Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),*/
          },
          /* {
            id: 'transferState', // Required because our accessor is not a string != null
            Header: t('transferState'),
            accessor: d => `${d.log_message} ? ${d.log_message} : ${d.senderStatus.description}`,
          },*/
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
            /*Cell: cellInfo => (
              <div>
                {cellInfo.original.log_message != null && cellInfo.original.senderStatus.id === 'PEJ' ? (
                  <span data-type="info"
                        data-tip={cellInfo.original.log_message}>{cellInfo.original.log_message}
                  </span>
                  )
                  :
                  (
                  <span>
                    {cellInfo.original.senderStatus.description}
                  </span>
                )
                }
              </div>
            ),*/
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                  <tr>
                    {cellInfo.original.resource
                    && (
                      <td>
                        <a
                          href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button"
                        >
                            <span className="icon" title={t('download')}>
                              <a className="ml-">
                                {customIcon('FaDownload')}
                              </a>
                            </span>
                        </a>
                      </td>
                    )}
                    {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets, cellInfo.original.request_id)
                    && (
                      <td>
                        <button
                          className="button is-primary is-inverted"
                          onClick={() => onToggle({
                            singleApprove: true,
                            id: cellInfo.original.id,
                            transaction: cellInfo.original,
                          })}
                          disabled={approveDisabled}
                        >
                          <span className="icon" title={t('approve')}>
                            {customIcon('FaCheck')}
                          </span>
                          {/* <i className="fas fa-check mr-8px" aria-hidden="true"/>
                            {t('')}*/}
                        </button>
                      </td>
                    )}
                    {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets, cellInfo.original.request_id)
                    && (
                      <td>
                        <button
                          className="button is-danger is-inverted"
                          onClick={() => {
                            this.setState({
                              isEdit: true,
                            }, () => {
                              /*onToggle({
                                isBuyer: true,
                                transaction: cellInfo.original.id,
                                onSubmit: this.rejectInvoice,
                              });*/
                              onToggle({
                                singleReject: true,
                                id: cellInfo.original.id,
                                transaction: cellInfo.original,
                              })
                            });
                          }
                          }
                        >
                            <span className="icon" title={t('delete')}>
                              {customIcon('FaTimes')}
                            </span>
                          {/*<i className="fas fa-times mr-8px" aria-hidden="true"/>
                            {t('')}*/}
                        </button>
                      </td>
                    )}
                    {/* {cellInfo.original.sender_status_id === 'PEJ'
                        && (
                          <div className="column ">
                            <button
                              className="button is-success is-inverted"
                              onClick={() => {
                                this.setState({
                                  type: 'differ',
                                }, () => this.updateRates(cellInfo.original));
                                onToggle({
                                  transaction: cellInfo.original,
                                  paymentCondition: defer_condition,
                                  onSubmit: this.deferPayment,
                                });
                              }
                              }
                            >
                              <i className="far fa-clock mr-8px" aria-hidden="true" />
                              {t('differ')}
                            </button>
                          </div>
                        )} */}
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          fetchTransaction({
                            params: {
                              id: cellInfo.original.id,
                              role: 'sender',
                            },
                            callback: () => {
                              history.push('/invoice-detail');
                            }
                          });
                        }}
                      >
                          <span className="icon" title={t('detail')}>
                            {customIcon('FaRegFileAlt')}
                          </span>
                        {/*<i className="far fa-file-alt mr-8px" aria-hidden="true"/>*/}
                      </button>
                    </td>
                    {(cellInfo.original.log_message !== null && cellInfo.original.sender_status_id !== 'PAG' ) && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          onToggle({
                            showInvoiceError: true,
                            transaction: cellInfo.original,
                          });
                        }}
                      >
                          <span className="icon" title={t('invoiceErrors')} style={{color: "#f68121"}}>
                            {customIcon('FaExclamationCircle')}
                          </span>
                        {/*<i className="far fa-file-alt mr-8px" aria-hidden="true"/>*/}
                      </button>
                    </td>)}
                  </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = sender;
        title = this.getTitle();
        break;
      case 'seller':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: 'due_date',
            //accessor: d => formatDate(d.due_date),
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => `${formatCurrency(d.adjusted_amount)}`,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            Cell: cellInfo => (

              <div>
                {cellInfo.original.log_message !== null && cellInfo.original.receiver_status_id === 'PEJ' ? (
                  <span data-type="info"
                        data-tip={cellInfo.original.log_message}>{cellInfo.original.log_message}
                  </span>
                  )
                  :
                  (
                  <span>
                    {cellInfo.original.receiverStatus.description}
                  </span>
                )
                }
              </div>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            width: 130,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                      >
                        <span className="icon" title={t('download')}>
                          <a className="ml-">
                            {customIcon('FaDownload')}
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {/*                {cellInfo.original.receiver_status_id === 'PEJ'
                      && (
                        <div className="column ">
                          <button
                            className="button is-success is-inverted"
                            onClick={() => {
                              this.setState({
                                type: 'advance',
                              }, () => this.updateRates(cellInfo.original));
                              onToggle({
                                transaction: cellInfo.original,
                                paymentCondition: advance_condition,
                                onSubmit: this.advancePayment,
                              });
                            }
                            }
                          >
                            <i className="far fa-clock mr-8px" aria-hidden="true" />
                            {t('advance')}
                          </button>
                        </div>
                      )}*/}
                  {cellInfo.original.receiver_status_id === 'REC'
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          this.setState({
                            rejectReason: cellInfo.original.rec_reason,
                            isEdit: false,
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              transaction: cellInfo.original,
                            });
                          });
                        }
                        }
                      >
                          <span className="icon" title={t('rejectReason')}>
                            {customIcon('FaRegEye')}
                          </span>
                      </button>
                    </td>
                  )}
                  {pathname === '/review-invoices'
                  && (
                    <td>
                      <Link
                        className="button is-danger is-inverted"
                        to="/review-invoices"
                        onClick={() => {
                          deleteTransaction({
                            data: {
                              id: cellInfo.original.id
                            },
                            t,
                            callback: () => {
                              listTransactionsReceiver({

                              });
                            }
                          });
                        }}
                      >
                          <span className="icon" title={t('delete')}>
                            {customIcon('FaTimes')}
                          </span>
                      </Link>
                    </td>
                  )}
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'receiver',
                          },
                          callback: () => {
                            history.push('/invoice-detail');
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          {customIcon('FaRegFileAlt')}
                        </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          }
        ];
        data = receiver;
        title = this.getTitle();
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  approveInvoice = (transaction_id, onToggle) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: { user: { user_id, profile } },
      showMessage,
      t
    } = this.props;
    const data = {
      transaction_id,
      approved: true,
      user_id: profile.user_id,
      role_id: profile.role_id
    };

    approveInvoice({
      data,
      callback: (resp) => {
        listTransactionsSender({ });
        if (resp.process) {
          showMessage({
            message: t('invoiceApprovedSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        onToggle();
      },
    });
  };

  rejectInvoice = (transaction, onToggle) => {
    const { approveInvoice, listTransactionsSender, auth: { user: { user_id, profile } }, showMessage, t } = this.props;
    const {
      rejectReason
    } = this.state;

    const data = {
      transaction_id: transaction,
      approved: false,
      user_id: profile.user_id,
      role_id: profile.role_id,
      note: rejectReason,
    };

    if (rejectReason !== '') {
      approveInvoice({
        data,
        callback: (resp) => {
          listTransactionsSender({ });
          if (resp.process) {
            showMessage({
              message: t('invoiceRejectedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.setState({ rejectReason: '' });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
          onToggle();
        },
      });
    } else {
      showMessage({
        message: t('emptyFields'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  editInvoice = (transaction, onToggle) => {
    const {
      editTransaction,
      auth: {
        user: {
          user_id,
        },
      },
      clients,
      listTransactionsReceiver,
    } = this.props;
    const {
      invoice_date,
      invoice_amount,
      invoice_number,
      invoice_description,
      client,
      binaryFile,
    } = this.state;
    const data = {
      id: transaction.id,
      date: invoice_date,
      invoice_number,
      amount: invoice_amount,
      buyer_id: clients.list[client].id,
      seller_id: user_id,
      description: invoice_description,
      file: binaryFile,
    };
    editTransaction({
      data,
      callback: () => {
        listTransactionsReceiver({});
        onToggle();
      },
    });
  };

  deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const { days } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    deferInvoice({
      data,
      callback: () => {
        listTransactionsSender({ });
        fetchStats();
        onToggle();
      },
    });
  };

  updateRates = (data) => {
    const { days, type } = this.state;
    const {
      auth: {
        user: {
          b2b: {
            advance_condition,
            defer_condition,
          },
        },
      },
    } = this.props;
    let interest_rate = 0;
    let discount_amount = 0;
    const payment_condition = data.paymentCondition;
    if (type === 'advance') {
      interest_rate = 1 - (((Number(advance_condition.percentage)
        / advance_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * payment_condition.receiver_discount_share) / 100)) * -1;
    } else {
      interest_rate = 1 + (((Number(defer_condition.percentage)
        / defer_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * (1 - payment_condition.receiver_discount_share)) / 100));
    }
    this.setState({
      interest_rate: Number(Math.abs(((Number(data.adjusted_amount) + discount_amount)
        * (interest_rate - 1)) * -1))
        .toFixed(2),
      adjusted_amount: Number((Number(data.adjusted_amount) + discount_amount)
        * interest_rate)
        .toFixed(2),
      discount_amount: Math.abs(discount_amount)
        .toFixed(2),
    });
  };

  handleDaysChange = (event, data) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    }, () => this.updateRates(data));
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'invoice_amount' && this.isNumber(value)) {
      this.setState({
        [name]: value,
      });
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    this.setState({
      binaryFile: files[0],
      file: event.target.value,
    });
  };

  handleDateChange = (newDate) => {
    this.setState({
      invoice_date: newDate,
    });
  };

  handleClientChange = (newDate) => {
    this.setState({
      client: newDate.value,
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      listTransactionsReceiver,
      fetchStats,
      auth: {
        user: {
          b2b: { id },
        },
      },
    } = this.props;
    const { days } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({});
        fetchStats();
        onToggle();
      },
    });
  };


  render() {
    const {
      t,
      transactions: { sender, balance, charge, loading: transactionLoading },
      auth: { user: { b2b: { type_user, advance_condition, defer_condition } }, contacts },
      clients,
      clients: { loading: clientLoading },
      router: { location: { pathname } },
    } = this.props;
    const {
      days,
      interest_rate,
      adjusted_amount,
      type,
      file,
      client,
      invoice_amount,
      discount_amount,
      invoice_date,
      invoice_description,
      invoice_number,
      binaryFile,
      amount,
      rejectReason,
      isEdit,
      totalAmountSelected,
      selected,
    } = this.state;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const loading = transactionLoading || clientLoading;

    let totalInvoices = 0;
    for(var i = 0; i<sender.length; i++){
      totalInvoices += parseFloat(sender[i].adjusted_amount);
    }

  let countInvoices = 0;
  countInvoices = Object.entries(selected).map(item => item[1] ? item[0] : '' ).filter(Boolean).length;

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    toggled && toggleProps.isApprove ? (
                        <Modal onToggle={onToggle}>
                          <FormApprove
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            handleSelectChange={this.handleSelectChange}
                          />
                        </Modal>
                      )
                      // eslint-disable-next-line no-nested-ternary
                      : toggled && toggleProps.isBuyer ? (
                        <Modal onToggle={onToggle}>
                          <FormReject
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            onSubmit={() => this.rejectInvoice(toggleProps.transaction, onToggle)}
                            handleInputChange={this.handleInputChange}
                            data={{ rejectReason }}
                            isEdit={isEdit}
                          />
                        </Modal>
                      )
                      : toggled && toggleProps.isMultipleApprove ? (
                        <Modal onToggle={onToggle}>
                          {loading && <Loading/>}
                          <Panel headingText={" "}>
                            <div style={{textAlign: "center"}}>
                              <p>
                                {countInvoices+" "+
                                t("invoices")+" "+t("selectedApprove")+" "+formatCurrency(totalAmountSelected)}
                              </p>
                              <br/>
                              <p>
                                {t("confirmApproval")}
                              </p>
                            </div>
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => this.approveMultiple(true, onToggle)}
                              >
                                {t('yes')}
                              </button>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("no")}
                              </button>
                            </div>                           
                          </Panel>
                        </Modal>
                      )
                      : toggled && toggleProps.invoiceErrors ? (
                        <Modal onToggle={onToggle}>
                          <Panel headingText={" "}>
                            <div style={{textAlign: "left"}}>
                              <p style={{padding: '1em'}}>
                                {t("invoiceErrors")}
                                <span className="icon" title={t('invoiceErrors')} style={{color: "#f68121"}}>
                                  {customIcon('FaExclamationCircle')}
                                </span>
                              </p>
                            </div>     
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("accept")}
                              </button>
                            </div>        
                          </Panel>
                        </Modal>
                      )
                      : toggled && toggleProps.showInvoiceError ? (
                        <Modal onToggle={onToggle}>
                          <Panel headingText={" "}>
                            <div style={{textAlign: "center"}}>
                              <p style={{padding: '1em'}}>
                                {toggleProps.transaction.log_message}
                              </p>
                            </div>     
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("accept")}
                              </button>
                            </div>        
                          </Panel>
                        </Modal>
                      )
                      : toggled && toggleProps.singleApprove ? (
                        <Modal onToggle={onToggle}>
                          {loading && <Loading/>}
                          <Panel headingText={" "}>
                            <div style={{textAlign: "center"}}>
                              <p style={{padding: '1em'}}>
                                {t("confirmApprovalNumber")+" "+toggleProps.transaction.invoice_number+" "+t("byAmount")+" "+toggleProps.transaction.base_amount+"?"}
                              </p>
                            </div>     
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => this.approveInvoice(toggleProps.id, onToggle)}                              
                              > 
                                {t("yes")}
                              </button>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("no")}
                              </button>
                            </div>        
                          </Panel>
                        </Modal>
                      )
                      : toggled && toggleProps.rejectAll ? (
                        <Modal onToggle={onToggle}>
                          {loading && <Loading/>}
                          <Panel headingText={" "}>
                            <div style={{textAlign: "center"}}>
                              <p style={{padding: '1em'}}>
                                {"¿Está seguro que desea rechazar "+countInvoices+" facturas?"}
                              </p>
                            </div>     
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => this.approveMultiple(false, onToggle)}                              
                              > 
                                {t("yes")}
                              </button>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("no")}
                              </button>
                            </div>        
                          </Panel>
                        </Modal>
                      )
                      : toggled && toggleProps.singleReject ? (
                        <Modal onToggle={onToggle}>
                          {loading && <Loading/>}
                          <Panel headingText={" "}>
                            <div style={{textAlign: "center"}}>
                              <p style={{padding: '1em'}}>
                                {"¿Está seguro que desea rechazar la factura "+toggleProps.transaction.invoice_number+"?"}
                              </p>
                            </div>     
                            <div style={{textAlign: "center"}}>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => this.rejectSingle(onToggle, toggleProps.transaction)}                              
                              > 
                                {t("yes")}
                              </button>
                              <button
                                className="button is-primary"
                                style={{margin: "1em"}}
                                onClick={() => onToggle()}                              
                              > 
                                {t("no")}
                              </button>
                            </div>        
                          </Panel>
                        </Modal>
                      )
                      : toggled && !toggleProps.isEdit
                        ? (
                          <Modal onToggle={onToggle}>
                            <TableList title={t('newPaymentCondition')}>
                              <div className="content">
                                <ul>
                                  <TextInput
                                    name="days"
                                    label={type === 'advance' ? t('daysAdvance') : t('daysDefer')}
                                    value={days}
                                    onChange={event => this.handleDaysChange(event,
                                      toggleProps.transaction)}
                                    placeholder="1"
                                    type="number"
                                    controlClasses="is-expanded has-icons-left"
                                    inputClasses="has-text-right"
                                    min="1"
                                    step="1"
                                    iconPosition="is-left"
                                    icon="FaUsd"
                                  />
                                  <ItemCard
                                    name={t('percentage')}
                                    value={` ${formatCurrency(type === 'advance' ? advance_condition.percentage : defer_condition.percentage)} %`}
                                  />
                                  <ItemCard
                                    name={t('discountAmount')}
                                    value={` ${formatCurrency(discount_amount)}`}
                                  />
                                  <ItemCard
                                    name={t('interest_rate')}
                                    value={` ${formatCurrency(interest_rate)}`}
                                  />
                                  <ItemCard
                                    name={t('totalPay')}
                                    value={` ${formatCurrency(adjusted_amount)}`}
                                  />
                                </ul>
                                <button
                                  className="button is-primary is-pulled-right"
                                  onClick={() => toggleProps.onSubmit(toggleProps.transaction.id,
                                    toggleProps.paymentCondition.id, onToggle)}
                                >
                                  {customIcon('FaCheck')}
                                  {t('confirm')}
                                </button>
                              </div>
                            </TableList>
                          </Modal>
                        ) : toggled && (
                        <Modal onToggle={onToggle}>
                          <LoadInvoicesForm
                            formData={{ ...this.state }}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            balance={balance}
                            handleInputChange={this.handleInputChange}
                            handleFileChange={this.handleFileChange}
                            handleDateChange={this.handleDateChange}
                            handleClientChange={this.handleClientChange}
                            contacts={contacts}
                            chargeAmount={charge_amount}
                            invoiceAmount={invoice_amount}
                            invoiceDate={invoice_date}
                            invoiceNumber={invoice_number}
                            description={invoice_description}
                            clients={clients.list}
                            selectedClient={client}
                            onSubmit={() => this.editInvoice(toggleProps.transaction, onToggle)}
                            file={file}
                            fileName={binaryFile && binaryFile.name}
                          />
                        </Modal>
                      )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        {((type_user === 'seller') && (pathname === "/approved-invoices" || pathname === "/paid-invoices")) &&
                          <div style={{margin: '1rem'}}>
                            <p>{t('nextDayConditions')}</p>
                          </div>
                        }
                        {((type_user === 'buyer') && (pathname === "/review-invoices")) &&
                        <div className='Invoices__select-panel'>
                          <div>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={this.state.selectAll === 1}
                              ref={input => {
                                if (input) {
                                  input.indeterminate = this.state.selectAll === 2;
                                }
                              }}
                              onChange={() => this.toggleSelectAll()}
                            />
                            <p>{t('selectAllFemale')}</p>
                          </div>
                          <div className='Invoices__totals'>
                            <p>{`${t('generalTotal')}: ${formatCurrency(totalInvoices)}`}</p>
                            <p>{`${t('totalSelected')}: ${formatCurrency(totalAmountSelected)}`}</p>
                          </div>
                          <div>
                            <button
                              className="button is-primary is-inverted"
                              disabled={totalAmountSelected === 0 ? true : false/*Object.keys(selected).length < 1 ? true : false*/}
                              onClick={() => 
                                onToggle({
                                  isMultipleApprove: true,
                                })
                              }                              
                            >
                              <span className="icon" title={t('approve')}>
                                {customIcon('FaCheck')}
                              </span>
                              <p>{t('approveMultipleTransactions')}</p>
                            </button>
                          </div>
                          <div>
                            <button
                              className="button is-danger is-inverted"
                              onClick={() => onToggle({
                                rejectAll: true,
                              })}
                              disabled={totalAmountSelected === 0 ? true : false/*Object.keys(selected).length < 1 ? true : false*/}
                            >
                              <span className="icon" title={t('reject')}>
                                {customIcon('FaTimes')}
                              </span>
                              <p>{t('rejectMultipleTransactions')}</p>
                            </button>
                          </div>
                        </div>}
                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>

    );
  }
}

Invoices.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  editTransaction: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({
                          transactions,
                          auth,
                          router,
                          clients,
                        }) => ({
  transactions,
  auth,
  router,
  clients,
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...transactionsActions, ...clientsActions, ...providersActions,
  }),
  withNamespaces(),
)(Invoices);
