import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const createPaymentCondition = createAction(types.CREATE_PAYMENT_CONDITION);
const editPaymentCondition = createAction(types.EDIT_PAYMENT_CONDITION);
const deletePaymentCondition = createAction(types.DELETE_PAYMENT_CONDITION);
const getPaymentCondition = createAction(types.GET_PAYMENT_CONDITION);
const savePaymentCondition = createAction(types.SAVE_PAYMENT_CONDITION);
const getDiscountRate = createAction(types.GET_DISCOUNT_RATE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  createPaymentCondition,
  editPaymentCondition,
  deletePaymentCondition,
  getPaymentCondition,
  savePaymentCondition,
  getDiscountRate,
};
