import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '..';

const SearchPersonForm = ({
    t,
    searchPerson,
    handleInputChange,
    document_number,
    name,
    email,
    min_amount,
    max_amount,
}) => (
    <Panel>
        <Form
        submitText={t('search')}
        onSubmit={(event) => {
            event.preventDefault();
            searchPerson();
        }}
        buttonClasses="mr-8px"
        >
            <div className="columns field-body">
                <div className="column">
                    <TextInput
                    name="name"
                    label={t('businessName')}
                    value={name}
                    onChange={handleInputChange}
                    placeholder={t('businessName')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    iconPosition="is-left"
                    icon="FaSearch"
                    />
                </div>
                <div className="column">
                    <TextInput
                    name="document_number"
                    label={t('identificationNumber')}
                    value={document_number}
                    onChange={handleInputChange}
                    placeholder={t('identificationNumber')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    iconPosition="is-left"
                    icon="FaSearch"
                    />
                </div>
            </div>
            <div className="columns field-body">
                <div className="column">
                    <TextInput
                    name="email"
                    label={t('email')}
                    value={email}
                    onChange={handleInputChange}
                    placeholder={t('email')}
                    type="text"
                    controlClasses="is-expanded"
                    />
                </div>
                <div className="column">
                    <TextInput
                    name="min_amount"
                    label={t('operationRank')}
                    value={min_amount}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    min="0"
                    step="1000"
                    controlClasses="is-expanded"
                    inputClasses="has-text-right"
                    />
                </div>
                <div className="column">
                    <TextInput
                    name="max_amount"
                    label={t('maxRank')}
                    value={max_amount}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    min="0"
                    step="1000"
                    controlClasses="is-expanded"
                    inputClasses="has-text-right"
                    />
                </div>
            </div>
        </Form>
    </Panel>
);

export default SearchPersonForm;