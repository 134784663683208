/**
 * Created by mariale on 29/10/18.
 */

import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableList } from '../../../../components';
import './styles.scss';
import { customIcon } from '../../../../util';

const TransferDetail = ({ t, detailTransfer, onFinish }) => (
  <div>
    <TableList title={t('transferDetail2')}>
      <tr>
        <th className="has-text-centered is-size-5" colSpan="2">
          {t('extractDetail1')}
          <span className="icon text-verde">
            {customIcon('FaCheckCircle')}
          </span>
        </th>
      </tr>
      <tr>
        <th>{t('date')}</th>
        <td>{detailTransfer.transaction_date}</td>
      </tr>
      <tr>
        <th>{t('transferDetail3')}</th>
        <td>{detailTransfer.destination_name}</td>
      </tr>
      <tr>
        <th>{t('transferType')}</th>
        <td>{detailTransfer.transaction_type}</td>
      </tr>
      <tr>
        <th>{t('transferDetail4')}</th>
        <td>{detailTransfer.amount}</td>
      </tr>
      <tr>
        <th>{t('transferCharge')}</th>
        <td>{detailTransfer.charge}</td>
      </tr>
      <tr>
        <th>{t('transferDetail5')}</th>
        <td>{parseFloat(detailTransfer.amount) + parseFloat(detailTransfer.charge)}</td>
      </tr>
      <tr>
        <th>{t('transferState')}</th>
        <td>{detailTransfer.transaction_state}</td>
      </tr>
      <tr>
        <th colSpan="2">
          <div className="has-text-right">
            <button
              type="button"
              className="button is-primary"
              onClick={onFinish}
            >
              {t('finish')}
            </button>
          </div>
        </th>
      </tr>
    </TableList>
  </div>
);

TransferDetail.propTypes = {
  t: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  detailTransfer: PropTypes.shape(Object).isRequired,
};

export default withNamespaces()(TransferDetail);
