const START_FETCH = 'paymentConditions/START_FETCH';
const END_FETCH = 'paymentConditions/END_FETCH';
const API_CALL = 'paymentConditions/API_CALL';
const MESSAGE = 'paymentConditions/MESSAGE';
const CREATE_PAYMENT_CONDITION = 'paymentConditions/CREATE_PAYMENT_CONDITION';
const EDIT_PAYMENT_CONDITION =  'paymentConditions/EDIT_PAYMENT_CONDITION';
const DELETE_PAYMENT_CONDITION = 'paymentConditions/DELETE_PAYMENT_CONDITION';
const GET_PAYMENT_CONDITION = 'paymentConditions/GET_PAYMENT_CONDITION';
const SAVE_PAYMENT_CONDITION = 'paymentConditions/SAVE_PAYMENT_CONDITION';
const GET_DISCOUNT_RATE = 'paymentConditions/GET_DISCOUNT_RATE';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  CREATE_PAYMENT_CONDITION,
  EDIT_PAYMENT_CONDITION,
  DELETE_PAYMENT_CONDITION,
  GET_PAYMENT_CONDITION,
  SAVE_PAYMENT_CONDITION,
  GET_DISCOUNT_RATE,
};
