import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { transactionsActions } from '../../state/ducks/transactions';
import {
  Loading,
  Panel,
  TableList,
  Toggle,
  TransactionRow,
  Modal,
} from '../../components';
import { customIcon } from '../../util';

class AccountDetails extends Component {
  componentDidMount() {
    const {
      authorizeTransaction,
      fetchTransactions,
      checkBalance,
    } = this.props;
    authorizeTransaction();
    fetchTransactions();
    checkBalance();
  }

  onChangePage = (page) => {
    const { fetchTransactions } = this.props;
    fetchTransactions({ page });
  };

  render() {
    const {
      t,
      transactions,
      auth: { account },
    } = this.props;
    const {
      list,
      loading,
      currency,
      balance,
      total,
    } = transactions;
    return (
      <div>
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => (
            <Fragment>
              {
                toggled
                && (
                  <Modal onToggle={onToggle}>
                    <TableList title={t('detail')}>
                      {toggleProps.map(detail => (
                        <tr key={detail.text}>
                          <th>{detail.text}</th>
                          <td>{detail.value}</td>
                        </tr>
                      ))}
                    </TableList>
                  </Modal>
                )
              }
              <Panel>
                <table className="table">
                  <tbody>
                    <tr key={account.id}>
                      <td>
                        {account.name}
                        <br />
                        <span className="text-verde">{account.account_number}</span>
                      </td>
                      <td>{`${currency} ${balance}`}</td>
                      <td className="has-text-right">
                        <Link
                          className="button is-link"
                          to="/deposit"
                        >
                          <span className="icon is-small">
                            {customIcon('FaLevelDownAlt')}
                          </span>
                          &nbsp;
                          {t('deposit')}
                        </Link>
                        &nbsp;
                        <Link
                          className="button is-link"
                          to="/extract"
                        >
                          <span className="icon is-small">
                            {customIcon('FaLevelUpAlt')}
                          </span>
                          &nbsp;
                          {t('extract')}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <TableList
                totalElements={total}
                onChangePage={this.onChangePage}
                title={t('lastMovements')}
                head={(
                  <tr>
                    <th>{t('detail')}</th>
                    <th>{t('date')}</th>
                    <th>{t('concept')}</th>
                    <th>{t('import')}</th>
                  </tr>
                )}
              >
                {list.map(transaction => (
                  <TransactionRow
                    key={transaction.id}
                    openDetail={() => {
                      onToggle(transaction.detail);
                    }}
                  >
                    <td>{transaction.transaction_date}</td>
                    <td>{transaction.transaction_name}</td>
                    <td>{transaction.transaction_amount}</td>
                  </TransactionRow>
                ))}
              </TableList>
            </Fragment>
          )}
        </Toggle>
      </div>
    );
  }
}

AccountDetails.propTypes = {
  t: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.arrayOf(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  authorizeTransaction: PropTypes.func.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions }) => (
  {
    transactions,
  }
);

export default compose(
  connect(mapStateToProps, { ...transactionsActions }),
  withNamespaces(),
)(AccountDetails);
