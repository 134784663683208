/**
  * Created by Jonas on 23/08/19.
  * @param t
  * @param onSubmit
  * @param handleInputChange
  * @param name
  * @param handleSelectChange
  * @param documentTypesList listado de tipos de documentos
  * @param documentNumber
  * @param roleList
  * @param email
  * @param password
  * @param confirmPassword
  * @param documentType
  * @param bankType
  * @param title
  * @return {*}
*/

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';

const BankForm = ({
  t,
  onSubmit,
  handleInputChange,
  name,
  handleSelectChange,
  documentTypesList,
  documentNumber,
  roleList,
  email,
  password,
  confirmPassword,
  documentType,
  bankType,
  title,
}) => (
  <Panel headingText={title}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      {/* <div className="field is-horizontal subtitle is-5 text-black">
                  {t('demographicInformation')}
                </div> */}
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifths">
            <TextInput
              name="name"
              label={t('bankName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column is-tree-fifths">
            <SelectSingle
              name="documentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                {
                  label: t('Select'),
                  value: '',
                },
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentType + 1}
            />
          </div>
          <div className="column is-tree-fifths">
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="bankType"
              label={t('bankType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                {
                  label: t('Select'),
                  value: '',
                },
                ...roleList.map((item, index) => ({
                  value: index,
                  label: t(item.name),
                })),
              ]}
              controlClasses="is-expanded"
              value={bankType + 1}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifth">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column is-tree-fifths">
            <TextInput
              name="password"
              label={t('password')}
              value={password}
              onChange={handleInputChange}
              placeholder={t('password')}
              type="password"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column is-tree-fifths">
            <TextInput
              name="confirmPassword"
              label={t('confirmPassword')}
              value={confirmPassword}
              onChange={handleInputChange}
              placeholder={t('confirmPassword')}
              type="password"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
    </Form>
  </Panel>
);

BankForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  roleList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  documentType: PropTypes.number,
  bankType: PropTypes.number,
};

BankForm.defaultProps = {
  documentType: '',
  bankType: '',
};
export default BankForm;
