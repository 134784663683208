import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { authActions } from '../../../state/ducks/auth';
import { Loading, Toggle } from '../../../components';
import FormLogin from './components/FormLogin';
import FormRecover from './components/FormRecover';
//import Logo from '../../../resources/images/assets/logo_with_amex.png';
import LogoNew from '../../../resources/images/assets/abl2.jpg';
import './styles.scss';
import Modal from '../../../components/Modal';
import { TOAST_CONFIG } from '../../../config/constants';
import Fingerprint2 from 'fingerprintjs2';
import SignInFooter from './components/SignInFooter';
import { customIcon } from '../../../util';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    const { auth: { language } } = this.props;
    this.setState({ language });

    const promise = new Promise((resolve) => {

      const options = {
        exclutes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        }
      };

      Fingerprint2.get(options, function (components) {
        const values = components.map(function (component) {
          return component.value;
        });
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(murmur);
      });
    });

    promise.then(result => {
      this.setState({ device_info: result });
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { signIn } = this.props;
    const { ...data } = this.state;
    signIn({ data });
  };

  handleReset = (onToggle) => {
    const { t, passwordReset, showMessage } = this.props;
    const { username } = this.state;
    const email = username;

    passwordReset({
      email,
      callback: (response) => {
        showMessage({
          message: t(response.message),
          config: TOAST_CONFIG.SUCCESS,
        });
        onToggle();
        this.resetState();
        window.location.reload(true);
      },
    });
  };
  getInitialState = () => ({
    language: '',
    username: '',
    password: '',
    device_info: '',
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  render() {
    const {
      auth: { logged, loading, user: { buyer_multirole } },
      t,
    } = this.props;
    const { language, username, password, device_info } = this.state;

    if (logged === true) {
      if (buyer_multirole === 1) {
        return <Redirect to="/select-role" />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }
    return (
      <Toggle>
        {({ toggled, onToggle }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <FormRecover
                    handleReset={() => this.handleReset(onToggle)}
                    handleInputChange={this.handleInputChange}
                    data={{ username }}
                  />
                </Modal>
              )
            }
            <div className="SignIn">
              <ToastContainer />
              {loading && <Loading />}
              <section className="hero is-info is-fullheight">
                <div className="hero-body">
                  <div style={{
                    alignSelf: 'flex-end',
                    marginBottom: '1rem'
                  }}>
                    <div className="field">
                      <label htmlFor="language" className="label has-text-white">
                        {t('language')}
                        <div className="control has-icons-left">
                          <div className="select is-small">
                            <select name="language" value={language}
                              onChange={this.handleSelectChange}>
                              <option value="es">{t('es')}</option>
                              <option value="pt">{t('pt')}</option>
                              <option value="en">{t('en')}</option>
                            </select>
                          </div>
                          <div className="icon is-small is-left">
                            {customIcon('FaGlobe')}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="container">
                    <div className="column panel fadeInUp animated" style={{ marginTop: '4vh' }}>
                      {/*<figure className="image is-centered mt-32px mb-16px">*/}
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3.5em', marginBottom: '2.5em' }}>
                        <img src={LogoNew} alt="logo" />
                      </div>
                      {/*</figure>*/}
                      <FormLogin
                        handleSubmit={this.handleSubmit}
                        handleInputChange={this.handleInputChange}
                        data={{
                          username,
                          password,
                          device_info,
                        }}
                      />
                      <div className="center link-standard">
                        <a href={'#'}
                          onClick={() => {
                            onToggle({
                              isEdit: true,
                            });
                          }}
                        >
                          {t('forgotPassword')}
                        </a>
                      </div>
                      <div style={{ paddingTop: '1em' }} />
                      <hr class="solid" />
                      <div className="center link-standard">
                        <label className="label">{t('agreementText')} {" "}
                          <a href={'/tyc'}>
                            {t('terms')}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <SignInFooter disclaimer={t('disclaimer')} />
              </section>
            </div>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  passwordReset: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(SignIn);
