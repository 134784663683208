import { authActions, authTypes } from '../ducks/auth';
import configureStore from '../store';
import { initialState } from '../../App';

const signIn = ({ dispatch }) => next => (action) => {

  next(action);
  if (action.type !== authTypes.SIGN_IN) {
    return;
  }

  const { data } = action.payload;
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/login-admin',
        data,
      },
      authorization: false,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        const color = "#2E77BB";
        if (response.data.success) {
          response.data.data.colorAvatar = color;
          dispatch(authActions.updateUser(response.data.data));
          dispatch(authActions.updateAccount({ account_number: response.data.data.phone_number }));
          dispatch(authActions.logged());
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const logout = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.LOGOUT) {
    return;
  }
  const { persistor } = configureStore(initialState);

  persistor.purge();
  dispatch(authActions.clear());
};

const getContacts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_CONTACTS) {
    return;
  }

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'api/contact-list',
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.updateContacts(response.data.data));
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const updateStatus = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.UPDATE_STATUS) {
    return;
  }

  const {
    data: {
      id_user = '',
      new_status = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'user/update-user-status',
        data: {
          id_user,
          new_status,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordReset = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET) {
    return;
  }
  const {
    email,
    callback
  } = action.payload;

  console.dir(action);

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/request-password-reset',
        data: {
          email,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordResetToken = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET_TOKEN) {
    return;
  }
  const {
      params: {
        token,
        password,
      },
      callback,
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/reset-password',
        data: {
          token,
          password,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordResetLogin = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET_LOGIN) {
    return;
  }
  const {
    email,
    password,
    callback,
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/reset-password-login',
        data: {
          email,
          password,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const changeRole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.CHANGE_ROLE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b/change-role`,
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.saveRole(response.data.data));
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

export default [signIn, logout, getContacts, updateStatus, passwordReset, passwordResetToken,
  passwordResetLogin, changeRole];
