import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import UserForm from '../../components/UserForm';

class EditBankUser extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    lastName: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    adminPosition: '',
    roles: [],
    roleList1: [],
    edit: false,
    hidden: false,
  });

  componentDidMount() {
    const {
      auth: { user: { user_id } },
      role: { list: roleList },
      fetchRoles,
      fetchDocumentTypes,
      documentType: { list: documentTypeList },
    } = this.props;

    const {
      userInfo
    } = this.props.location.state;

    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        owner_id: user_id,
      },
    });

    const roles1 = roleList;
    let band = false;
    roles1.sort();
    for (let i = 0; i < userInfo.roles.length; i++) {
      for (let j = 0; j < roles1.length; j++) {
        if (Number(userInfo.roles[i].id) === Number(roles1[j].id)) {
          roles1.splice(j, 1);
          band = true;
        }
      }
    }

    this.setState({
      name: userInfo.idPerson.name,
      lastName: userInfo.idPerson.lastname,
      documentType: userInfo.idPerson.idDocumentType.document_type === 'CI' ? 0 : 1,
      documentNumber: userInfo.idPerson.document_number,
      email: userInfo.username,
      roles: userInfo.roles,
      adminPosition: userInfo.admin_position,
      roleList1: this.labelType(roles1),
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleItemChange = (newValue, event) => {
    const {
      role: { list: roleList },
    } = this.props;
    const {
      roleList1
    } = this.state;

    if (event.action === 'remove-value') {
      roleList1.push({
        id: event.removedValue.id,
        name: event.removedValue.name,
        type: event.removedValue.type,
        owner_id: event.removedValue.owner_id,
        label_type: event.removedValue.label_type,
      });
    }

    if (event.action === 'select-option') {
      for (let j = 0; j < roleList1.length; j++) {
        if (Number(event.option.id) === Number(roleList1[j].id)) {
          roleList1.splice(j, 1);
        }
      }
    }

    if (event.action === 'clear') {
      roleList1.splice(0, roleList1.length);
      ;
      for (let j = 0; j < roleList.length; j++) {
        roleList1.push({
          id: roleList[j].id,
          name: roleList[j].name,
          type: roleList[j].type,
          owner_id: roleList[j].owner_id,
        });
      }
    }
    this.setState({ roles: newValue });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  editBankUser = () => {
    const {
      editProvider,
      t,
      showMessage,
      documentType: { list: documentTypesList },
      role: { list: roleList },
      router: { location: { pathname } },
      history,
    } = this.props;

    const {
      name,
      lastName,
      documentType,
      documentNumber,
      email,
      roles,
      adminPosition,
    } = this.state;

    const {
      userInfo
    } = this.props.location.state;

    const type = documentType === 0 ? 'CI' : 'Pasaporte';

    const type1 = documentTypesList
      .map((documentTypeMap, index) => ({
        ...documentTypeMap,
        index
      }))
      .filter(documentType => documentType.document_type
        === type);

  const data = {
    id_user: userInfo.id,
    name: name,
    lastname: lastName,
    documentType: type1[0].id,
    documentNumber: documentNumber,
    email,
    country_code: null,
    phone_number: '',
    password: '',
    bankType: roles,
    b2b: {
      issuing_bank: 0,
      acquiring_bank: 1,
      admin_position: adminPosition,
    }
  };

  editProvider({
    data,
    callback: (response) => {
      history.push('/bank-user-list');
      showMessage({
        message: t('editUserSuccess', { name }),
        config: TOAST_CONFIG.SUCCESS,
      });
    }
  });
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      lastName,
      documentNumber,
      documentType,
      email,
      roles,
    } = this.state;

    return !(
      name === '' ||
      lastName === '' ||
      documentNumber === '' ||
      documentType === -1 ||
      email === '' ||
      roles.length < 0);
  };

  labelType = (roleList) => {
    const {
      t,
    } = this.props;
    const roles = [];

    for (let i = 0; i < roleList.length; i++) {
      roles.push({
        id: roleList[i].id,
        name: roleList[i].name,
        type: roleList[i].type,
        owner_id: roleList[i].owner_id,
        label_type: t(roleList[i].name)
      });
    }

    return roles;
  };

  render() {
    const {
      t,
      documentType: { list: documentTypesList, loading: documentLoading },
      role: { list: roleList, loading: roleLoading },
    } = this.props;
    const {
      name,
      lastName,
      documentNumber,
      email,
      documentType,
      edit,
      hidden,
      roles,
      adminPosition,
    } = this.state;

    let filteredRoleList = roleList.filter(r => !roles.some(r2 => r2.id == r.id));
    console.dir([roles, roleList, filteredRoleList]);

    return (
      <div>
        {(documentLoading || roleLoading) && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <UserForm
              t={t}
              title={t('edit_user')}
              onSubmit={this.editBankUser}
              handleInputChange={this.handleInputChange}
              name={name}
              lastName={lastName}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              documentNumber={documentNumber}
              documentType={documentType}
              email={email}
              position={adminPosition}
              roleList={this.labelType(filteredRoleList)}
              roles={this.labelType(roles)}
              edit={edit}
              hidden={hidden}
            />
          </div>
        </div>
      </div>
    );
  }
}

EditBankUser.propTypes = {
  /*auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  roles: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,*/
};


const mapStateToProps = ({
                          providers,
                          documentType,
                          auth,
                          role,
                          router,
                        }) => ({
  providers,
  documentType,
  auth,
  role,
  router,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...roleActions,
  }),
  withNamespaces(),
)(EditBankUser);
