import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextArea } from '../../../../components';
import SelectSingle from '../../../../components/SelectSingle';

const FormApprove = (props) => {
  const {
    t,
    toggleProps,
    handleSelectChange,
  } = props;
  return (
    <Panel headingText={t('paymentMethod')}>
      <div className="FormLogin">
        <Form
          className="column is-full"
          submitText={t('send')}
          onSubmit={(event) => {
            event.preventDefault();
            toggleProps.onSubmit();
          }}
          buttonClasses="mr-8px"
        >
          <SelectSingle
            name="paymentMethod"
            label={t('paymentMethod')}
            placeholder={t('paymentMethod')}
            onChange={handleSelectChange}
            options={
              [{
                label: t('target'),
                value: 0,
              }, {
                label: t('account'),
                value: 1,
              }, {
                label: t('line'),
                value: 2,
              }]}
            selectClasses="is-fullwidth"
            controlClasses="has-icons-left"
            icon="FaRegUser"
            iconPosition="is-left"
            isClearable={false}
          />
          <br />
        </Form>
      </div>
    </Panel>
  );
};

FormApprove.propTypes = {
  t: PropTypes.func.isRequired,
  toggleProps: PropTypes.shape(Object).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
};

export default withNamespaces()(FormApprove);
