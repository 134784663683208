import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles.scss';
import { insertAfter } from '../../util';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-undef
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const root = document.getElementById('root');
    insertAfter(this.el, root);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    document.body.removeChild(this.el);
  }

  render() {
    const { children, className, onToggle, band } = this.props;
    return ReactDOM.createPortal(
      <div className={"Modal"}>
        <div className="modal is-active">
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="modal-background"
            onClick={onToggle}
          />
          <div
            className={band ? "modal-content-overflow " + className : "modal-content " + className}
          >
            {children}
          </div>
          <button
            type="button"
            className="modal-close is-large"
            aria-label="close"
            onClick={onToggle}
          />
        </div>
      </div>,
      this.el,
    );
  }
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Modal;
