import { itemActions, itemTypes } from '../ducks/item';

const itemData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_ITEMS) {
    return;
  }

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b/items',
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(itemActions.updateItems({
            list: data,
          }));
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

export default [itemData];
