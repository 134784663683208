import * as FaIcons from 'react-icons/fa';
import * as TbIcons from 'react-icons/tb';

// Helper function for insert a node after other
export const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

export const getPath = (p, o) => (p.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : null), o));

export const formatDate = date => new Intl.DateTimeFormat('es', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).format(date * 1000);

export const formatFriendlyDate = date => new Intl.DateTimeFormat('es', {
  month: 'short',
  day: '2-digit',
  weekday: 'short',
}).format(date);

export const formatFriendlylongDate = (date, lng) => new Intl.DateTimeFormat(lng, {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date);

export const formatFriendlyDateLong = date => new Intl.DateTimeFormat('es', {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date * 1000);

export const formatCurrency = amount => Number(amount)
  .toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

export const customIcon = ( name ) => {
  const Icon = FaIcons[name] || TbIcons[name];
  return Icon ? <Icon /> : "";
};