import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { logActions } from '../../state/ducks/log';
import TextArea from '../../components/TextArea';
import { customIcon } from '../../util';

class LogView extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    params: '',
    respJson: '',
    respXml: '',
  });

  componentDidMount() {
    const {
      fetchLog,
    } = this.props;
    fetchLog({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      log: { list },
    } = this.props;
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'date', // Required because our accessor is not a string
        Header: t('date'),
        accessor: d => `${d.date} `,
      },
      {
        id: 'response', // Required because our accessor is not a string
        Header: t('response'),
        accessor: d => (d.response_json && d.response_json.mensaje && typeof(d.response_json.mensaje) === "string") ? (d.response_json.mensaje) : "",
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    this.setState({
                      params: JSON.stringify(cellInfo.original.params),
                      respJson: JSON.stringify(cellInfo.original.response_json),
                      respXml: JSON.stringify(cellInfo.original.response_xml),
                    }, () => {
                      onToggle({});
                    });
                  }}
                >
                  <span className="mr-8px">
                    {customIcon('FaRegEye')}
                  </span>
                  {t('view')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('logView');
    return {
      columns,
      data,
      title,
    };
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  render() {
    const {
      t,
      log: { loading },
    } = this.props;
    const { params, respJson, respXml } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <Panel headingText={t('logView')}>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="params"
                                  label={t('params')}
                                  value={params}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respJson"
                                  label={t('respJson')}
                                  value={respJson}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respXml"
                                  label={t('respXml')}
                                  value={respXml}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="is-fullwidth has-text-right">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={() => onToggle({})}
                            >
                              {t('accept')}
                            </button>
                          </div>
                        </Panel>
                      </Modal>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

LogView.propTypes = {
  t: PropTypes.func.isRequired,
  fetchLog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ role, auth, log }) => (
  {
    role,
    auth,
    log,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...logActions }),
  withNamespaces(),
)(LogView);
