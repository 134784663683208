import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { documentTypeActions } from '../../state/ducks/documentType';
import { leadActions } from '../../state/ducks/lead';
import { authActions } from '../../state/ducks/auth';
import { Loading, Toggle } from '../../components';
import TextInput from '../../components/TextInput';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import FormRecover from './components/FormRecover';
import { TOAST_CONFIG } from '../../config/constants';

class AccountProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    name: '',
    documentType: '',
    documentNumber: '',
    email: '',
    password: '',
    address: '',
    edit: '',
    confirmPassword: '',
    cod: '',
  });

  componentDidMount() {
    const {
      fetchDocumentTypes,
      auth: { user },
    } = this.props;
    this.setState({
      edit: true,
      name: user.name,
      documentType: user.document_type,
      documentNumber: user.document_number,
      address: user.b2b.address,
      email: user.email,
      cod: user.b2b.cod,
    });
    fetchDocumentTypes({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleReset = (onToggle) => {
    const { t, showMessage, passwordResetLogin, history } = this.props;
    const { email, password, confirmPassword } = this.state;

    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        passwordResetLogin({
          email,
          password,
          callback: () => {
            onToggle();
            showMessage({
              message: t('changePasswordSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          }
        });
      } else {
        showMessage({
          message: t('nonMatchingPasswords'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    } else if (password !== '' || confirmPassword !== '') {
      showMessage({
        message: t('emptyFields'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  render() {
    const {
      t, documentType: { list: documentTypesList },
      auth: { loading, user: { profile } },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      address,
      documentType,
      edit,
      password,
      confirmPassword,
      cod,
    } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <FormRecover
                    handleInputChange={this.handleInputChange}
                    onSubmit={() => this.handleReset(onToggle)}
                    data={{ password, confirmPassword }}
                  />
                </Modal>
              )
            }
            <Panel headingText={t('edit')}>
              {loading && <Loading />}
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-two-fifths">
                    <TextInput
                      name="name"
                      label={t('name')}
                      value={name}
                      onChange={this.handleInputChange}
                      placeholder={t('name')}
                      type="text"
                      controlClasses="is-expanded"
                      disabled={edit}
                    />
                  </div>
                  <div className="column is-one-fifth">
                    <TextInput
                      name="documentType"
                      label={t('documentType')}
                      selectClasses="is-fullwidth"
                      placeholder={t('documentType')}
                      value={documentType}
                      disabled={edit}
                    />
                  </div>
                  <div className="column is-two-fifths">
                    <TextInput
                      name="documentNumber"
                      label={t('documentNumber')}
                      value={documentNumber}
                      onChange={this.handleInputChange}
                      placeholder={t('documentNumber')}
                      type="text"
                      controlClasses="is-expanded"
                      disabled={edit}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="address"
                      label={t('address')}
                      value={address}
                      onChange={this.handleInputChange}
                      placeholder={t('address')}
                      type="text"
                      controlClasses="is-expanded"
                      disabled={edit}
                    />
                  </div>
                  {(profile.role === 'buyer' || profile.role === 'seller') &&
                  <div className="column is-two-fifths">
                    <TextInput
                      name="uniqueCode"
                      label={t('uniqueCode')}
                      value={cod !== null ? cod : ''}
                      onChange={this.handleInputChange}
                      placeholder={t('uniqueCode')}
                      type="text"
                      controlClasses="is-expanded"
                      disabled={edit}
                    />
                  </div>
                  }
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="email"
                      label={t('email')}
                      value={email}
                      onChange={this.handleInputChange}
                      placeholder={t('email')}
                      type="email"
                      controlClasses="is-expanded"
                      disabled={edit}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <Link
                      to="/account-profile"
                      onClick={() => {
                        onToggle({
                          isEdit: true,
                        });
                      }}
                    >
                      {t('changePassword')}
                    </Link>
                  </div>
                </div>
              </div>
              <br />
            </Panel>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

AccountProfile.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  passwordResetLogin: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  documentType,
  auth,
}) => ({
  documentType,
  auth,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...leadActions,
    ...authActions,
  }),
  withNamespaces(),
)(AccountProfile);
