import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { customIcon } from '../../../../util';

const AccountRow = (props) => {
  const {
    t,
    adjusted_amount,
    due_date,
    sender: { idPerson },
    receiverStatus: { description },
    receiver_status_id,
    openDetail,
  } = props;
  const dateFormat = new Date(due_date * 1000);
  return (
    <tr>
      <td>
        {new Intl.DateTimeFormat('es-AR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(dateFormat)}
      </td>
      <td>
        {`$ ${adjusted_amount}`}
      </td>
      <td>
        {`${idPerson.name}`}
      </td>
      <td>
        {`${description}`}
      </td>
      <td>
        {receiver_status_id === 'PEJ' && (
          <Link
            className="button is-success is-inverted"
            to="/customers"
            onClick={openDetail}
          >
            <span className="mr-8px">
              {customIcon('FaRegClock')}
            </span>
            {t('advance')}
          </Link>
        )}
      </td>
    </tr>
  );
};

AccountRow.propTypes = {
  t: PropTypes.func.isRequired,
  adjusted_amount: PropTypes.number.isRequired,
  due_date: PropTypes.string.isRequired,
  sender: PropTypes.shape({ idPerson: PropTypes.string.isRequired }).isRequired,
  receiverStatus: PropTypes.shape({ description: PropTypes.string.isRequired }).isRequired,
  receiver_status_id: PropTypes.string.isRequired,
  openDetail: PropTypes.string.isRequired,
};

export default withNamespaces()(AccountRow);
