import React from 'react';
import './styles.scss';
import BankLogo from '../../../../resources/images/assets/bankLogo.png';

const Footer = (props) => {
  const d = new Date();
  const n = d.getFullYear();
  const { disclaimer } = props;
  return (
    <footer>
      <div className="footer-bank">
        <img src={BankLogo} alt="Bank logo" />
        <p>
          Banco Guayaquil - Todos los derechos reservados, una solución de crédito por medio de Amex Business Link™ 2020 - Teléfono 3730100 - csf@bancoguayaquil.com
        </p>
      </div>
      <div className="footer-amex">
        <p>
          {disclaimer}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
