import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Aside, Dropdown, Footer } from './components';
import { authActions } from '../../state/ducks/auth';
import { RouteWithSubRoutes } from '../../config/routes';
import './styles.scss';

const leadNumber = (list) => {
  if (Object.is(list)) {
    if (Object.keys(list).length > 0) {
      return 1;
    }
    return 0;
  }
  if (Array.isArray(list)) {
    return list.length;
  }
  return 0;
};

const Layout = (props) => {
  const {
    auth,
    auth: {
      user: {
        b2b: { type_user, label_type_user, paths, admin_name, roles },
        name,
        lastname,
        name_abbreviation,
        colorAvatar,
        profile,
        profile: { role: currentRole }
      },
      language: defaultLanguage,
    },
    logout,
    location,
    routes,
    transactions: { pending_invoices, reject_invoices },
    lead: { list: leadList },
    location: { pathname },
    t,
    i18n,
  } = props;

  const [language, setLanguage] = useState(defaultLanguage);

  const updateUser = (index) => {
    const {
      auth: {
        user: {
          b2b: { roles },
          profile: { user_id },
        },
      },
      changeRole,
      history,
    } = props;

    const data = {
      user_id,
      new_role: roles[index].id,
    };
    changeRole({
      data,
      callback: () => {
        history.push('/recover-password');
      },
    });
  };

  return (
    <div className="Layout">
      <ToastContainer/>
      <div className="be-wrapper">
        <Dropdown
          logout={logout}
          name={name}
          lastName={lastname}
          adminName={admin_name}
          shortName={name_abbreviation}
          colorAvatar={colorAvatar}
          typeUser={type_user}
          labelTypeUser={profile ? profile.label_role === 'acquiring_query' || profile.label_role === 'issuing_query' ? 'Consultor' : profile.label_role : label_type_user}
          //mailboxNumber={type_user=='seller' ? reject_invoices : type_user === 'buyer' ? pending_invoices : leadNumber(leadList)}
          //mailboxRoute={type_user === 'seller' ? '/' + 'reject-invoices': type_user === 'buyer' ? '/review-invoices' : '/lead-list'}
          mailboxNumber={type_user === 'buyer' ? pending_invoices : leadNumber(leadList)}
          mailboxRoute={type_user === 'buyer' ? '/review-invoices' : '/lead-list'}
          t={t}
          i18n={i18n}
          language={language}
          setLanguage={setLanguage}
          roles={roles}
          currentRole={currentRole}
          pathname={pathname}
          updateUser={updateUser}
        />
        {
          pathname !== '/select-role' &&
            <Aside
              location={location}
              auth={auth}
            />
        }
        <div className="be-content">
          <div className="main-content container is-fluid">
            <Switch>
              {routes.map(
                route => paths.some(path => path.includes(route.path)) &&
                  <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
              <Redirect to="/dashboard"/>
            </Switch>
          </div>
        </div>
      </div>
      <Footer disclaimer={t('disclaimer')}/>
    </div>
  );
};

Layout.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape({}).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool.isRequired,
  }).isRequired).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  lead: PropTypes.shape(Object).isRequired,
  logout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  changeRole: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions, lead }) => ({
  auth,
  transactions,
  lead,
});

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(Layout);
