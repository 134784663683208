import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchItems = createAction(types.FETCH_ITEMS);
const updateItems = createAction(types.UPDATE_ITEMS);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchItems,
  updateItems,
};
