import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { providersActions } from '../../state/ducks/providers';
import { roleActions } from '../../state/ducks/role';
import { customIcon } from '../../util';

class CustomerCode extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        codes: [],
    });

    componentDidMount() {

        this.updateCodes();
    }

    updateCodes = () => {
        const {
            auth: { user: { user_id, b2b: { roles } } },
            fetchRoles,
            providerCommerceCode
        } = this.props;

        if (roles[0].owner_id !== null) {
            let idSeller;
            fetchRoles({
                params: {
                    type: 'external',
                    name: 'seller'
                },
                callback: (response) => {
                    idSeller = (response.map(
                      (documentTypeMap, index) => ({
                          ...documentTypeMap,
                          index
                      })
                    )
                      .filter(documentTypeFil => documentTypeFil.name
                        === 'seller')[0].id);
                    providerCommerceCode({
                        params: {
                            user: user_id,
                            role: idSeller,
                        },
                        callback: (response) => {
                            this.setState({ codes: response.commerce_codes });
                        },
                    });
                }
            });
        } else {
            providerCommerceCode({
                params: {
                    user: user_id,
                    role: roles[0].id,
                },
                callback: (response) => {
                    this.setState({ codes: response.commerce_codes });
                },
            });
        }
    };

    resetState = () => {
        this.setState(this.getInitialState());
    };

    getGridSettings = () => {
        const {
            t,
        } = this.props;

        const { codes } = this.state;

        let columns;
        let data;
        let title = null;

        columns = [
            {
                expander: true,
                show: false,
            },
            {
                id: 'commerceCode', // Required because our accessor is not a string
                Header: t('commerceCode'),
                accessor: d => `${d.commerce_code}`,
            },
            {
              id: 'description', // Required because our accessor is not a string
                Header: t('description'),
                accessor: d => `${d.description}`,
            },
        ];
        data = codes;
        
        return {
            columns,
            data,
            title,
        };
    }

    render() {
        const {
            t,
            role: { loading: roleLoading },
            providers: { loading: providerLoading },
        } = this.props;
        const { columns, data } = this.getGridSettings();
        const loading = roleLoading || providerLoading;
        return (
          <div>
              {loading && <Loading/>}
              <Panel headingText={t('affiliationCode')}>
                  <div className="column">
                      <button
                        className="button is-info"
                        style={{ marginBottom: '1em' }}
                        onClick={() => {
                            this.updateCodes();
                        }}
                      >
                    <span className="icon is-small">
                        {customIcon('FaSyncAlt')}
                    </span>
                          <p>{t('updateInfo')}</p>
                      </button>
                      <TableSimple data={data} columns={columns}/>
                  </div>
              </Panel>
          </div>
        );
    }

}

const mapStateToProps = ({ auth, providers, role, }) => (
  {
      auth,
      providers,
      role,
  }
);

export default compose(
    connect(mapStateToProps, {
        ...providersActions,
        ...authActions,
        ...roleActions,
    }),
    withNamespaces(),
)(CustomerCode);  