import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const saveClients = createAction(types.SAVE_CLIENTS);
const getClients = createAction(types.GET_CLIENTS);
const getClientsConditions = createAction(types.GET_CLIENTS_CONDITIONS);
const approveInvoice = createAction(types.APPROVE_INVOICE);
const deferInvoice = createAction(types.DEFER_INVOICE);
const setClientsConditions = createAction(types.SET_CLIENTS_CONDITIONS);
const getClient = createAction(types.GET_CLIENT);
const editUser = createAction(types.EDIT_USER);
const setClient = createAction(types.SET_CLIENT);
const searchUser = createAction(types.SEARCH_USER);
const addRoleUser = createAction(types.ADD_ROLE_USER);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  saveClients,
  showMessage,
  getClients,
  getClientsConditions,
  approveInvoice,
  deferInvoice,
  setClientsConditions,
  getClient,
  editUser,
  setClient,
  searchUser,
  addRoleUser,
};
