/**
 * Created by Nelson on 29/10/18.
 */

import React from 'react';
import { withNamespaces } from 'react-i18next';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  Form,
  Panel,
  SelectSingle,
  TextInput,
} from '../../../../components';

const TransferForm = (props) => {
  const {
    t,
    onToggle,
    disabled,
    formData,
    balance,
    handleInputChange,
    contacts,
    chargeAmount,
    total,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('transfer')}>
          <Form
            submitText={t('continue')}
            onSubmit={(event) => {
              event.preventDefault();
              onToggle(formData);
            }}
            buttonClasses="margin-right-5"
            disabled={disabled}
          >
            <div className="field is-horizontal">
              <div className="field-body">
                <TextInput
                  name="originAccount"
                  value={`${t('virtualAccount')} ${balance}`}
                  onChange={handleInputChange}
                  type="text"
                  label={t('originAccount')}
                  disabled
                  controlClasses="has-icons-left"
                  icon="FaRegEnvelope"
                  iconPosition="is-left"
                />
                <SelectSingle
                  name="destination_phone_number"
                  label={t('destinationAccount')}
                  placeholder="Cuentas el callao"
                  onChange={handleInputChange}
                  options={[
                    ...contacts.filter(contact => contact.is_core_user === '1')
                      .map(contact => ({
                        text: `${contact.contact_name} ${contact.contact_lastname}`,
                        value: contact.contact_phone,
                      })),
                    {
                      text: '-----------------------------',
                      value: '',
                      disabled,
                    },
                    ...contacts.filter(contact => contact.is_core_user === '0')
                      .map(contact => ({
                        text: `${contact.contact_name} ${contact.contact_lastname}`,
                        value: contact.contact_phone,
                      })),
                  ]}
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="FaRegUser"
                  iconPosition="is-left"
                />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <TextInput
                  name="amount"
                  label={t('amount')}
                  placeholder="150"
                  onChange={handleInputChange}
                  type="number"
                  disabled={false}
                  controlClasses="is-expanded"
                  min="0.10"
                  step="0.01"
                />
                <TextInput
                  name="charge"
                  label={t('charge')}
                  value={chargeAmount}
                  placeholder="1.5"
                  type="number"
                  disabled
                  controlClasses="is-expanded"
                  min="0.10"
                  step="0.01"
                />
                <TextInput
                  name="total"
                  label={t('total')}
                  value={total}
                  placeholder="151.5"
                  type="number"
                  disabled
                  controlClasses="is-expanded"
                  min="0.10"
                  step="0.01"
                />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <TextInput
                  name="time"
                  label={t('date')}
                  value={moment()
                    .format('YYYY-MM-DD')}
                  onChange={handleInputChange}
                  type="text"
                  disabled
                  fieldClasses="is-expanded"
                  controlClasses="is-expanded"
                  icon=""
                  iconPosition=""
                />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <TextInput
                  name="detail"
                  label={t('concept')}
                  onChange={handleInputChange}
                  type="textarea"
                  controlClasses="is-expanded"
                />
              </div>
            </div>
          </Form>
        </Panel>
      </div>
    </div>
  );
};


TransferForm.propTypes = {
  t: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formData: PropTypes.shape({}).isRequired,
  balance: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object),
  chargeAmount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

TransferForm.defaultProps = {
  disabled: false,
  contacts: [],
};

export default withNamespaces()(TransferForm);
