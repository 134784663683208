import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const stats = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.stats,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_RELATED_USERS: {
      const { payload } = action;
      return {
        ...state,
        relatedUsers: payload,
      };
    }
    case types.UPDATE_STATS: {
      const {
        buyer_total, active_buyer_total, deferred_buyer_total, buyers,
        seller_total, active_seller_total, paid_seller_total, sellers,
        next_payment, total_debt_amount, total_owed_amount, graph_data, account_balance,
        pending_amount, paid_amount, financed_amount, paid_total, invoice_total, total_paid,
      } = action.payload;
      return {
        ...state,
        buyer_total,
        active_buyer_total,
        deferred_buyer_total,
        buyers,
        seller_total,
        active_seller_total,
        paid_seller_total,
        sellers,
        next_payment,
        total_debt_amount,
        total_owed_amount,
        graph_data,
        account_balance,
        pending_amount,
        paid_amount,
        financed_amount,
        paid_total,
        invoice_total,
        total_paid,
      };
    }
    default:
      return state;
  }
};

export default stats;
