import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Panel, TextInput, } from '../index';
import './styles.scss';

const InvoiceDetailForm = (props) => {
  const {
    t,
    handleInputChange,
    invoiceAmount,
    invoice_date,
    due_date,
    description,
    invoiceNumber,
    client,
    wf_status,
    sender,
    receiver,
    cardPayment,
    isEdit,
    message,
    cardMask,
    auth,
    days,
    cardCollectionDate,
    //payProviderDate,
    receiverStatusId,
    exempt_amount,
    tax_amount,
    commerceCode,
    descriptionCommerceCode,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('invoiceDetail')}>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third select-lead-require">
                <div className="width-select-lead-require">
                  <TextInput
                    name="invoice_date"
                    label={t('invoiceDate')}
                    value={invoice_date}
                    onChange={handleInputChange}
                    type="text"
                    fieldClasses="is-expanded"
                    controlClasses="is-expanded"
                    icon=""
                    iconPosition=""
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="column is-two-third">
                <TextInput
                  name="invoice_number"
                  type="text"
                  label={t('invoicesNumber')}
                  value={invoiceNumber}
                  onChange={handleInputChange}
                  inputClasses="has-text-right"
                  iconPosition=""
                  icon=""
                  disabled={isEdit}
                />
              </div>
              {/* <div className="column is-two-third">
                <TextInput
                  name="due_date"
                  type="text"
                  label={t('cardPaymentDate')}
                  value={due_date}
                  onChange={handleInputChange}
                  inputClasses="has-text-left"
                  iconPosition=""
                  icon=""
                  disabled={isEdit}
                />
              </div>*/}
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third select-lead-require">
                <div className="width-select-lead-require">
                  <TextInput
                    name="client"
                    label={sender ? t('provider') : t('customer')}
                    onChange={handleInputChange}
                    selectClasses="is-fullwidth"
                    controlClasses="has-icons-left"
                    icon="FaRegUser"
                    iconPosition="is-left"
                    value={client}
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="column is-two-third">
                <TextInput
                  name="invoice_description"
                  label={t('description')}
                  value={description}
                  onChange={handleInputChange}
                  placeholder=""
                  fieldClasses="is-expanded"
                  controlClasses="is-expanded"
                  disabled={isEdit}
                />
              </div>
            </div>
          </div>
          {receiver && (
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <TextInput
                      name="commerceCode"
                      label={t('merchantAffiliateCode')}
                      onChange={handleInputChange}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="FaStore"
                      iconPosition="is-left"
                      value={commerceCode}
                      disabled={isEdit}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="descriptionCommerceCode"
                    label={t('description')}
                    value={descriptionCommerceCode}
                    onChange={handleInputChange}
                    placeholder=""
                    fieldClasses="is-expanded"
                    controlClasses="is-expanded"
                    disabled={isEdit}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third select-lead-require">
                <div className="width-select-lead-require">
                  <TextInput
                    name="due_date"
                    label={t('chargeDate')}
                    value={due_date}
                    onChange={handleInputChange}
                    type="text"
                    fieldClasses="is-expanded"
                    controlClasses="is-expanded"
                    icon=""
                    iconPosition=""
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="column">
                <TextInput
                  name="days"
                  label={t('arrangedDays')}
                  value={days}
                  onChange={handleInputChange}
                  type="text"
                  inputClasses="has-text-right"
                  fieldClasses="is-expanded"
                  controlClasses="is-expanded"
                  icon=""
                  iconPosition=""
                  disabled={isEdit}
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third select-lead-require">
                <div className="width-select-lead-require">
                  <TextInput
                    name="exempt_amount"
                    label={t('exemptAmount')}
                    value={exempt_amount}
                    onChange={handleInputChange}
                    type="text"
                    inputClasses="has-text-right"
                    fieldClasses="is-expanded"
                    controlClasses="is-expanded"
                    icon=""
                    iconPosition=""
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="column is-one-third select-lead-require">
                <div className="width-select-lead-require">
                  <TextInput
                    name="tax_amount"
                    label={t('taxAmount')}
                    value={tax_amount}
                    onChange={handleInputChange}
                    type="text"
                    inputClasses="has-text-right"
                    fieldClasses="is-expanded"
                    controlClasses="is-expanded"
                    icon=""
                    iconPosition=""
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="column is-two-third">
                <TextInput
                  name="invoiceAmount"
                  label={t('amountTotal')}
                  onChange={handleInputChange}
                  value={invoiceAmount}
                  inputClasses="has-text-right"
                  controlClasses="has-icons-right"
                  icon="FaDollarSign"
                  iconPosition="is-right"
                  disabled={isEdit}
                />
              </div>
            </div>
          </div>
          {/*          {
            payProviderDate != null && receiver
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('datePayment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {payProviderDate}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }*/}
          {
            cardCollectionDate != null && sender
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('cardPaymentDate')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {cardCollectionDate}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }
          {
            message != null
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('message')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {message}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }
          {
            wf_status.length > 0
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('flowApproval')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    {
                      sender &&
                      (
                        wf_status.map((flow, index) => (
                          <li key={flow.id} style={{ marginLeft: '35px' }}>
                            <p>
                              <label>{flow.actual ? t('present') + ':' : t('transferState') + ':'}</label>
                              &nbsp;
                              {flow.actual || (flow.state === 'complete' ? 'Completado' : flow.state)}
                            </p>
                            <p>
                              <label>{t('inCharge') + ':'}</label>
                              &nbsp;
                              {flow.role || flow.responsable}
                            </p>
                            <p>
                              <label>Status:</label>
                              &nbsp;
                              {flow.status}
                            </p>
                            <br/>
                          </li>
                        ))
                      )
                    } {
                    receiver && (
                      <li style={{ marginLeft: '35px' }}>
                        <p>
                          {wf_status === 'complete' ? 'Completado' : wf_status}
                        </p>
                      </li>
                    )
                  }
                  </ul>
                </div>
              </div>
            )
          }
          {
            cardPayment.length > 0 && sender
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('payment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    {
                        cardPayment.map((card, index) => (
                          <li key={card.number} style={{ marginLeft: '35px' }}>
                            {
                              sender && (
                                <p>
                                  <label>{t('alias') + ':'}</label>
                                  &nbsp;
                                  {cardMask}
                                </p>
                              )
                            }
                            {
                              auth !== '' &&
                              (
                                <p>
                                  <label>{t('authorization') + ':'}</label>
                                  &nbsp;
                                  {auth}
                                </p>
                              )
                            }
                            {
                              sender &&
                              (
                                <p>
                                  <label>{t('cort') + ':'}</label>
                                  &nbsp;
                                  {card.collection_date}
                                </p>
                              )
                            }
                          </li>
                        ))

                    }
                  </ul>
                </div>
              </div>
            )
          }
          {
            auth !== '' && receiver && receiverStatusId !== 'PAP' && receiverStatusId !== 'REC'
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('payment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      {
                        auth !== '' &&
                        (
                          <p>
                            <label>{t('authorization') + ':'}</label>
                            &nbsp;
                            {auth}
                          </p>
                        )
                      }
                    </li>
                  </ul>
                </div>
              </div>
            )
          }
          <br/>
        </Panel>
      </div>
    </div>
  );
};

InvoiceDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  isEdit: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  wf_status: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.instanceOf(Date).isRequired,
  due_date: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  client: PropTypes.string,
  sender: PropTypes.bool,
  receiver: PropTypes.bool,
  cardPayment: PropTypes.array,
  message: PropTypes.string,
  cardMask: PropTypes.string,
  auth: PropTypes.string,
  cardCollectionDate: PropTypes.string,
};

InvoiceDetailForm.defaultProps = {
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  invoiceDate: '',
};

export default withNamespaces()(InvoiceDetailForm);
