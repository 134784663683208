const en = {
  translations: {
    accept: 'To accept',
    acceptTerms: 'You must agree to the terms terms and conditions', //
    account: 'Account',
    accountBalance: 'Account balance',
    accountPlural: 'Accounts',
    accumulatedAmount: 'Daily accumulated amount',
    acquiring_bank: 'Acquiring Bank',
    action: 'Action',
    active: 'Active',
    activate: 'Activate',
    addComment: 'Add a comment ...',
    addCondition: ' Add condition',
    addFinanceProductSuccess: 'Financing product created successfully',
    addFinancingProducts: 'Agregar Productos de financiamiento',
    additionalInformation: 'Additional Information',
    address: 'Address',
    adjusted_amount: 'Adjusted amount',
    admin: 'Manage',
    adminName: 'Name',
    adminLastname: 'Last name',
    adminPosition: 'Position',
    adminRoutes: 'Admin routes',
    adminUser: 'Administator',
    authUser: 'Authorized user',
    advance: 'Anticipate',
    advanceCommercialConditions: 'Commercial advance conditions',
    advanceMount: 'Advance Amount',
    affiliateCode: 'Affiliate Code',
    agreementText: 'By using our platform you agree to our',
    alias: 'Alias',
    amount: 'Amount',
    amountTotal: 'Total amount',
    amountInstallments: 'Amount of fees',
    amountMoney: 'Amount of money',
    amountToCollect: 'Daily total receivable', //
    amountToGet: 'Amount to Receive',
    amountToPay: 'Amount to Pay',
    anticipatedAmount: 'Advance Amount',
    anualRate: 'Anual rate',
    approvalDate: 'Approval date',
    approve: 'Approve',
    approved: 'Approved',
    approvedInvoices: 'Approved I.',
    approvedInvoicesTitle: 'Approved Invoices',
    approvedMount: 'Amount approved',
    arrangedDays: 'Days agreed',
    assign: 'Assign',
    assignRoute: 'Assign routes',
    assignedRoutes: 'Assigned routes',
    assignRoleRoute: 'The role and route were assigned successfully', // ...
    assignRouteSuccess: 'The route was assigned successfully',
    authorization: 'Authorization',
    available: 'Available',
    avance: 'Advance',
    balance: 'Balance',
    bankList: 'Bank list',
    bankName: 'Bank name',
    banks: 'Banks',
    bankType: 'Bank type',
    baseConditions: 'Base Conditions (Invoice Payment)',
    bills: 'Invoices \nsent',
    billPayment: 'Bill payment',
    buyerCreateLead: 'Require client registration',
    buyerName: 'Client name',
    businessName: 'Business name',
    by_amount: 'By amount',
    by_hierarchy: 'By position hierarchy',
    cantDeleteRol: 'The role cannot be deleted because it has one or more routes assigned',
    cantDeleteRoute: 'The route cannot be deleted because it is assigned to one or more roles',
    language: 'Language',
    cardPaymentDate: 'Car payment date',
    charge: 'Charge',
    chargeDate: 'Charge date',
    clientName: 'Customer name',
    clientPaymentCondition: 'Determine the payment terms for this',
    codeArea: 'area code + phone number',
    commercialConditions: 'Commercial terms',
    condition: 'Condition',
    concept: 'Concept',
    configuration: 'Settings',
    confirm: 'Confirm',
    confirmInvoices: 'Confirm invoices',
    confirmPassword: 'Confirm password',
    confirmTransfer: 'Confirm transaction',
    continue: 'Continue',
    consult: 'Consult',
    consultTradeAffiliationCode: 'Consult trade affiliation code',
    cort: 'Cort',
    cortDate: 'Cutoff date',
    createBank: 'Create new bank',
    createBankSuccess: 'Bank {{name}} created successfully ',
    createClientSuccess: 'Customer {{name}} created successfully',
    createCustomer: 'Create \nCustomer',
    createInvoiceSuccess: 'Invoice N° {{invoice_number}} has been successfully registered',
    createLead: 'Require Registration',
    createUser: 'Create user',
    createUserSuccess: 'User {{name}} has been successfully registered',
    changePassword: 'Change Password',
    changePasswordSuccess: 'Password change success',
    createNewItem: 'Create new industry "{{inputValue}}"',
    createPaymentCondition: 'Create payment \ncondition',
    createPaymentConditionSuccess: 'Payment condition created successfully',
    createProvider: 'Create \nProvider',
    createProviderSuccess: '{{name}} provider created successfully',
    createProfile: 'Create Profile',
    createProfileSuccess: 'Profile {{name}} created successfully',
    createRol: 'Create rol',
    createRoleSuccess: 'Role {{rolName}} created successfully ',
    createRolSuccess: '{{rolName}} role created successfully',
    createFlowApproval: 'Create approval flow',
    createFlowApprovalSuccess: 'Approval flow, created successfully',
    creditPeriod: 'Credit period',
    customer: 'Client',
    customerInformation: 'Selected Customer Information',
    customerName: 'Customer name',
    customers: 'Customers',
    code: 'Code',
    daily: 'Daily',
    dashboard: 'Resume',
    date: 'Date',
    daysAdvance: 'Days to advance',
    daysDefer: 'Days to defer',
    daysNumber: 'Number of days',
    defer: 'Diferido',
    deferCommercialConditions: 'Commercial Deferral Conditions',
    deferMount: 'Deferred Amount',
    deferredAmount: 'Deferred Amount',
    deleteInvoice: 'Invoice was remove',
    deletePaymentCondition: 'Payment condition was remove',
    delete: 'Remove',
    deleteRol: 'Can\'t delete rol',
    deleteRoute: 'Can\'t delete route',
    demographicInformation: 'Identification data',
    deposit: 'Deposit',
    description: 'Description',
    descriptionOfTheMerchantAffiliateCode: 'Description of the merchant affiliate code',
    deactivate: 'Ddeactivate',
    destinationAccount: 'Destination account',
    detail: 'Detail',
    differ: 'To differ',
    discountAmount: 'Discount amount',
    discountRate: 'Discount rate',
    discountRateOutOfLimits: 'Discount rate out of bounds',
    document: 'Document',
    documentNumber: 'Document number',
    documentType: 'Document type',
    dontPose: 'Does not have',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    emptyAll: 'Be sure to fill in all the information',
    editPaymentConditionSuccess: 'Payment condition success edit',
    editClientSuccess: 'Client {{name}} success edit',
    editProviderSuccess: 'Provider {{name}} success edit',
    editProfileSuccess: 'Profile {{name}} success edit',
    editUserSuccess: 'User {{name}} success edit',
    emptyFields: 'Please complete all fields.',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    enter: 'Enter',
    enterCodeSms: 'Check and type the code\n that we send you through SMS',
    errors: 'Mistakes',
    errorDate: 'The date \'since\' has to be less',
    errorLoad: 'You must upload an extension file {{type}}',
    expiredInvoices: 'Approved / Paid',
    extract: 'Extract',
    extractAccount: 'Extract in B2B network',
    extractDetail1: 'Successfully generated approval code',
    extractDetail2: 'Amount to withdraw',
    extractDetail3: 'In',
    extractDetail4: 'Complete the extraction in a point of our Network',
    extractDetail5: 'With your phone number',
    extractDetail6: 'Provide your telephone number to the operator of the point to complete the extraction started',
    extractDetail7: 'QR Code',
    extractDetail8: 'Introduce yourself with the following code at the point of the network',
    financingProducts: 'Financing \nproducts',
    finish: 'Finish',
    fiscaldocument: 'Fiscal Document',
    flexibleConditions: 'Flexible Conditions (Financing)',
    flowApproval: 'Flow approval',
    flowsApproval: 'Flows approval',
    flowApprovalCheck: 'Default flow approval',
    forgotPassword: 'Forgot your password?',
    fullName: 'Name and last name',
    goBack: 'Back',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    lessThanOrEqualTo: 'Less than or equal to',
    greaterThanOrEqualTo: 'Greater than or equal to',
    fileSize: 'The maximum size allowed is {{size}}',
    fileSizeExceeded: 'File size exceeded',
    issuedInvoices: 'Issued invoices',
    import: 'Import',
    inCharge: 'In Charge',
    installmentNumber: 'Number of fees',
    installments: 'No. de cuotas',
    interest_rate: 'Mount rate',
    newProfile: 'New user',
    incorrectDate: 'You cannot enter a date before {{dateNow}}',
    incorrectEmail: '{{email}} it doesn\'t look like a valid email. Check that you have the correct format: ejemplo@mail.com',
    incorrectDays: 'You must enter a value greater than or equal to 0',
    interestRate: 'Interest rate',
    invalidAmount: 'Invalid amount',
    invalidRoleOrRoute: 'The role and route, can\'t be empty',
    invoiceCount: 'Number of Invoices',
    invoiceDate: 'Invoice date',
    invoice: 'Invoice',
    invoices: 'Invoices',
    invoicesApproved: 'Approved Invoices',
    invoicesToApproved: 'Invoices to approved',
    invoicesApprover: 'Invoices approver',
    invoicesApproverCheckbox: 'Allow invoices approvement  from inside or outside of approvement´s flow.',
    invoiceDetail: 'Invoice detail',
    invoicesError: 'Invoices with errors: {{ cant }} ',
    invoiceGroup: 'Invoice group',
    invoicesNumber: 'Invoice number',
    invoiceTotalAmount: 'Invoice Amount',
    invoiceSend: 'Shipping date',
    invoicePending: 'To emit',
    invoiceProvidersReceiver: 'Receipt invoice',
    invoiceManualResolution: 'Invoices that require manual resolution',
    invoiceMarkPaid: 'Invoice # {{invoiceNumber}} was marked as paid',
    invoicesSentSuccess: 'Invoices sent successfully',
    issuing_bank: 'Issuing bank',
    items: 'Industries',
    quantity: 'Quantity',
    lastDays: 'Last days',
    lastMovements: 'Last Movements',
    lead: 'Lead',
    leadList: 'Require list',
    listInvoices: 'In review',
    listPayments: 'Payment List',
    load: 'Load',
    loadCsv: 'Load CSV',
    loadZip: 'Load media ZIP',
    loading: 'Loading...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Load \nInvoice',
    loanPeriod: 'Loan period',
    logout: 'Logout',
    loginAsCustomer: 'Login as customer',
    loginAsProvider: 'Login as provider',
    logView: 'Log View',
    operationLog: 'Event Log',
    mainUser: 'Main User',
    maximumPaymentDate: 'Maximum payment date',
    manualResolution: 'Manual Resolution',
    markAsPaid: 'Mark as paid',
    message: 'Message',
    messageUserProcess: 'You do not currently have a configured approval flow type. Please contact your representative to activate this functionality.',
    merchantAffiliateCode: 'Merchant Affiliate Code',
    monthly: 'Monthly',
    name: 'Name',
    newBank: 'New bank',
    newPassword: 'New password',
    newPaymentCondition: 'New Payment Conditions',
    newRecharge: 'New Recharge',
    newRole: 'New role',
    newTransfer: 'New Transfer',
    next: 'Next',
    nextCollection: 'Next collection',
    nextDays: 'Next days',
    profileList: 'User List',
    nextPage: 'Next',
    nextPayment: 'Next payment',
    nInvoices: 'No. of Invoices',
    noCard: 'You don\'t have a prepaid Card', //
    noData: 'No information',
    noItemsAvailable: 'No industries available',
    nonMatchingPasswords: 'The passwords don\'t match.',
    noRowsFound: 'No information',
    noTransactions: 'This Card doesn\'t have movements',
    null: '',
    numberInstallments: 'Number of fees',
    of: 'of',
    operator: 'Condition',
    operationVolume: 'Volume of Operations',
    originAccount: 'Origin account',
    params: 'Params',
    page: 'pages',
    paid: 'Paid',
    paidAmount: 'Amount Paid',
    paidInvoices: 'Invoices paid',
    paidOut: 'Paid out',
    password: 'Password',
    payedAmounts: 'Daily total billed', //
    billsToPay: 'Daily total to be billed',
    payment: 'Payment',
    datePayment: 'Payment date',
    paymentDateInvoice: 'Bill collection date:',
    paymentConditionProvider: 'Commercial conditions for suppliers of',
    paymentConditionClients: 'Commercial conditions for customers of',
    paymentClient: 'The client pyment is',
    paymentCondition: 'Condition',
    paymentDate: 'Payment date',
    paymentDateTC: 'TC charge date',
    paymentDeadline: 'Payment deadline',
    paymentOrders: 'Payment orders',
    paymentsTransfers: 'Payments and Transfers',
    pending: 'Pending',
    pendingInvoices: 'Pending I.',
    percentage: 'Anual interest percentage',
    period: 'Period',
    prepaidCard: 'Prepaid Card',
    present: 'Present',
    previous: 'Previous',
    previous_page: 'Previous',
    printQR: 'Print QR',
    productName: 'Product name',
    profileName: 'Profile name',
    provider: 'Provider',
    providerPaymentCondition: 'Determine the payment terms for this provider',
    providerName: 'Provider\'s name',
    providers: 'Providers',
    query: 'Query',
    providerRUC: 'Provider RUC',
    rate: 'Porcentaje',
    receivedInvoices: 'Invoices Received',
    receiverDiscountShare: 'Provider payment percentage',
    receptionDate: 'Reception date',
    recharge: 'Recharge',
    recipient: 'Recipient',
    recoverPassword: 'Recover Password',
    insertYourEmailAddress: 'Insert your email address.',
    successEmail: 'An email has been sent to your email address.',
    enterYourNewPassword: 'Enter your new password',
    dateRange: 'Date range',
    registerBank: 'Register bank',
    registerRole: 'Register role',
    registerRoute: 'Register route',
    reject: 'To refuse',
    rejectedInvoices: 'Rejected I:',
    relatedUsers: 'Related Users',
    removeRouteSuccess: 'The route was removed succeffully',
    reportDate: 'Report Date',
    reports: 'Reports',
    requiredInstallments: 'The number of dues is invalid', //
    requiredName: 'The name is invalid', //
    requiredPeriod: 'The period is invalid', //
    requiredRate: 'The rate is invalid', //
    requiredTypeFinance: 'The financing type is invalid', //
    requireSuccess: '{{name}} required succeffully',
    resource: 'Resource',
    rejectionDate: 'Rejection date',
    rejectReason: 'Reject Reason',
    respJson: 'Response JSON',
    respXml: 'Response XML',
    response: 'Response',
    roleCantBeEmpty: 'The role can\'t be empty',
    roleList: 'Role List',
    roles: 'Roles',
    rol: 'Rol',
    roleType: 'Rol type',
    rolName: 'Rol name',
    rolType: 'Rol type',
    routeList: 'Route list',
    routes: 'Routes',
    rows: 'Rows',
    save: 'Save',
    Select: 'Select ...',
    selected: 'To select',
    SelectDocumentType: 'Select a document type',
    SelectBank: 'Select a bank',
    SelectOption: 'Select an option',
    selectOrTypeItems: 'Select or type industries ...',
    selectOrTypeRoutes: 'Select or type the route',
    selectOrTypeRol: 'Select or type the roles',
    selectRecipient: 'SelectSingle recipient',
    sellerCreateLead: 'Requires provider registration',
    sellerName: 'Provider name',
    send: 'Submit',
    sentInvoices: 'Invoices \nsent',
    sendInvoice: 'Invoice shipping date',
    sendInvoices: 'Invoices to be sent: {{ cant }}',
    sendSelectedInvoices: 'Send selected invoices',
    settings: 'Settings',
    seeHow: 'See how',
    shippingDate: 'Shipping date',
    showQR: 'Show QR code',
    since: 'Since',
    startCountingFrom: 'Days agreed from',
    summary: 'Summary',
    targetNumber: 'Target number',
    total: 'Total',
    terms: 'terms and conditions',
    totalAmount: 'Total import',
    totalBuyers: 'Total Paying Companies',
    totalBuyersDeferred: 'Companies with Deferred Invoices',
    totalBuyersInvoiced: 'Companies with Approved Invoices',
    totalCommissionAmount: 'Total commission',
    totalCharged: 'Total charged {{month}}',
    totalDebtAmount: 'Total amount to pay',
    totalOwedAmount: 'Total amount receivable',
    totalPay: 'Total to pay',
    totalSellers: 'Affiliated Providers',
    totalSellersCharged: 'Providers with Approved Invoices',
    totalSellersInvoiced: 'Suppliers with Pending Invoices',
    totalToApprove: 'Total to approve',
    totalToReceiver: 'Total to receiver',
    transactionType: 'Transaction type',
    transfer: 'Transfer',
    transferCharge: 'Operation charge',
    transferDetail2: 'Voucher',
    transferDetail3: 'Send to',
    transferDetail4: 'Amount send',
    transferDetail5: 'Discoundted total amount',
    transferDetail: 'Succesfull Transfer',
    transferState: 'State',
    transferType: 'Transaction type',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Type of approval flow',
    typeOfEmitFlow: 'Emission flow type',
    underReview: 'In Review',
    underReviewInvoices: 'Invoices Under Review',
    until: 'Until',
    uniqueCode: 'Unique code',
    updateBank: 'Update bank',
    updateInformation: 'Update information',
    uploadedFiles: 'Invoices processed successfully',
    username: 'Username',
    view: 'Watch',
    virtualAccount: 'Virtual account',
    yearly: 'Annual',
    transactions: 'Transactions',
    totalReceiver: 'Total to receiver',
    providersReceiver: 'Providers to receive',
    clientsPay: 'Customers payable',
    clients: 'Clients',
    selectedBank: 'Bank',
    uploadInvoice: 'Upload invoice',
    loadInvoiceData: 'Load invoice Data',
    naoReter: 'Not retain IR (advertising, publicity, commissions and correctje.)',
    invoiceApprovedSuccess: 'Invoice approved successfully',
    invoicesApprovedSuccess: 'Invoice(s) approved successfully',
    invoiceRejectedSuccess: 'Invoice rejected successfully',
    invoicesRejectedSuccess: 'Invoice(s) rejected successfully',
    actionNotAllowed: 'Action not allowed',
    disable: 'To disable',
    status: 'Status',
    clientPay: 'Client pay',
    referrer: 'Referrer',
    buyer: 'Client',
    limit: 'Limit',
    limitAmount: 'Limit amount',
    new: 'New',
    paymentMethod: 'Payment method',
    target: 'Target',
    line: 'Line',
    defaultFlow: 'Default flow',
    condition_amount: 'Amount',
    invoiceNumberValidate: 'The format must be xxx-xxx-xxxxxxxxx',
    amountValidate: 'The amount must be positive',
    cantCreateTransaction: 'The invoice number already exists',
    penddingInvoices: 'You have {{pending_invoices}} pendding invoice without approve',
    rejectInvoices: 'You have {{reject_invoices}} invoice(s) rejected',
    emitInvoices: 'You have {{emit_invoices}} invoice(s) without emit',
    search: 'Search',
    operationRank: 'Range of operations (from)',
    maxRank: '(to)',
    support: 'Support',
    main_bank: 'Bank Main User',
    user_list: 'User list',
    edit_user: 'Edit User',
    acquiring_query: 'Acquiring Consultation Bank',
    issuing_query: 'Issuing Consultation Bank',
    enable: 'Enable',
    transactionReport: 'Transaction report',
    paymentDateRange: 'Bill collection date range',
    paymentDateRangeClient: 'Bill payment date range',
    identificationNumber: 'Identification number',
    time: 'Hour',
    event: 'Event',
    info: 'information',
    infoInvoiceSuccess: '{{cant}} selected invoices to send',
    infoInvoiceError: '{{cant}} invoices with errors',
    invoiceErrorInfo: '(Invoices with errors will not be taken into account for shipping)',
    questionInfo: 'Are you sure you want to send {{cant}} invoices?',
    infoInvoices: 'Invoice information',
    infoError: 'Error information',
    infoLog: 'Event information',
    editFlowApprovalSuccess: 'Approval flow edited successfully',
    emissionFlow: 'Emission Flow',
    higherHierarchy: 'Higher hierarchy',
    highestHierarchyUser: 'Highest hierarchy user',
    yes: 'Yes',
    no: 'No',
    owner_role: "Owner Role",
    taxAmount: 'Subtotal with VAT',
    taxAmountTable: 'Sub. with VAT',
    exemptAmount: 'Sub. excluding VAT',
    ccChargeDate: 'CC charge date',
    nextDayConditions: '*Conditions apply: 24 hours after payment is approved, except weekends and holidays, consider the first business day.',
    vendorMerchantAffiliateCodes: 'Vendor Merchant Affiliate Codes: {{provider}}',
    affiliationCode: 'Merchant Affiliate Codes',
    buyerCode: 'Client commerce code',
    seller: 'Provider',
    sellerCode: 'Provider commerce code',
    welcomeToB2b: 'Welcome to B2B',
    byAmount: 'por un monto de',
    financialCost: 'Financial cost',
    percentageChargeForService: 'Percentage charge for service borne by the provider',
    serviceInChargeOfTheClient: 'Percentage charge for service in charge of the client',
    supplierPaymentPercentage: '% payment provider',
    customerPaymentPercentage: '% customer payment',
    updateFinancialCost: 'Update % of financial cost',
    notHaveRelatedClients: 'They do not have related users',
    negotiatedFinancialCost: 'Negotiated financial cost',
    reconciliationReport: 'Reconciliation report',
    disclaimer: 'American Express® and Amex Business Link™ are trademarks of American Express.',
    '/create-provider': 'Create providers',
    '/create-customer': 'Create customers',
    '/dashboard': 'Main screen',
    '/load-invoice': 'Load invoices',
    '/customers': 'List, edit or disable clients',
    // '/invoices': 'List, edit, approve or reject invoices without approving',
    '/expired-invoices': 'List of expired invoices',
    '/related-users': 'Related users',
    '/register-bank': 'Create banks',
    '/financing-products': 'Finance products',
    '/bank-list': 'List, edit or disable banks',
    '/register-role': 'Create roles',
    '/role-list': 'List, edit and delete roles',
    '/register-route': 'Create routes',
    '/route-list': 'List, edit and delete routes',
    '/register-financing-products': 'Create a financing product',
    '/admin-routes': 'Assign or remove routes to roles',
    '/review-invoices': 'List, edit, approve or reject invoices without approving',
    '/approved-invoices': 'List, advance or defer approved invoices',
    '/paid-invoices': 'List paid invoices',
    '/create-lead': 'Require customers or providers',
    '/lead-list': 'List, approve or reject required',
    '/reports': 'Export invoices to excel by customer or providers according to the user logged in',
    '/create-payment-condition': 'Create payment condition',
    '/transactions': 'Bank generated invoices',
    '/create-profile': 'Create Profiles',
    '/profile-list': 'List, edit or delete profiles',
    '/create-approval-flow': 'Create invoice approval flow',
    '/account-profile': 'Profile account',
    '/invoices': 'Invoices',
    '/reject-invoices': 'Rejected Invoices',
    '/invoice-detail': 'Invoice Detail',
    '/approval-flow-list': 'List, edit approval flows',
    '/edit-approval-flow': 'Edit approval flow',
    '/select-role': 'Select type of role to enter B2B',
    '/related-users-client': 'Related Users',
    '/user-commerce-code': 'Merchant Affiliate Codes',
    '/list-invoice': 'Invoice list',
    '/load-invoice-group': 'Upload invoice group',
    //'/pending-invoices': '',
  },
};

export default en;
