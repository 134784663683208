const START_FETCH = 'item/START_FETCH';
const END_FETCH = 'item/END_FETCH';
const API_CALL = 'item/API_CALL';
const MESSAGE = 'item/MESSAGE';
const FETCH_ITEMS = 'item/FETCH_ITEMS';
const UPDATE_ITEMS = 'item/UPDATE_ITEMS';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_ITEMS,
  UPDATE_ITEMS,
};
