import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';


class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    address: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    provider: '',
    startCountingFrom: 1,
    baseProvider: null,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminPosition: '',
    adminDocumentType: -1,
    adminDocumentNumber: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    edit: false,
    hidden: false,
  });

  componentDidMount() {
    const {
      fetchDocumentTypes,
      fetchItems,
      getProviders,
      fetchRoles,
      router: { location: { pathname } },
      documentType: { list: documentTypeList },
    } = this.props;
    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchItems();
    getProviders({});
    fetchRoles({
      params: {
        type: 'external',
        name: 'buyer'
      },
    });

    if (pathname === '/create-customer') {
      this.setState({
        hidden: true,
      });
    }
    if (pathname === '/edit-customer') this.setState(this.arrayCustomer);
  }

  arrayCustomer = () => {
    const {
      clients: { list: clientList },
      documentType: { list: documentTypeList },
    } = this.props;
    let documentType = -1;
    if (clientList.buyer[0].idPerson.idDocumentType !== null) {
      /*if (clientList.buyer[0].idPerson.idDocumentType.document_type === 'CI' ||
        clientList.buyer[0].idPerson.idDocumentType.document_type === 'RUC' ||
        clientList.buyer[0].idPerson.idDocumentType.document_type === 'Pasaporte') {*/
        documentType = clientList.buyer[0].idPerson.idDocumentType === null ? -1 :
        //documentTypeList.findIndex(document => document.id = clientList.buyer[0].idPerson.idDocumentType.id);
          documentTypeList.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === clientList.buyer[0].idPerson.idDocumentType.document_type)[0].index;
      //}
    }
    const item = [];
    for(let i = 0; i < clientList.items.length; i++ ){
      console.dir(clientList.items[i].id);
      item.push({id: clientList.items[i].id, description: clientList.items[i].description});
    }
    const period = clientList.buyer[0].paymentConditionAsBuyer[0] !== null ?
      (this.getDays(clientList.buyer[0].paymentConditionAsBuyer[0].days)) : -1;

    return {
      name: clientList.buyer[0].idPerson.name,
      documentType,
      documentNumber: clientList.buyer[0].idPerson.document_number,
      address: clientList.buyer[0].address,
      adminName: clientList.buyer[0].admin_name,
      adminLastname: clientList.buyer[0].admin_lastname,
      adminDocumentNumber: clientList.buyer[0].admin_document_number,
      adminDocumentType: clientList.buyer[0].admin_document_type === 'CI' ||
      clientList.buyer[0].admin_document_type === 'Cédula' ? 0 : 1,
      adminPosition: clientList.buyer[0].admin_position,
      email: clientList.buyer[0].idPerson.idEmailActive.email,
      percentage: clientList.buyer[0].paymentConditionAsBuyer[0] !== null ?
        clientList.buyer[0].paymentConditionAsBuyer[0].percentage : 0,
      period,
      edit: true,
      items: item,
      hidden: true,
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-customer':
        return t('createCustomer');
      case '/edit-customer':
        return t('edit');
      default:
        return t('');
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'documentNumber') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else if (name !== 'documentNumber') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;
    const {
      t,
      showMessage,
    } = this.props;

    const value = type === 'checkbox' ? checked : targetValue;

    if (adminDocumentType === -1) {
      showMessage({
        message: t('SelectDocumentType'),
        config: TOAST_CONFIG.WARNING,
      });
    } else {
      if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
        let num = this.formatNumber(event.target.value);
        this.setState({
          [name]: (num),
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    }
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  validatePaymentCondition = () => {
    const {
      discountRate, provider,
    } = this.state;
    const { t, showMessage } = this.props;
    if (provider !== '' && discountRate > 0) {
      if (discountRate > 100) {
        showMessage({
          message: t('discountRateOutOfLimits'),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }
      return true;
    }
    if (provider === '' && discountRate === 0) {
      return false;
    }
    showMessage({
      message: t('completeComercialConditionOrEmptyAll'),
      config: TOAST_CONFIG.WARNING,
    });
  };

  createProvider = () => {
    const {
      createProvider,
      editUser,
      auth,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
      t,
      showMessage,
      createPaymentCondition,
      deleteLead,
      documentType: { list: documentTypesList },
      providers,
      fetchLeads,
      role: { list: roleList },
      router: { location: { pathname } },
      clients: { list: clientList },
      history,
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      address,
      percentage,
      period,
      installments,
      items,
      lead,
      lead_id,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      provider,
      receiverDiscountShare,
      baseDays,
    } = this.state;

    if (documentType === -1) {
      showMessage({
        message: t('SelectDocumentType'),
        config: TOAST_CONFIG.WARNING,
      });
      return;
    }

    const data = {
      name,
      document_type: documentTypesList[documentType].document_type,
      document_number: documentNumber,
      email,
      password,
      user_type: 'flecha',
      agency_user_type: 'business_admin',
      role_name: roleList[0].id,
      items,
      b2b: {
        bank_id: auth.user.b2b.id,
        admin: 0,
        issuing_bank: 0,
        acquiring_bank: 0,
        seller: 0,
        buyer: 1,
        address,
        admin_name: adminName,
        admin_lastname: adminLastname,
        admin_document_number: adminDocumentNumber,
        admin_document_type: adminDocumentType,
        admin_position: adminPosition,
      },
    };

    if(pathname === '/create-customer') {

      switch(data.b2b.admin_document_type){
        case 0:
          data.b2b.admin_document_type = 'CI';
        break;
        case 1:
          data.b2b.admin_document_type = "DNI";
        break;
        case 2:
          data.b2b.admin_document_type = "Pasaporte";
        break;
      }

      if (this.validateEmptyFields()) {
       // if (this.isEmail(email)) {
          createProvider({
            data,
            callback: (response) => {
              const { b2b: { id } } = response;
              /*const conditionData = {
                sender_id: id,
                receiver_id: auth.user.b2b.id,
                percentage,
                days: this.getDaysInv(period),
                installments,
                type: 'defer',
              };*/
              if (lead) {
                deleteLead({
                  params: {
                    id: lead_id,
                  },
                  callback: fetchLeads({
                    params: {
                      role_id: type_user === 'issuing_bank' ? 'buyer' : 'seller',
                    },
                  }),
                });
              }
              showMessage({
                message: t('createClientSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
              this.resetState();
              history.push('/dashboard');
              /* //Al crear Cliente en el banco emisor no tiene, condicion comercial
               createPaymentCondition({
                 data: conditionData,
                 callback: () => {
                   showMessage({
                     message: t('createClientSuccess', { name }),
                     config: TOAST_CONFIG.SUCCESS,
                   });
                   this.resetState();
                 },
               }); */
            },
          });
        /* } else {
           showMessage({
             message: t('incorrectEmail', { email }),
             config: TOAST_CONFIG.WARNING,
           });
           return;
         }*/
      } else {
        showMessage({
          message: t('emptyAll'),
          config: TOAST_CONFIG.WARNING,
        });
      }
    }

    if(pathname === '/edit-customer') {
      const data = {
        id_user: clientList.buyer[0].id,
        name,
        documentType: documentTypesList[documentType].id,
        documentNumber: documentNumber,
        email,
        items,
        address,
        admin_name: adminName,
        admin_lastname: adminLastname,
        admin_document_number: adminDocumentNumber,
        admin_document_type: adminDocumentType,
        admin_position: adminPosition,
        percentage,
        days: this.getDaysInv(period),
        type: 'B',
        //admin_document_number: adminDocumentNumber,
        //admin_document_type: adminDocumentType,
      };

      switch(data.admin_document_type){
        case 0:
          data.admin_document_type = 'CI';
        break;
        case 1:
          data.admin_document_type = "DNI";
        break;
        case 2:
          data.admin_document_type = "Pasaporte";
        break;
      }

      if (adminName === '' || adminPosition === '' || email === '') {
        showMessage({
          message: t('emptyAll'),
          config: TOAST_CONFIG.WARNING,
        });
      } else {
        //if (this.isEmail(email)) {
          editUser({
            data,
            callback: () => {
              history.push('/dashboard');
              this.resetState();
              showMessage({
                message: t('editClientSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
            },
          });
        /*} else {
          showMessage({
            message: t('incorrectEmail', { email }),
            config: TOAST_CONFIG.WARNING,
          });
          return;
        }*/
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      documentNumber,
      documentType,
      email,
      adminPosition,
      items,
    } = this.state;

    return !(
      adminName === '' || 
      adminLastname === '' || 
      adminDocumentType === -1 || 
      adminDocumentNumber === '' || 
      documentNumber === '' || 
      documentType === -1 || 
      email === ''|| 
      adminPosition === '' || 
      items.length < 0);
  };

  render() {
    const {
      t,
      providers: { list: providerList, loading: providerLoading },
      clients: { loading: clientLoading },
      documentType: { list: documentTypesList },
      item: { list: itemList },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      password,
      address,
      percentage,
      baseDays,
      items,
      documentType,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      period,
      edit,
      hidden,
    } = this.state;
    return (
      <div>
        {(providerLoading || clientLoading) && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <PersonForm
              t={t}
              title={this.getTitle()}
              onSubmit={this.createProvider}
              handleInputChange={this.handleInputChange}
              handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
              name={name}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              documentsEcuador={this.state.documentsEcuador}
              documentNumber={documentNumber}
              address={address}
              documentType={documentType}
              items={items}
              itemList={itemList}
              email={email}
              password={password}
              persons={providerList}
              baseDays={baseDays}
              percentage={percentage}
              discountRate={discountRate}
              client
              adminName={adminName}
              adminLastname={adminLastname}
              adminDocumentType={adminDocumentType}
              adminDocumentNumber={adminDocumentNumber}
              adminPosition={adminPosition}
              handlePercentageChange={this.handlePercentageChange}
              receiverDiscountShare={receiverDiscountShare}
              clientPayment={clientPayment}
              period={period}
              edit={edit}
              hidden={hidden}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  providers: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                           providers,
                           documentType,
                           auth,
                           item,
                           role,
                           router,
                           clients,
                         }) => ({
  providers,
  documentType,
  auth,
  item,
  role,
  router,
  clients,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...itemActions,
    ...leadActions,
    ...roleActions,
    ...clientsActions,
  }),
  withNamespaces(),
)(CreateCustomer);
