import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const addProviders = createAction(types.ADD_PROVIDER);
const fetchTransactions = createAction(types.FETCH_TRANSACTIONS);
const extractQr = createAction(types.EXTRACT);
const transfer = createAction(types.TRANSFER);
const updateTransactions = createAction(types.UPDATE_TRANSACTIONS);
const clear = createAction(types.CLEAR);
const authorizeTransaction = createAction(types.AUTHORIZE_TRANSACTION);
const checkBalance = createAction(types.CHECK_BALANCE);
const updateCharge = createAction(types.UPDATE_CHARGE);
const updateBalance = createAction(types.UPDATE_BALANCE);
const loadInvoice = createAction(types.LOAD_INVOICE);
const advanceInvoice = createAction(types.ADVANCE_INVOICE);
const createProvider = createAction(types.CREATE_PROVIDER);
const getProviders = createAction(types.GET_PROVIDERS);
const getProvidersConditions = createAction(types.GET_PROVIDERS_CONDITIONS);
const saveProviders = createAction(types.SAVE_PROVIDERS);
const editProvider = createAction(types.EDIT_PROVIDER);
const altaRequire = createAction(types.ALTA_REQUIRE);
const readInvoiceQr = createAction(types.QR_READER);
const setProvidersConditions = createAction(types.SET_PROVIDERS_CONDITIONS);
const getProvider = createAction(types.GET_PROVIDER);
const setProvider = createAction(types.SET_PROVIDER);
const loadInvoiceGroup = createAction(types.LOAD_INVOICE_GROUP);
const loadPreInvoice = createAction(types.LOAD_PRE_INVOICE);
const backPreInvoice = createAction(types.BACK_PRE_INVOICE);
const providerCommerceCode = createAction(types.PROVIDER_COMMERCE_CODE);
const getProviderCommerceCode = createAction(types.GET_PROVIDER_COMMERCE_CODE);
const getCommerceCode = createAction(types.GET_COMMERCE_CODE);
const getPaymentCommerceCode = createAction(types.GET_PAYMENT_COMMERCE_CODE);
const consultCommerceCode = createAction(types.CONSULT_COMMERCE_CODE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  addProviders,
  fetchTransactions,
  extractQr,
  transfer,
  updateTransactions,
  clear,
  showMessage,
  authorizeTransaction,
  checkBalance,
  updateCharge,
  updateBalance,
  loadInvoice,
  advanceInvoice,
  createProvider,
  getProviders,
  getProvidersConditions,
  saveProviders,
  editProvider,
  altaRequire,
  readInvoiceQr,
  setProvidersConditions,
  getProvider,
  setProvider,
  loadInvoiceGroup,
  loadPreInvoice,
  backPreInvoice,
  getProviderCommerceCode,
  providerCommerceCode,
  getCommerceCode,
  getPaymentCommerceCode,
  consultCommerceCode,
};
