import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, PaymentConditionForm, TableSimple, Toggle } from '../../components';
import { customIcon, getPath } from '../../util';
import { statsActions } from '../../state/ducks/stats';
import { authActions } from '../../state/ducks/auth';
import './styles.scss';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import Modal from '../../components/Modal';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { roleActions } from '../../state/ducks/role';
import { TOAST_CONFIG } from '../../config/constants';

class RelatedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    persons: '',
    client: null,
    provider: null,
    baseDays: 0,
    editable: false,
    startCountingFrom: 0,
    limitAmount: 0,
    band: false,
    clientEdit: '',
    codes: [],
    codeDescription: '',
    commerceCode: '',
    negotiatedFinancialCost: '',
  });

  componentDidMount() {
    const {
      auth: { user: { user_id, b2b: { type_user, roles } } },
      fetchRelatedUsers,
      getClients,
      getProviders,
      showMessage,
      t,
    } = this.props;

    //var role_id = roles.findIndex(r => type_user == r.name);

    fetchRelatedUsers({
      role: type_user,
      callback: (data) => {
        if ( !Array.isArray(data) && data.error !== false ) {
          showMessage({
            message: ( data.error ),
            config: TOAST_CONFIG.ERROR,
          });
        } else if ( Array.isArray(data) ) {
          showMessage({
            message: ( t('notHaveRelatedClients') ),
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    });

    /*fetchRelatedUsers({
      role: type_user,//roles[role_id].id,
    });*/
    getClients({});
    getProviders({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t,
      stats: { relatedUsers },
      auth: {
        user: {
          user_id, b2b: { type_user },
        },
      },
      getPaymentCondition,
      clients: { list: listClient },
      providers: { list: listProvider },
      router: { location: { pathname } },
      role: { list },
      providerCommerceCode,
    } = this.props;

    var role_id = '';
    for(var i = 0; i < list.length; i++){
      if(list[i].name === "seller"){
        role_id = list[i].id;
      }
    }

    switch (type_user) {
      case 'buyer':
      case 'seller':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('documentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d.user)} ${getPath(['idPerson', 'document_number'], d.user)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          {
            id: 'buyerCode',
            Header: t('buyerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.sender !== null ? `${d.commerce_codes.sender}` : '',
          },
          {
            id: 'sellerCode',
            Header: t('sellerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.receiver !== null ? `${d.commerce_codes.receiver}` : '',
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            headerClassName: 'wordwrap',
            accessor: d => d.user.address !== null ? `${d.user.address}` : '',
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.user.username}`,
          },
          {
            id: 'approvedInvoices', // Required because our accessor is not a string
            Header: t('approvedInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.apr_count,
          },
          {
            id: 'pendingInvoices', // Required because our accessor is not a string
            Header: t('pendingInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.pap_count,
          },
          {
            id: 'rejectedInvoices', // Required because our accessor is not a string
            Header: t('rejectedInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.rec_count,
          },
          {
            id: 'receiverDiscountShare', // Required because our accessor is not a string
            Header: t('supplierPaymentPercentage'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.condition.receiver_discount_share} %`,//`${d.conditions.receiver_discount_share * 100} %`,
          },
          {
            id: 'limitAmount', // Required because our accessor is not a string
            Header: t('customerPaymentPercentage'),
            headerClassName: 'wordwrap',
            width: 90,
            accessor: d => `${d.condition.discount_rate} %`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('commercialConditions'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.user.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted ml-8px"
                      to="/payment-conditions"
                      onClick={() => {
                        type_user === 'seller' ?
                          getPaymentCondition({
                            params: {
                              idCondition: cellInfo.original.condition.id,
                              person: cellInfo.original.user.id,
                              isClient: false,
                            },
                            callback: (response) => {
                              this.openModal(response, cellInfo.original.user.id, onToggle);
                            },
                          })
                          :
                          getPaymentCondition({
                            params: {
                              person: cellInfo.original.user.id,
                              isClient: true,
                              idCondition: cellInfo.original.condition.id,
                            },
                            callback: (response) => {
                              this.openModal(response, cellInfo.original.user.id, onToggle);
                            },
                          });
                      }}
                    >
                  <span className="icon" title={t('detail')}>
                    {customIcon('FaRegEye')}
                  </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = typeof(relatedUsers) !== 'undefined' ? relatedUsers.payment_conditions : []; //&& relatedUsers.length > 0
        title = type_user === 'seller' ? t('customers') : t('providers');
        break;
      case'admin_amex':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('identificationNumber'),
            accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            accessor: d => `${d.address}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => `${d.username}`,
          },
        ];
        data = pathname === '/clients' ? listClient : listProvider;
        title = pathname === '/clients' ? t('customers') : t('providers');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  openModal = (response, id, onToggle) => {
    const {
      stats: { relatedUsers },
      auth: {
        user: { b2b: { type_user } },
      },
    } = this.props;
    const { codes } = this.state;
    let client;
    if (type_user === 'seller') {
      // this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //clientEdit: ,
      //});
    } else if (type_user === 'buyer') {
      //this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //});
    }

    if(response.personCode != null){
      this.setState({
        commerceCode: response.personCode.commerce_code,
        codeDescription: response.personCode.description,
      });
    }else{
      this.setState({
        commerceCode: "",
        codeDescription: "",
      });
    }

    this.setState({
      id: id,
      clientEdit: relatedUsers.related_users[client].idPerson.name,
      baseDays: response.days,
      limitAmount: response.limit_amount !== null ? this.formatNumber(response.limit_amount) : 0,
      persons: relatedUsers.related_users,
      startCountingFrom: response.due_from_approval,
      percentageChargeForService: response.receiver_discount_share === '100.00' ? 0 :
        response.receiver_discount_share === '50.00' ? 1 : 2,
      clientPayment: 100 - response.receiver_discount_share,
      negotiatedFinancialCost: response.negotiated_financial_cost === '0.00' ? '' : response.negotiated_financial_cost,
      editable: true,
      band: true,
    });
    onToggle();
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    this.setState({
      [name]: value,
    });
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  updateCodes = () => {
    const {
      auth: { user: { user_id, b2b: { type_user, roles } } },
      fetchRoles,
      providerCommerceCode,
      getDiscountRate,
      fetchRelatedUsers,
      showMessage,
      t,
    } = this.props;

    fetchRelatedUsers({
      role: type_user,
      callback: (data) => {
        if ( !Array.isArray(data) && data.error !== false ) {
          showMessage({
            message: ( data.error ),
            config: TOAST_CONFIG.ERROR,
          });
        } else if ( Array.isArray(data) ) {
          showMessage({
            message: ( t('notHaveRelatedClients') ),
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    });
  };

  render() {
    const {
      t,
      stats: { loading: statsLoading },
      auth: { loading: authLoading, user: { b2b: { type_user } } },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const {
      client,
      provider,
      baseDays,
      editable,
      startCountingFrom,
      limitAmount,
      persons,
      band,
      clientEdit,
      codes,
      commerceCode,
      codeDescription,
      percentageChargeForService,
      clientPayment,
      negotiatedFinancialCost,
    } = this.state;
    const loading = statsLoading || authLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div>
              {loading && <Loading/>}
              <div className="dashboard">
                <div className="columns is-multiline">
                  <Fragment>
                    {
                      toggled
                      && (
                        <Modal className="condition-modal" onToggle={onToggle} band>
                          <PaymentConditionForm
                            t={t}
                            onSubmit={() => this.closeModal(onToggle)}
                            handleInputChange={this.handleInputChange}
                            handlePercentageChange={this.handlePercentageChange}
                            isClient={type_user !== 'seller'}
                            handleSelectChange={this.handleSelectChange}
                            persons={persons}
                            client={client}
                            provider={provider}
                            baseDays={baseDays}
                            //discountRate={discountRate}
                            //receiverDiscountShare={receiverDiscountShare}
                            //clientPayment={clientPayment}
                            startCountingFrom={startCountingFrom}
                            limitAmount={limitAmount}
                            clientEdit={clientEdit}
                            editable={editable}
                            hidden={true}
                            band={band}
                            codes={codes}
                            commerceCode={commerceCode}
                            codeDescription={codeDescription}
                            percentageChargeForService={percentageChargeForService}
                            clientPayment={clientPayment}
                            negotiatedFinancialCost={negotiatedFinancialCost}
                          />
                        </Modal>
                      )
                    }
                    {(
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <button
                            className="button is-info"
                            style={{ marginBottom: '1em' }}
                            onClick={() => {
                              this.updateCodes();
                            }}
                          >
                            <span className="icon is-small">
                              {customIcon('FaSyncAlt')}
                            </span>
                            <p>{t('updateFinancialCost')}</p>
                          </button>
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

RelatedUsers.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRelatedUsers: PropTypes.func.isRequired,
  getPaymentCondition: PropTypes.func.isRequired,
  stats: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  router: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ auth, clients, providers, stats, paymentConditions, router, role }) => (
  {
    auth,
    clients,
    providers,
    stats,
    paymentConditions,
    router,
    role,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...authActions, ...paymentConditionsActions, ...clientsActions,
    ...providersActions, ...roleActions,
  }),
  withNamespaces(),
)(RelatedUsers);
