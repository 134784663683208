/**
 * Created by Jonas on 02/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '..';
import { customIcon } from '../../util';

const PersonForm = ({
                      t,
                      onSubmit,
                      handleInputChange,
                      handleInputChangeAdminDocument,
                      handleSelectChange,
                      handleItemChange,
                      name,
                      documentTypesList,
                      documentNumber,
                      adminDocumentType,
                      adminDocumentNumber,
                      documentType,
                      address,
                      items,
                      itemList,
                      email,
                      password,
                      persons,
                      baseDays,
                      percentage,
                      discountRate,
                      client,
                      adminName,
                      adminLastname,
                      adminPosition,
                      receiverDiscountShare,
                      handlePercentageChange,
                      clientPayment,
                      title,
                      period,
                      edit,
                      hidden,
                      consultCode,
                      commerceCodes,
                      commerceCode,
                      descriptionCommerceCode,
                      handleCommerceChange,
                    }) => (
  <Panel headingText={title}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('demographicInformation')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column" style={{ display: (edit ? '' : 'none') }}>
            <TextInput
              name="name"
              label={t('businessName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="documentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentType}
              //isDisabled={hidden ? hidden : edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={13}
              //disabled={edit}
            />
          </div>
          <div className="column" style={{
            display: ((client || edit) ? 'none' : ''),
            marginTop: '22px'
          }}>
            <button
              type="button"
              className="button is-primary"
              onClick={consultCode}
            >
              <span className="mr-10px">
                {customIcon('FaSearch')}
              </span>
              &nbsp;&nbsp;
              {t('consultTradeAffiliationCode')}
            </button>
          </div>
        </div>
      </div>
      {(!client && !edit) && (
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column is-two-third">
              <SelectSingle
                name="commerceCode"
                label={t('descriptionOfTheMerchantAffiliateCode')}
                selectClasses="is-fullwidth"
                placeholder={t('Select')}
                onChange={handleCommerceChange}
                options={
                  [
                    ...commerceCodes.map((item, index) => ({
                      label: item.description,
                      value: index,
                    })),
                  ]}
                value={commerceCode}
              />
            </div>
            <div className="column is-one-third select-lead-require">
              <div className="width-select-lead-require">
                <TextInput
                  name="descriptionCommerceCode"
                  label={t('code')}
                  value={descriptionCommerceCode}
                  onChange={handleInputChange}
                  placeholder={t('description')}
                  type="text"
                  controlClasses="is-expanded"
                  title="Three letter country code"
                  disabled={true}
                  isRequired
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(!client && edit) && (
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column is-two-third">
              <TextInput
                name="descriptionCommerceCode"
                label={t('description')}
                value={descriptionCommerceCode}
                onChange={handleInputChange}
                placeholder={t('description')}
                fieldClasses="is-expanded"
                controlClasses="is-expanded"
                disabled={edit}
              />
            </div>
            <div className="column is-one-third select-lead-require">
              <div className="width-select-lead-require">
                <TextInput
                  name="commerceCode"
                  label={t('merchantAffiliateCode')}
                  placeholder={t('merchantAffiliateCode')}
                  onChange={handleInputChange}
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="FaStore"
                  iconPosition="is-left"
                  value={commerceCode}
                  disabled={edit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="field is-horizontal" style={{ display: (hidden ? 'none' : '') }}>
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="address"
              label={t('address')}
              value={address}
              onChange={handleInputChange}
              placeholder={t('address')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal"
           style={{ display: (client ? 'none' : hidden ? 'none' : '') }}>
        <div className="field-body columns">
          <div className="column">
            <CreatableMultiSelect
              name="items"
              label={t('items')}
              value={items}
              onChange={handleItemChange}
              placeholder={t('selectOrTypeItems')}
              noOptionsMessage={() => t('noItemsAvailable')}
              formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.id}
              getNewOptionData={(inputValue, optionLabel) => ({
                id: optionLabel,
                description: optionLabel,
              })
              }
              controlClasses="is-expanded"
              options={itemList}
            />
          </div>
          {/* {!client
            && (
            <div className="column">
              <br />
              <label htmlFor="noRetain" className="checkbox" style={{ width: '100%' }} />
              <input
                name="noRetain"
                value={noRetain}
                onChange={handleInputChange}
                type="checkbox"
              />
                {` ${t('naoReter')}`}
            </div>
            )} */}
        </div>
      </div>
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('authUser')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminName"
              label={t('adminName')}
              value={adminName}
              onChange={handleInputChange}
              placeholder={t('adminName')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column">
            <TextInput
              name="adminLastname"
              label={t('adminLastname')}
              value={adminLastname}
              onChange={handleInputChange}
              placeholder={t('adminLastname')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column">
            <TextInput
              name="adminPosition"
              label={t('adminPosition')}
              value={adminPosition}
              onChange={handleInputChange}
              placeholder={t('adminPosition')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column" style={{ display: (hidden ? 'none' : '') }}>
            <TextInput disabled={edit}
                       name="password"
                       label={t('password')}
                       value={password}
                       onChange={handleInputChange}
                       placeholder={t('password')}
                       type="password"
                       controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="adminDocumentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={
                [{
                  label: 'CI',
                  value: 0,
                }, {
                  label: 'Pasaporte',
                  value: 2,
                },]}
              value={adminDocumentType}
              //isDisabled={edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="adminDocumentNumber"
              label={t('documentNumber')}
              value={adminDocumentNumber}
              onChange={handleInputChangeAdminDocument}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={13}
              //disabled={edit}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal subtitle is-5 text-black"
           style={{ display: (hidden ? 'none' : '') }}>
        {t('commercialConditions')}
      </div>
      <div className="field is-horizontal subtitle is-4 text-black"
           style={{ display: (hidden ? 'none' : '') }}>
        {t('flexibleConditions')}
      </div>
      <div className="field is-horizontal" style={{ display: (hidden ? 'none' : '') }}>
        <div className="columns field-body">
          <div className="column">
            <TextInput
              name="percentage"
              label={t('interestRate')}
              value={percentage}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              min="0"
              step="0.01"
              iconPosition="is-right"
              icon="FaPercent"
            />
          </div>
          <div className="column">
            <SelectSingle
              name="period"
              label={t('period')}
              //placeholder={t('SelectOption')}
              onChange={handleSelectChange}
              options={
                [{
                  label: t('yearly'),
                  value: 0,
                }, {
                  label: t('monthly'),
                  value: 1,
                }, {
                  label: t('daily'),
                  value: 2,
                },]}
              value={period}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="FaRegUser"
              iconPosition="is-left"
              isClearable={false}
            />
          </div>
        </div>
      </div>

    </Form>
  </Panel>
);

PersonForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  documentType: PropTypes.number.isRequired,
  password: PropTypes.string.isRequired,
  persons: PropTypes.arrayOf(Object).isRequired,
  baseDays: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  client: PropTypes.bool,
  adminName: PropTypes.string,
  adminLastname: PropTypes.string.isRequired,
  adminDocumentNumber: PropTypes.string.isRequired,
  adminDocumentType: PropTypes.number.isRequired,
  adminPosition: PropTypes.string,
  receiverDiscountShare: PropTypes.number.isRequired,
  handlePercentageChange: PropTypes.func.isRequired,
  clientPayment: PropTypes.number.isRequired,
};

PersonForm.defaultProps = {
  discountRate: 0,
  client: false,
  adminName: '',
  adminLastname: '',
  adminPosition: '',
};

export default PersonForm;
