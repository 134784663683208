import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AccountDetails,
  AccountProfile,
  AssignRoute,
  Banks,
  BankUserList,
  CommerceCode,
  CreateBank,
  CreateCustomer,
  CreateFinancingProducts,
  CreateFlowApproval,
  CreateLead,
  CreateProfile,
  CreateProvider,
  CreateRole,
  CreateRoute,
  CreateUser,
  CustomerCode,
  Customers,
  Dashboard,
  Deposit,
  EditBankUser,
  Extract,
  FinancingProducts,
  FlowApproval,
  InvoiceDetail,
  InvoiceGroup,
  Invoices,
  Layout,
  Leads,
  LogView,
  OperationLog,
  PaymentConditions,
  PrepaidCard,
  Providers,
  Recharge,
  RecoverPassword,
  RelatedUsers,
  Reports,
  Roles,
  Routes,
  SearchUser,
  SelectRole,
  SignIn,
  TermsComponent,
  Transactions,
  Transfer,
  UserProfile,
  ConciliationReport,
} from '../scenes';

const mapStateToProps = ({ auth }) => ({
  auth,
});

export const privateRoute = WrappedComponent => connect(mapStateToProps)(
  ({ auth, ...rest }) => (auth.logged === true
    ? <WrappedComponent auth={auth} {...rest} />
    : <Redirect to="/login" />
  ),
);

export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      <route.component {...props} {...route} />
    )}
  />
);

export const NotFound = () => (
  <Redirect to="/dashboard" />
);

const routes = [
  {
    path: '/login',
    component: SignIn,
    exact: true,
  },
  {
    path: '/recover-password',
    component: RecoverPassword,
    exact: true,
  },
  {
    path: '/tyc',
    component: TermsComponent,
    exact: true,
  },
  {
    path: '/',
    component: privateRoute(Layout),
    routes: [
      {
        path: '/create-provider',
        component: privateRoute(CreateProvider),
        exact: true,
      },
      {
        path: '/edit-provider',
        component: privateRoute(CreateProvider),
        exact: true,
      },
      {
        path: '/create-customer',
        component: privateRoute(CreateCustomer),
        exact: true,
      },
      {
        path: '/edit-customer',
        component: privateRoute(CreateCustomer),
        exact: true,
      },
      {
        path: '/create-profile',
        component: privateRoute(CreateProfile),
        exact: true,
      },
      {
        path: '/dashboard',
        component: privateRoute(Dashboard),
        exact: true,
      },
      {
        path: '/account-details',
        component: privateRoute(AccountDetails),
        exact: true,
      },
      {
        path: '/extract',
        component: privateRoute(Extract),
        exact: true,
      },
      {
        path: '/prepaid-Card',
        component: privateRoute(PrepaidCard),
        exact: true,
      },
      {
        path: '/prepaid-Card/recharge',
        component: privateRoute(Recharge),
        exact: true,
      },
      {
        path: '/transfer',
        component: privateRoute(Transfer),
        exact: true,
      }, {
        path: '/deposit',
        component: privateRoute(Deposit),
        exact: true,
      }, {
        path: '/load-invoice',
        component: privateRoute(Providers),
        exact: true,
      }, {
        path: '/load-invoice-group',
        component: privateRoute(InvoiceGroup),
        exact: true,
      }, {
        path: '/list-invoice',
        component: privateRoute(InvoiceGroup),
        exact: true,
      }, {
        path: '/customers',
        component: privateRoute(Customers),
        exact: true,
      }, {
        path: '/invoices',
        component: privateRoute(Invoices),
        exact: true,
      }, {
        path: '/expired-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/related-users',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/related-users-client',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/clients',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/providers',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/register-bank',
        component: privateRoute(CreateBank),
        exact: true,
      },
      {
        path: '/bank-list',
        component: privateRoute(Banks),
        exact: true,
      },
      {
        path: '/role-list',
        component: privateRoute(Roles),
        exact: true,
      },
      {
        path: '/log-view',
        component: privateRoute(LogView),
        exact: true,
      },
      {
        path: '/invoice-detail',
        component: privateRoute(InvoiceDetail),
        exact: true,
      },
      {
        path: '/register-financing-products',
        component: privateRoute(CreateFinancingProducts),
        exact: true,
      },
      {
        path: '/financing-products',
        component: privateRoute(FinancingProducts),
        exact: true,
      },
      {
        path: '/register-role',
        component: privateRoute(CreateRole),
        exact: true,
      },
      {
        path: '/route-list',
        component: privateRoute(Routes),
        exact: true,
      },
      {
        path: '/register-route',
        component: privateRoute(CreateRoute),
        exact: true,
      },
      {
        path: '/admin-routes',
        component: privateRoute(AssignRoute),
        exact: true,
      },
      {
        path: '/review-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/reject-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/approved-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/paid-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/create-lead',
        component: privateRoute(CreateLead),
        exact: true,
      },
      {
        path: '/lead-list',
        component: privateRoute(Leads),
        exact: true,
      },
      {
        path: '/reports',
        component: privateRoute(Reports),
        exact: true,
      },
      {
        path: '/transactions',
        component: privateRoute(Transactions),
        exact: true,
      },
      {
        path: '/profile-list',
        component: privateRoute(UserProfile),
        exact: true,
      },
      {
        path: '/create-approval-flow',
        component: privateRoute(CreateFlowApproval),
        exact: true,
      },
      {
        path: '/edit-approval-flow',
        component: privateRoute(CreateFlowApproval),
        exact: true,
      },
      {
        path: '/approval-flow-list',
        component: privateRoute(FlowApproval),
        exact: true,
      },
      {
        path: '/account-profile',
        component: privateRoute(AccountProfile),
        exact: true,
      },
      {
        path: '/payment-conditions',
        component: privateRoute(PaymentConditions),
        exact: true,
      },
      {
        path: '/create-bank-user',
        component: privateRoute(CreateUser),
        exact: true,
      },
      {
        path: '/edit-bank-user',
        component: privateRoute(EditBankUser),
        exact: true,
      },
      {
        path: '/bank-user-list',
        component: privateRoute(BankUserList),
        exact: true,
      },
      {
        path: '/operation-log',
        component: privateRoute(OperationLog),
        exact: true,
      },
      {
        path: '/commerce-code',
        component: privateRoute(CommerceCode),
        exact: true,
      },
      {
        path: '/user-commerce-code',
        component: privateRoute(CustomerCode),
        exact: true,
      },
      {
        path: '/select-role',
        component: privateRoute(SelectRole),
        exact: true,
      },
      {
        path: '/search-user',
        component: privateRoute(SearchUser),
        exact: true,
      },
      {
        path: '/conciliation',
        component: privateRoute(ConciliationReport),
        exact: true,
      },
    ],
  },
  {
    component: NotFound,
  },
];

export default routes;
