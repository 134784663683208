import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { CreatableMultiSelect, Loading, Panel, SelectSingle, } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import { routeActions } from '../../state/ducks/route';


class AssignRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => {
    const { rolesRoutes: propsRolesRoutes } = this.props;
    return {
      rolesRoutes: propsRolesRoutes,
      role: '',
      route: '',
      assignLoading: false,
    };
  };

  componentDidMount() {
    const { fetchRoutesByType, fetchRoles, auth: { user: { user_id, b2b: { type_user } } } } = this.props;

    fetchRoles({
      params: {
        owner_id: user_id,
      },
    });
    fetchRoutesByType({
      params: {
        type: type_user
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    const { fetchRoutesRole } = this.props;
    this.setState({
      [label.name]: newVal.value,
    });
    if(newVal.value !== "") {
      fetchRoutesRole({
        params: {
          role_id: newVal.value,
          isFull: true,
        },
        callback: responseData => this.setState({ rolesRoutes: responseData.data }),
      });
    }
    else{
      this.setState({ rolesRoutes: [] });
    }
  };

  handleItemChange = (newValue, action) => {
    const { role } = this.state;
    const {
      createRouteRole,
      deleteRouteRole,
      showMessage,
      t,
    } = this.props;
    if (role !== '') {
      if (action.action === 'select-option') {
        createRouteRole({
          data: {
            role_id: role,
            route_id: action.option.id,
          },
          callback: () => {
            this.setState({ rolesRoutes: newValue });
            showMessage({
              message: t('assignRouteSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
      } else if (action.action === 'remove-value') {
        deleteRouteRole({
          params: {
            role_id: role,
            route_id: action.removedValue.id,
          },
          callback: () => {
            this.setState({ rolesRoutes: newValue });
            showMessage({
              message: t('removeRouteSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
      }
    } else {
      showMessage({
        message: t('roleCantBeEmpty'),
        config: TOAST_CONFIG.SUCCESS,
      });
    }
  };

  createAssignRoute = () => {
    const { saveAssignRoute, t, showMessage } = this.props;
    const { role, route } = this.state;

    if (role !== null && role !== '' && route !== null && route !== '') {
      const data = {
        role_id: role,
        route_id: route,
      };
      this.setState({ assignLoading: true });
      saveAssignRoute({
        data,
        callback: () => {
          showMessage({
            message: t('assignRoleRoute'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    } else {
      showMessage({
        message: t('invalidRoleOrRoute'),
        config: TOAST_CONFIG.WARNING,
      });
    }
  };

  render() {
    const {
      t,
      route: {
        list: routeList,
        loading: routeLoading,
      },
      role: {
        list: roleList,
        loading: roleLoading,
      },
    } = this.props;
    const { assignLoading, rolesRoutes } = this.state;
    const loading = roleLoading || routeLoading || assignLoading;
    return (

      <div>
        {loading && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <Panel headingText={t('assignRoute')}>

              {/* <div className="field is-horizontal subtitle is-5 text-black">
                  {t('demographicInformation')}
                </div> */}
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-tree-fifths">
                    <SelectSingle
                      name="role"
                      label={t('roles')}
                      selectClasses="is-fullwidth"
                      placeholder={t('Select')}
                      onChange={this.handleSelectChange}
                      options={roleList ? [
                        {
                          label: t('Select'),
                          value: '',
                        },
                        ...roleList.map(item => ({
                          label: item.name,
                          value: item.id,
                        })),
                      ] : []}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-tree-fifths">
                    <CreatableMultiSelect
                      name="route"
                      label={t('routes')}
                      value={rolesRoutes}
                      onChange={this.handleItemChange}
                      placeholder={t('selectOrTypeRoutes')}
                      noOptionsMessage={() => t('noItemsAvailable')}
                      formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
                      getOptionLabel={option => ` (${t(option.path)}) `}
                      getOptionValue={option => option.id}
                      getNewOptionData={(inputValue, optionLabel) => ({
                        id: optionLabel,
                        description: optionLabel,
                      })
                      }
                      controlClasses="is-expanded"
                      options={routeList}
                    />
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>

    );
  }
}

AssignRoute.propTypes = {
  role: PropTypes.shape(Object).isRequired,
  rolesRoutes: PropTypes.shape(Object).isRequired,
  route: PropTypes.shape(Object).isRequired,
  fetchRoutesRole: PropTypes.func.isRequired,
  createRouteRole: PropTypes.func.isRequired,
  deleteRouteRole: PropTypes.func.isRequired,
  fetchRoutes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  saveAssignRoute: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
  auth,
  role,
  route,
}) => ({
  auth,
  role,
  route,
});
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...routeActions,
  }),
  withNamespaces(),
)(AssignRoute);
