const START_FETCH = 'log/START_FETCH';
const END_FETCH = 'log/END_FETCH';
const API_CALL = 'log/API_CALL';
const MESSAGE = 'log/MESSAGE';
const GET_LOG = 'log/GET_LOG';
const FETCH_LOG = 'log/FETCH_LOG';
const EVENT_LOG = 'log/EVENT_LOG';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  GET_LOG,
  FETCH_LOG,
  EVENT_LOG,
};