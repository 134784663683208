/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, LoadInvoicesForm, Modal, Panel, TableList, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { authActions } from '../../state/ducks/auth';
import { TransferDetail } from './components';
import { TOAST_CONFIG } from '../../config/constants';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import './styles.scss';
import { customIcon } from '../../util';

class Providers extends Component {
  constructor(props) {
    super(props);
    const { t, transactions: { currency } } = props;
    this.state = {
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      exempt_amount: null,
      tax_amount: null,
      tax_sum: null,
      exempt_sum: null,
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
      file: undefined,
      binaryFile: null,
    };
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    this.setState({
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      showForm: true,
      uploadQrLoading: false,
      detail_amount: null,
      inputValue: '',
      exempt_amount: null,
      tax_amount: null,
      tax_sum: null,
      exempt_sum: null,
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
      file: undefined,
      binaryFile: null,
    });
  };

  componentDidMount() {
    const {
      getClients,
      auth: { user: { b2b: { id } } },
    } = this.props;
    this.getInitialState();
    getClients({
      params: {
        id,
        related: true,
      },
    });
  }

  resetState = () => {
    this.getInitialState();
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    if (name === 'invoice_amount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleExemptChange = (event) => {

    const {
      target: {
        checked, value: targetValue, type,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    let num = parseFloat(value.replace(/,/g, ''));
    
    this.setState({
      exempt_amount: value,
      exempt_sum: num,
      invoice_amount: num + this.state.tax_sum,
    });

  }

  handleTaxChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    let num = parseFloat(value.replace(/,/g, ''));
    
    this.setState({
      tax_amount: value,
      tax_sum: num,
      invoice_amount: num + this.state.exempt_sum,
    });
  }

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const { name } = event.target;
    const files = Array.from(event.target.files);
    if(files.length > 0){
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: t('fileSizeExceeded'),
          config: TOAST_CONFIG.WARNING,
        });
      } else {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      }
    }else{
      this.setState({
        binaryFile: null,
        [name]: undefined
      });
    }
  };

  qrUploadInvoiceFile = (event) => {
    const {
      readInvoiceQr,
      clients: { list: clientList },
      auth: {
        user: {
          user_id,
        },
      },
    } = this.props;
    const files = Array.from(event.target.files);
    this.setState({
      uploadQrLoading: true,
      binaryFile: files[0],
      file: null,
    }, () => readInvoiceQr({
      params: {
        provider_id: user_id,
      },
      callback: ({
                  invoice_date,
                  invoice_number,
                  invoice_amount,
                  invoice_description,
                  client,
                  detail_amount,
                }) => setTimeout(
        () => this.setState({
          uploadQrLoading: false,
          showForm: true,
          invoice_date: new Date(invoice_date),
          invoice_number,
          invoice_amount: String(invoice_amount),
          invoice_description,
          client: client === '' ? null : clientList.map((mapClient, index) => ({ index, ...mapClient }))
            // eslint-disable-next-line eqeqeq
            .filter(rowClient => rowClient.id_person == client)[0].index,
          detail_amount,
        }),
        3000,
      ),
    }));
  };

  formatDate = (date) => {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    return day + '/' + month + '/' + date.getFullYear();
  };

  handleDateChange = (newDate) => {
    const {
      t,
      showMessage,
    } = this.props;
    const f = new Date();
    const dateNow = this.formatDate(f);

    if (newDate.setHours(0, 0, 0, 0) < f.setHours(0, 0, 0, 0)) {
      showMessage({
        message: t('incorrectDate', { dateNow }),
        config: TOAST_CONFIG.WARNING,
      });
    } else {
      this.setState({
        invoice_date: newDate,
      });
    }
  };

  handleClientChange = (newDate) => {
    const {
      auth: { user: { b2b: { id } } },
      getPaymentCommerceCode,
      clients: { list },
      getDiscountRate,
    } = this.props;

    getDiscountRate({
      params: {
        client: list[newDate.value].id,
        provider: id,
      },
    });

    getPaymentCommerceCode({
      params: {
        sender: list[newDate.value].id,
        receiver: id,
      },
      callback: (response) => {
        this.setState({
          commerceCodes: response,
          commerceCode: -1,
          descriptionCommerceCode: '',
        });
      }
    });

    this.setState({
      client: newDate.value,
    });
  };

  handleCommerceChange = (newVal, label) => {
    const {
      providers: { list },
    } = this.props;

    this.setState({
      [label.name]: newVal.value,
      descriptionCommerceCode: list[newVal.value].commerce_code,
    });
  };

  onSubmit = () => {
    const {
      client,
      invoice_amount,
      invoice_date,
      invoice_number,
      invoice_description,
      binaryFile,
      detail_amount,
      exempt_amount,
      tax_amount,
      commerceCode,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          b2b: {
            id,
          },
        },
      },
      providers: { list },
      clients,
      loadInvoice,
      history,
      showMessage,
    } = this.props;
    

    let exempt_send = exempt_amount;
    let tax_send = tax_amount;
    
    if(exempt_send === null){
      exempt_send = "0.00";
    }

    if(tax_send === null){
      tax_send = "0.00";
    }

    const data = {
      buyer: clients.list[client].id,
      seller: id,
      amount: invoice_amount,
      detail_amount,
      date: invoice_date,
      invoice_number,
      description: invoice_description,
      file: binaryFile,
      exempt_amount: parseFloat(exempt_send.replace(/,/g, '')),
      tax_amount: parseFloat(tax_send.replace(/,/g, '')),
      commerce_code: list[commerceCode].id,
    };

    if (data.amount < 0.1) {
      showMessage({
        message: t('amountValidate'),
        config: TOAST_CONFIG.WARNING,
      });
    } else {
      loadInvoice({
        data,
        callback: () => {
          history.push('/review-invoices');
          showMessage({
            message: t('createInvoiceSuccess', { invoice_number }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
        t,
      });
    }
  };

  handleSubmit = (event, total) => {
    event.preventDefault();
    const { ...data } = this.state;
    const { transfer, transactions: { balance } } = this.props;

    if (parseFloat(total) <= parseFloat(balance)) {
      transfer({
        data,
        callback: (detailTransfer) => {
          this.setState(() => ({
            detailTransfer,
          }));
        },
      });
    } else {
      const { showMessage, t } = this.props;
      showMessage({
        message: t('invalidAmount'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  onFinish = () => {
    const { checkBalance } = this.props;
    this.setState(() => ({
      detailTransfer: null,
      amount: '',
      destination_phone_number: '',
    }));
    checkBalance();
  };

  validateEmptyFields = () => {
    const {
      invoice_amount, client, invoice_number, invoice_description, commerceCode,
    } = this.state;
    return (invoice_amount === null || invoice_amount === '' || invoice_number === null ||
      invoice_description === '' || invoice_number === '' || false || client === null ||
      commerceCode === -1);
  };

  render() {
    const {
      t,
      transactions: { balance, charge, loading: transactionLoading },
      auth: { contacts },
      clients,
      providers: { loading: providerLoading },
    } = this.props;
    const {
      amount,
      detailTransfer,
      client,
      invoice_amount,
      invoice_date,
      invoice_description,
      invoice_number,
      file,
      binaryFile,
      showForm,
      uploadQrLoading,
      detail_amount,
      inputValue,
      exempt_amount,
      tax_amount,
      commerceCode,
      commerceCodes,
      descriptionCommerceCode,
    } = this.state;
    const loading = transactionLoading || providerLoading || uploadQrLoading;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const total = parseFloat(amount) + charge_amount;
    const disabledForm = this.validateEmptyFields();

    if (detailTransfer) {
      return (
        <TransferDetail
          detailTransfer={detailTransfer}
          onFinish={this.onFinish}
        />
      );
    }

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => (
          <Fragment>
            {loading && <Loading/>}
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <TableList
                    title={t('confirmTransfer')}
                    head={(
                      <tr>
                        <th>{t('transactionType')}</th>
                        <th>{t('recipient')}</th>
                        <th>{t('amount')}</th>
                        <th>{t('totalAmount')}</th>
                        <th>{t('concept')}</th>
                      </tr>
                    )}
                  >
                    <tr key={toggleProps.text}>
                      <td>{t(`${toggleProps.transaction_type}`)}</td>
                      <td>{toggleProps.destination_phone_number}</td>
                      <td>{`${toggleProps.product} ${toggleProps.amount}`}</td>
                      <td>{`${toggleProps.product} ${total}`}</td>
                      <td>{toggleProps.detail}</td>
                    </tr>
                    <tr>
                      <th colSpan="5">
                        <div className="field is-grouped is-grouped-right">
                          <p className="control">
                            <button
                              type="button"
                              className="button margin-right-5"
                              onClick={onToggle}
                            >
                              {t('goBack')}
                            </button>
                          </p>
                          <p className="control">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={(event) => {
                                this.handleSubmit(event, total);
                                onToggle();
                              }}
                            >
                              {t('confirm')}
                            </button>
                          </p>
                        </div>
                      </th>
                    </tr>
                  </TableList>
                </Modal>
              )
            }
            {showForm && (
              <LoadInvoicesForm
                formData={{ ...this.state }}
                onToggle={onToggle}
                toggle={toggled}
                toggleProps={toggleProps}
                disabled={disabledForm}
                balance={balance}
                handleInputChange={this.handleInputChange}
                handleFileChange={this.handleFileChange}
                handleDateChange={this.handleDateChange}
                handleClientChange={this.handleClientChange}
                handleSelectChange={this.handleCommerceChange}
                contacts={contacts}
                chargeAmount={charge_amount}
                invoiceAmount={invoice_amount}
                invoiceDate={invoice_date}
                invoiceNumber={invoice_number}
                description={invoice_description}
                clients={clients.list}
                selectedClient={client}
                onSubmit={this.onSubmit}
                file={file}
                fileName={binaryFile && binaryFile.name}
                detailAmount={detail_amount}
                handleOnInputChange={this.handleOnInputChange}
                inputValue={inputValue}
                exemptAmount={exempt_amount}
                taxAmount={tax_amount}
                handleExemptChange={this.handleExemptChange}
                handleTaxChange={this.handleTaxChange}
                commerceCode={commerceCode}
                commerceCodes={commerceCodes}
                descriptionCommerceCode={descriptionCommerceCode}
              />
            )}
            {!showForm && (
              <Panel headingText={t('loadInvoice')}>
                <div className="columns">
                  <div className="column">
                    <div className="file has-name">
                      <label htmlFor="resume" className="file-label">
                        <input className="file-input" type="file" name="resume"
                              onChange={this.qrUploadInvoiceFile}/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="button is-outlined is-info is-large is-fullwidth">
                          <span className="">{t('uploadInvoice')}</span>
                          <span className="icon is-small">
                            {customIcon('FaUpload')}
                          </span>
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="column">
                    <button className="button is-outlined is-success is-large is-fullwidth"
                            onClick={() => this.setState({ showForm: true })}>
                      <span>{t('loadInvoiceData')}</span>
                      <span className="icon is-small">
                        {customIcon('FaEdit')}
                      </span>
                    </button>
                  </div>
                </div>
              </Panel>
            )}
          </Fragment>
        )}
      </Toggle>
    );
  }
}

Providers.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  transfer: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  readInvoiceQr: PropTypes.func.isRequired,
  clients: PropTypes.shape(Object).isRequired,
  loadInvoice: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                          transactions,
                          clients,
                          auth,
                          providers,
                          paymentConditions,
                        }) => (
  {
    transactions,
    clients,
    auth,
    providers,
    paymentConditions,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
  }),
  withNamespaces(),
)(Providers);
