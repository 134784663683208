/**
 * Created by Jonas on 29/08/19.
*/

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';

const RoleForm = ({
  t,
  onSubmit,
  handleInputChange,
  name,
  editable,
  handleSelectChange,
  typeList,
  roleType,
  checked
}) => (
  <Panel headingText={editable ? t('edit') : t('createRol') } >
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      {/* <div className="field is-horizontal subtitle is-5 text-black">
                {t('demographicInformation')}
              </div> */}
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifths">
            <TextInput
              name="name"
              label={t('rolName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              maxlength={20}
              controlClasses="is-expanded"
              title={t('rolName')}
              isRequired
            />
          </div>
        </div>
      </div>
      {
        roleType !== 'seller' &&
          <div className="field is-horizontal" style={ {alignItems: "baseline"} }>
            <input 
            type="checkbox" 
            name="is_approver"
            onChange={handleInputChange}
            checked={checked === 1 ? true : false}
            style={ {marginRight: "10px"} } 
            />
            <p>{t('invoicesApproverCheckbox')}</p>
          </div>
      }
    </Form>
  </Panel>
);

RoleForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RoleForm;
